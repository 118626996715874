export type LineItemType = {
  clientId: string;
  customerId: string;
  invoiceId: string;
  sourceId: string;
  date: Date;
  itemCode: string;
  itemDescription: string;
  amount: number;
  type: INVOICE_LINE_ITEM_TYPE;
  metadata: any;
};

export enum INVOICE_LINE_ITEM_TYPE {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  VOIDED = 'VOIDED',
}
