const ConnectionLostIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='491' height='338' viewBox='0 0 491 338' fill='none'>
      <g clipPath='url(#clip0_6158_45110)'>
        <path
          d='M205.3 315.5H133.2C115.4 315.5 112 319.7 119.5 322.8C127 325.9 91.0999 324.4 84.9999 329.9C78.7999 335.4 94.5999 337.5 111.7 337.5H249.1C262.8 337.5 259.4 327.5 251.2 326.2C243 324.9 264.5 326.7 272.7 323.8C280.9 320.9 275 315.5 205.3 315.5Z'
          fill='#99ADF9'
        />
        <path
          d='M310.5 333.8C308.4 336.7 301.4 337.4 289.5 337.1C280.5 336.8 271.1 336.1 271.1 331.9C271.1 327.7 294 328.2 302 329.1C308.9 329.9 311.7 332.1 310.5 333.8Z'
          fill='#C5D3FF'
        />
        <path
          d='M103.298 319.8C101.898 321.9 97.0979 322.5 88.6979 322.5C82.3979 322.5 75.7979 322.2 75.5979 319.3C75.3979 316.3 91.4979 316.2 97.1979 316.7C101.998 317.1 104.098 318.5 103.298 319.8Z'
          fill='#C5D3FF'
        />
        <path
          d='M455.4 313H419.7C418.349 312.994 417.055 312.455 416.1 311.5C415.145 310.545 414.606 309.251 414.6 307.9V271C414.606 269.649 415.145 268.355 416.1 267.4C417.055 266.445 418.349 265.906 419.7 265.9H455.4C456.751 265.906 458.045 266.445 459 267.4C459.955 268.355 460.494 269.649 460.5 271V307.9C460.494 309.251 459.955 310.545 459 311.5C458.045 312.455 456.751 312.994 455.4 313Z'
          fill='#C5DFFF'
        />
        <path
          d='M453.7 311.3H419.4C418.101 311.298 416.856 310.781 415.938 309.862C415.019 308.944 414.502 307.699 414.5 306.4V270.8C414.502 269.501 415.019 268.256 415.938 267.338C416.856 266.419 418.101 265.902 419.4 265.9H453.7C454.999 265.902 456.244 266.419 457.162 267.338C458.081 268.256 458.598 269.501 458.6 270.8V306.4C458.598 307.699 458.081 308.944 457.162 309.862C456.244 310.781 454.999 311.298 453.7 311.3Z'
          fill='#E1ECFF'
        />
        <path
          d='M436.9 303.4C444.853 303.4 451.3 296.953 451.3 289C451.3 281.047 444.853 274.6 436.9 274.6C428.947 274.6 422.5 281.047 422.5 289C422.5 296.953 428.947 303.4 436.9 303.4Z'
          fill='#C5DFFF'
        />
        <path
          d='M431.2 295.5C430.961 295.5 430.732 295.405 430.564 295.236C430.395 295.068 430.3 294.839 430.3 294.6V283.5C430.3 283.261 430.395 283.032 430.564 282.864C430.732 282.695 430.961 282.6 431.2 282.6V282.6C431.439 282.6 431.668 282.695 431.836 282.864C432.005 283.032 432.1 283.261 432.1 283.5V294.6C432.1 294.839 432.005 295.068 431.836 295.236C431.668 295.405 431.439 295.5 431.2 295.5Z'
          fill='#1F1933'
        />
        <path
          d='M441.6 295.5C441.361 295.5 441.132 295.405 440.964 295.236C440.795 295.068 440.7 294.839 440.7 294.6V283.5C440.7 283.261 440.795 283.032 440.964 282.864C441.132 282.695 441.361 282.6 441.6 282.6V282.6C441.839 282.6 442.068 282.695 442.236 282.864C442.405 283.032 442.5 283.261 442.5 283.5V294.6C442.5 294.839 442.405 295.068 442.236 295.236C442.068 295.405 441.839 295.5 441.6 295.5Z'
          fill='#1F1933'
        />
        <path
          d='M349.7 250.6C348.999 250.617 348.297 250.583 347.6 250.5C332.1 249.4 321.5 244.1 303.7 217C297.5 207.6 293.3 198 289.2 188.7C282.2 172.8 276.2 159 262.6 152.6C241.7 142.8 221.2 159 221 159.2C220.574 159.531 220.036 159.683 219.5 159.622C218.964 159.561 218.473 159.292 218.133 158.873C217.793 158.455 217.63 157.919 217.68 157.382C217.73 156.845 217.989 156.349 218.4 156C219.3 155.3 241.1 138 264.3 148.9C279.3 155.9 285.9 171 292.9 187C296.9 196.2 301.1 205.7 307.1 214.7C324 240.3 333.7 245.3 347.9 246.4C358.5 247.2 364.2 240.8 369.7 234.5C371.539 232.347 373.51 230.31 375.6 228.4C380.428 224.19 386.3 221.357 392.6 220.2C392.863 220.161 393.131 220.173 393.388 220.237C393.646 220.302 393.889 220.416 394.102 220.574C394.316 220.732 394.496 220.93 394.633 221.157C394.77 221.385 394.861 221.637 394.9 221.9C394.94 222.163 394.927 222.43 394.863 222.688C394.799 222.946 394.684 223.189 394.526 223.402C394.369 223.616 394.17 223.796 393.943 223.933C393.715 224.07 393.463 224.161 393.2 224.2C387.694 225.327 382.565 227.84 378.3 231.5C376.344 233.277 374.506 235.181 372.8 237.2C367.2 243.6 361 250.6 349.7 250.6Z'
          fill='#1F1933'
        />
        <path
          d='M407.9 213.3L416.3 206.2C416.501 206.029 416.734 205.901 416.986 205.824C417.238 205.746 417.503 205.721 417.765 205.749C418.027 205.777 418.28 205.858 418.51 205.987C418.739 206.116 418.94 206.291 419.1 206.5C419.395 206.888 419.529 207.376 419.473 207.86C419.417 208.345 419.176 208.789 418.8 209.1L410.3 216.6L407.9 213.3Z'
          fill='#1F1933'
        />
        <path
          d='M402.7 207.3L411.1 200.2C411.301 200.029 411.534 199.901 411.786 199.824C412.038 199.746 412.303 199.721 412.565 199.749C412.827 199.777 413.08 199.858 413.31 199.987C413.539 200.116 413.74 200.291 413.9 200.5C414.195 200.888 414.329 201.376 414.273 201.86C414.217 202.345 413.976 202.789 413.6 203.1L405.1 210.6L402.7 207.3Z'
          fill='#1F1933'
        />
        <path
          d='M413.8 217C411.187 219.981 408.381 222.787 405.4 225.4C400.3 229.8 393.7 229 390.7 225.6C390.5 225.4 390.4 225.2 390.2 225C388.809 223.233 388.157 220.996 388.381 218.758C388.604 216.52 389.687 214.456 391.4 213C395.5 209.2 402.3 203.8 402.3 203.8L410.5 213.3L413.8 217Z'
          fill='#E1ECFF'
        />
        <path
          d='M413.8 217C411.187 219.981 408.381 222.787 405.4 225.4C400.3 229.8 393.7 229 390.7 225.6C390.5 225.4 390.4 225.2 390.2 225C393.82 225.014 397.365 223.972 400.4 222C403.969 219.382 407.314 216.472 410.4 213.3L413.8 217Z'
          fill='#C5DFFF'
        />
        <path
          d='M260.8 293.6H260.4C253.6 293.5 235.9 280.2 231.4 274C226.8 267.8 222.7 259.9 228.3 254.8C229.582 253.723 231.142 253.03 232.8 252.8C235.867 252.32 239.004 252.998 241.6 254.7C246.4 257.8 259.9 273.5 263.7 280.2C267.4 286.8 267.3 293.5 260.8 293.6Z'
          fill='#1F1933'
        />
        <path
          d='M260.8 293.6C261.8 293 262.5 292 263.1 290C264.1 286.5 260.6 279.5 249.1 266.1C242.2 258 236.3 254.4 232.8 252.8C235.867 252.32 239.004 252.998 241.6 254.7C246.4 257.8 259.9 273.5 263.7 280.2C267.4 286.8 267.3 293.5 260.8 293.6Z'
          fill='#384D89'
        />
        <path
          d='M227 259.101C228 256.301 233.8 256.801 238.8 261.701C243.8 266.601 251.4 276.301 249.5 280.501C247.6 284.701 240.4 281.301 234.5 275.501C228.6 269.701 225.4 263.601 227 259.101Z'
          fill='#6461F3'
        />
        <path
          d='M239.6 271.6C239.52 273.415 239.042 275.19 238.2 276.8C238.2 276.8 211.3 289.6 177.6 266.1C143.3 242.2 143.2 212.4 143.2 212.1C160.8 194.9 180.6 213.8 180.6 213.8C181.656 221.269 183.884 228.525 187.2 235.3C190.3 241.5 194.8 247.8 201.2 252C210.84 258.263 222.41 260.854 233.8 259.3C233.8 259.3 237 260.3 238.9 266.4C239.553 268.05 239.794 269.836 239.6 271.6Z'
          fill='#E1ECFF'
        />
        <path
          d='M239.6 271.6C239.52 273.415 239.042 275.19 238.2 276.8C238.2 276.8 211.3 289.6 177.6 266.1C143.3 242.2 143.2 212.4 143.2 212.1C160.8 194.9 180.6 213.8 180.6 213.8C181.656 221.269 183.884 228.525 187.2 235.3C184.561 237.359 181.629 239.011 178.5 240.2C174.78 241.201 170.951 241.739 167.1 241.8C167.1 241.8 184 267.1 209 271.7C222.5 274 232.9 273 239.6 271.6Z'
          fill='#C5DFFF'
        />
        <path
          d='M201.899 276.698C201.629 276.697 201.366 276.609 201.15 276.447C200.934 276.284 200.776 276.057 200.699 275.798C200.636 275.634 200.607 275.459 200.613 275.284C200.619 275.108 200.661 274.936 200.736 274.777C200.811 274.618 200.917 274.476 201.048 274.359C201.179 274.243 201.333 274.154 201.499 274.098C201.599 274.098 206.999 271.998 208.599 267.598C209.623 265.012 209.729 262.153 208.899 259.498C208.774 259.179 208.777 258.824 208.908 258.507C209.038 258.191 209.286 257.937 209.599 257.798C209.918 257.673 210.273 257.676 210.59 257.807C210.906 257.937 211.161 258.185 211.299 258.498C212.347 261.767 212.241 265.297 210.999 268.498C208.899 274.098 202.599 276.398 202.299 276.498C202.26 276.567 202.201 276.623 202.13 276.659C202.058 276.695 201.978 276.708 201.899 276.698Z'
          fill='#C5DFFF'
        />
        <path
          d='M178.7 266.4C178.535 266.408 178.371 266.383 178.216 266.327C178.061 266.271 177.919 266.184 177.798 266.073C177.677 265.961 177.58 265.826 177.511 265.676C177.443 265.527 177.405 265.365 177.4 265.2C177.397 264.861 177.516 264.532 177.737 264.275C177.958 264.017 178.264 263.848 178.6 263.8C178.7 263.8 185.7 263.3 189.2 258.7C191.291 256.008 192.449 252.708 192.5 249.3C192.473 248.955 192.585 248.614 192.81 248.351C193.035 248.089 193.355 247.927 193.7 247.9C194.045 247.873 194.386 247.985 194.649 248.21C194.911 248.435 195.073 248.755 195.1 249.1C195.151 253.158 193.81 257.111 191.3 260.3C187 265.8 179.1 266.3 178.7 266.4Z'
          fill='#C5DFFF'
        />
        <path
          d='M131.3 247.499C130.88 248.461 130.342 249.368 129.7 250.199C128.585 251.645 127.146 252.81 125.5 253.599C124.369 254.182 123.154 254.587 121.9 254.799C121.9 254.799 107.8 241.099 95.4999 216.699C83.1999 192.399 82.2999 164.399 82.2999 164.399C83.3362 163.837 84.4047 163.336 85.4999 162.899C88.7368 161.615 92.2534 161.202 95.6999 161.699C98.2489 162.017 100.702 162.869 102.9 164.199C102.9 164.199 103.7 187.399 111.9 208.299C120.1 229.299 132.1 241.699 132.1 241.699C132.354 243.669 132.078 245.671 131.3 247.499Z'
          fill='#E1ECFF'
        />
        <path
          d='M131.3 247.5C126.9 243.6 118.5 234.7 110.4 217.5C100.7 196.9 96.7999 170.4 95.7999 161.7C98.3489 162.018 100.802 162.87 103 164.2C103 164.2 103.8 187.4 112 208.3C120.2 229.3 132.2 241.7 132.2 241.7C132.387 243.678 132.078 245.672 131.3 247.5Z'
          fill='#F8FBFF'
        />
        <path
          d='M125.5 253.6C124.369 254.183 123.154 254.588 121.9 254.8C121.9 254.8 107.8 241.1 95.4999 216.7C83.1999 192.4 82.2999 164.4 82.2999 164.4C83.3362 163.838 84.4047 163.337 85.4999 162.9C86.1999 171.9 89.4999 201.5 103.5 224.7C113.1 240.5 120.8 249.1 125.5 253.6Z'
          fill='#C5DFFF'
        />
        <path
          d='M153.4 268C149.948 269.871 145.949 270.472 142.1 269.7C136 268.3 133.7 266.3 133.7 266.3C133.7 266.3 131.7 263.3 133.2 261.6L133.4 261.4C134.688 260.333 136.245 259.641 137.9 259.4C139.803 260.889 141.886 262.132 144.1 263.1C146.7 264 151.3 263.1 153 264.2C153.337 264.446 153.592 264.786 153.735 265.178C153.877 265.57 153.9 265.995 153.8 266.4C153.794 266.957 153.657 267.505 153.4 268Z'
          fill='#E1ECFF'
        />
        <path
          d='M153.4 268C149.948 269.871 145.949 270.472 142.1 269.7C136 268.3 133.7 266.3 133.7 266.3C133.7 266.3 131.7 263.3 133.2 261.6C135 263.3 139.3 267 144.3 267.6C147.517 267.994 150.782 267.582 153.8 266.4C153.794 266.957 153.657 267.505 153.4 268Z'
          fill='#C5DFFF'
        />
        <path
          d='M151.5 265.2C147.474 265.863 143.343 265.122 139.8 263.1C138.23 262.319 136.888 261.148 135.9 259.7C135.907 259.046 136.043 258.401 136.3 257.8C136.557 257.199 136.931 256.656 137.4 256.2C138.2 255.5 139.5 255.2 141.5 255.8C141.5 255.8 145.4 259.2 151.9 256.9C158.4 254.6 158 253.2 159.4 252.8C160.4 252.5 161.7 254 162 255.7C162.188 256.468 162.117 257.276 161.8 258C160.671 259.844 159.173 261.436 157.401 262.675C155.629 263.913 153.62 264.773 151.5 265.2Z'
          fill='#E1ECFF'
        />
        <path
          d='M151.5 265.2C147.474 265.863 143.343 265.122 139.8 263.1C138.23 262.319 136.888 261.148 135.9 259.7C135.907 259.046 136.043 258.401 136.3 257.8C136.557 257.199 136.931 256.656 137.4 256.2C139.8 258.6 145 262.9 150.7 261.8C154.992 260.863 158.939 258.751 162.1 255.7C162.288 256.468 162.217 257.276 161.9 258C160.737 259.835 159.217 261.418 157.43 262.655C155.644 263.892 153.627 264.757 151.5 265.2Z'
          fill='#C5DFFF'
        />
        <path
          d='M150.4 258.601C146.58 259.251 142.653 258.544 139.3 256.601C137.799 255.89 136.52 254.783 135.6 253.401C135.615 252.13 136.116 250.914 137 250.001C137.8 249.301 139 249.001 140.9 249.601C140.9 249.601 144.6 252.801 150.8 250.601C157 248.401 156.6 247.101 157.9 246.701C158.8 246.401 160.1 247.901 160.4 249.501C160.583 250.236 160.513 251.011 160.2 251.701C159.142 253.474 157.722 255.005 156.034 256.194C154.346 257.382 152.426 258.203 150.4 258.601Z'
          fill='#E1ECFF'
        />
        <path
          d='M150.4 258.601C146.58 259.251 142.653 258.544 139.3 256.601C137.799 255.89 136.52 254.783 135.6 253.401C135.615 252.13 136.116 250.914 137 250.001C139.2 252.301 144.2 256.401 149.6 255.301C153.658 254.378 157.39 252.374 160.4 249.501C160.583 250.236 160.513 251.011 160.2 251.701C159.142 253.474 157.722 255.005 156.034 256.194C154.346 257.382 152.426 258.203 150.4 258.601Z'
          fill='#C5DFFF'
        />
        <path
          d='M149.699 253.6C146.379 254.722 142.772 254.651 139.499 253.4C138.084 252.974 136.807 252.18 135.799 251.1C135.719 250.533 135.758 249.956 135.913 249.406C136.068 248.855 136.336 248.342 136.699 247.9C137.299 247.2 138.399 246.8 140.199 247C140.199 247 143.899 249.3 149.199 246.6C154.499 243.8 153.999 242.7 155.099 242.1C155.899 241.7 157.199 242.8 157.699 244.2C157.934 244.841 157.968 245.539 157.799 246.2C156.169 249.63 153.262 252.286 149.699 253.6Z'
          fill='#E1ECFF'
        />
        <path
          d='M149.699 253.6C146.379 254.722 142.772 254.651 139.499 253.4C138.084 252.974 136.807 252.18 135.799 251.1C135.719 250.533 135.758 249.956 135.913 249.406C136.068 248.855 136.336 248.342 136.699 247.9C138.999 249.6 143.899 252.6 148.599 250.9C152.166 249.552 155.285 247.231 157.599 244.2C157.834 244.841 157.868 245.539 157.699 246.2C156.93 247.907 155.825 249.441 154.451 250.713C153.076 251.984 151.46 252.966 149.699 253.6Z'
          fill='#C5DFFF'
        />
        <path
          d='M146.2 248.8C145.939 250.151 145.606 251.486 145.2 252.8C144.374 255.748 143.091 258.548 141.4 261.1C140.676 262.034 139.873 262.904 139 263.7C137.549 265.125 135.76 266.157 133.8 266.7C133.8 266.7 126.1 259.3 123.9 256.8C122.468 255.215 121.132 253.545 119.9 251.8C119.547 249.883 119.547 247.917 119.9 246C120.157 244.301 120.884 242.707 122 241.4C122.583 240.84 123.219 240.337 123.9 239.9C126.14 238.909 128.663 238.767 131 239.5C131 239.5 138.4 244.1 141.7 245.9C143.298 246.705 144.807 247.677 146.2 248.8Z'
          fill='#E1ECFF'
        />
        <path
          d='M146.2 248.8C145.939 250.151 145.606 251.486 145.2 252.8C142.499 251.259 139.895 249.556 137.4 247.7C133.059 244.834 128.551 242.229 123.9 239.9C126.14 238.909 128.663 238.767 131 239.5C131 239.5 138.4 244.1 141.7 245.9C143.298 246.705 144.807 247.677 146.2 248.8Z'
          fill='#F8FBFF'
        />
        <path
          d='M139 263.6C137.515 264.979 135.737 266.005 133.8 266.6C133.8 266.6 126.1 259.1 123.9 256.7C122.468 255.115 121.132 253.445 119.9 251.7C119.547 249.783 119.547 247.817 119.9 245.9C123 249.4 131.3 258.4 137.1 262.2C137.8 262.8 138.5 263.2 139 263.6Z'
          fill='#C5DFFF'
        />
        <path
          d='M110.4 238C109.54 237.934 108.696 237.732 107.9 237.4C107.614 237.235 107.402 236.967 107.309 236.65C107.216 236.334 107.248 235.993 107.4 235.7C107.465 235.555 107.558 235.424 107.673 235.315C107.789 235.206 107.926 235.121 108.074 235.065C108.223 235.01 108.382 234.984 108.541 234.99C108.7 234.996 108.856 235.033 109 235.1C109.1 235.2 111.9 236.5 115.6 234.1C119.3 231.7 119.9 228.7 119.9 228.6C119.911 228.436 119.959 228.276 120.039 228.133C120.119 227.989 120.23 227.865 120.364 227.77C120.498 227.674 120.651 227.61 120.813 227.58C120.975 227.551 121.141 227.558 121.3 227.6C121.622 227.683 121.902 227.882 122.086 228.159C122.271 228.436 122.347 228.771 122.3 229.1C122.3 229.3 121.6 233.3 116.9 236.4C114.939 237.565 112.677 238.122 110.4 238Z'
          fill='#C5DFFF'
        />
        <path
          d='M99.7 217.9C98.4638 217.77 97.252 217.467 96.1 217C95.8138 216.835 95.6021 216.567 95.509 216.25C95.4159 215.934 95.4485 215.593 95.6 215.3C95.6571 215.16 95.7416 215.034 95.8486 214.928C95.9556 214.822 96.0829 214.738 96.2228 214.682C96.3628 214.626 96.5125 214.599 96.6632 214.602C96.8139 214.605 96.9625 214.638 97.1 214.7C97.2 214.8 100.7 216.5 105.3 214.4C109.8 212.4 110.6 209.5 110.6 209.3C110.626 209.146 110.685 208.999 110.771 208.868C110.858 208.737 110.97 208.626 111.102 208.542C111.233 208.457 111.381 208.401 111.536 208.376C111.691 208.352 111.849 208.36 112 208.4C112.311 208.516 112.567 208.745 112.715 209.042C112.864 209.339 112.894 209.682 112.8 210C112.8 210.2 111.7 214.2 106.1 216.8C104.103 217.732 101.893 218.112 99.7 217.9Z'
          fill='#C5DFFF'
        />
        <path
          d='M92.1001 195.4C90.4828 195.367 88.9135 194.844 87.6001 193.9C87.3521 193.677 87.1948 193.37 87.1579 193.038C87.1211 192.706 87.2072 192.372 87.4001 192.1C87.4855 191.959 87.6021 191.839 87.7409 191.749C87.8798 191.66 88.0372 191.603 88.2012 191.584C88.3652 191.565 88.5314 191.583 88.6872 191.638C88.843 191.693 88.9842 191.782 89.1001 191.9C89.2001 191.9 91.4001 193.6 96.3001 192.6C102.5 191.3 104.2 188.9 104.3 188.8C104.387 188.662 104.501 188.544 104.636 188.454C104.771 188.363 104.923 188.302 105.084 188.273C105.244 188.245 105.408 188.251 105.566 188.29C105.724 188.329 105.872 188.4 106 188.5C106.248 188.723 106.405 189.03 106.442 189.362C106.479 189.694 106.393 190.028 106.2 190.3C106 190.6 103.8 193.5 96.8001 195.1C95.2569 195.432 93.673 195.533 92.1001 195.4Z'
          fill='#C5DFFF'
        />
        <path
          d='M106.9 178.7C102.351 180.768 97.3965 181.793 92.4 181.7C89.3076 181.731 86.2427 181.118 83.4 179.9C82.341 179.463 81.3612 178.855 80.5 178.1C80.5 178.1 80.6 176.3 80.3 170.4C79.3 151.8 87.7 149.4 93.2 149.2C94.5233 149.196 95.8391 149.398 97.1 149.8C99.179 150.586 101.047 151.845 102.557 153.476C104.066 155.107 105.177 157.067 105.8 159.2C107.5 165.3 106.9 178.7 106.9 178.7Z'
          fill='#1F1933'
        />
        <path
          d='M94.6 160.7C88.3 164.1 85.7 161.9 85.7 161.9C85.7 161.9 84.2 167 83.5 177.1C83.4 178.1 83.4 179.1 83.3 179.9C82.241 179.463 81.2612 178.855 80.4 178.1C80.4 178.1 80.5 176.3 80.2 170.4C79.2 151.8 87.6 149.4 93.1 149.2C94.4233 149.196 95.7391 149.398 97 149.8C98.6 152.5 100.4 157.6 94.6 160.7Z'
          fill='#384D89'
        />
        <path
          d='M222.3 191.9C222 193.5 221.7 195 221.3 196.5C218.9 205.8 214.7 213.3 208.8 218.2C191.9 232.3 168.7 239.8 138.9 220.5C115.1 205.1 93.1999 170.2 85.2999 156.7C83.2999 153.3 82.1999 151.2 82.1999 151.2C82.1999 151.2 95.8999 150.5 117.5 140.6C128.152 135.619 138.357 129.73 148 123C167.5 109.4 178.8 96.7 185.3 86.8C193 75.2 194.1 67.6 194.1 67.6C194.1 67.6 196 71.1 199.3 78.8C205.826 94.6991 211.435 110.959 216.1 127.5C223.4 152.7 225.4 175 222.3 191.9Z'
          fill='#E1ECFF'
        />
        <path
          d='M199.3 78.8C196.478 84.34 193.235 89.6556 189.6 94.7C179.106 108.861 166.088 120.964 151.2 130.4C141.759 136.602 131.826 142.02 121.5 146.6C103.7 154.5 91.3999 156.3 85.1999 156.6C83.1999 153.2 82.0999 151.1 82.0999 151.1C82.0999 151.1 95.7999 150.4 117.4 140.5C128.052 135.519 138.257 129.63 147.9 122.9C167.4 109.3 178.7 96.6 185.2 86.7C192.9 75.1 194 67.5 194 67.5C195.963 71.1712 197.732 74.9431 199.3 78.8Z'
          fill='#1F1933'
        />
        <path
          d='M221.3 196.5C218.9 205.8 214.7 213.3 208.8 218.2C191.9 232.3 168.7 239.8 138.9 220.5C115.1 205.1 93.1999 170.2 85.2999 156.7C83.2999 153.3 82.1999 151.2 82.1999 151.2C82.1999 151.2 95.8999 150.5 117.5 140.6C126 153.8 144.8 181.1 163.3 197.2C184.8 216 209.3 204.9 221.3 196.5Z'
          fill='#C5DFFF'
        />
        <path
          d='M121.5 146.7C103.7 154.6 91.3999 156.4 85.1999 156.7C83.1999 153.3 82.0999 151.2 82.0999 151.2C82.0999 151.2 95.7999 150.5 117.4 140.6C118.6 142.4 120 144.5 121.5 146.7Z'
          fill='#6461F3'
        />
        <path
          d='M219.5 182.2C217 171.9 213.6 158.9 209.3 145.1C202.4 122.7 194.6 105.4 190.3 97.9C196.3 88.8 197.4 85.1 197.4 85.1C197.4 85.1 198.9 87.9 201.5 93.9C206.585 106.341 210.959 119.062 214.6 132C220.4 151.6 222 169 219.5 182.2Z'
          fill='#F8FBFF'
        />
        <path
          d='M199.3 78.8C196.478 84.34 193.235 89.6556 189.6 94.7C188 91.6 186.6 88.9 185.3 86.7C193 75.1 194.1 67.5 194.1 67.5C194.1 67.5 196.1 71.2 199.3 78.8Z'
          fill='#384D89'
        />
        <path
          d='M168 190.6C168 190.6 159 197.4 140.7 205C135.6 207.1 130.6 208.9 126.1 210.4L124.9 209.2C112.1 196.9 100.6 181 92.8 169C92.3 168.3 91.9 167.6 91.5 167C101.943 164.191 112.142 160.546 122 156.1C138.9 148.6 143.2 145.2 143.2 145.2L168 190.6Z'
          fill='#E1ECFF'
        />
        <path
          d='M165.2 190.5C165.2 190.5 157.1 197 139.9 203.8C134.6 205.9 129.3 207.7 124.8 209.2C112 196.9 100.5 181 92.7 169L94.3 168.6C103.941 165.949 113.368 162.572 122.5 158.5C138.1 151.5 142.5 149.2 142.5 149.2L165.2 190.5Z'
          fill='#1F1933'
        />
        <path
          d='M120.599 194.4C120.273 194.408 119.955 194.302 119.699 194.1L108.699 184.3L107.999 186.4C107.943 186.566 107.854 186.72 107.738 186.851C107.621 186.982 107.479 187.088 107.32 187.163C107.161 187.238 106.989 187.28 106.813 187.286C106.638 187.292 106.463 187.263 106.299 187.2C106.133 187.144 105.979 187.055 105.848 186.939C105.717 186.822 105.611 186.68 105.536 186.521C105.461 186.362 105.419 186.19 105.413 186.014C105.407 185.839 105.436 185.664 105.499 185.5L106.899 181.5C106.979 181.296 107.1 181.111 107.255 180.956C107.41 180.801 107.595 180.68 107.799 180.6C108.025 180.553 108.258 180.555 108.482 180.607C108.706 180.659 108.917 180.759 109.099 180.9L118.799 189.5L118.299 185.6C118.241 185.309 118.289 185.007 118.437 184.75C118.584 184.493 118.819 184.297 119.099 184.2L152.199 169.9C152.363 169.826 152.54 169.786 152.72 169.782C152.899 169.777 153.078 169.808 153.246 169.873C153.413 169.939 153.566 170.036 153.695 170.161C153.825 170.286 153.928 170.435 153.999 170.6C154.073 170.764 154.113 170.941 154.117 171.121C154.122 171.3 154.091 171.479 154.026 171.646C153.961 171.814 153.863 171.967 153.738 172.096C153.613 172.225 153.464 172.329 153.299 172.4L121.099 186.3L121.999 192.8C122.043 193.077 122 193.361 121.874 193.612C121.749 193.864 121.547 194.069 121.299 194.2C121.077 194.3 120.841 194.367 120.599 194.4Z'
          fill='#FFCE94'
        />
        <path
          d='M194.1 67.6C194.1 67.6 192.6 78.3 181.1 93.2C174.5 101.8 164.5 111.8 149.5 122C124.9 138.8 105.5 145.9 93.9 149C90.0444 150.102 86.0937 150.839 82.1 151.2C82.1 151.2 57 108.4 61.1 69.6C62.2 59.5 65.3 51.1 70 44.2C73.3395 39.2301 77.5386 34.8956 82.4 31.4C96.3 21.4 115 17.9 133.2 20.1C166.4 24.2 194.1 67.6 194.1 67.6Z'
          fill='#FEC272'
        />
        <path
          d='M194.1 67.6C194.1 67.6 193.4 72.4 189.6 80C187.216 84.7049 184.368 89.1602 181.1 93.3C174.5 101.9 164.5 111.9 149.5 122.1C123 140.1 102.5 147 91.3999 149.7C88.3447 150.467 85.2358 151.002 82.0999 151.3C82.0999 151 82.1999 128 119.6 98.8C157.2 69.3 194.1 67.6 194.1 67.6Z'
          fill='#FCAD4F'
        />
        <path
          d='M189.6 80C187.216 84.7048 184.368 89.1602 181.1 93.3C174.5 101.9 164.5 111.9 149.5 122.1C123 140.1 102.5 147 91.4 149.7C95.6 140.1 104.8 125.9 125.5 109.7C144.227 94.8861 166.191 84.7093 189.6 80V80Z'
          fill='#F99D3A'
        />
        <path
          d='M194.1 67.6C194.1 67.6 192.6 78.3 181.1 93.2C177.513 86.9433 173.469 80.9604 169 75.3C169 75.3 154.3 88.6 142.3 79.1C130.3 69.5 139.3 51.9 139.3 51.9C130.512 43.3127 119.497 37.3549 107.5 34.7C99.2028 32.9221 90.7753 31.8185 82.3 31.4C96.2 21.4 114.9 17.9 133.1 20.1C166.4 24.2 194.1 67.6 194.1 67.6Z'
          fill='#FFDBB3'
        />
        <path
          d='M94 149C90.1445 150.102 86.1937 150.839 82.2 151.2C82.2 151.2 57.1 108.4 61.2 69.6C62.3 59.5 65.4 51.1 70.1 44.2C68.1244 66.7231 70.5703 89.4152 77.3 111C81.5285 124.214 87.1255 136.949 94 149Z'
          fill='#FFDBB3'
        />
        <path
          d='M208.2 323.2C208.214 323.835 208.181 324.47 208.1 325.1C207.7 331 189.6 332.1 177 332.7C164.6 333.3 151.5 329.8 156 325.7L156.2 325.5C159.4 322.8 165.6 319.7 172.5 317.2C176.489 315.725 180.563 314.489 184.7 313.5C190.1 312.2 201.1 309.6 204.7 312.3C205.451 312.895 206.065 313.646 206.5 314.5C207.727 317.231 208.309 320.208 208.2 323.2Z'
          fill='#1F1933'
        />
        <path
          d='M208.2 323.2C208.214 323.835 208.181 324.47 208.1 325.1C207.7 331 189.6 332.1 177 332.7C164.6 333.3 151.5 329.8 156 325.7C158.7 327.3 164.6 329.2 176.9 328.7C193.7 328 203.9 325.2 208.2 323.2Z'
          fill='#384D89'
        />
        <path
          d='M206.399 314.5C205.313 315.46 204.102 316.267 202.799 316.9C197.999 319.3 183.399 321.9 174.499 320.4C170.599 319.7 170.899 318.5 172.399 317.2C176.388 315.725 180.462 314.489 184.599 313.5C189.999 312.2 200.999 309.6 204.599 312.3C205.35 312.895 205.964 313.646 206.399 314.5Z'
          fill='#6461F3'
        />
        <path
          d='M183.9 266.5C187.9 292.1 202.5 311.7 202.5 311.7C201.336 313.205 199.914 314.491 198.3 315.5C196.955 316.342 195.511 317.014 194 317.5C191.461 318.188 188.815 318.392 186.2 318.1C184.512 317.932 182.84 317.631 181.2 317.2C181.2 317.2 156.3 285.1 153.2 248.5C150.1 211.9 169.8 193.5 169.8 193.5C169.947 193.42 170.082 193.319 170.2 193.2C173.414 191.22 177.111 190.163 180.886 190.145C184.661 190.127 188.368 191.15 191.6 193.1C192.327 193.488 193.029 193.922 193.7 194.4C201.8 199.7 203 205.1 203 205.1C199.216 208.596 195.98 212.641 193.4 217.1C189.3 224.1 179.9 240.9 183.9 266.5Z'
          fill='#E1ECFF'
        />
        <path
          d='M183.9 266.5C187.9 292.1 202.5 311.7 202.5 311.7C201.336 313.205 199.914 314.491 198.3 315.5C191 305.4 173.5 274.8 174.1 245.7C174.6 219.1 188.4 200.6 193.8 194.4C201.9 199.7 203.1 205.1 203.1 205.1C199.316 208.596 196.08 212.641 193.5 217.1C189.3 224.1 179.9 240.9 183.9 266.5Z'
          fill='#C5DFFF'
        />
        <path
          d='M186.1 318C184.412 317.832 182.74 317.531 181.1 317.1C181.1 317.1 156.2 285 153.1 248.4C150 211.8 169.7 193.4 169.7 193.4C169.847 193.32 169.982 193.219 170.1 193.1C166.3 200.9 157.8 221.1 158.6 243.9C159.6 273.2 172.3 295.8 181.2 310.2C182.9 313.1 184.6 315.7 186.1 318Z'
          fill='#F8FBFF'
        />
        <path
          d='M167.1 292C166.929 292.009 166.759 291.977 166.602 291.908C166.446 291.838 166.308 291.733 166.2 291.6C165.947 291.354 165.796 291.022 165.778 290.67C165.759 290.317 165.874 289.971 166.1 289.7C168.609 287.399 171.623 285.721 174.9 284.8C180.8 283 187.3 285 187.6 285.1C187.94 285.213 188.224 285.453 188.391 285.769C188.559 286.086 188.598 286.455 188.5 286.8C188.387 287.14 188.147 287.424 187.831 287.591C187.514 287.759 187.145 287.798 186.8 287.7C186.7 287.7 180.8 285.9 175.7 287.4C170.6 289 168.1 291.5 168.1 291.6C167.978 291.743 167.824 291.854 167.649 291.923C167.475 291.993 167.287 292.019 167.1 292Z'
          fill='#C5DFFF'
        />
        <path
          d='M156.796 260C156.551 260.008 156.308 259.947 156.095 259.823C155.883 259.699 155.71 259.518 155.596 259.3C155.498 259.144 155.432 258.97 155.404 258.788C155.376 258.605 155.386 258.419 155.433 258.241C155.479 258.063 155.563 257.896 155.677 257.751C155.791 257.607 155.934 257.487 156.096 257.4C160.045 255.241 164.408 253.946 168.896 253.6C173.411 253.482 177.851 254.776 181.596 257.3C181.746 257.401 181.873 257.531 181.97 257.684C182.067 257.836 182.132 258.006 182.16 258.185C182.188 258.363 182.179 258.545 182.134 258.72C182.089 258.895 182.008 259.058 181.896 259.2C181.795 259.35 181.665 259.477 181.513 259.574C181.36 259.671 181.19 259.736 181.011 259.764C180.833 259.792 180.651 259.783 180.476 259.738C180.302 259.692 180.138 259.611 179.996 259.5C179.896 259.5 174.896 256 168.996 256.3C164.92 256.607 160.961 257.801 157.396 259.8C157.296 260 156.996 260 156.796 260Z'
          fill='#C5DFFF'
        />
        <path
          d='M184.2 233.596C184.029 233.605 183.859 233.573 183.702 233.504C183.546 233.434 183.408 233.329 183.3 233.196C183.2 233.096 175.8 226.396 168.4 225.496C161.2 224.596 156.8 227.696 156.8 227.796C156.505 227.998 156.144 228.08 155.79 228.024C155.437 227.968 155.119 227.779 154.9 227.496C154.698 227.201 154.616 226.84 154.672 226.486C154.728 226.133 154.917 225.814 155.2 225.596C155.4 225.396 160.5 221.696 168.8 222.796C177 223.896 184.8 230.896 185.2 231.196C185.337 231.311 185.449 231.452 185.528 231.612C185.608 231.772 185.654 231.946 185.664 232.124C185.673 232.302 185.645 232.481 185.583 232.648C185.52 232.815 185.424 232.968 185.3 233.096C184.99 233.366 184.608 233.54 184.2 233.596Z'
          fill='#C5DFFF'
        />
        <path
          d='M271.501 205.2C270.065 209.432 267.293 213.082 263.601 215.6C257.601 219.5 254.201 219.9 254.201 219.9C254.201 219.9 250.301 219.3 250.301 216.8V216.5C250.546 214.646 251.27 212.889 252.401 211.4C255.111 210.928 257.761 210.157 260.301 209.1C263.101 207.7 266.101 203.2 268.301 202.7C268.755 202.618 269.223 202.668 269.649 202.845C270.075 203.023 270.44 203.32 270.701 203.7C271.069 204.139 271.342 204.65 271.501 205.2Z'
          fill='#E1ECFF'
        />
        <path
          d='M271.501 205.2C270.065 209.432 267.293 213.082 263.601 215.6C257.601 219.5 254.201 219.9 254.201 219.9C254.201 219.9 250.301 219.3 250.301 216.8C253.001 216.6 259.401 215.8 263.901 212.2C266.812 209.908 269.142 206.961 270.701 203.6C271.062 204.081 271.333 204.623 271.501 205.2Z'
          fill='#C5DFFF'
        />
        <path
          d='M267.801 204.7C265.053 208.487 261.162 211.291 256.701 212.7C254.892 213.417 252.93 213.658 251.001 213.4C250.494 212.91 250.098 212.318 249.84 211.662C249.581 211.007 249.465 210.304 249.501 209.6C249.601 208.4 250.401 207.1 252.501 205.9C252.501 205.9 258.201 205.2 261.801 198.2C265.401 191.2 263.901 190.5 264.801 189.1C265.401 188.1 267.601 188.2 269.101 189.1C269.833 189.51 270.372 190.193 270.601 191C271.501 193.5 271.401 199.8 267.801 204.7Z'
          fill='#E1ECFF'
        />
        <path
          d='M267.801 204.7C265.053 208.487 261.162 211.291 256.701 212.7C254.892 213.417 252.93 213.658 251.001 213.4C250.494 212.91 250.098 212.318 249.84 211.662C249.581 211.007 249.465 210.304 249.501 209.6C253.201 209.4 260.801 208.4 264.501 202.9C267.266 198.806 268.856 194.034 269.101 189.1C269.833 189.51 270.372 190.193 270.601 191C271.501 193.5 271.401 199.8 267.801 204.7Z'
          fill='#C5DFFF'
        />
        <path
          d='M261.101 201.6C258.485 205.215 254.765 207.882 250.501 209.2C248.782 209.859 246.923 210.066 245.101 209.8C244.621 209.337 244.248 208.775 244.006 208.154C243.764 207.532 243.66 206.866 243.701 206.2C243.801 205.1 244.601 203.8 246.601 202.7C246.601 202.7 252.001 202.1 255.401 195.4C258.801 188.8 257.401 188.1 258.201 186.7C258.801 185.8 260.801 185.8 262.301 186.7C263.001 187.1 263.534 187.739 263.801 188.5C264.601 191 264.501 197 261.101 201.6Z'
          fill='#E1ECFF'
        />
        <path
          d='M261.101 201.6C258.485 205.215 254.765 207.882 250.501 209.2C248.782 209.859 246.923 210.066 245.101 209.8C244.621 209.337 244.248 208.775 244.006 208.154C243.764 207.532 243.66 206.866 243.701 206.2C247.201 206 254.401 205.1 258.001 199.9C260.587 195.993 262.104 191.476 262.401 186.8C263.101 187.2 263.634 187.839 263.901 188.6C264.341 190.844 264.319 193.153 263.838 195.388C263.356 197.623 262.425 199.736 261.101 201.6Z'
          fill='#C5DFFF'
        />
        <path
          d='M258.401 210.5C258.483 212.943 258.004 215.371 257.001 217.6C256.419 218.878 255.643 220.059 254.701 221.1C254.701 221.1 229.401 230 202.801 207.8C189.101 196.4 178.101 181.5 170.701 169.8C166.71 163.541 163.071 157.064 159.801 150.4C159.801 150.4 157.701 143.9 161.601 138.3C163.064 136.18 165.066 134.489 167.401 133.4C172.101 131 176.001 130.8 179.101 131.6C182.281 132.438 185.116 134.257 187.201 136.8C187.201 136.8 191.101 145.4 196.901 156C203.701 168.3 213.101 183.3 222.101 190.3C238.801 203.3 252.701 198.3 252.701 198.3C253.817 199.47 254.822 200.743 255.701 202.1C256.801 204.2 258.301 207.2 258.401 210.5Z'
          fill='#E1ECFF'
        />
        <path
          d='M196.6 155.8C193.353 160.901 188.496 164.772 182.8 166.8C178.853 168.328 174.714 169.304 170.5 169.7C166.509 163.441 162.87 156.964 159.6 150.3C159.6 150.3 156 139 167.1 133.2C179.6 126.7 186.9 136.6 186.9 136.6C186.9 136.6 190.8 145.3 196.6 155.8Z'
          fill='#C5DFFF'
        />
        <path
          d='M256.901 217.5C256.319 218.778 255.544 219.959 254.601 221C254.601 221 229.301 229.9 202.701 207.7C189.001 196.3 178.001 181.4 170.601 169.7C166.61 163.441 162.971 156.964 159.701 150.3C159.701 150.3 157.601 143.8 161.501 138.2C164.801 145.7 178.901 176.3 196.801 193.9C217.301 214 228.401 216.5 248.101 217.3C251.301 217.4 254.301 217.5 256.901 217.5Z'
          fill='#C5DFFF'
        />
        <path
          d='M255.4 202C250.3 203.5 243.2 204.6 235.8 202.3C222 198 210.7 189.3 199.6 172.6C190.5 158.8 181.7 138.4 178.9 131.5C182.08 132.338 184.915 134.157 187 136.7C187 136.7 190.9 145.3 196.7 155.9C203.5 168.2 212.9 183.2 221.9 190.2C238.6 203.2 252.5 198.2 252.5 198.2C253.594 199.364 254.566 200.637 255.4 202Z'
          fill='#F8FBFF'
        />
        <path
          d='M186.099 186.3C184.677 186.305 183.262 186.103 181.899 185.7C181.56 185.564 181.288 185.299 181.142 184.964C180.996 184.628 180.987 184.249 181.119 183.908C181.25 183.566 181.51 183.29 181.843 183.139C182.176 182.988 182.556 182.974 182.899 183.1C183.099 183.2 187.799 184.8 194.099 181.4C197.856 179.298 200.652 175.821 201.899 171.7C201.938 171.529 202.011 171.368 202.113 171.225C202.214 171.083 202.343 170.962 202.492 170.869C202.64 170.776 202.806 170.713 202.979 170.684C203.151 170.655 203.328 170.661 203.499 170.7C203.67 170.739 203.831 170.812 203.974 170.914C204.116 171.016 204.238 171.144 204.33 171.293C204.423 171.441 204.486 171.607 204.515 171.78C204.544 171.952 204.538 172.129 204.499 172.3C203.812 174.735 202.644 177.007 201.064 178.982C199.484 180.957 197.524 182.596 195.299 183.8C192.504 185.423 189.332 186.285 186.099 186.3Z'
          fill='#C5DFFF'
        />
        <path
          d='M205.2 208.7C204.829 208.7 204.473 208.553 204.21 208.29C203.948 208.027 203.8 207.671 203.8 207.3C203.8 206.929 203.948 206.573 204.21 206.31C204.473 206.048 204.829 205.9 205.2 205.9C205.3 205.9 211.5 205.8 215 201.5C218.6 197.1 217.9 190.9 217.9 190.8C217.88 190.623 217.895 190.443 217.945 190.272C217.995 190.1 218.077 189.94 218.189 189.801C218.3 189.662 218.438 189.546 218.595 189.46C218.751 189.374 218.923 189.32 219.1 189.3C219.455 189.275 219.806 189.385 220.084 189.607C220.362 189.83 220.546 190.148 220.6 190.5C220.6 190.8 221.4 197.9 217.1 203.2C212.8 208.7 205.5 208.7 205.2 208.7Z'
          fill='#C5DFFF'
        />
        <path
          d='M227.199 220.4C226.916 220.402 226.64 220.316 226.407 220.155C226.174 219.994 225.997 219.765 225.899 219.5C225.768 219.155 225.779 218.773 225.929 218.436C226.079 218.098 226.355 217.834 226.699 217.7C228.452 216.929 230.01 215.774 231.256 214.32C232.502 212.866 233.406 211.15 233.899 209.3C235.399 203.1 233.299 199.5 233.199 199.4C233.013 199.091 232.951 198.724 233.025 198.371C233.099 198.019 233.304 197.707 233.599 197.5C233.908 197.314 234.275 197.252 234.628 197.326C234.98 197.4 235.292 197.605 235.499 197.9C235.599 198.1 238.399 202.6 236.499 209.9C235.889 212.177 234.771 214.286 233.23 216.07C231.688 217.854 229.763 219.266 227.599 220.2C227.499 220.4 227.399 220.4 227.199 220.4Z'
          fill='#C5DFFF'
        />
        <path
          d='M197.397 143.8C197.191 144.826 196.89 145.83 196.497 146.8C195.097 150.2 191.797 155.8 184.297 160.7C172.697 168.2 162.397 165 162.397 165C157.589 158.142 154.534 150.211 153.497 141.9C152.597 134.2 157.697 128.2 164.297 125.3C169.197 123.2 174.897 122.8 179.497 124.8C190.397 129.7 197.397 143.8 197.397 143.8Z'
          fill='#1F1933'
        />
        <path
          d='M197.401 143.8C197.195 144.826 196.894 145.83 196.501 146.8C196.501 146.7 196.401 146.7 196.401 146.6C187.101 134.3 179.801 131.8 179.801 131.8C179.801 131.8 177.101 138.2 168.201 134.5C162.001 132 163.101 127.6 164.401 125.3C169.301 123.2 175.001 122.8 179.601 124.8C190.401 129.7 197.401 143.8 197.401 143.8Z'
          fill='#384D89'
        />
        <path
          d='M75.8989 90.9C75.0007 90.7478 74.0782 90.9417 73.3173 91.4428C72.5564 91.9438 72.0138 92.7147 71.7989 93.6C70.5775 98.6484 69.6096 103.755 68.8989 108.9C68.3989 113.1 68.1989 116.6 68.0989 119C68.0731 119.847 68.3513 120.676 68.8831 121.336C69.415 121.997 70.1654 122.445 70.9989 122.6C71.4672 122.674 71.9458 122.652 72.4052 122.534C72.8645 122.417 73.2949 122.206 73.6697 121.916C74.0445 121.625 74.3558 121.261 74.5844 120.845C74.813 120.43 74.9541 119.972 74.9989 119.5C75.3051 115.065 75.9064 110.655 76.7989 106.3C77.7989 101.2 78.4989 97.6 78.9989 95.2C79.1266 94.2233 78.877 93.2347 78.301 92.4357C77.725 91.6367 76.8659 91.0875 75.8989 90.9Z'
          fill='#1F1933'
        />
        <path
          d='M58.6 101.6C58.7037 101.13 58.9122 100.689 59.2099 100.311C59.5076 99.9324 59.8868 99.6261 60.3194 99.4146C60.7519 99.2032 61.2266 99.092 61.708 99.0895C62.1895 99.087 62.6653 99.1931 63.1 99.4C66.1 100.7 70.5 102.6 74.7 104.1C79 105.6 83 107.1 85.5 108.1C86.3195 108.406 86.9971 109.004 87.403 109.779C87.809 110.554 87.9147 111.452 87.7 112.3C87.5827 112.757 87.3744 113.187 87.0876 113.562C86.8008 113.937 86.4413 114.251 86.0307 114.484C85.6201 114.717 85.1667 114.866 84.6975 114.92C84.2283 114.974 83.7531 114.933 83.3 114.8C78.5378 113.454 73.8624 111.817 69.3 109.9C66.0642 108.547 62.9237 106.977 59.9 105.2C59.3572 104.791 58.9452 104.233 58.7143 103.593C58.4834 102.954 58.4437 102.261 58.6 101.6Z'
          fill='#1F1933'
        />
        <path
          d='M118.896 66.5C117.722 66.3271 116.526 66.6011 115.545 67.2678C114.563 67.9344 113.868 68.9451 113.596 70.1C112.496 75.1 110.896 83.5 110.196 90.1C109.696 95.6 109.396 100.1 109.396 103.2C109.353 104.289 109.72 105.355 110.423 106.187C111.126 107.02 112.115 107.56 113.196 107.7C113.804 107.793 114.425 107.759 115.02 107.6C115.614 107.44 116.169 107.158 116.649 106.773C117.128 106.387 117.522 105.906 117.806 105.36C118.09 104.813 118.257 104.214 118.296 103.6C118.496 99.6 119.096 93.5 120.196 86.5C121.296 79.8 122.196 75.1 122.696 72C122.831 71.3874 122.839 70.7536 122.718 70.138C122.597 69.5224 122.351 68.9383 121.994 68.4222C121.638 67.9061 121.178 67.469 120.645 67.1383C120.112 66.8076 119.517 66.5903 118.896 66.5Z'
          fill='#1F1933'
        />
        <path
          d='M96.6969 80.9C96.8369 80.2949 97.1068 79.7275 97.4878 79.237C97.8688 78.7465 98.3518 78.3447 98.9035 78.0594C99.4551 77.774 100.062 77.612 100.683 77.5845C101.303 77.557 101.922 77.6646 102.497 77.9C106.397 79.5 112.097 81.8 117.597 83.7C123.197 85.6 128.397 87.4 131.697 88.6C132.768 88.9747 133.66 89.7369 134.196 90.7364C134.733 91.736 134.876 92.9003 134.597 94C134.452 94.601 134.188 95.1669 133.822 95.6647C133.455 96.1624 132.993 96.582 132.462 96.8987C131.931 97.2154 131.342 97.4228 130.73 97.5089C130.118 97.595 129.495 97.558 128.897 97.4C122.709 95.7892 116.63 93.7851 110.697 91.4C106.475 89.7728 102.394 87.7994 98.4969 85.5C97.7948 84.98 97.2535 84.2726 96.9351 83.459C96.6167 82.6453 96.5341 81.7584 96.6969 80.9Z'
          fill='#1F1933'
        />
        <path
          d='M457.4 257.6C460.786 258.739 463.818 260.737 466.2 263.4C469.9 267.8 470.4 274.9 470.4 274.9L490.6 265.5C490.6 265.5 480.4 269.4 477 262.6C473.6 255.8 484.9 245 484.9 245C484.9 245 472.4 255.2 467.5 253.7C462.6 252.2 461.8 246.1 461.8 246.1L457.4 257.6Z'
          fill='#FEC272'
        />
        <path d='M438.3 255.3L441.9 255.4L447.5 214.4L434.8 212L438.3 255.3Z' fill='#D2E9FF' />
        <path d='M447.1 255.5L455.5 217.1L467.6 223L449.3 256.5L447.1 255.5Z' fill='#D2E9FF' />
        <path d='M46.9 61.7L44.8 66.3L0.899994 52.8L6.79999 35.5L46.9 61.7Z' fill='#FEC272' />
        <path d='M53.5 46.5L49.6 52.1L0 14.3L13.7 0L53.5 46.5Z' fill='#FEC272' />
      </g>
      <defs>
        <clipPath id='clip0_6158_45110'>
          <rect width='490.6' height='337.5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectionLostIcon;
