import { isValid, format, parseISO } from 'date-fns';
import { TaskType } from '@finance-ops/types';

export const formatDate = (date: string | Date): string => {
  let dateToFormat: Date;
  if (typeof date === 'string') {
    dateToFormat = new Date(date);
  } else {
    dateToFormat = date;
  }

  return isValid(dateToFormat) ? format(dateToFormat, 'MM/dd/yyyy') : 'Invalid Date';
};

export const calculateLastMessageDate = (task: TaskType): Date | undefined => {
  const { lastInboundMessageDate, lastOutboundMessageDate, lastMessageDate } = task;

  if (lastMessageDate) return lastMessageDate;

  if (!lastInboundMessageDate && !lastOutboundMessageDate) {
    return task.createdAt;
  }

  if (!lastInboundMessageDate) {
    return lastOutboundMessageDate;
  }

  if (!lastOutboundMessageDate) {
    return lastInboundMessageDate;
  }

  return lastInboundMessageDate > lastOutboundMessageDate ? lastInboundMessageDate : lastOutboundMessageDate;
};

export const getUserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isIsoDateString = (value: string): boolean => {
  const date = parseISO(value);
  return isValid(date);
};

export const convertDates = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) return obj;

  for (const key in obj) {
    if (typeof obj[key] === 'string' && isIsoDateString(obj[key])) {
      obj[key] = parseISO(obj[key]);
    } else if (typeof obj[key] === 'object') {
      convertDates(obj[key]);
    }
  }
  return obj;
};

export const getDatePastDays = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
};
