import { COMMUNICATION_MODE, CommonType, GenericObject } from '../common';

export type TaskType = CommonType & {
  clientId: string;
  customerId: string;
  internalAssignedUserId?: string;
  externalAssignedUserId?: string;
  internalState: TASK_STATE;
  externalState: TASK_STATE;
  lastInboundMessageDate?: Date;
  lastInboundMessageId?: string;
  lastMessageDate?: Date;
  lastOutboundMessageDate?: Date;
  lastOutboundContactNumber?: string;
  lastInboundEmailDate?: Date;
  lastOutboundEmailDate?: Date;
  worklistRank?: number;
  worklistRankSummary?: string;
  mode: TASK_MODE;
  assignedDate?: Date;
  assignedAmount?: number;
  completedDate?: Date;
  metadata?: GenericObject;
  primaryInternalCommunicationId?: string;
  preferredModesOfCommunication?: COMMUNICATION_MODE[];
  lastPaymentDate?: Date;
  lastPaymentLinkOpenedAt?: Date;
};

export enum TASK_STATE {
  WORKLIST = 'WORKLIST',
  ASSIGNED = 'ASSIGNED',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
  AUTOPILOT = 'AUTOPILOT',
  UNASSIGNED = 'UNASSIGNED',
  EXTERNAL = 'EXTERNAL', // This is a internal state, it is used to identify tasks that are not yet assigned to finance, i.e. mode = COPILOT-EXTERNAL, it only exists to make chat filtering easier
}

export enum TASK_MODE {
  COPILOT_INTERNAL = 'COPILOT-INTERNAL',
  COPILOT_EXTERNAL = 'COPILOT-EXTERNAL',
  AUTOPILOT = 'AUTOPILOT',
}

export enum TASK_STATE_KEY_NAME {
  internalState = 'internalState',
  externalState = 'externalState',
}

export enum TASK_ASSIGNED_USER_KEY_NAME {
  internalAssignedUserId = 'internalAssignedUserId',
  externalAssignedUserId = 'externalAssignedUserId',
}

export enum TASK_ASSIGN_TYPE {
  ASSIGN_TO_SINGLE_AGENT = 'To Agent',
  ASSIGN_TO_SINGLE_AGENT_SELECTED = 'To Agent (selected)',
  ASSIGN_TO_SINGLE_AGENT_FILTERED = 'To Agent (filtered)',
  ASSIGN_TO_AUTOPILOT_SELECTED = 'To AutoPilot (selected)',
  ASSIGN_TO_AUTOPILOT_FILTERED = 'To AutoPilot (filtered)',
}

export const TASK_MODE_INTERNAL_MAP = {
  [TASK_MODE.AUTOPILOT]: 'AutoPilot',
  [TASK_MODE.COPILOT_INTERNAL]: 'Copilot Internal',
  [TASK_MODE.COPILOT_EXTERNAL]: 'Copilot External',
};

export const TASK_MODE_EXTERNAL_MAP = {
  [TASK_MODE.AUTOPILOT]: 'AutoPilot',
  [TASK_MODE.COPILOT_INTERNAL]: 'Copilot',
  [TASK_MODE.COPILOT_EXTERNAL]: 'Copilot',
};

export const getTaskModeStringForUsers = (mode: TASK_MODE, isInternal = false) => {
  if (!isInternal) {
    switch (mode) {
      case TASK_MODE.AUTOPILOT:
      case TASK_MODE.COPILOT_INTERNAL:
        return TASK_MODE_EXTERNAL_MAP[TASK_MODE.AUTOPILOT];
      case TASK_MODE.COPILOT_EXTERNAL:
        return TASK_MODE_EXTERNAL_MAP[TASK_MODE.COPILOT_EXTERNAL];
      default:
        return mode;
    }
  } else return TASK_MODE_INTERNAL_MAP[mode];
};
