const NoDataCross = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='110' height='118' viewBox='0 0 110 118' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.7408 2.00006H76.7003C77.1361 2.00006 77.5693 2.02792 77.9999 2.08366C95.0796 3.27808 108.342 17.4523 108.4 34.5736V83.434C108.337 101.462 93.6871 116.034 75.6591 116H34.7408C16.7099 116.034 2.05867 101.457 2 83.4264V34.5736C2.05867 16.5428 16.7099 1.96642 34.7408 2.00006Z'
        stroke='#6461F3'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.4 36.0735C109.228 36.0735 109.9 35.4019 109.9 34.5735C109.9 33.745 109.228 33.0735 108.4 33.0735V36.0735ZM78 34.5735H76.5C76.5 35.4019 77.1715 36.0735 78 36.0735V34.5735ZM79.5 2.0835C79.5 1.25507 78.8284 0.583496 78 0.583496C77.1715 0.583496 76.5 1.25507 76.5 2.0835H79.5ZM41.0607 46.5393C40.4749 45.9535 39.5251 45.9535 38.9393 46.5393C38.3536 47.1251 38.3536 48.0748 38.9393 48.6606L41.0607 46.5393ZM54.1393 63.8606C54.7251 64.4464 55.6749 64.4464 56.2606 63.8606C56.8464 63.2748 56.8464 62.3251 56.2606 61.7393L54.1393 63.8606ZM56.2606 61.7393C55.6749 61.1535 54.7251 61.1535 54.1393 61.7393C53.5535 62.3251 53.5535 63.2748 54.1393 63.8606L56.2606 61.7393ZM69.3393 79.0606C69.9251 79.6464 70.8748 79.6464 71.4606 79.0606C72.0464 78.4748 72.0464 77.525 71.4606 76.9393L69.3393 79.0606ZM56.2606 63.8606C56.8464 63.2748 56.8464 62.3251 56.2606 61.7393C55.6749 61.1535 54.7251 61.1535 54.1393 61.7393L56.2606 63.8606ZM38.9393 76.9393C38.3536 77.525 38.3536 78.4748 38.9393 79.0606C39.5251 79.6464 40.4749 79.6464 41.0607 79.0606L38.9393 76.9393ZM54.1393 61.7393C53.5535 62.3251 53.5535 63.2748 54.1393 63.8606C54.7251 64.4464 55.6749 64.4464 56.2606 63.8606L54.1393 61.7393ZM71.4606 48.6606C72.0464 48.0748 72.0464 47.1251 71.4606 46.5393C70.8748 45.9535 69.9251 45.9535 69.3393 46.5393L71.4606 48.6606ZM108.4 33.0735H78V36.0735H108.4V33.0735ZM79.5 34.5735V2.0835H76.5V34.5735H79.5ZM38.9393 48.6606L54.1393 63.8606L56.2606 61.7393L41.0607 46.5393L38.9393 48.6606ZM54.1393 63.8606L69.3393 79.0606L71.4606 76.9393L56.2606 61.7393L54.1393 63.8606ZM54.1393 61.7393L38.9393 76.9393L41.0607 79.0606L56.2606 63.8606L54.1393 61.7393ZM56.2606 63.8606L71.4606 48.6606L69.3393 46.5393L54.1393 61.7393L56.2606 63.8606Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default NoDataCross;
