import { CustomerType } from '../customer';
import { UserType } from '../user';
import { MessageSendWhatsAppTemplateRequestType } from '../websocket';
import { MESSAGE_STATUS } from './MessageType';

export type MessageWhatsAppPayloadType =
  | {
      messaging_product: 'whatsapp';
      recipient_type: 'individual';
      to: string;
      type: WHATSAPP_MESSAGE_TYPE.TEXT;
      text: WhatsAppMessageTextType;
    }
  | {
      messaging_product: 'whatsapp';
      recipient_type: 'individual';
      to: string;
      type: WHATSAPP_MESSAGE_TYPE.TEMPLATE;
      template: WhatsAppMessageTemplateType;
    };

export enum WHATSAPP_MESSAGE_TYPE {
  TEXT = 'text',
  TEMPLATE = 'template',
  IMAGE = 'image',
  LOCATION = 'location',
  CONTACTS = 'contacts',
  INTERACTIVE = 'interactive',
}

type WhatsAppMessageTextType = {
  preview_url: boolean;
  body: string;
};

type ImageParameter = {
  type: 'image';
  image: {
    link: string;
  };
};

export type TextParameter = {
  type: 'text';
  text: string;
};

type CurrencyParameter = {
  type: 'currency';
  currency: {
    fallback_value: string;
    code: string;
    amount_1000: number;
  };
};

type DateTimeParameter = {
  type: 'date_time';
  date_time: {
    fallback_value: string;
  };
};

type PayloadParameter = {
  type: 'payload';
  payload: string;
};

type HeaderComponent = {
  type: 'header';
  parameters: ImageParameter[];
};

type BodyComponent = {
  type: 'body';
  parameters: (TextParameter | CurrencyParameter | DateTimeParameter)[];
};

type ButtonComponent = {
  type: 'button';
  sub_type: 'quick_reply';
  index: '0' | '1';
  parameters: PayloadParameter[];
};

type WhatsAppMessageTemplateType = {
  name: string;
  language: {
    code: string;
  };
  components: (HeaderComponent | BodyComponent | ButtonComponent)[];
};

type WhatsAppContact = {
  input: string;
  wa_id: string;
};

type WhatsAppMessage = {
  id: string;
  message_status: 'accepted' | 'held_for_quality_assessment';
};

export type WhatsAppResultPayload = {
  messaging_product: 'whatsapp';
  contacts: WhatsAppContact[];
  messages: WhatsAppMessage[];
};

type WhatsAppReceivedMessageTextType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'text';
  text?: {
    body: string;
  };
};

type WhatsAppReceivedMessageDocumentType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'document';
  document: {
    filename: string;
    mime_type: string;
    sha256: string;
    id: string;
  };
};

type WhatsAppReceivedMessageImageType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'image';
  image: {
    caption: string;
    mime_type: string;
    sha256: string;
    id: string;
  };
};

type WhatsAppReceivedMessageVideoType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'video';
  video: {
    mime_type: string;
    sha256: string;
    id: string;
  };
};

type WhatsAppReceivedMessageAudioType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'audio';
  audio: {
    mime_type: string;
    sha256: string;
    id: string;
    voice: boolean;
  };
};

type WhatsAppReceivedMessageReactionType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'reaction';
  reaction: {
    message_id: string;
    emoji: string;
  };
};

type WhatsAppReceivedMessageStickerType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'sticker';
  sticker: {
    mime_type: string;
    sha256: string;
    id: string;
  };
};

type WhatsAppReceivedMessageUnknownType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'unknown';
  errors: {
    code: string;
    details: string;
    title: string;
  };
};

type WhatsAppReceivedMessageUnsupportedType = {
  from: string;
  id: string;
  timestamp: string;
  type: 'unsupported';
  errors: {
    code: string;
    title: string;
    message: string;
    error_data: {
      details: string;
    };
  };
};

export type WhatsAppReceiveWebhookType = {
  object: 'whatsapp_business_account';
  entry: Array<{
    id: string;
    changes: Array<{
      value: {
        messaging_product: string;
        metadata: {
          display_phone_number: string;
          phone_number_id: string;
        };
        contacts: Array<{
          profile: {
            name: string;
          };
          wa_id: string;
        }>;
        messages?: Array<
          | WhatsAppReceivedMessageTextType
          | WhatsAppReceivedMessageDocumentType
          | WhatsAppReceivedMessageImageType
          | WhatsAppReceivedMessageVideoType
          | WhatsAppReceivedMessageAudioType
          | WhatsAppReceivedMessageStickerType
          | WhatsAppReceivedMessageReactionType
          | WhatsAppReceivedMessageUnknownType
          | WhatsAppReceivedMessageUnsupportedType
        >;
        statuses?: Array<{
          id: string;
          status: MESSAGE_STATUS;
          timestamp: string;
          recipient_id: string;
          conversation: {
            id: string;
            origin: {
              type: string;
            };
          };
          pricing: {
            billable: boolean;
            pricing_model: string;
            category: string;
          };
        }>;
      };
      field: 'messages';
    }>;
  }>;
};

export type WhatsAppMediaUrlDownloadType = {
  messaging_product: 'whatsapp';
  url: string;
  mime_type: string;
  sha256: string;
  file_size: number;
  id: string;
};

export type WhatsAppMessageSendBodyType = {
  user: UserType;
  payload: MessageSendWhatsAppTemplateRequestType;
  customer: CustomerType;
};
