import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum StrategyViews {
  LANDING = 'LANDING',
  CUSTOMER_SEGMENT = 'CUSTOMER_SEGMENT',
  ACTIONS = 'ACTIONS',
  ALLOCATE_STRATEGY = 'ALLOCATE_STRATEGY',
  DASHBOARD = 'DASHBOARD',
  ACTIONS_COMPARISION = 'ACTIONS_COMPARISION',
}

export interface StrategyAction {
  name: string;
  actions: ActiveAction[];
}

export interface Strategy {
  name: string;
  actions: StrategyAction[];
}

export enum ActionCategory {
  NEGOTIATION = 'NEGOTIATION',
  CONVERSATION = 'CONVERSATION',
}

export interface ActionOption {
  label: string;
  value: string;
  type: 'range' | 'dropdown';
  options?: string[];
  category: ActionCategory;
  id?: string;
  showValueField?: boolean;
}

export interface ActiveAction {
  action: ActionOption;
  value: any;
}

export enum StrategyFiledNames {
  daysPastDue = 'daysPastDue',
  aiCustomerSegment = 'aiCustomerSegment',
  amount = 'dueAmount',
}

export enum StrategyFiledNameTypes {
  min_max = 'min_max',
  dropdown = 'dropdown',
  singleValue = 'singleValue',
}

export enum StrategyFiledLabels {
  daysPastDue = 'Days Past Due',
  aiCustomerSegment = 'AI Customer Segment',
  amount = 'Amount',
}

export const fieldNameTypeMapping: Record<StrategyFiledNames, StrategyFiledNameTypes> = {
  [StrategyFiledNames.daysPastDue]: StrategyFiledNameTypes.min_max,
  [StrategyFiledNames.amount]: StrategyFiledNameTypes.min_max,
  [StrategyFiledNames.aiCustomerSegment]: StrategyFiledNameTypes.dropdown,
};

export const fieldNameLabelMapping: Record<StrategyFiledNames, StrategyFiledLabels> = {
  [StrategyFiledNames.daysPastDue]: StrategyFiledLabels.daysPastDue,
  [StrategyFiledNames.amount]: StrategyFiledLabels.amount,
  [StrategyFiledNames.aiCustomerSegment]: StrategyFiledLabels.aiCustomerSegment,
};

export interface StrategyRule {
  fieldName: StrategyFiledNames;
  type: StrategyFiledNameTypes;
  value?: string | number | number[] | string[];
  label: StrategyFiledLabels;
  options?: string[];
}

export const AllRules: StrategyRule[] = [
  {
    fieldName: StrategyFiledNames.daysPastDue,
    type: fieldNameTypeMapping[StrategyFiledNames.daysPastDue],
    value: [0, 30],
    label: fieldNameLabelMapping[StrategyFiledNames.daysPastDue],
  },
  {
    fieldName: StrategyFiledNames.aiCustomerSegment,
    type: fieldNameTypeMapping[StrategyFiledNames.aiCustomerSegment],
    value: ['High Value', 'Low Value'],
    label: fieldNameLabelMapping[StrategyFiledNames.aiCustomerSegment],
  },
  {
    fieldName: StrategyFiledNames.daysPastDue,
    type: fieldNameTypeMapping[StrategyFiledNames.daysPastDue],
    value: [0, 0],
    label: fieldNameLabelMapping[StrategyFiledNames.daysPastDue],
  },
  {
    fieldName: StrategyFiledNames.amount,
    type: fieldNameTypeMapping[StrategyFiledNames.amount],
    value: [0, 0],
    label: fieldNameLabelMapping[StrategyFiledNames.amount],
  },
];

export interface Strategy {
  name: string;
  id: string;
  rules: StrategyRule[];
}

interface StrategyBuilderSlice {
  currentStrategyView: StrategyViews;
  currentStrategyName: string;
  strategies: any[];
  editingActionName: string;
  editingActionId: string;
  strategyList: any[];
  isEditingStrategies: boolean;
  isViewingStrategy: boolean;
}

const initialState: StrategyBuilderSlice = {
  currentStrategyView: StrategyViews.LANDING,
  currentStrategyName: '',
  strategies: [],
  editingActionName: '',
  editingActionId: '',
  strategyList: [],
  isEditingStrategies: false,
  isViewingStrategy: false,
};

const StrategyBuilderSlice = createSlice({
  name: 'StrategyBuilderStateNew',
  initialState,
  reducers: {
    setCurrentStrategyView: (state, action: PayloadAction<StrategyViews>) => {
      state.currentStrategyView = action.payload;
    },
    setCurrentStrategyName: (state, action: PayloadAction<string>) => {
      state.currentStrategyName = action.payload;
    },
    setStrategies: (state, action: PayloadAction<any[]>) => {
      state.strategies = action.payload;
    },
    setStrategyList: (state, action: PayloadAction<any[]>) => {
      state.strategyList = action.payload;
    },
    setEditingActionName: (state, action: PayloadAction<string>) => {
      state.editingActionName = action.payload;
    },
    setEditingActionId: (state, action: PayloadAction<string>) => {
      state.editingActionId = action.payload;
    },
    setIsEditingStrategies: (state, action: PayloadAction<boolean>) => {
      state.isEditingStrategies = action.payload;
    },
    setIsViewingStrategy: (state, action: PayloadAction<boolean>) => {
      state.isViewingStrategy = action.payload;
    },
  },
});

export const {
  setCurrentStrategyView,
  setCurrentStrategyName,
  setStrategies,
  setEditingActionName,
  setStrategyList,
  setIsEditingStrategies,
  setEditingActionId,
  setIsViewingStrategy,
} = StrategyBuilderSlice.actions;

export default StrategyBuilderSlice.reducer;
