import IconStyles from 'apps/webapp/src/types/iconStyles';

const SheetIcon = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';

  return (
    <svg width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.38317 15.8371L1.36367 16.8657C1.28518 16.9047 1.192 16.9001 1.11778 16.8534C1.04356 16.8067 0.999017 16.7248 1.00023 16.6371V2.09146C0.98811 1.50105 1.45671 1.01249 2.04712 1H12.524C13.1144 1.01249 13.583 1.50105 13.5709 2.09146V16.6371C13.5721 16.7248 13.5276 16.8067 13.4534 16.8534C13.3792 16.9001 13.286 16.9047 13.2075 16.8657L11.188 15.8371C11.1076 15.796 11.0111 15.8021 10.9366 15.8531L9.31136 16.9571C9.22721 17.0143 9.11665 17.0143 9.03249 16.9571L7.42558 15.8656C7.34143 15.8084 7.23087 15.8084 7.14672 15.8656L5.53981 16.9571C5.45565 17.0143 5.34509 17.0143 5.26094 16.9571L3.6346 15.8576C3.56089 15.8052 3.46442 15.7973 3.38317 15.8371Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.85742 8.25019C3.44321 8.25019 3.10742 8.58598 3.10742 9.00019C3.10742 9.41441 3.44321 9.75019 3.85742 9.75019V8.25019ZM10.7148 9.75019C11.129 9.75019 11.4648 9.41441 11.4648 9.00019C11.4648 8.58598 11.129 8.25019 10.7148 8.25019V9.75019ZM4.99996 10.536C4.58574 10.536 4.24996 10.8718 4.24996 11.286C4.24996 11.7002 4.58574 12.036 4.99996 12.036V10.536ZM9.57153 12.036C9.98575 12.036 10.3215 11.7002 10.3215 11.286C10.3215 10.8718 9.98575 10.536 9.57153 10.536V12.036ZM4.99996 5.96436C4.58574 5.96436 4.24996 6.30014 4.24996 6.71436C4.24996 7.12857 4.58574 7.46436 4.99996 7.46436V5.96436ZM9.57153 7.46436C9.98575 7.46436 10.3215 7.12857 10.3215 6.71436C10.3215 6.30014 9.98575 5.96436 9.57153 5.96436V7.46436ZM3.85742 9.75019H10.7148V8.25019H3.85742V9.75019ZM4.99996 12.036H9.57153V10.536H4.99996V12.036ZM4.99996 7.46436H9.57153V5.96436H4.99996V7.46436Z'
        fill={color}
      />
    </svg>
  );
};

export default SheetIcon;
