import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientType, FileType, INVOICE_STATUS } from '@finance-ops/types';
import { clientSelectors } from '../store/client';
import {
  ChatCommunicationTypes,
  selectSelectedCustomer,
  selectedCustomerContext,
  setAddNewMail,
  setCommunicationType,
} from '../store/customercontext';
import { notify } from '../notification/Notifications';
import { Track } from '../@core/track';
import { httpGet } from '../httpClient';
import { setInvoiceAttachment } from '../store/email';

export enum LinkTypes {
  payment = 'payment',
  invoice = 'invoice',
  downloadInvoice = 'downloadInvoice',
  sendInvoice = 'sendInvoice',
  charge = 'charge',
  generatePayment = 'generatePayment',
}

type InteractionValuesType = {
  handleMenuSelection: (type: LinkTypes) => void;
  isDownloadingInvoice: boolean;
  handlePaymentShortLink: () => void;
  handleDownloadInvoice: () => Promise<void>;
  handleSendInvoice: () => Promise<void>;
  handleInvoiceGenerationClick: () => void;
};

const defaultValues: InteractionValuesType = {
  handleMenuSelection: (type: LinkTypes) => Promise.resolve(),
  isDownloadingInvoice: false,
  handlePaymentShortLink: () => null,
  handleDownloadInvoice: () => Promise.resolve(),
  handleSendInvoice: () => Promise.resolve(),
  handleInvoiceGenerationClick: () => null,
};

const InteractionContext = React.createContext(defaultValues);

type Props = {
  children: ReactNode;
};

const InteractionProvider = ({ children }: Props) => {
  const clients: ClientType[] = useSelector(clientSelectors.selectAll);
  const selectedCustomerCtx = useSelector(selectedCustomerContext);
  const [isDownloadingInvoice, setIsDownloadingInvoice] = React.useState<boolean>(false);
  const clientInfo = clients.find(client => client.id === selectedCustomerCtx?.task.clientId);
  const selectedChat = useSelector(selectSelectedCustomer);
  const dispatch = useDispatch();

  const handleInvoiceGenerationClick = async () => {
    await navigator.clipboard.writeText(
      `https://${clientInfo?.metadata.hostName}/invoice/verify-otp/?id=${selectedChat?._id}`,
    );
    notify({ msg: { content: 'Invoice link copied.' }, playAudio: false });
  };

  const selectedInvoice = selectedCustomerCtx?.invoices?.find(
    invoice =>
      invoice.counterPartyId === selectedChat?._id.toString() &&
      (invoice.status === INVOICE_STATUS.UNPAID || invoice.status === INVOICE_STATUS.PARTIALLY_PAID),
  );

  const handlePaymentShortLink = async () => {
    if (!selectedChat) return;
    Track.getInstance().clickedOnPaymentGenerationLink({
      customerId: selectedChat._id.toString(),
      clientId: selectedChat.clientId.toString(),
    });
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

    const clientName = isLocalhost ? 'http://localhost:4200' : `https://${clientInfo?.metadata.hostName}`;
    await navigator.clipboard.writeText(
      `${clientName}/pay/?invoice=${
        selectedInvoice?._id.toString() ?? selectedCustomerCtx?.invoices[0]?._id.toString()
      }`,
    );
    notify({ msg: { content: 'Payment link copied.' }, playAudio: false });
  };

  const handleDownloadInvoice = async () => {
    const res = await httpGet(`/downloadInvoice?id=${selectedChat?._id}`);
    const fileType: FileType = res.data;
    const fileRes = await httpGet(`/files/${fileType.key}`);
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + fileRes.data;
    a.download = fileType.filename as string;
    a.click();
  };

  const handleSendInvoice = async () => {
    const res = await httpGet(`/downloadInvoice?id=${selectedChat?._id}`);
    const fileType: FileType = res.data;
    const fileRes = await httpGet(`/files/${fileType.key}`);
    dispatch(
      setInvoiceAttachment({
        filename: fileType.key,
        path: 'data:application/pdf;base64,' + fileRes.data,
      }),
    );
    dispatch(setCommunicationType(ChatCommunicationTypes.emails));
    dispatch(setAddNewMail(true));
  };

  const handleMenuSelection = async (type: LinkTypes) => {
    if (type === LinkTypes.invoice) {
      handleInvoiceGenerationClick();
    } else if (type === LinkTypes.payment) {
      handlePaymentShortLink();
    } else if (type === LinkTypes.downloadInvoice) {
      setIsDownloadingInvoice(true);
      notify({ msg: { content: 'Download in progress, please wait' }, type: 'info', playAudio: false });
      await handleDownloadInvoice();
      setIsDownloadingInvoice(false);
    } else if (type === LinkTypes.sendInvoice) {
      setIsDownloadingInvoice(true);
      notify({ msg: { content: 'Setting up email with invoice, please wait' }, type: 'info', playAudio: false });
      await handleSendInvoice();
      setIsDownloadingInvoice(false);
    }
  };
  const values = {
    handleMenuSelection,
    isDownloadingInvoice,
    handlePaymentShortLink,
    handleDownloadInvoice,
    handleSendInvoice,
    handleInvoiceGenerationClick,
  };

  return <InteractionContext.Provider value={values}>{children}</InteractionContext.Provider>;
};

export { InteractionContext, InteractionProvider };
