import { ObjectId } from 'bson';
import { DIALECT, LANGUAGE } from '../common';

export type TemplateType = {
  id?: string;
  name: string;
  displayName: string;
  type: TEMPLATE_TYPE[];
  clientId: ObjectId;
  language: LANGUAGE;
  dialect?: DIALECT;
  namespace: string;
  parameters: string[];
  preview: string;
  previewsTranslated: Record<LANGUAGE, string>;
  createdBy: string;
  updatedBy: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type TemplateDocumentType = Omit<TemplateType, 'id'> & {
  _id: string;
};

export enum TEMPLATE_TYPE {
  WHATSAPP = 'whatsapp',
  SMS = 'sms',
  EMAIL = 'email',
  NOTIFICATION_EMAIL = 'notification-email',
  NOTIFICATION_SMS = 'notification-sms',
}
