import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '.';
import { CustomerType, TemplateType } from '@finance-ops/types';

type TemplateStateItems = {
  selectedTemplateId: string | undefined;
};

interface TemplateState extends EntityState<TemplateType>, TemplateStateItems {}

const templateEntityAdapter = createEntityAdapter<TemplateType>({
  selectId: entity => entity.id ?? '',
});

const initialState: TemplateState = {
  ...templateEntityAdapter.getInitialState(),
  selectedTemplateId: undefined,
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplates: templateEntityAdapter.setAll,
    setSelectedTemplateId: (state, action) => {
      state.selectedTemplateId = action.payload;
    },
  },
});

export const templateSelectors = templateEntityAdapter.getSelectors((state: RootState) => state.template);
const selectedTemplateId = (state: RootState) => state.template.selectedTemplateId;
export const selectedTemplate = createSelector(
  templateSelectors.selectAll,
  selectedTemplateId,
  (templates: TemplateType[], selectedTemplateId: string | undefined) => {
    return templates.find(template => template.id === selectedTemplateId);
  },
);

export const selectTemplatesByCustomer = (customer?: CustomerType) => {
  return createSelector(templateSelectors.selectAll, (templates: TemplateType[]) => {
    if (!customer) {
      return [];
    }
    return templates.filter(
      template =>
        template.clientId === customer.clientId &&
        template.language === customer.language &&
        template.dialect === customer.dialect,
    );
  });
};

export const { setTemplates, setSelectedTemplateId } = templateSlice.actions;

export default templateSlice.reducer;
