const WriteNewMailIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3272 1.11846L13.7811 2.27626C13.9216 2.40842 14.0007 2.593 13.9997 2.78582C13.9986 2.97864 13.9174 3.16234 13.7756 3.29294L12.6707 4.61509L8.91129 9.0987C8.84553 9.17402 8.75789 9.22693 8.6606 9.25004L6.71081 9.69199C6.455 9.70479 6.23231 9.51887 6.19922 9.26489V7.26124C6.20586 7.16465 6.24477 7.07309 6.30971 7.00127L9.99947 2.82964L11.2659 1.31715C11.5165 0.98873 11.9748 0.902924 12.3272 1.11846Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.06573 3.48486C7.47995 3.48486 7.81573 3.14908 7.81573 2.73486C7.81573 2.32065 7.47995 1.98486 7.06573 1.98486V3.48486ZM4.466 2.73486V1.98486L4.4642 1.98487L4.466 2.73486ZM1.00001 6.21664H1.75001L1.75001 6.21504L1.00001 6.21664ZM1.00001 11.4393L1.75001 11.4409V11.4393H1.00001ZM4.466 14.9211L4.4642 15.6711H4.466V14.9211ZM9.66546 14.9211V15.6711L9.66726 15.6711L9.66546 14.9211ZM13.1315 11.4393H12.3815L12.3815 11.4409L13.1315 11.4393ZM13.8815 7.95753C13.8815 7.54332 13.5457 7.20753 13.1315 7.20753C12.7172 7.20753 12.3815 7.54332 12.3815 7.95753H13.8815ZM10.6929 2.54331C10.5348 2.16043 10.0963 1.97819 9.71344 2.13624C9.33057 2.2943 9.14832 2.73281 9.30638 3.11568L10.6929 2.54331ZM12.8327 5.34356C13.2372 5.25415 13.4926 4.8538 13.4032 4.44935C13.3138 4.0449 12.9134 3.78951 12.509 3.87892L12.8327 5.34356ZM3.59934 11.5602C3.18513 11.5602 2.84934 11.896 2.84934 12.3102C2.84934 12.7244 3.18513 13.0602 3.59934 13.0602V11.5602ZM10.5323 13.0602C10.9465 13.0602 11.2823 12.7244 11.2823 12.3102C11.2823 11.896 10.9465 11.5602 10.5323 11.5602V13.0602ZM7.06573 1.98486H4.466V3.48486H7.06573V1.98486ZM4.4642 1.98487C2.1316 1.99047 0.245016 3.88564 0.25001 6.21825L1.75001 6.21504C1.74679 4.7107 2.96347 3.48848 4.46781 3.48486L4.4642 1.98487ZM0.250008 6.21664V11.4393H1.75001V6.21664H0.250008ZM0.25001 11.4377C0.245016 13.7703 2.1316 15.6655 4.4642 15.6711L4.46781 14.1711C2.96347 14.1675 1.74679 12.9453 1.75001 11.4409L0.25001 11.4377ZM4.466 15.6711H9.66546V14.1711H4.466V15.6711ZM9.66726 15.6711C11.9999 15.6655 13.8864 13.7703 13.8815 11.4377L12.3815 11.4409C12.3847 12.9453 11.168 14.1675 9.66366 14.1711L9.66726 15.6711ZM13.8815 11.4393V7.95753H12.3815V11.4393H13.8815ZM9.30638 3.11568C9.48977 3.55992 9.86333 4.18874 10.4227 4.67543C10.9957 5.17398 11.8234 5.56668 12.8327 5.34356L12.509 3.87892C12.0987 3.96962 11.7403 3.83355 11.4073 3.54382C11.0607 3.24224 10.8085 2.82328 10.6929 2.54331L9.30638 3.11568ZM3.59934 13.0602H10.5323V11.5602H3.59934V13.0602Z'
        fill='white'
      />
    </svg>
  );
};

export default WriteNewMailIcon;
