import { TaskType, UpdateTaskDtoType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { upsertCustomerContextTask } from '../customercontext';
import { baseQuery } from './baseQuery';

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: baseQuery,
  tagTypes: ['tasks'],
  endpoints: builder => ({
    updateTaskMode: builder.mutation<TaskType, { taskId: string; update: UpdateTaskDtoType }>({
      query: ({ taskId, update }) => ({
        url: `/v2/tasks/${taskId}/update/mode`,
        method: 'PATCH',
        body: update,
      }),
      async onQueryStarted({ taskId, update }, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(upsertCustomerContextTask(response.data));
        } catch (error) {
          console.error('Error updating task mode:', error);
        }
      },
    }),
    updateTaskStatus: builder.mutation<TaskType, { taskId: string; update: UpdateTaskDtoType }>({
      query: ({ taskId, update }) => ({
        url: `/v2/tasks/${taskId}/update/status`,
        method: 'PATCH',
        body: update,
      }),
      async onQueryStarted({ taskId, update }, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(upsertCustomerContextTask(response.data));
        } catch (error) {
          console.error('Error updating task status:', error);
        }
      },
    }),
  }),
});

export const { useUpdateTaskModeMutation, useUpdateTaskStatusMutation } = tasksApi;
