const MaintainanceIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='191' height='233' viewBox='0 0 191 233' fill='none'>
      <path
        d='M6.0863 210.003C6.0863 197.703 44.7863 187.603 92.4863 187.603C140.186 187.603 178.886 197.703 178.886 210.103C178.87 211.507 178.415 212.871 177.586 214.003C170.386 224.503 134.986 232.503 92.4863 232.503C44.7863 232.503 6.0863 222.403 6.0863 210.003Z'
        fill='#7A97F4'
      />
      <path
        d='M6.0863 205.903C6.0863 193.603 44.7863 183.503 92.4863 183.503C140.186 183.503 178.886 193.603 178.886 206.003C178.87 207.407 178.415 208.771 177.586 209.903C170.386 220.403 134.986 228.403 92.4863 228.403C44.7863 228.403 6.0863 218.303 6.0863 205.903Z'
        fill='#E1ECFF'
      />
      <path
        d='M177.586 209.903C170.386 220.403 135.086 228.403 92.4863 228.403C44.7863 228.403 6.0863 218.303 6.0863 205.903C6.0863 205.903 21.0863 224.003 89.4863 224.003C148.286 224.103 171.986 213.003 177.586 209.903Z'
        fill='#C2D1FF'
      />
      <path
        d='M141.686 200.703C138.386 197.103 117.586 194.303 92.4863 194.303C66.2863 194.303 44.8863 197.303 42.9863 201.103C43.8863 198.903 49.8863 191.303 92.4863 191.303C131.686 191.303 139.986 198.103 141.686 200.703Z'
        fill='#C2D1FF'
      />
      <path
        opacity='0.28'
        d='M52.9872 101.3L32.4872 205.3C31.3872 210.9 34.9872 216.2 39.8872 216.2H148.487C153.287 216.2 156.887 211.1 155.987 205.7L139.187 101.7C138.487 97.5003 135.387 94.5003 131.687 94.5003H60.4872C56.8872 94.4003 53.7872 97.3003 52.9872 101.3Z'
        fill='#FEC272'
      />
      <path
        d='M145.585 107.803L155.485 140.703C155.524 140.87 155.599 141.025 155.705 141.159C155.811 141.293 155.945 141.402 156.098 141.478C156.251 141.555 156.418 141.596 156.589 141.601C156.759 141.605 156.929 141.572 157.085 141.503V141.503C157.355 141.399 157.576 141.197 157.705 140.938C157.835 140.68 157.863 140.382 157.785 140.103L151.285 106.403C151.182 105.978 150.989 105.58 150.718 105.237C150.448 104.894 150.106 104.613 149.717 104.413C149.328 104.214 148.9 104.101 148.463 104.082C148.026 104.063 147.59 104.138 147.185 104.303V104.303C146.541 104.595 146.024 105.111 145.73 105.754C145.436 106.397 145.385 107.125 145.585 107.803V107.803Z'
        fill='#FEC272'
      />
      <path
        d='M126.885 119.702L133.285 145.102C133.308 145.209 133.353 145.31 133.417 145.399C133.481 145.488 133.562 145.562 133.656 145.619C133.749 145.675 133.853 145.711 133.962 145.726C134.07 145.74 134.18 145.732 134.285 145.702V145.702C134.378 145.678 134.465 145.635 134.541 145.575C134.617 145.516 134.68 145.443 134.727 145.359C134.773 145.275 134.803 145.182 134.813 145.086C134.823 144.991 134.813 144.894 134.785 144.802L130.685 118.902C130.635 118.61 130.52 118.333 130.349 118.092C130.177 117.85 129.954 117.65 129.695 117.506C129.435 117.362 129.147 117.278 128.852 117.26C128.556 117.242 128.26 117.291 127.985 117.402V117.402C127.573 117.61 127.241 117.948 127.042 118.364C126.843 118.781 126.788 119.251 126.885 119.702V119.702Z'
        fill='#FEC272'
      />
      <path
        d='M43.4853 111.003L35.1853 136.303C35.1555 136.415 35.1013 136.519 35.0266 136.607C34.9519 136.695 34.8586 136.766 34.7535 136.813C34.6483 136.861 34.5338 136.885 34.4183 136.883C34.3028 136.882 34.189 136.854 34.0853 136.803V136.803C33.8985 136.721 33.7463 136.576 33.655 136.394C33.5638 136.211 33.5391 136.002 33.5853 135.803L39.5853 109.903C39.6617 109.618 39.798 109.351 39.985 109.122C40.172 108.893 40.4056 108.706 40.6702 108.573C40.9349 108.441 41.2246 108.366 41.5203 108.354C41.8159 108.342 42.1108 108.393 42.3853 108.503V108.503C42.8441 108.712 43.21 109.082 43.4129 109.543C43.6159 110.005 43.6416 110.524 43.4853 111.003V111.003Z'
        fill='#FEC272'
      />
      <path
        d='M59.9853 118.802L49.1853 165.802C49.1621 165.909 49.1173 166.01 49.0537 166.099C48.99 166.188 48.9088 166.263 48.8151 166.319C48.7214 166.375 48.6172 166.411 48.5089 166.426C48.4006 166.44 48.2904 166.432 48.1853 166.402V166.402C48.0924 166.378 48.0055 166.335 47.9297 166.276C47.8539 166.216 47.7907 166.143 47.7441 166.059C47.6974 165.975 47.6681 165.882 47.658 165.787C47.6479 165.691 47.6572 165.594 47.6853 165.502L56.1853 118.002C56.2356 117.71 56.3506 117.433 56.5221 117.192C56.6936 116.95 56.917 116.75 57.1761 116.606C57.4351 116.462 57.7232 116.378 58.019 116.36C58.3148 116.342 58.6108 116.391 58.8853 116.502V116.502C59.3329 116.666 59.6986 116.998 59.9042 117.428C60.1098 117.858 60.1389 118.351 59.9853 118.802V118.802Z'
        fill='#FEC272'
      />
      <path
        d='M82.8853 124.299L77.6853 158.399C77.6714 158.511 77.6322 158.618 77.5709 158.712C77.5096 158.806 77.4276 158.885 77.3313 158.943C77.235 159.001 77.1268 159.036 77.0148 159.045C76.9029 159.055 76.7902 159.04 76.6853 158.999V158.999C76.4989 158.938 76.3384 158.816 76.2296 158.653C76.1208 158.49 76.07 158.295 76.0853 158.099L78.8853 123.699C78.929 123.412 79.0347 123.138 79.1952 122.896C79.3556 122.653 79.5669 122.449 79.8144 122.297C80.0619 122.144 80.3395 122.048 80.6281 122.014C80.9167 121.98 81.2092 122.009 81.4853 122.099V122.099C81.7218 122.152 81.9457 122.25 82.1441 122.389C82.3425 122.528 82.5116 122.705 82.6416 122.909C82.7717 123.113 82.8601 123.341 82.9019 123.58C82.9438 123.819 82.9381 124.063 82.8853 124.299V124.299Z'
        fill='#FEC272'
      />
      <path
        d='M109.685 123.603L110.985 177.703C110.984 177.817 110.958 177.928 110.911 178.031C110.864 178.134 110.796 178.226 110.711 178.301C110.627 178.377 110.527 178.433 110.42 178.468C110.312 178.503 110.198 178.515 110.085 178.503V178.503C109.895 178.47 109.722 178.372 109.595 178.227C109.467 178.081 109.394 177.897 109.385 177.703L105.685 123.603C105.677 123.312 105.734 123.023 105.851 122.756C105.968 122.489 106.143 122.251 106.363 122.06C106.583 121.869 106.842 121.729 107.123 121.65C107.403 121.571 107.698 121.555 107.985 121.603V121.603C108.452 121.697 108.874 121.945 109.183 122.308C109.491 122.671 109.668 123.127 109.685 123.603Z'
        fill='#FEC272'
      />
      <path
        d='M5.7863 74.5034C4.77932 73.3232 3.93821 72.0111 3.2863 70.6034L0.986298 55.2034L6.2863 60.1034L5.7863 74.5034Z'
        fill='#8784FF'
      />
      <path
        d='M154.986 79.7034C152.179 85.0326 148.645 89.9462 144.486 94.3034C135.486 103.903 120.286 114.803 97.6863 114.803C81.7863 114.803 69.9863 109.503 61.5863 103.103C53.6802 97.1229 47.3622 89.2941 43.1863 80.3034L154.986 79.7034Z'
        fill='#1F1933'
      />
      <path
        d='M154.986 79.7034C152.179 85.0326 148.645 89.9462 144.486 94.3034C135.133 97.8053 125.399 100.188 115.486 101.403C92.2863 104.503 74.1863 104.303 61.5863 103.103C53.6802 97.1229 47.3622 89.2941 43.1863 80.3034L154.986 79.7034Z'
        fill='#6461F3'
      />
      <path
        d='M190.186 54.6034C189.65 59.3585 188.782 64.07 187.586 68.7034C187.247 69.9807 186.707 71.1958 185.986 72.3034C183.886 75.7034 179.886 79.4034 173.386 82.9034C172.286 83.5034 170.986 84.1034 169.686 84.7034C168.686 85.2034 167.686 85.6034 166.586 86.1034C165.086 86.7034 163.586 87.3034 161.986 87.9034C155.45 90.1297 148.767 91.9006 141.986 93.2034C141.386 93.3034 140.886 93.4034 140.286 93.5034C138.786 93.8034 136.986 94.0034 135.086 94.2034C127.086 95.1034 116.486 95.7034 105.186 95.9034C103.086 95.9034 100.986 96.0034 98.8863 96.0034C88.1863 96.1034 77.3863 95.8034 67.9863 95.3034C65.9863 95.2034 63.9863 95.1034 62.1863 94.9034C57.5568 94.6094 52.9488 94.0417 48.3863 93.2034C43.2863 92.2034 38.6863 91.1034 34.5863 89.9034C32.8863 89.4034 31.2863 88.9034 29.6863 88.4034C17.2863 84.3034 9.7863 79.3034 5.8863 74.7034C5.32337 74.0864 4.82106 73.4166 4.3863 72.7034C3.97586 72.1138 3.63997 71.4756 3.3863 70.8034C3.06488 70.1709 2.82946 69.4983 2.6863 68.8034C1.83313 65.3806 1.19852 61.9069 0.786301 58.4034C0.386301 55.1034 0.286301 52.9034 0.286301 52.9034L95.2863 60.0034L190.286 54.7034L190.186 54.6034Z'
        fill='#99ADF9'
      />
      <path
        d='M190.086 54.4034C189.914 55.4278 189.424 56.3723 188.686 57.1034C186.186 60.0034 180.186 64.3034 169.986 68.2034C168.686 68.7034 167.286 69.2034 165.786 69.7034C165.086 69.9034 164.386 70.2034 163.686 70.4034C162.186 70.9034 160.586 71.4034 158.886 71.8034C152.351 73.635 145.704 75.038 138.986 76.0034C137.186 76.3034 135.286 76.6034 133.286 76.8034C123.897 77.9927 114.449 78.6604 104.986 78.8034C102.986 78.8034 100.886 78.9034 98.7863 78.9034H98.6863C87.9863 78.9034 78.0863 78.4034 69.1863 77.6034C67.1863 77.4034 65.1863 77.2034 63.2863 77.0034C54.5353 76.0399 45.8521 74.537 37.2863 72.5034C35.5863 72.1034 33.8863 71.6034 32.2863 71.2034C17.3863 67.1034 7.5863 62.0034 3.0863 57.8034C2.29942 57.0838 1.59508 56.2788 0.986304 55.4034C0.441509 54.6796 0.127833 53.8083 0.0863037 52.9034C0.0863037 45.8034 28.0863 30.7034 99.1863 30.7034C170.286 30.6034 190.086 49.5034 190.086 54.4034Z'
        fill='#1F1933'
      />
      <path
        d='M58.1863 38.8034L6.18631 60.1034C4.20203 58.7225 2.41845 57.0735 0.886307 55.2034L56.4863 37.9034L58.1863 38.8034Z'
        fill='#6461F3'
      />
      <path
        d='M190.086 54.5034C189.886 55.4892 189.399 56.3938 188.686 57.1034L135.286 38.8034L136.986 38.0034L190.086 54.5034Z'
        fill='#6461F3'
      />
      <path
        d='M66.9863 44.3034L37.2863 72.4034C35.5863 72.0034 33.8863 71.5034 32.2863 71.1034L65.1863 44.2034L66.9863 44.3034Z'
        fill='#6461F3'
      />
      <path
        d='M83.2863 47.6034L69.1863 77.6034C67.1863 77.4034 65.1863 77.2034 63.2863 77.0034L81.4863 47.5034L83.2863 47.6034Z'
        fill='#6461F3'
      />
      <path
        d='M139.086 76.1034C137.286 76.4034 135.286 76.7034 133.386 76.9034L119.586 47.6034L121.486 47.5034L139.086 76.1034Z'
        fill='#6461F3'
      />
      <path
        d='M104.986 78.9034C102.986 78.9034 100.886 79.0034 98.7863 79.0034H98.6863L101.086 49.5034L102.986 49.4034L104.986 78.9034Z'
        fill='#6461F3'
      />
      <path
        d='M169.886 68.2034C168.586 68.7034 167.186 69.2034 165.686 69.7034L129.786 44.3034L131.686 44.2034L169.886 68.2034Z'
        fill='#6461F3'
      />
      <path
        d='M105.086 95.7033C102.986 95.7033 100.886 95.8033 98.7863 95.8033V78.9033L104.986 78.8033L105.086 95.7033Z'
        fill='#8784FF'
      />
      <path
        d='M140.086 93.3034C138.486 93.6034 136.786 93.8034 134.886 94.0034L133.286 76.8034L138.986 76.0034L140.086 93.3034Z'
        fill='#8784FF'
      />
      <path
        d='M173.286 82.8034C172.186 83.4034 170.886 84.0034 169.586 84.6034L165.786 69.6034L169.886 68.2034L173.286 82.8034Z'
        fill='#8784FF'
      />
      <path
        d='M69.1863 77.6034L67.9863 95.1034C65.9863 95.0034 63.9863 94.9034 62.1863 94.7034L63.3863 77.0034L69.1863 77.6034Z'
        fill='#8784FF'
      />
      <path
        d='M37.2863 72.4034L34.4863 89.7034C32.7863 89.2034 31.1863 88.7034 29.5863 88.2034L32.1863 71.1034L37.2863 72.4034Z'
        fill='#8784FF'
      />
      <path
        d='M6.28631 60.3033L4.28631 72.6033C3.87587 72.0137 3.53998 71.3755 3.28631 70.7033C2.96489 70.0709 2.72947 69.3982 2.58631 68.7033C1.73314 65.2805 1.09853 61.8068 0.68631 58.3033L0.98631 55.3033C0.98631 55.3033 2.68631 57.3033 3.38631 58.0033C3.98631 58.6033 6.28631 60.3033 6.28631 60.3033Z'
        fill='#8784FF'
      />
      <path
        d='M94.4863 61.4034C123.923 61.4034 147.786 54.6429 147.786 46.3034C147.786 37.9639 123.923 31.2034 94.4863 31.2034C65.0495 31.2034 41.1863 37.9639 41.1863 46.3034C41.1863 54.6429 65.0495 61.4034 94.4863 61.4034Z'
        fill='#6461F3'
      />
      <path
        d='M143.186 43.9034C143.186 43.9034 139.286 48.3034 129.686 52.1034C122.586 54.9034 112.386 57.3034 98.3863 57.3034C91.6773 57.3477 84.9796 56.7449 78.3863 55.5034C59.6863 51.9034 51.5863 44.1034 51.5863 44.1034C51.5863 44.1034 55.6863 13.7034 81.2863 3.40342C87.2096 1.08304 93.5252 -0.0714334 99.8863 0.00342015C135.086 0.00342015 143.186 43.9034 143.186 43.9034Z'
        fill='#99ADF9'
      />
      <path
        d='M78.3863 55.5034C59.6863 51.9034 51.5863 44.1034 51.5863 44.1034C51.5863 44.1034 55.6863 13.7034 81.2863 3.40344C76.2863 7.60344 71.3863 16.0034 70.5863 29.7034C69.7863 39.9034 72.1863 48.4034 78.3863 55.5034Z'
        fill='#7A97F4'
      />
      <path
        d='M143.186 43.9034C143.186 43.9034 139.286 48.3034 129.686 52.1034C128.685 48.4804 127.415 44.9372 125.886 41.5034C125.886 41.5034 116.486 43.5034 112.286 34.6034C108.086 25.7034 118.186 19.3034 118.186 19.3034C118.186 19.3034 115.786 11.6034 103.386 6.60342C90.9863 1.60342 81.0863 3.40342 81.0863 3.40342C87.0096 1.08304 93.3252 -0.0714334 99.6863 0.00342015C135.086 0.00342015 143.186 43.9034 143.186 43.9034Z'
        fill='#C2D1FF'
      />
      <path
        d='M102.886 25.1034C104.377 25.1034 105.586 23.9394 105.586 22.5034C105.586 21.0675 104.377 19.9034 102.886 19.9034C101.395 19.9034 100.186 21.0675 100.186 22.5034C100.186 23.9394 101.395 25.1034 102.886 25.1034Z'
        fill='#C2D1FF'
      />
      <path
        d='M93.4863 27.6034C94.6461 27.6034 95.5863 26.708 95.5863 25.6034C95.5863 24.4988 94.6461 23.6034 93.4863 23.6034C92.3265 23.6034 91.3863 24.4988 91.3863 25.6034C91.3863 26.708 92.3265 27.6034 93.4863 27.6034Z'
        fill='#C2D1FF'
      />
      <path
        d='M99.7863 41.1034C102.051 41.1034 103.886 39.3125 103.886 37.1034C103.886 34.8943 102.051 33.1034 99.7863 33.1034C97.5219 33.1034 95.6863 34.8943 95.6863 37.1034C95.6863 39.3125 97.5219 41.1034 99.7863 41.1034Z'
        fill='#C2D1FF'
      />
    </svg>
  );
};

export default MaintainanceIcon;
