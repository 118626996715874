import IconStyles from 'apps/webapp/src/types/iconStyles';

const WorkListIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.93632 13.0551L1.29548 13.8909C1.23171 13.9226 1.156 13.9188 1.0957 13.8809C1.03539 13.843 0.999201 13.7764 1.00019 13.7051V1.88681C0.99034 1.4071 1.37108 1.01015 1.85079 1H10.3633C10.843 1.01015 11.2237 1.4071 11.2139 1.88681V13.7051C11.2149 13.7764 11.1787 13.843 11.1184 13.8809C11.0581 13.9188 10.9824 13.9226 10.9186 13.8909L9.27774 13.0551C9.21239 13.0218 9.13405 13.0267 9.07345 13.0681L7.75298 13.9651C7.6846 14.0116 7.59477 14.0116 7.5264 13.9651L6.22079 13.0783C6.15241 13.0318 6.06258 13.0318 5.99421 13.0783L4.68859 13.9651C4.62022 14.0116 4.53039 14.0116 4.46201 13.9651L3.14061 13.0718C3.08072 13.0293 3.00234 13.0228 2.93632 13.0551Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.78467 8.85736C3.50853 8.85736 3.28467 9.08121 3.28467 9.35736C3.28467 9.6335 3.50853 9.85736 3.78467 9.85736V8.85736ZM8.34782 9.85736C8.62396 9.85736 8.84782 9.6335 8.84782 9.35736C8.84782 9.08121 8.62396 8.85736 8.34782 8.85736V9.85736ZM4.60267 5.75365C4.4074 5.55839 4.09082 5.55839 3.89556 5.75365C3.7003 5.94891 3.7003 6.26549 3.89556 6.46076L4.60267 5.75365ZM5.17772 7.03581L4.82416 7.38936C5.01942 7.58462 5.33601 7.58462 5.53127 7.38936L5.17772 7.03581ZM8.31707 4.60355C8.51234 4.40829 8.51234 4.09171 8.31707 3.89645C8.12181 3.70118 7.80523 3.70118 7.60997 3.89645L8.31707 4.60355ZM3.78467 9.85736H8.34782V8.85736H3.78467V9.85736ZM3.89556 6.46076L4.82416 7.38936L5.53127 6.68225L4.60267 5.75365L3.89556 6.46076ZM5.53127 7.38936L8.31707 4.60355L7.60997 3.89645L4.82416 6.68225L5.53127 7.38936Z'
        fill={color}
      />
    </svg>
  );
};

export default WorkListIcon;
