import IconStyles from 'apps/webapp/src/types/iconStyles';

const ExpandIcon = (styles: IconStyles) => {
  const color = styles?.color || '#111';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
      <path
        d='M2.77046 8.11596C2.79802 7.70266 2.48531 7.34529 2.07201 7.31773C1.65872 7.29018 1.30134 7.60289 1.27379 8.01618L2.77046 8.11596ZM0.896051 13.6822C0.868498 14.0955 1.1812 14.4529 1.5945 14.4804C2.0078 14.508 2.36518 14.1953 2.39273 13.782L0.896051 13.6822ZM1.11365 13.2018C0.820754 13.4947 0.820754 13.9696 1.11365 14.2625C1.40654 14.5554 1.88141 14.5554 2.17431 14.2625L1.11365 13.2018ZM14.2618 2.17499C14.5547 1.8821 14.5547 1.40722 14.2618 1.11433C13.9689 0.821436 13.4941 0.821436 13.2012 1.11433L14.2618 2.17499ZM1.59519 12.9839C1.1819 13.0115 0.869189 13.3689 0.896742 13.7822C0.924295 14.1955 1.28167 14.5082 1.69497 14.4806L1.59519 12.9839ZM7.36099 14.1029C7.77429 14.0753 8.08699 13.7179 8.05944 13.3046C8.03189 12.8913 7.67451 12.5786 7.26121 12.6062L7.36099 14.1029ZM14.4802 1.69457C14.5078 1.28127 14.1951 0.923892 13.7818 0.89634C13.3685 0.868786 13.0111 1.18149 12.9836 1.59479L14.4802 1.69457ZM12.6058 7.26081C12.5783 7.67411 12.891 8.03149 13.3043 8.05904C13.7176 8.08659 14.075 7.77389 14.1025 7.36059L12.6058 7.26081ZM13.7826 2.39286C14.1959 2.3653 14.5086 2.00792 14.481 1.59463C14.4535 1.18133 14.0961 0.868625 13.6828 0.896178L13.7826 2.39286ZM8.01676 1.27391C7.60346 1.30147 7.29075 1.65885 7.31831 2.07214C7.34586 2.48544 7.70324 2.79815 8.11654 2.77059L8.01676 1.27391ZM1.27379 8.01618L0.896051 13.6822L2.39273 13.782L2.77046 8.11596L1.27379 8.01618ZM2.17431 14.2625L14.2618 2.17499L13.2012 1.11433L1.11365 13.2018L2.17431 14.2625ZM1.69497 14.4806L7.36099 14.1029L7.26121 12.6062L1.59519 12.9839L1.69497 14.4806ZM12.9836 1.59479L12.6058 7.26081L14.1025 7.36059L14.4802 1.69457L12.9836 1.59479ZM13.6828 0.896178L8.01676 1.27391L8.11654 2.77059L13.7826 2.39286L13.6828 0.896178Z'
        fill={color}
      />
    </svg>
  );
};

export default ExpandIcon;
