import { CustomerContext, CustomerContextCountPerStateType, CustomerContextGet } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { stringify } from 'qs';
import api from '../../httpClient/api';
import { setCustomerContextPaginationLoading, upsertManyCustomerContexts } from '../customercontext';
import { baseQuery } from './baseQuery';

export const customerContextApi = createApi({
  reducerPath: 'customerContextApi',
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ['CustomerContext', 'CustomerContextCountGroupedByTaskState'],
  endpoints: builder => ({
    getCustomerContext: builder.query<CustomerContext[], CustomerContextGet>({
      query: customerContextGet => {
        const queryString = stringify(customerContextGet);
        return {
          url: `${api.customerContext}?${queryString}`,
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(setCustomerContextPaginationLoading(true));
          const { data } = await queryFulfilled;
          dispatch(upsertManyCustomerContexts(data));
        } catch (error) {
          console.error(error);
        } finally {
          dispatch(setCustomerContextPaginationLoading(false));
        }
      },
      providesTags: ['CustomerContext'],
    }),

    getCustomerContextCountGroupedByTaskState: builder.query<CustomerContextCountPerStateType, CustomerContextGet>({
      query: customerContextGetCount => {
        const queryString = stringify(customerContextGetCount);
        return {
          url: `${api.customerContext}/countGroupedByTaskState?${queryString}`,
          method: 'GET',
        };
      },
      providesTags: ['CustomerContextCountGroupedByTaskState'],
    }),
  }),
});

export const { useGetCustomerContextQuery, useGetCustomerContextCountGroupedByTaskStateQuery } = customerContextApi;
