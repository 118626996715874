import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ReportType } from '@finance-ops/types';
import { RootState } from './index';
import api from '../httpClient/api';
import { httpGet } from '../httpClient';
import { addDays, endOfDay, endOfToday, startOfDay, subDays } from 'date-fns';

export type StatsType = {
  Clients: number;
  Sent: number;
  Received: number;
};

type GetReportsArgs = {
  date: Date;
  selectedClientId?: string;
};

type ReportSliceState = {
  loading: boolean;
  currentDate: Date;
  stats: StatsType;
} & ReturnType<typeof reportsEntityAdapter.getInitialState>;

export const getReports = createAsyncThunk('report', async ({ date, selectedClientId }: GetReportsArgs) => {
  const startDate = startOfDay(date).toISOString();
  const endDate = endOfDay(date).toISOString();
  const query: Record<string, any> = { startDate, endDate };
  if (selectedClientId) {
    query['clientId'] = selectedClientId;
  }
  const response = await httpGet(api.reports, query);
  return response.data;
});

const reportsEntityAdapter = createEntityAdapter<ReportType>({
  selectId: entity => entity._id,
});

const aggregateAllReports = (reports: ReportType[]): StatsType => {
  // return an object with { client: total number of reports, sent: total of sent of all reports, received: total of received of all reports }
  const aggregatedReports = reports.reduce(
    (acc, report) => {
      acc.Sent += report.sent;
      acc.Received += report.received;
      return acc;
    },
    { Clients: 0, Sent: 0, Received: 0 } as StatsType,
  );

  return {
    Clients: reports.length,
    Sent: aggregatedReports.Sent,
    Received: aggregatedReports.Received,
  };
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    ...reportsEntityAdapter.getInitialState(),
    loading: false,
    currentDate: new Date(),
    stats: {},
  } as ReportSliceState,
  reducers: {
    upsertOneReport: reportsEntityAdapter.upsertOne,
    setLoading: (state, action: { payload: boolean; type: string }) => {
      state.loading = action.payload;
    },
    decrementDate: state => {
      state.currentDate = subDays(state.currentDate, 1);
    },
    incrementDate: state => {
      state.currentDate = addDays(state.currentDate, 1);
    },
    setToToday: state => {
      state.currentDate = endOfToday();
    },
  },
  extraReducers: builder => {
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const reports: ReportType[] = action.payload;
        state.stats = aggregateAllReports(reports);
        reportsEntityAdapter.setAll(state, reports);
      }
    });
  },
});

export const reportsSelectors = reportsEntityAdapter.getSelectors((state: RootState) => state.reports);

export const { upsertOneReport, setLoading, incrementDate, decrementDate, setToToday } = reportsSlice.actions;

export default reportsSlice.reducer;
