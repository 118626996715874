import IconStyles from 'apps/webapp/src/types/iconStyles';

const FilterIcon = (styles: IconStyles) => {
  const color = styles?.color || '#111';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='15' viewBox='0 0 13 15' fill='none' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.00017 4.49381V2.52397C0.987826 1.6948 1.64987 1.01257 2.47903 1H9.87334C10.7025 1.01257 11.3645 1.6948 11.3522 2.52397V4.49381C11.3528 4.69482 11.2751 4.88815 11.1355 5.03285L7.8717 7.65487C7.73218 7.79958 7.65447 7.99291 7.65504 8.19392V12.0456C7.65661 12.3312 7.49892 12.5938 7.24614 12.7266L5.76728 13.4882C5.53493 13.6045 5.25862 13.5902 5.03945 13.4507C4.82028 13.3112 4.6904 13.0669 4.69732 12.8072V8.19392C4.69789 7.99291 4.62019 7.79958 4.48067 7.65487L1.21682 5.03211C1.07748 4.8876 0.99979 4.69456 1.00017 4.49381Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default FilterIcon;
