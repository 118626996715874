import {
  ACTION_CENTER_STATUS_TYPES,
  EVENT_TYPE,
  NOTIFICATION_DELIVERY_CHANNEL,
  NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS,
} from '@finance-ops/constants';
import {
  NOTIFICATION_STATUS,
  NotificationType,
  PERMISSION_ACTION,
  PERMISSION_SUBJECT,
  WebSocketMessageType,
  getFullName,
} from '@finance-ops/types';
import { Clear } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CustomAvatar from 'apps/webapp/src/@core/components/mui/avatar';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import CustomIcon from '../../@core/components/custom-icons';
import { formatDateAndTime } from '../../@core/utils/format';
import { getInitials } from '../../@core/utils/get-initials';
import IconNames from '../../constants/IconNames';
import { useAuth } from '../../hooks/useAuth';
import { AbilityContext } from '../../layouts/components/acl/Can';
import { RootState } from '../../store';
import { clientSelectors } from '../../store/client';
import { selectNotificationsByUserId } from '../../store/notification';
import { sendMessage } from '../../websocket/WebSocketClient';

export const notificationCategories: {
  text: string;
  buttonText: string;
  types: {
    [key in EVENT_TYPE]?: {
      GetTemplate: (notification: NotificationType, clientName: string) => React.ReactNode;
    };
  };
}[] = [
  {
    text: 'Action Items',
    buttonText: 'View Action',
    types: {
      [EVENT_TYPE.ACTION_CREATED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography fontSize='14px' variant='body1' component='span'>
                A new&nbsp;
              </Typography>
              <Typography
                fontSize='14px'
                variant='body1'
                component='span'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.error.main}
              >
                {notification.data.actions?.type}&nbsp;
              </Typography>
              <Typography fontSize='14px' variant='body1' component='span'>
                case has been created for&nbsp;
              </Typography>
              <Typography
                variant='body1'
                component='span'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
              >
                {notification?.data?.customers ? getFullName(notification.data.customers) : ''}
              </Typography>
              &nbsp;on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
      [EVENT_TYPE.ACTION_UPDATE]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          const colorsForStatus: { [key in ACTION_CENTER_STATUS_TYPES]: string } = {
            Closed: theme.palette.success.main,
            Open: theme.palette.warning.main,
            Resolved: theme.palette.error.main,
            'In Progress': theme.palette.success.main,
          };
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography
                variant='body1'
                component='span'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
                's&nbsp;
              </Typography>
              <Typography fontSize='14px' variant='body1' component='span'>
                {notification.data.actions?.type} case is now&nbsp;
              </Typography>
              <Typography
                fontSize='14px'
                variant='body1'
                component='span'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={colorsForStatus[notification.data.actions?.userEntity?.status as ACTION_CENTER_STATUS_TYPES]}
              >
                {' '}
                {notification.data.actions?.userEntity?.status}
              </Typography>
              &nbsp;on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
      [EVENT_TYPE.COMMENT_ADDED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();

          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                A new comment has been added to&nbsp;
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
                's&nbsp;
              </Typography>
              <Typography fontSize='14px' variant='body1' component='span'>
                {notification.data.actions?.type} case.&nbsp;
              </Typography>
              &nbsp;on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
    },
  },
  {
    text: 'Messages',
    buttonText: 'View Message',
    types: {
      [EVENT_TYPE.MESSAGE_INBOUND]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                A new message has been received from&nbsp;
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
              </Typography>
              &nbsp;on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
      [EVENT_TYPE.NOTE_CREATED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                {`${
                  notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content
                } on ${formatDateAndTime(notification.updatedAt)}`}
              </Typography>
            </>
          );
        },
      },
    },
  },
  {
    text: 'Emails',
    buttonText: 'View Email',
    types: {
      [EVENT_TYPE.EMAIL_RECEIVED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                A new email has been received from&nbsp;
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
              </Typography>
              &nbsp;on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
    },
  },
  {
    text: 'Tasks',
    buttonText: 'View Task',
    types: {
      [EVENT_TYPE.TASK_UPDATE_MODE]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              {' '}
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                {`${
                  notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content
                } on ${formatDateAndTime(notification.updatedAt)}`}
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                {notification.data.customers ? ` Customer: ${getFullName(notification.data.customers)}` : ''}
              </Typography>
            </>
          );
        },
      },
      [EVENT_TYPE.TASK_UPDATE_BULK_ASSIGN_STATUS]: {
        GetTemplate: (notification, clientName) => {
          return (
            <>
              <Typography variant='body1' fontSize='14px' component='span'>
                {`${
                  notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content
                } on ${formatDateAndTime(notification.updatedAt)}`}
              </Typography>
            </>
          );
        },
      },
      [EVENT_TYPE.TASK_UPDATE_ASSIGNED_AGENT]: {
        GetTemplate: (notification, clientName) => {
          return (
            <>
              <Typography variant='body1' fontSize='14px' component='span'>
                {`${
                  notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content
                } on ${formatDateAndTime(notification.updatedAt)}`}
              </Typography>
            </>
          );
        },
      },
    },
  },
  {
    text: 'Payments',
    buttonText: 'View Payment',
    types: {
      [EVENT_TYPE.PAYMENT_SUCCESS]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
                &nbsp;({clientName})
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                &nbsp;made a payment. &nbsp;
              </Typography>
              <Typography fontSize='14px' variant='body1' component='span'>
                {notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content}.&nbsp;
              </Typography>
              on {formatDateAndTime(notification.updatedAt)}
            </>
          );
        },
      },
      [EVENT_TYPE.PAYMENT_LINK_OPENED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                Opened payment link on {formatDateAndTime(notification.updatedAt)}
              </Typography>
            </>
          );
        },
      },
      [EVENT_TYPE.INVOICE_LINK_OPENED]: {
        GetTemplate: (notification, clientName) => {
          const theme = useTheme();
          return (
            <>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                ({clientName}) &nbsp;
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontFamily: 'DM Sans', fontWeight: 700 }}
                color={theme.palette.primary.dark}
                fontSize='14px'
                component='span'
              >
                {notification.data.customers ? getFullName(notification.data.customers) : ''}
              </Typography>
              <Typography variant='body1' fontSize='14px' component='span'>
                Opened Invoice Link on {formatDateAndTime(notification.updatedAt)}
              </Typography>
            </>
          );
        },
      },
    },
  },
  {
    text: 'Clients',
    buttonText: 'View Client',
    types: {
      [EVENT_TYPE.CLIENT_UPDATE_ASSIGN_USER]: {
        GetTemplate: (notification, clientName) => {
          return (
            <>
              <Typography variant='body1' fontSize='14px' component='span'>
                {`${
                  notification.templates?.[NOTIFICATION_DELIVERY_CHANNEL.WEBAPP] ?? notification.content
                } on ${formatDateAndTime(notification.updatedAt)}`}
              </Typography>
            </>
          );
        },
      },
    },
  },
];

export const getNotificationCategoriesTypes = (notificationCategories: any) =>
  notificationCategories.reduce(
    (acc: any, curr: any) => {
      return { types: { ...acc.types, ...curr.types } };
    },
    { types: {} },
  ) as any;

function NotificationCenter() {
  const theme = useTheme();
  const ability = useContext(AbilityContext);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const { user } = useAuth();
  const router = useRouter();
  const clients = useSelector(clientSelectors.selectAll);

  const notifications: NotificationType[] = useSelector((state: RootState) =>
    selectNotificationsByUserId(state, user?.id),
  );

  const getNotificationCategoriesForUser = () => {
    if (ability?.can(PERMISSION_ACTION.READ, PERMISSION_SUBJECT.PLAYGROUND)) {
      return notificationCategories;
    }
    return [notificationCategories[0], notificationCategories[3]];
  };
  const notificationsToShow = notifications
    .filter(notification => {
      return Object.keys(getNotificationCategoriesForUser()[activeIdx].types).includes(notification.event);
    })
    .sort((a, b) => {
      // Prioritize unread over read
      if (a.status === NOTIFICATION_STATUS.UNREAD && b.status === NOTIFICATION_STATUS.READ) return -1;
      if (a.status === NOTIFICATION_STATUS.READ && b.status === NOTIFICATION_STATUS.UNREAD) return 1;

      // If both have the same status, compare by createdAt
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

  const getNotificationTemplate = (notification: NotificationType, clientName: string) => {
    return getNotificationCategoriesForUser()[activeIdx].types[notification.event]?.GetTemplate(
      notification,
      clientName,
    );
  };

  const getButtonName = () => {
    return getNotificationCategoriesForUser()[activeIdx].buttonText;
  };

  const onClickHandler = (notification: NotificationType) => {
    if (NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS.includes(notification.event)) {
      router.push('/resolution-center/?id=' + notification.data?.actions?.id);
      return;
    }
    if (notification.event === EVENT_TYPE.MESSAGE_INBOUND) {
      router.push(
        `/chat-beta/?clientId=${notification.data?.tasks?.clientId}&taskId=${notification.data?.tasks?.id.toString()}`,
      );
      return;
    }
    if (
      notification.event === EVENT_TYPE.TASK_UPDATE_MODE ||
      notification.event === EVENT_TYPE.PAYMENT_LINK_OPENED ||
      notification.event === EVENT_TYPE.INVOICE_LINK_OPENED ||
      notification.event === EVENT_TYPE.EMAIL_RECEIVED
    ) {
      router.push(
        `/chat-beta/?clientId=${notification.data?.tasks?.clientId}&taskId=${notification.data?.tasks?.id.toString()}`,
      );
      return;
    }
  };

  return (
    <Grid container sx={{ padding: '20px 40px' }} rowGap={3}>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography
            color={theme.palette.customColors.headingPrimary}
            fontSize='24px'
            fontWeight={700}
            lineHeight='normal'
          >
            Notification Center
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              router.push('/');
            }}
          >
            <Clear />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container sx={{ mb: '24px' }} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container spacing={5}>
            {getNotificationCategoriesForUser().map((button, idx) => {
              const isActive = activeIdx === idx ? true : false;

              return (
                <Grid item key={idx}>
                  <Button
                    type='button'
                    sx={{
                      fontWeight: '700',
                      borderRadius: '18px',
                      padding: '3px 21px',
                      width: '136px',
                      fontSize: '12px',
                      color: isActive ? theme.palette.common.white : theme.palette.primary.dark,
                      backgroundColor: isActive ? theme.palette.primary.main : theme.palette.common.white,
                      border: isActive ? `1px solid ${theme.palette.primary.main}` : 'none',
                      boxShadow: isActive ? 'none' : '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
                      '&:hover': {
                        backgroundColor: isActive
                          ? theme.palette.primary.main
                          : theme.palette.customColors.themeColorBg,
                        color: isActive ? theme.palette.common.white : theme.palette.primary.dark,
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => {
                      setActiveIdx(idx);
                    }}
                  >
                    {button.text}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant='text'
            sx={{ borderRadius: '30px' }}
            onClick={() => {
              sendMessage(WebSocketMessageType.NOTIFICATION_UPDATE_MANY_READ, {
                ids: notificationsToShow.map(notification => notification.id),
              });
            }}
          >
            Mark All as Read
          </Button>
        </Grid>
      </Grid>

      <Grid container direction='column' rowGap={10}>
        {notificationsToShow.map((notification, index) => {
          const clientName = clients.find(client => client.id === notification.data.tasks?.clientId)?.name || '';
          return (
            <>
              <Grid container key={index} alignItems='center'>
                {notification.status === NOTIFICATION_STATUS.UNREAD && (
                  <Grid item xs={0} component='div' position='absolute' ml='-30px'>
                    <CustomIcon
                      name={IconNames.ellipse}
                      // sx={{ width: '8px', height: '8px', fill: theme.palette.primary.main }}
                    />
                  </Grid>
                )}
                <Grid container justifyContent='space-between' xs={12}>
                  <Grid item>
                    <Grid container alignItems='center'>
                      <Grid item>
                        <CustomAvatar
                          skin='light'
                          sx={{
                            width: 40,
                            height: 40,
                            fontSize: '1rem',
                            ml: 0,
                            mr: 3,
                          }}
                        >
                          {getInitials(notification.data.customers ? getFullName(notification.data.customers) : '')}
                        </CustomAvatar>
                      </Grid>
                      <Grid item sx={{ display: 'flex' }}>
                        {getNotificationTemplate(notification, clientName)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      type='button'
                      variant='contained'
                      sx={{
                        fontWeight: '700',
                        borderRadius: '18px',
                        padding: '3px 21px',
                        width: '136px',
                        fontSize: '12px',
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                      onClick={() => onClickHandler(notification)}
                    >
                      {getButtonName()}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        <Grid item></Grid>
      </Grid>
    </Grid>
  );
}

export default NotificationCenter;
