// ** Redux Imports
import { getUserFullName, UserType } from '@finance-ops/types';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

const usersEntityAdapter = createEntityAdapter<UserType>({
  selectId: entity => entity.id,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersEntityAdapter.getInitialState(),
  reducers: {
    setUsers: (state, action: PayloadAction<UserType[]>) => {
      usersEntityAdapter.setMany(state, action.payload);
    },
    deleteUser: usersEntityAdapter.removeOne,
    upsertUser: usersEntityAdapter.upsertOne,
    resetUsers: () => {
      return usersEntityAdapter.getInitialState();
    },
  },
});
export const usersSelectors = usersEntityAdapter.getSelectors<RootState>(state => state.users);

export const selectRespectiveAgents = createSelector(
  [usersSelectors.selectAll, (_, filteringObject: { isInternalUser: boolean; clientId: string }) => filteringObject],
  (users, filteringObject) => {
    const { isInternalUser, clientId } = filteringObject;

    return users?.filter(user => {
      if (isInternalUser) {
        return user?.role?.isInternal ?? false;
      }
      return user.customerId === clientId;
    });
  },
);

export const selectAllUsers = usersSelectors.selectAll;

export const selectUserNameById = createSelector([usersSelectors.selectAll, (_, id) => id], (users, id) => {
  const userFound = users.find(user => user.id === id);
  if (userFound) {
    return getUserFullName(userFound);
  }
});

export const { setUsers, resetUsers, deleteUser, upsertUser } = usersSlice.actions;

export default usersSlice.reducer;
