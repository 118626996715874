import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { createSelector } from 'reselect';
import { CustomerType, GetP2PsRequestType, InvoiceType } from '@finance-ops/types';

const defaultPaginationState = {
  page: 1,
  pageSize: 25,
};

interface UserEntity {
  date: string;
  reason: string;
  for: string;
  note?: string;
  amount?: number;
  status?: string;
  invoiceIds: string[];
  paidDate?: string;
  paidAmount?: number;
  paymentPlanId?: string;
}

interface Currency {
  code: string;
  symbol: string;
  name: string;
}

export interface P2PItem {
  _id: string;
  clientId: string;
  customerId: string;
  communicationId: string;
  type: string;
  aiEntity?: any;
  userEntity: UserEntity;
  createdAt: string;
  updatedAt: string;
  __v: number;
  invoicesDetails: InvoiceType[];
  sourceId?: string;
  counterPartyDetails: CustomerType;
}

interface PaginationType {
  page: number;
  pageSize: number;
}

interface P2PSlice {
  selectedP2PId: string;
  isLoading: boolean;
  paginationLoading: boolean;
  p2pFilters?: Record<any, any>;
  p2pList: P2PItem[];
  pagination: PaginationType;
  totalP2p: number;
  totalUpcoming: string | number;
  p2pRequest?: GetP2PsRequestType;
}

const initialState: P2PSlice = {
  selectedP2PId: '',
  isLoading: false,
  paginationLoading: false,
  p2pList: [],
  pagination: defaultPaginationState,
  totalP2p: 0,
  totalUpcoming: '-',
  p2pFilters: {},
};

const P2PSlice = createSlice({
  name: 'P2PState',
  initialState,
  reducers: {
    setP2PList: (state, action: PayloadAction<any[]>) => {
      state.p2pList = action.payload;
      state.isLoading = false;
    },
    setSelectedP2PId: (state, action: PayloadAction<string>) => {
      state.selectedP2PId = action.payload;
    },
    setP2PFilters: (state, action: PayloadAction<any>) => {
      state.p2pFilters = action.payload;
    },
    setP2PPagination: (state, action: PayloadAction<PaginationType>) => {
      state.pagination = action.payload;
      state.paginationLoading = false;
    },
    setP2PPaginationLoading: (state, action: PayloadAction<boolean>) => {
      state.paginationLoading = action.payload;
    },
    setP2PLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalP2p: (state, action: PayloadAction<number>) => {
      state.totalP2p = action.payload;
    },
    setTotalUpcomingP2p: (state, action: PayloadAction<number>) => {
      state.totalUpcoming = action.payload;
    },
    setP2PRequest: (state, action: PayloadAction<GetP2PsRequestType>) => {
      state.p2pRequest = action.payload;
    },
  },
});

const selectP2PList = (state: RootState) => state.p2p.p2pList;
const selectSelectedP2PId = (state: RootState) => state.p2p.selectedP2PId;

export const getSelectedP2PItem = createSelector([selectP2PList, selectSelectedP2PId], (p2pList, selectedP2PId) =>
  p2pList.find(item => item._id === selectedP2PId),
);

export const {
  setP2PList,
  setSelectedP2PId,
  setP2PFilters,
  setP2PPagination,
  setP2PPaginationLoading,
  setP2PLoading,
  setTotalP2p,
  setTotalUpcomingP2p,
  setP2PRequest,
} = P2PSlice.actions;

export default P2PSlice.reducer;
