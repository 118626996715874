import IconStyles from 'apps/webapp/src/types/iconStyles';

const PaidIcon = (styles: IconStyles) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 2.73701V11.263C1.00159 11.7255 1.18689 12.1683 1.51511 12.4941C1.84332 12.8199 2.28755 13.0019 2.75 13H13.25C13.7124 13.0019 14.1567 12.8199 14.4849 12.4941C14.8131 12.1683 14.9984 11.7255 15 11.263V2.73701C14.9984 2.27457 14.8131 1.83171 14.4849 1.50593C14.1567 1.18015 13.7124 0.998154 13.25 1.00001H2.75C2.28755 0.998154 1.84332 1.18015 1.51511 1.50593C1.18689 1.83171 1.00159 2.27457 1 2.73701Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 5.85498C15.4142 5.85498 15.75 5.51919 15.75 5.10498C15.75 4.69077 15.4142 4.35498 15 4.35498V5.85498ZM1 4.35498C0.585786 4.35498 0.25 4.69077 0.25 5.10498C0.25 5.51919 0.585786 5.85498 1 5.85498V4.35498ZM8.875 8.93398C9.28921 8.93398 9.625 8.59819 9.625 8.18398C9.625 7.76977 9.28921 7.43398 8.875 7.43398V8.93398ZM1 7.43398C0.585786 7.43398 0.25 7.76977 0.25 8.18398C0.25 8.59819 0.585786 8.93398 1 8.93398V7.43398ZM12.375 8.93398C12.7892 8.93398 13.125 8.59819 13.125 8.18398C13.125 7.76977 12.7892 7.43398 12.375 7.43398V8.93398ZM11.5 7.43398C11.0858 7.43398 10.75 7.76977 10.75 8.18398C10.75 8.59819 11.0858 8.93398 11.5 8.93398V7.43398ZM15 4.35498H1V5.85498H15V4.35498ZM8.875 7.43398H1V8.93398H8.875V7.43398ZM12.375 7.43398H11.5V8.93398H12.375V7.43398Z'
        fill='black'
      />
    </svg>
  );
};

export default PaidIcon;
