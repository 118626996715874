import { TASK_ASSIGNED_USER_KEY_NAME, TASK_STATE_KEY_NAME } from '@finance-ops/types';
import { useAuth } from './useAuth';

export const useTaskStateAndAssignedKeyNames = () => {
  const isInternalUser = useAuth().user?.role?.isInternal;
  if (isInternalUser) {
    return {
      taskStateKeyName: TASK_STATE_KEY_NAME.internalState,
      assignedUserKeyName: TASK_ASSIGNED_USER_KEY_NAME.internalAssignedUserId,
    };
  }
  return {
    taskStateKeyName: TASK_STATE_KEY_NAME.externalState,
    assignedUserKeyName: TASK_ASSIGNED_USER_KEY_NAME.externalAssignedUserId,
  };
};
