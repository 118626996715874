import IconStyles from 'apps/webapp/src/types/iconStyles';

const CompletedIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none' {...styles}>
      <path
        d='M0.830769 12V10.5H11.1692V12H0.830769ZM4.19077 9L0 4.74375L1.05231 3.675L4.19077 6.8625L10.9477 0L12 1.06875L4.19077 9Z'
        fill={color}
      />
    </svg>
  );
};

export default CompletedIcon;
