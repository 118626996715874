import { AmplitudeAnalytics } from '../thirdParty';
import {
  REDIRECTED_TO_LOGIN_PAGE,
  USER_CLICKED_AI_GENERATE_BUTTON,
  USER_CLICKED_GENERATE_PAYMENT_LINK,
  USER_VISITED_PAGE,
} from './actions/names';

class Track {
  private static instance: Track;

  constructor() {
    // will add common data here
  }

  static getInstance = (): Track => {
    if (!Track.instance) Track.instance = new Track();
    return Track.instance;
  };

  private getCommonData = () => {
    const data: Record<string, any> = {};
    return data;
  };

  ampEvent = (eventName: string, data = {}) => {
    AmplitudeAnalytics?.track(eventName, data);
  };

  setUserId = (userId: string | null, quId: string | null) => {
    AmplitudeAnalytics?.setUserId(userId || undefined);
  };

  clickedAIGenerateButton = (data: any) => {
    this.ampEvent(USER_CLICKED_AI_GENERATE_BUTTON, data);
  };

  visitedPage(pageName: string) {
    this.ampEvent(USER_VISITED_PAGE, { pageName });
  }

  clickedOnPaymentGenerationLink({ customerId, clientId }: { customerId: string; clientId: string }) {
    this.ampEvent(USER_CLICKED_GENERATE_PAYMENT_LINK, { customerId, clientId });
  }

  redirectedToLoginPage({ reason }: { reason: string }) {
    this.ampEvent(REDIRECTED_TO_LOGIN_PAGE, { reason });
  }
}
export { Track };
