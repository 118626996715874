import {
  INVOICE_STATUS,
  InvoicePageDataType,
  InvoicePageOverviewDataCountType,
  InvoicePageRequestType,
} from '@finance-ops/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const defaultPaginationState = {
  page: 1,
  pageSize: 25,
};

interface PaginationType {
  page: number;
  pageSize: number;
}

export interface Invoices {
  selectedInvoiceId: string;
  isLoading: boolean;
  paginationLoading: boolean;
  invoicesForPage: InvoicePageDataType[];
  pagination: PaginationType;
  invoiceFilters: Record<any, any>;
  invoicePageSearchQuery: string;
  totalInvoices: number;
  overview: InvoicePageOverviewDataCountType;
  invoicePageRequest?: InvoicePageRequestType;
}

const initialState: Invoices = {
  selectedInvoiceId: '',
  isLoading: false,
  paginationLoading: false,
  invoicesForPage: [],
  pagination: defaultPaginationState,
  invoiceFilters: {},
  totalInvoices: 0,
  invoicePageSearchQuery: '',
  overview: { [INVOICE_STATUS.PAID]: 0, [INVOICE_STATUS.UNPAID]: 0, [INVOICE_STATUS.PARTIALLY_PAID]: 0 },
};

const InvoicePage = createSlice({
  name: 'InvoicePage',
  initialState,
  reducers: {
    setInvoiceList: (state, action: PayloadAction<InvoicePageDataType[]>) => {
      state.invoicesForPage = action.payload;
      state.isLoading = false;
    },
    setSelectedInvoiceId: (state, action: PayloadAction<string>) => {
      state.selectedInvoiceId = action.payload;
    },
    setInvoiceFilters: (state, action: PayloadAction<any>) => {
      state.invoiceFilters = action.payload;
    },
    setInvoicePageSearchQuery: (state, action: PayloadAction<string>) => {
      state.invoicePageSearchQuery = action.payload;
    },
    setInvoicePagePagination: (state, action: PayloadAction<PaginationType>) => {
      state.pagination = action.payload;
      state.paginationLoading = false;
    },
    setInvoicePagePaginationLoading: (state, action: PayloadAction<boolean>) => {
      state.paginationLoading = action.payload;
    },
    setInvoicesLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalInvoices: (state, action: PayloadAction<number>) => {
      state.totalInvoices = action.payload;
    },
    setInvoiceOverviewData: (state, action: PayloadAction<InvoicePageOverviewDataCountType>) => {
      state.overview = action.payload;
    },
    setInvoicePageRequest: (state, action: PayloadAction<InvoicePageRequestType>) => {
      state.invoicePageRequest = action.payload;
    },
  },
});

export const {
  setInvoiceList,
  setSelectedInvoiceId,
  setInvoiceFilters,
  setInvoicePagePagination,
  setInvoicePagePaginationLoading,
  setInvoicesLoading,
  setTotalInvoices,
  setInvoiceOverviewData,
  setInvoicePageSearchQuery,
  setInvoicePageRequest,
} = InvoicePage.actions;

export default InvoicePage.reducer;
