import IconStyles from 'apps/webapp/src/types/iconStyles';

const DelayIcon = (styles: IconStyles) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none' {...styles}>
      <path
        d='M0 0V4.2H0.00666682L0 4.207L2.66667 7L0 9.8L0.00666682 9.807H0V14H8V9.807H7.99333L8 9.8L5.33333 7L8 4.207L7.99333 4.2H8V0H0ZM6.66667 10.15V12.6H1.33333V10.15L4 7.35L6.66667 10.15ZM4 6.65L1.33333 3.85V1.4H6.66667V3.85L4 6.65Z'
        fill='black'
      />
    </svg>
  );
};

export default DelayIcon;
