import IconStyles from 'apps/webapp/src/types/iconStyles';

const EmailAttchmentIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        d='M7.90794 5.32405L5.05762 8.36867C4.81582 8.63883 4.47171 8.79506 4.10917 8.79927C3.74664 8.80348 3.39899 8.65529 3.15098 8.39082C2.60312 7.81889 2.61232 6.91412 3.1717 6.35345L6.99998 2.25942C7.42225 1.78931 8.02327 1.51918 8.65518 1.51551C9.28709 1.51184 9.89121 1.77496 10.3189 2.24014C11.2313 3.24836 11.2247 4.78577 10.3039 5.78624L6.24489 10.1232C5.66722 10.703 4.87651 11.0197 4.05829 10.999C3.24007 10.9784 2.46636 10.6221 1.9187 10.0139C0.666889 8.60184 0.697862 6.46839 1.99014 5.09331L5.82413 1'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EmailAttchmentIcon;
