import { AttachmentType } from '../common';

/* eslint-disable no-unused-vars */
export type InvoiceType = {
  _id: string;
  sourceId?: string;
  referenceId?: string;
  invoiceNumber: string;
  counterPartyId: string;
  customerId: string;
  status: INVOICE_STATUS;
  issuedDate: Date;
  totalAmount: number;
  dueAmount: number;
  dueDate: Date;
  currency: CurrencyType;
  metadata?: any;
  promiseToPayAmount?: number;
  promiseToPayDate?: Date;
  followUpDate?: Date;
  followUpReason?: LOAN_DELINQUENCY_REASON;
  lastUpdateAmount: number;
  daysPastDue: number;
  transactions: InvoiceTransactionType[];
  createdAt: Date;
  updatedAt: Date;
  payOffBalance: number;
  assignedAmount: number;
  collectibleAmount: number;
  paymentLinkOptions?: {
    amount?: number;
    isVariable?: boolean;
  };
  lastPaymentDate?: Date;
  lastPaymentId?: string;
  completedDate?: Date;
  createdBy?: string;
  updatedBy?: string;
};

export enum TransactionTypeTransactionMethod {
  STRIPE = 'STRIPE',
  OTHERS = 'OTHERS',
}

export type InvoiceTransactionType = {
  date: Date;
  amount: number;
  isInternal?: boolean;
  tid?: string;
  transactionMethod?: TransactionTypeTransactionMethod.STRIPE | TransactionTypeTransactionMethod.OTHERS;
  attachments?: AttachmentType[];
};

export enum INVOICE_STATUS {
  PAID = 'Paid',
  PARTIALLY_PAID = 'Partially Paid',
  UNPAID = 'Unpaid',
}

export enum LOAN_DELINQUENCY_REASON {
  OptedPaymentPlan = 'Opted For Payment Plan',
  PaymentStuck = 'Payment Stuck',
  PromiseToCallBack = 'Promised To Callback',
  Unemployment = 'Unemployment',
  FinancialEmergency = 'Financial Emergency',
  PoorMoneyManagement = 'Poor Money Management',
  HighDebt = 'High Debt',
  MedicalEmergency = 'Medical Emergency',
  DivorceSeparation = 'Divorce Separation',
  NaturalDisaster = 'Natural Disaster',
  LegalIssues = 'Legal Issues',
  MentalHealth = 'Mental Health',
  LackOfUnderstanding = 'Lack of Understanding',
  DelayedIncorrectPayments = 'Delayed Incorrect Payments',
  PoorCreditHistory = 'Poor Credit History',
  FamilyEmergencies = 'Family Emergencies',
  PrioritizingOtherExpenses = 'Prioritizing Other Expenses',
  ChangesInLoanTerms = 'Changes In Loan Terms',
  IdentityTheft = 'Identity Theft',
  GamblingAddictiveBehavior = 'Gambling Addictive Behavior',
  ImpulseSpending = 'Impulse Spending',
  OverRelianceOnCredit = 'Over Reliance On Credit',
  LackOfCommunication = 'Lack of Communication',
}

export type CurrencyType =
  | {
      code: 'USD';
      symbol: '$';
      name: 'US Dollar';
    }
  | {
      code: 'INR';
      symbol: '₹';
      name: 'Indian Rupee';
    }
  | {
      code: 'MXN';
      symbol: '$';
      name: 'Mexican Peso';
    }
  | {
      code: 'SAR';
      symbol: 'SAR';
      name: 'Saudi Riyal';
    };
