const RightArrow = () => {
  return (
    <svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.53017 8.49511C7.33021 8.68555 7.32249 9.00204 7.51293 9.20201C7.70337 9.40197 8.01986 9.40969 8.21983 9.21925L7.53017 8.49511ZM12.3448 5.29068C12.5448 5.10023 12.5525 4.78374 12.3621 4.58378C12.1716 4.38381 11.8551 4.37609 11.6552 4.56654L12.3448 5.29068ZM11.6552 5.29069C11.8551 5.48113 12.1716 5.47341 12.3621 5.27345C12.5525 5.07348 12.5448 4.75699 12.3448 4.56655L11.6552 5.29069ZM8.21983 0.63798C8.01986 0.447536 7.70337 0.455256 7.51293 0.65522C7.32249 0.855186 7.33021 1.17167 7.53017 1.36212L8.21983 0.63798ZM12 5.42862C12.2761 5.42862 12.5 5.20476 12.5 4.92862C12.5 4.65248 12.2761 4.42862 12 4.42862V5.42862ZM1 4.42862C0.723858 4.42862 0.5 4.65248 0.5 4.92862C0.5 5.20476 0.723858 5.42862 1 5.42862V4.42862ZM8.21983 9.21925L12.3448 5.29068L11.6552 4.56654L7.53017 8.49511L8.21983 9.21925ZM12.3448 4.56655L8.21983 0.63798L7.53017 1.36212L11.6552 5.29069L12.3448 4.56655ZM12 4.42862L1 4.42862V5.42862L12 5.42862V4.42862Z'
        fill='black'
      />
    </svg>
  );
};

export default RightArrow;
