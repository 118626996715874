import { createSlice } from '@reduxjs/toolkit';
import { HistoryLogType } from '@finance-ops/types';

const initialState: { historyLogs: HistoryLogType[]; loadingHistoryLogs: boolean } = {
  historyLogs: [],
  loadingHistoryLogs: false,
};
export const historyLogSlice = createSlice({
  name: 'historyLog',
  initialState,
  reducers: {
    resetHistoryLog: () => {
      return initialState;
    },
    setLoadingHistoryLogs: (state, action) => {
      state.loadingHistoryLogs = action.payload;
    },
    setHistoryLogs: (state, action) => {
      state.historyLogs = action.payload;
      state.loadingHistoryLogs = false;
    },
  },
});

export const { resetHistoryLog, setHistoryLogs, setLoadingHistoryLogs } = historyLogSlice.actions;

export default historyLogSlice.reducer;
