import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '.';

export enum CurrentPageNamesList {
  'Strategy builder' = 'Strategy builder',
  'Create conversation strategy' = 'Create strategy',
  'Create negotiation strategy' = 'Create negotiation strategy',
}

interface Editing {
  status: boolean;
  fieldIndex: number;
}
export interface Condition {
  attribute: string;
  operator?: string;
  amount?: string | number;
  smallerAmount?: string | number;
}

interface Actions {
  attribute: string;
  operator?: string;
  subOperator?: string;
}

export interface ConversationFlow {
  name?: string;
  conditions?: Condition[];
  actions?: Actions[];
  id: number | string;
}
export interface NegotiationFlow {
  name?: string;
  conditions?: Condition[];
  actions?: Actions[];
}
export interface StrategyItem {
  id?: number;
  name?: string;
  conversationFlow?: ConversationFlow[];
  negotiationFlow?: NegotiationFlow[];
}

export interface StrategyStats {
  totalOpenAccounts: number;
  targetedAccounts: number;
  totalOpenAmount: number;
  targetedOpenAmount: number;
  totalAmountCollected?: number;
  conversationRate?: number;
}
interface StrategyBuilderSlice {
  strategies: StrategyItem[];
  currentStrategyId: number;
  currentPageName: CurrentPageNamesList;
  editing: Editing;
  isUpdating: boolean;
  strategyStats?: StrategyStats;
}

const initialState: StrategyBuilderSlice = {
  strategies: [
    // {
    //   id: 1,
    //   name: 'Core Strategy 1',
    //   conversationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'amount', operator: 'less than', amount: 500 },
    //         { attribute: 'delinquentDays', operator: 'greater than' },
    //       ],
    //       actions: [
    //         { attribute: 'Discount', operator: 'Overall' },
    //         { attribute: 'Wave of interest', operator: '20%' },
    //       ],
    //     },
    //   ],
    //   negotiationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Delinquent Days', operator: 'less than', amount: 10 },
    //         { attribute: 'Customer Characterstics', operator: 'Always late to send money' },
    //       ],
    //       actions: [{ attribute: 'Frequency', operator: 'Twice a week' }],
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   name: 'Core Strategy 2',
    //   conversationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Bucket', operator: 'Level 1' },
    //       ],
    //       actions: [
    //         { attribute: 'Discount', operator: 'Overall' },
    //         { attribute: 'Wave of interest', operator: '20%' },
    //       ],
    //     },
    //   ],
    //   negotiationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Delinquent Days', operator: 'less than', amount: 10 },
    //         { attribute: 'Customer Characterstics', operator: 'Always late to send money' },
    //       ],
    //       actions: [{ attribute: 'Frequency', operator: 'Twice a week' }],
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   name: 'Core Strategy 3',
    //   conversationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Bucket', operator: 'Level 1' },
    //       ],
    //       actions: [
    //         { attribute: 'Discount', operator: 'Overall' },
    //         { attribute: 'Wave of interest', operator: '20%' },
    //       ],
    //     },
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Bucket', operator: 'Level 1' },
    //       ],
    //       actions: [
    //         { attribute: 'Discount', operator: 'Overall' },
    //         { attribute: 'Wave of interest', operator: '20%' },
    //       ],
    //     },
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Bucket', operator: 'Level 1' },
    //       ],
    //       actions: [
    //         { attribute: 'Discount', operator: 'Overall' },
    //         { attribute: 'Wave of interest', operator: '20%' },
    //       ],
    //     },
    //   ],
    //   negotiationFlow: [
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Delinquent Days', operator: 'less than', amount: 10 },
    //         { attribute: 'Customer Characterstics', operator: 'Always late to send money' },
    //       ],
    //       actions: [{ attribute: 'Frequency', operator: 'Twice a week' }],
    //     },
    //     {
    //       conditions: [
    //         { attribute: 'Amount', operator: 'less than but greater than', amount: 500 },
    //         { attribute: 'Delinquent Days', operator: 'less than', amount: 10 },
    //         { attribute: 'Customer Characterstics', operator: 'Always late to send money' },
    //       ],
    //       actions: [{ attribute: 'Frequency', operator: 'Twice a week' }],
    //     },
    //   ],
    // },
  ],
  currentStrategyId: 0,
  currentPageName: CurrentPageNamesList['Strategy builder'],
  isUpdating: true,
  editing: {
    status: false,
    fieldIndex: -1,
  },
};

const StrategyBuilderSlice = createSlice({
  name: 'StrategyBuilderState',
  initialState,
  reducers: {
    setStrategies: (state, action: PayloadAction<StrategyItem>) => {
      state.strategies = [{ ...action.payload }];
    },
    setCurrentStrategyId: (state, action: PayloadAction<number>) => {
      state.currentStrategyId = action.payload;
    },
    setCurrentStrategyPageName: (state, action: PayloadAction<CurrentPageNamesList>) => {
      state.currentPageName = action.payload;
    },
    updateStrategy: (state, action: PayloadAction<StrategyItem>) => {
      const updatedStrategies = state.strategies?.map(strategy => {
        if (strategy.id === action.payload.id) {
          return action.payload;
        } else {
          return strategy;
        }
      });
      state.strategies = updatedStrategies;
    },
    deleteStrategy: (state, action: PayloadAction<number>) => {
      state.strategies = state.strategies.filter(strategy => strategy.id !== action.payload);
    },
    setStrategyEditing: (state, action: PayloadAction<Editing>) => {
      state.editing = action.payload;
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setStrategyStats: (state, action: PayloadAction<StrategyStats>) => {
      state.strategyStats = action.payload;
    },
    deleteAllStrategies: state => {
      state.strategies = [];
    },
  },
});

export const selectCurrentStrategy = createSelector(
  [
    (state: RootState) => state.strategyBuilder.strategies,
    (state: RootState) => state.strategyBuilder.currentStrategyId,
  ],
  (strategies, currentStrategyId) => strategies.find(strategy => strategy.id === currentStrategyId),
);

export const {
  setStrategies,
  setCurrentStrategyId,
  updateStrategy,
  setCurrentStrategyPageName,
  deleteStrategy,
  setStrategyEditing,
  setIsUpdating,
  setStrategyStats,
  deleteAllStrategies,
} = StrategyBuilderSlice.actions;

export default StrategyBuilderSlice.reducer;
