// ** React Imports
import { ReactNode, useState } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** Types
// ** Context Imports
import { AbilityContext } from 'apps/webapp/src/layouts/components/acl/Can';

// ** Component Import
import BlankLayout from 'apps/webapp/src/@core/layouts/BlankLayout';
import NotAuthorized from 'apps/webapp/src/pages/401';

// ** Hooks
import { useAuth } from 'apps/webapp/src/hooks/useAuth';

import { AnyMongoAbility } from '@casl/ability';
import { PermissionType, UserType } from '@finance-ops/types';
import { userAbilityBuilder } from 'apps/webapp/src/utils/user/userAbilityBuilder';
import { canAccess } from '../../../layouts/components/acl/acl-utils';

interface AclGuardProps {
  children: ReactNode;
  allowUnauthorizedAccess: boolean;
  permission: PermissionType;
}

const noAuthRoutes = ['/404', '/500', '/invoice'];

const AclGuard = (props: AclGuardProps) => {
  // ** Props
  const { permission, children, allowUnauthorizedAccess } = props;

  const [ability, setAbility] = useState<AnyMongoAbility | undefined>(undefined);

  // ** Hooks
  const auth = useAuth();
  const user = auth.user as UserType;
  const router = useRouter();

  // Check if route matches noAuthRoutes (exact or 'startsWith')
  const routeRequiresNoAuth = (route: string) => {
    for (const noAuthRoute of noAuthRoutes) {
      if ((noAuthRoute.endsWith('/') && route.startsWith(noAuthRoute)) || route === noAuthRoute) {
        return true;
      }
    }
    return false;
  };

  // Use created function in the if condition
  if (allowUnauthorizedAccess || routeRequiresNoAuth(router.route)) {
    return <>{children}</>;
  }

  // User is logged in, build ability for the user based on his role
  if (user && !ability) {
    const ability = userAbilityBuilder(user);
    setAbility(ability);
  }

  // Check the access of current user and render pages

  if (ability && canAccess(ability, permission)) {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  }

  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  );
};

export default AclGuard;
