// Env keys for easy auto-completion
export enum ENV_KEYS {
  QUEUE_URL_REQUEST_QUEUE = 'AWS_REQUEST_QUEUE_URL',
  QUEUE_URL_BATCH_QUEUE = 'AWS_BATCH_QUEUE_URL',
  QUEUE_URL_APP_GATEWAY_BATCH_QUEUE = 'AWS_APP_GATEWAY_BATCH_QUEUE_URL',
  QUEUE_URL_APP_GATEWAY_REQUEST_QUEUE = 'AWS_APP_GATEWAY_REQUEST_QUEUE_URL',
  QUEUE_URL_BG_SERVICE_BATCH_QUEUE = 'AWS_BG_SERVICE_BATCH_QUEUE_URL',
  QUEUE_URL_BG_SERVICE_REQUEST_QUEUE = 'AWS_BG_SERVICE_REQUEST_QUEUE_URL',
  QUEUE_URL_COMMUNICATION_SERVICE_REQUEST_QUEUE = 'AWS_COMMUNICATION_SERVICE_REQUEST_QUEUE_URL',
  QUEUE_TOPIC_AWS_SEND_MESSAGE_TOPIC_ARN = 'AWS_SEND_MESSAGE_TOPIC_ARN',
  QUEUE_TOPIC_AWS_RECEIVE_MESSAGE_TOPIC_ARN = 'AWS_RECEIVE_MESSAGE_TOPIC_ARN',
}
