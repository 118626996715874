import { Button, Typography, useTheme } from '@mui/material';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { RootState } from 'apps/webapp/src/store';
import { selectedClient } from 'apps/webapp/src/store/client';
import { socket } from 'apps/webapp/src/websocket/WebSocketClient';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const OfflineBanner = () => {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const currentClient = useSelector((state: RootState) => selectedClient(state, user?.customerId));
  const isDataIngestionInProgress = currentClient?.metadata?.isDataIngestionInProgress;
  const [isSocketConnected, setIsSocketConnected] = useState(true);

  socket?.on('disconnect', () => {
    setIsSocketConnected(false);
  });

  socket?.on('connect', () => {
    setIsSocketConnected(true);
  });

  const Router = useRouter();

  if (isDataIngestionInProgress) {
    return (
      <Typography
        style={{
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'auto',
          padding: '10px 20px',
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.grey[900],
          textAlign: 'center',
          zIndex: 1000,
          borderRadius: '0px 0px 10px 10px',
        }}
      >
        We are processing your data, please wait for upto 48 hours!
      </Typography>
    );
  }

  if (
    Router.pathname.includes('/login') ||
    Router.pathname.includes('/pay') ||
    Router.pathname.includes('/invoice') ||
    Router.pathname.includes('/forgot-password') ||
    Router.pathname.includes('/set-password') ||
    Router.pathname.includes('/onboarding') ||
    isSocketConnected
  ) {
    return <></>;
  }

  return (
    <Typography
      style={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'auto',
        padding: '10px 20px',
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.grey[900],
        textAlign: 'center',
        zIndex: 1000,
        borderRadius: '0px 0px 10px 10px',
      }}
    >
      You are offline!
      <Button variant='text' sx={{ '&:hover': { textDecoration: 'underline' } }} onClick={logout}>
        Logout and Retry...
      </Button>
    </Typography>
  );
};

export default OfflineBanner;
