// ** Type Imports
import { LayoutProps, NavGroup, NavLink, NavSectionTitle } from 'apps/webapp/src/@core/layouts/types';
// ** Component Imports
// ** Custom Menu Components
import CanWrapper from 'apps/webapp/src/layouts/components/acl/CanWrapper';
import VerticalNavGroup from './VerticalNavGroup';
import VerticalNavLink from './VerticalNavLink';
import VerticalNavSectionTitle from './VerticalNavSectionTitle';

interface Props {
  parent?: NavGroup;
  navHover?: boolean;
  navVisible?: boolean;
  groupActive: string[];
  isSubToSub?: NavGroup;
  currentActiveGroup: string[];
  navigationBorderWidth: number;
  settings: LayoutProps['settings'];
  saveSettings: LayoutProps['saveSettings'];
  setGroupActive: (value: string[]) => void;
  setCurrentActiveGroup: (item: string[]) => void;
  verticalNavItems?: LayoutProps['verticalLayoutProps']['navMenu']['navItems'];
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle;
  if ((item as NavGroup).children) return VerticalNavGroup;

  return VerticalNavLink;
};

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props;

  const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
    const TagName: any = resolveNavItemComponent(item);
    return (
      <CanWrapper permission={item.permission} key={index}>
        <TagName {...props} item={item} />
      </CanWrapper>
    );
  });

  return <>{RenderMenuItems}</>;
};

export default VerticalNavItems;
