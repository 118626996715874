export enum CommunicationChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CALL = 'CALL',
  WHATSAPP = 'WHATSAPP',
  FAX = 'FAX',
  MAIL = 'MAIL',
}

export enum CommunicationType {
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  OFFICE = 'WORK',
  EX_OFFICE = 'EX_OFFICE',
}

export enum CommunicationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  WRONG = 'WRONG',
  OPTED_OUT = 'OPTED_OUT',
}

export type ContactType = {
  value: string;
  type: CommunicationType;
  department: string;
  channel: CommunicationChannel;
  status: CommunicationStatus;
  level: number;
  parentId: string;
  isPrimary: boolean;
  isDND: boolean;
};
