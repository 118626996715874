import IconStyles from 'apps/webapp/src/types/iconStyles';

const ForwardIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none'>
      <path
        d='M10.944 0.469211C10.6508 0.176567 10.1759 0.176969 9.88329 0.470111C9.59065 0.763252 9.59105 1.23813 9.88419 1.53077L10.944 0.469211ZM13.7201 5.3602C14.0133 5.65284 14.4881 5.65244 14.7808 5.3593C15.0734 5.06616 15.073 4.59129 14.7799 4.29864L13.7201 5.3602ZM14.7632 5.37648C15.0652 5.09306 15.0804 4.61843 14.7969 4.31636C14.5135 4.01429 14.0389 3.99917 13.7368 4.2826L14.7632 5.37648ZM9.90089 7.88174C9.59882 8.16516 9.58371 8.63979 9.86713 8.94186C10.1506 9.24393 10.6252 9.25904 10.9273 8.97562L9.90089 7.88174ZM14.25 5.57931C14.6642 5.57931 15 5.24353 15 4.82931C15 4.4151 14.6642 4.07931 14.25 4.07931L14.25 5.57931ZM4.62071 4.82931L4.61843 5.57931L4.62071 5.57931L4.62071 4.82931ZM2.24116 5.80668L1.71237 5.27482L1.71237 5.27482L2.24116 5.80668ZM0.500003 8.17846C0.49886 8.59267 0.833719 8.92938 1.24793 8.93052C1.66214 8.93167 1.99885 8.59681 2 8.1826L0.500003 8.17846ZM9.88419 1.53077L13.7201 5.3602L14.7799 4.29864L10.944 0.469211L9.88419 1.53077ZM13.7368 4.2826L9.90089 7.88174L10.9273 8.97562L14.7632 5.37648L13.7368 4.2826ZM14.25 4.07931L4.62071 4.07931L4.62071 5.57931L14.25 5.57931L14.25 4.07931ZM4.623 4.07932C3.53263 4.076 2.48561 4.50604 1.71237 5.27482L2.76995 6.33855C3.26102 5.85032 3.92596 5.5772 4.61843 5.57931L4.623 4.07932ZM1.71237 5.27482C0.939124 6.04359 0.503012 7.08809 0.500003 8.17846L2 8.1826C2.00191 7.49012 2.27888 6.82678 2.76995 6.33855L1.71237 5.27482Z'
        fill={color}
      />
    </svg>
  );
};

export default ForwardIcon;
