export enum LANGUAGE {
  ENGLISH = 'en',
  UK_ENGLISH = 'en_uk',
  SPANISH = 'es',
  ARABIC = 'ar',
  FRENCH = 'fr',
}

export enum DIALECT {
  US = 'US',
  UK = 'UK',
  MX = 'MX',
  FR = 'FR',
}
