const IconNames = {
  clock: 'clock',
  paid: 'paid',
  unPaid: 'unPaid',
  delay: 'delay',
  filter: 'filter',
  user: 'user',
  assigned: 'assigned',
  ellipse: 'ellipse',
  worklist: 'worklist',
  completed: 'completed',
  unassigned: 'unassigned',
  external: 'external',
  autoPilot: 'autoPilot',
  review: 'review',
  dropdown: 'dropdown',
  maintainenceError: 'maintaince-error',
  fourOFourError: 'four-o-four-error',
  connectionLostError: 'connect-lost-error',
  dollar: 'dollar',
  laptop: 'laptop',
  hundred: 'hundred',
  trendUp: 'trendUp',
  trendDown: 'trendDown',
  rightArrow: 'rightArrow',
  priorityFlag: 'priorityFlag',
  download: 'download',
  checkedFilled: 'checkedFilled',
  congratulation: 'congratulation',
  conversation: 'conversation',
  negotiation: 'negotiation',
  openedEmail: 'openedEmail',
  sms: 'sms',
  openedEmaiFilled: 'openedEmailFilled',
  smsFilled: 'smsFilled',
  reply: 'reply',
  replyAll: 'replyAll',
  forward: 'forward',
  delete: 'delete',
  expand: 'expand',
  emailEmoji: 'emailEmoji',
  emailAttachment: 'emailAttachment',
  emailImage: 'emailImage',
  emailBold: 'emailBold',
  emailItalic: 'emailItalic',
  emailLink: 'emailLink',
  emailBackward: 'emailBackward',
  emailForward: 'emailForward',
  settings: 'settings',
  openedMail: 'openedMail',
  common: {
    plus: 'plus',
    moneyLink: 'moneyLink',
    chat: 'chat',
    euro: 'euro',
    info: 'info',
    moneyBag: 'moneyBag',
    note: 'note',
    notePen: 'notepen',
    sheet: 'sheet',
    sync: 'sync',
    whatsApp: 'whatsapp',
    activityLog: 'activitylog',
    user: 'pinnedUser',
  },
  changeRequest: 'changeRequest',
  addCircled: 'addCircled',
  noDataCross: 'noDataCross',
  checkedWithCircle: 'checkedWithCircle',
  calender: 'calender',
  calenderFilled: 'calenderFilled',
  workflow: 'workflow',
  writeNewMail: 'writeNewMail',
  strategyMenuIcon: 'strategyMenuIcon',
  timer: 'timer',
  refresh: 'refresh',
};

export default IconNames;
