import { ObjectId } from 'bson';
import { COMMUNICATION_MODE, DIALECT, GenericObject, LANGUAGE } from '../common';
import { AiCustomerSegment } from './AiCustomerSegment';

export type CustomerType = {
  _id: ObjectId;
  sourceId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  isPrimary: boolean;
  parentId: ObjectId | null;
  email: string;
  contactNumber: string;
  contactStatus: CONTACT_STATUS;
  contactStatusUpdateDate?: Date;
  emailStatus: CONTACT_STATUS;
  emailStatusReason: string;
  contactAttempts: number;
  clientId: ObjectId;
  consent: CONSENT;
  status?: CUSTOMER_STATUS;
  address?: string;
  engagementScore?: number;
  easeOfCollections: { value: 'High' | 'Low'; complexity: 'High' | 'Low' };
  aiCustomerSegment?: AiCustomerSegment;
  chatDisabled: boolean;
  location?: string;
  language: LANGUAGE;
  dialect: DIALECT | null;
  otp: string | null;
  otpExpiry: Date;
  otpAttempts: number;
  otpBlockedDate: Date;
  metadata?: GenericObject;
  createdAt: Date;
  updatedAt: Date;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  easeOfCollection?: { value: 'High' | 'Low'; complexity: 'High' | 'Low' };
  customerCharacteristics?: {
    [key: string]: any;
  };
};

export enum CONSENT {
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export type DummyAccountRequestType = {
  name: string;
  persona: string;
};

export type UpdateCustomer = {
  _id: string;
  easeToCollect: boolean;
};

export type CustomerDictionary = { [key: string]: CustomerType };

export type METADATA_COUNTERPARTY = {
  communication: {
    mode: COMMUNICATION_MODE;
  };
};

export enum CONTACT_STATUS {
  VALID = 'Valid', // status = sent or delivered
  INVALID = 'Invalid', // status = undelivered or failed
  WRONG_NUMBER = 'Wrong Number', // status = manually marked from UI
  OPTED_OUT = 'Opted Out', // as soon as a "STOP" message is received
  DND = 'DND', // if a collection agent marks the customer as DND
  NOT_REACHABLE = 'Not Reachable', // if the network provider marks the customer as not reachable
}

export enum CUSTOMER_STATUS {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DISPUTE = 'DISPUTE',
  INSURANCE_PENDING = 'INSURANCE_PENDING',
  INVOICE_PENDING = 'INVOICE_PENDING',
  HOLD = 'HOLD',
  WRITE_OFF = 'WRITE_OFF',
  COLLECTIONS = 'COLLECTIONS',
  INSURANCE_PAID = 'INSURANCE_PAID',
  BANKRUPT = 'BANKRUPT',
}

export const getFullName = (customer?: CustomerType, defaultValue = '-'): string => {
  if (!customer) {
    return defaultValue;
  }
  return `${customer.firstName} ${customer.middleName ? customer.middleName + ' ' : ''}${customer.lastName}`;
};
