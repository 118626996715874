export type CloneCustomerContextForCustomerRequest = {
  primaryCounterPartyId: string;
  taskId: string;
};

export type CloneCustomerContextForCustomerResponse = {
  [Key: string]: any;
};

export const PlayGroundClientId = '659e8a9dac12d8d9d3e0ce61';
