export interface TimezoneOption {
  key: string;
  name: string;
  value: string;
}

export const TIMEZONE_OPTIONS: TimezoneOption[] = [
  { key: 'UTC_MINUS_12', name: 'UTC-12:00 (Baker Island)', value: 'UTC-12:00' },
  { key: 'UTC_MINUS_11', name: 'UTC-11:00 (American Samoa)', value: 'UTC-11:00' },
  { key: 'UTC_MINUS_10', name: 'UTC-10:00 (Hawaii-Aleutian)', value: 'UTC-10:00' },
  { key: 'UTC_MINUS_9_30', name: 'UTC-09:30 (Marquesas Islands)', value: 'UTC-09:30' },
  { key: 'UTC_MINUS_9', name: 'UTC-09:00 (Alaska)', value: 'UTC-09:00' },
  { key: 'UTC_MINUS_8', name: 'UTC-08:00 (Pacific Time)', value: 'UTC-08:00' },
  { key: 'UTC_MINUS_7', name: 'UTC-07:00 (Mountain Time)', value: 'UTC-07:00' },
  { key: 'UTC_MINUS_6', name: 'UTC-06:00 (Central Time)', value: 'UTC-06:00' },
  { key: 'UTC_MINUS_5', name: 'UTC-05:00 (Eastern Time)', value: 'UTC-05:00' },
  { key: 'UTC_MINUS_4_30', name: 'UTC-04:30 (Venezuela)', value: 'UTC-04:30' },
  { key: 'UTC_MINUS_4', name: 'UTC-04:00 (Atlantic Time)', value: 'UTC-04:00' },
  { key: 'UTC_MINUS_3_30', name: 'UTC-03:30 (Newfoundland)', value: 'UTC-03:30' },
  { key: 'UTC_MINUS_3', name: 'UTC-03:00 (Argentina, Brazil, Uruguay)', value: 'UTC-03:00' },
  { key: 'UTC_MINUS_2', name: 'UTC-02:00 (South Georgia)', value: 'UTC-02:00' },
  { key: 'UTC_MINUS_1', name: 'UTC-01:00 (Azores)', value: 'UTC-01:00' },
  { key: 'UTC', name: 'UTC+00:00 (UTC)', value: 'UTC+00:00' },
  { key: 'UTC_PLUS_1', name: 'UTC+01:00 (Central European Time)', value: 'UTC+01:00' },
  { key: 'UTC_PLUS_2', name: 'UTC+02:00 (Eastern European Time)', value: 'UTC+02:00' },
  { key: 'UTC_PLUS_3', name: 'UTC+03:00 (Moscow Time)', value: 'UTC+03:00' },
  { key: 'UTC_PLUS_3_30', name: 'UTC+03:30 (Iran)', value: 'UTC+03:30' },
  { key: 'UTC_PLUS_4', name: 'UTC+04:00 (Gulf Standard Time)', value: 'UTC+04:00' },
  { key: 'UTC_PLUS_4_30', name: 'UTC+04:30 (Afghanistan)', value: 'UTC+04:30' },
  { key: 'UTC_PLUS_5', name: 'UTC+05:00 (Pakistan Standard Time)', value: 'UTC+05:00' },
  { key: 'UTC_PLUS_5_30', name: 'UTC+05:30 (Indian Standard Time)', value: 'UTC+05:30' },
  { key: 'UTC_PLUS_5_45', name: 'UTC+05:45 (Nepal Time)', value: 'UTC+05:45' },
  { key: 'UTC_PLUS_6', name: 'UTC+06:00 (Bangladesh Standard Time)', value: 'UTC+06:00' },
  { key: 'UTC_PLUS_6_30', name: 'UTC+06:30 (Cocos Islands)', value: 'UTC+06:30' },
  { key: 'UTC_PLUS_7', name: 'UTC+07:00 (Indochina Time)', value: 'UTC+07:00' },
  { key: 'UTC_PLUS_8', name: 'UTC+08:00 (China Standard Time)', value: 'UTC+08:00' },
  { key: 'UTC_PLUS_8_45', name: 'UTC+08:45 (Australian Central Western Standard Time)', value: 'UTC+08:45' },
  { key: 'UTC_PLUS_9', name: 'UTC+09:00 (Japan Standard Time)', value: 'UTC+09:00' },
  { key: 'UTC_PLUS_9_30', name: 'UTC+09:30 (Australian Central Standard Time)', value: 'UTC+09:30' },
  { key: 'UTC_PLUS_10', name: 'UTC+10:00 (Australian Eastern Standard Time)', value: 'UTC+10:00' },
  { key: 'UTC_PLUS_10_30', name: 'UTC+10:30 (Lord Howe Island)', value: 'UTC+10:30' },
  { key: 'UTC_PLUS_11', name: 'UTC+11:00 (Solomon Islands)', value: 'UTC+11:00' },
  { key: 'UTC_PLUS_12', name: 'UTC+12:00 (New Zealand Standard Time)', value: 'UTC+12:00' },
  { key: 'UTC_PLUS_12_45', name: 'UTC+12:45 (Chatham Islands)', value: 'UTC+12:45' },
  { key: 'UTC_PLUS_13', name: 'UTC+13:00 (Tonga)', value: 'UTC+13:00' },
  { key: 'UTC_PLUS_14', name: 'UTC+14:00 (Line Islands)', value: 'UTC+14:00' },
];
