import IconStyles from 'apps/webapp/src/types/iconStyles';

const CheckedFilled = (styles: IconStyles) => {
  const color = styles.color || '#6461F3';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
      <circle cx='7.5' cy='7.5' r='6.5' fill={color} stroke={color} strokeWidth='2' />
      <path
        d='M5 7.60714L6.66679 9.21429L10 6'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckedFilled;
