import IconStyles from 'apps/webapp/src/types/iconStyles';

const AddCircledIcon = (styles: IconStyles) => {
  const color = styles.color || '#6461F3';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
      <circle cx='10.5' cy='10.5' r='10.5' fill={color} />
    </svg>
  );
};

export default AddCircledIcon;
