import IconStyles from 'apps/webapp/src/types/iconStyles';

const WhatsAppIcon = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 15.0001C12.1157 14.9994 14.8559 12.9396 15.7224 9.94677C16.5889 6.95397 15.373 3.74874 12.7396 2.0836C10.1062 0.418453 6.68919 0.694279 4.35695 2.76026C2.02471 4.82624 1.3387 8.185 2.674 11.0001L1 15.0001L5.392 14.0001C6.4809 14.6564 7.72862 15.0022 9 15.0001Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.0406 7.06596C6.47129 7.061 6.01332 6.59634 6.01662 6.02701C6.01992 5.45769 6.48324 4.99837 7.05258 5C7.62191 5.00164 8.08258 5.46363 8.0826 6.03296C8.07985 6.60584 7.61349 7.06818 7.0406 7.06596V7.06596Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91702 9.91802C9.91378 10.3397 10.1652 10.7217 10.5538 10.8854C10.9424 11.0491 11.3914 10.9622 11.6909 10.6653C11.9904 10.3684 12.0811 9.92022 11.9208 9.53021C11.7605 9.1402 11.3807 8.88544 10.959 8.88502C10.3861 8.8828 9.91978 9.34514 9.91702 9.91802V9.91802Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.00004 6.0332C5.98604 9.5002 9.30004 11.3302 10.959 10.9512'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default WhatsAppIcon;
