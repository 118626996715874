const NegotiationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='38' height='38' viewBox='0 0 38 38' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.68958 10.8461C1.03998 9.19657 0.546472 6.71578 1.43918 4.56053C2.33188 2.40527 4.43499 1 6.76781 1C9.10063 1 11.2037 2.40527 12.0964 4.56053C12.9891 6.71578 12.4956 9.19657 10.846 10.8461C8.59366 13.0983 4.94195 13.0983 2.68958 10.8461Z'
        stroke='#F1F1FF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.1534 35.3109C25.5038 33.6614 25.0103 31.1806 25.903 29.0254C26.7957 26.8701 28.8989 25.4648 31.2317 25.4648C33.5645 25.4648 35.6676 26.8701 36.5603 29.0254C37.453 31.1806 36.9595 33.6614 35.3099 35.3109C33.0575 37.5632 29.4058 37.5632 27.1534 35.3109V35.3109Z'
        stroke='#F1F1FF'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path d='M35.3108 2.68994L2.6875 35.3132' stroke='#F1F1FF' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  );
};

export default NegotiationIcon;
