const SmsFilledIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
      <rect width='30' height='30' rx='15' fill='#6461F3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9258 10H17.2572C18.8846 10.0122 20.194 11.3347 20.182 12.9541V16.5408C20.1878 17.3186 19.8828 18.0668 19.3341 18.6208C18.7854 19.1748 18.038 19.4892 17.2564 19.4949H11.9258L9.00008 21.1265V12.9541C8.99434 12.1763 9.29936 11.4281 9.84804 10.8741C10.3967 10.3201 11.1441 10.0057 11.9258 10Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5972 14.7689C10.5972 14.3299 10.9548 13.9741 11.3959 13.9741C11.837 13.9741 12.1946 14.3299 12.1946 14.7689C12.1946 15.2078 11.837 15.5636 11.3959 15.5636C11.184 15.5636 10.9809 15.4799 10.8311 15.3308C10.6813 15.1818 10.5972 14.9796 10.5972 14.7689Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.792 14.7689C13.792 14.3299 14.1496 13.9741 14.5907 13.9741C15.0318 13.9741 15.3894 14.3299 15.3894 14.7689C15.3894 15.2078 15.0318 15.5636 14.5907 15.5636C14.1496 15.5636 13.792 15.2078 13.792 14.7689Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9873 14.7689C16.9873 14.3299 17.3449 13.9741 17.786 13.9741C18.2271 13.9741 18.5847 14.3299 18.5847 14.7689C18.5847 15.2078 18.2271 15.5636 17.786 15.5636C17.3449 15.5636 16.9873 15.2078 16.9873 14.7689Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SmsFilledIcon;
