import { P2P_ACTION_TYPES } from '@finance-ops/constants';

import { AGGREGATION_TYPE, TEAM_OVERVIEW_GRAPH_TYPE, collectionsResponseType } from '../websocket';
import { USER_TYPE, UserType } from '../user';

export type CommunicationMetricsValuesType = {
  assignedCustomers: number;
  closedCustomers: number;
  communicationMetrics: {
    SMS: number;
  };
};

export enum DashboardGraphTypes {
  ACTION_CENTER_GRAPH = 'ACTION_CENTER_GRAPH',
  CUSTOMER_STATUS_GRAPH = 'CUSTOMER_STATUS_GRAPH',
  TOP_DEBTORS = 'TOP_DEBTORS',
  RECENT_PAYMENTS = 'RECENT_PAYMENTS',
  DELINQUENCY_GRAPH = 'DELINQUENCY_GRAPH',
  PAYMENT_TYPES_GRAPH = 'PAYMENT_TYPES_GRAPH',
  PAYMENT_STATUS_GRAPH = 'PAYMENT_STATUS_GRAPH',
  RECEIVABLES_GRAPH = 'RECEIVABLES_GRAPH',
}

export enum UI_MODE {
  ALL = 'All',
  AUTOPILOT = 'Autopilot',
  COPILOT = 'Copilot',
}

export type selectUIModeType = UI_MODE.COPILOT | UI_MODE.AUTOPILOT;

export interface DashboardTotalSummaryInterface {
  _id: string;
  openAmount: number;
  collectedAmount: number;
  totalInvoices: number;
  openInvoices: number;
  paidInvoices: number;
  partiallyPaidInvoices: number;
  clientIds: string[] | string;
}

export interface CollectabilityObject {
  _id: {
    difficulty: string;
    amount: string;
  };
  count: number;
  amount: number;
}

export type P2psGroupedByType = {
  userEntityType: P2P_ACTION_TYPES;
  count: number;
  amount: number;
};

export type invoicesType = {
  open: number;
  paid: number;
  partiallyPaid: number;
  total: number;
};

export interface TopDebtor {
  customerId: string;
  customerName: string;
  delinquentDays: number;
  amount: number;
  assignedAgentId: string;
  assignedAgentName: string;
  hasPaymentPlan: 'Yes' | 'No';
  referenceId: string;
}
export interface RecentPayments {
  customerName: string;
  amount: number;
  transactionId: string;
  assignedAgentName: string;
  transactionDate: string;
}

export type GraphDataPoint = {
  x: Date;
  y?: number;
};

export type summaryAggregationType = {
  amount: number;
  customersCount: number;
  invoicesCount: number;
};

export type overallSummaryAggregationType = {
  totalDue: summaryAggregationType;
  collections: summaryAggregationType;
  currentDue: summaryAggregationType;
  overdue: summaryAggregationType;
};

const defaultSummaryAggregation: summaryAggregationType = {
  amount: 0,
  customersCount: 0,
  invoicesCount: 0,
};

export const defaultOverallSummaryAggregation: overallSummaryAggregationType = {
  totalDue: defaultSummaryAggregation,
  collections: defaultSummaryAggregation,
  currentDue: defaultSummaryAggregation,
  overdue: defaultSummaryAggregation,
};

export interface DashboardInterface {
  clientId: string | null;
  assignedAgentId: string | null;
  uiMode: UI_MODE;
  type: AGGREGATION_TYPE;
  forDate: Date;
  ctc: number;
  openAmount: number;
  dashboardMetrics: overallSummaryAggregationType;
  currentDueAmount: number;
  pastDueAmount: number;
  totalP2PAmount: number;
  totalDisputeAmount: number;
  averageDelinquentDays: number;
  agingSummary: DashboardAgingSummary;
  outgoingMsgCount: number;
  incomingMsgCount: number;
  allOutgoingMsgCount: number;
  allIncomingMsgCount: number;
  naEngagementScoreCount: number;
  poorEngagementScoreCount: number;
  mediumEngagementScoreCount: number;
  highEngagementScoreCount: number;
  collectabilityMatrix: CollectabilityObject[];
  grouped: boolean;
  newInvoicesAmounts: number;
  assignedCustomersCount: number;
  p2psGroupedByType: P2psGroupedByType[];
  createdAt?: Date;
  updatedAt?: Date;
  invoices: invoicesType;
  collectedAmount: number;
  paymentPlans: number;
  newInvoicesCollected: number;
  totalDue: number;
  predictedCollections: number;
}

// paymentTypes: {
//   text: PaymentMethods;
//   count: number;
// }[];

// paymentStatus: {
//   text: PaymentStatusType;
//   count: number;
// }[];

export const dashboardInitialValues: DashboardInterface = {
  clientId: null,
  openAmount: 0,
  currentDueAmount: 0,
  pastDueAmount: 0,
  totalP2PAmount: 0,
  totalDisputeAmount: 0,
  ctc: 0,
  agingSummary: {
    boundaries: [0, 30, 60, 90, 120],
    endDefault: 150,
    info: [],
    predicted: [],
  },
  dashboardMetrics: defaultOverallSummaryAggregation,
  averageDelinquentDays: 0,
  forDate: new Date(),
  outgoingMsgCount: 0,
  incomingMsgCount: 0,
  uiMode: UI_MODE.ALL,
  poorEngagementScoreCount: 0,
  mediumEngagementScoreCount: 0,
  highEngagementScoreCount: 0,
  naEngagementScoreCount: 0,
  collectabilityMatrix: [],
  assignedAgentId: null,
  type: AGGREGATION_TYPE.EXTERNAL,
  grouped: false,
  assignedCustomersCount: 0,
  newInvoicesAmounts: 0,
  p2psGroupedByType: [],
  allIncomingMsgCount: 0,
  allOutgoingMsgCount: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  invoices: {
    open: 0,
    paid: 0,
    partiallyPaid: 0,
    total: 0,
  },
  collectedAmount: 0,
  paymentPlans: 0,
  newInvoicesCollected: 0,
  totalDue: 0,
  predictedCollections: 0,
};

export type DashboardAgingSummary = {
  boundaries: number[];
  endDefault: number;
  info: {
    _id: string;
    data: {
      type: 'P2P' | 'Open' | 'Others';
      count: number;
      amount: number;
    }[];
  }[];
  predicted: {
    _id: string;
    amount: number;
    count: number;
  }[];
};

export type AgingSummaryDisplay = {
  open: number;
  p2p: number;
  others: number;
  predicted?: number;
};

export type DashboardAgingSummaryDisplay = {
  open: number[];
  p2p: number[];
  others: number[];
  predicted: number[];
};

export type DashboardCollectedSummary = {
  for: number[];
  collected: number[];
};

export interface DashboardTrendsInterface {
  currentDueAmountTrend: number;
  pastDueAmountTrend: number;
  delinquencyDaysTrend: number;
  totalDueAmountTrend: number;
}

export enum DownSamplingTimeFrame {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export type DashboardSummaryDtoType = {
  userType: USER_TYPE;
  clientId?: string;
  userId: string;
  uiMode: UI_MODE;
  agentId?: string;
  clientIdDropdown?: string;
  filters?: any;
  downSamplingSize?: DownSamplingTimeFrame;
  user?: UserType;
};

export type TeamOverviewAgentDetailsDtoType = {
  userType: USER_TYPE;
  clientId: string;
  userId: string;
  uiMode: UI_MODE;
  clientIdDropdown?: string;
  agentId: string;
  collectionsTimeframe: string;
};

export type GetAgentsDtoType = {
  userType: USER_TYPE;
  userId: string;
  clientId: string;
  uiMode: UI_MODE;
  clientIdDropdown?: string;
};

export type GetTeamOverviewGraphDtoType = {
  userType: USER_TYPE;
  clientId: string;
  uiMode: UI_MODE;
  clientIdDropdown?: string;
  agentId?: string;
  timeframe: string;
  graphType: TEAM_OVERVIEW_GRAPH_TYPE;
  userId: string;
};

export type AgentsTeamOverviewType = {
  _id: string;
  name: string;
  totalCustomersAlloted: number;
  totalDueToBeCollected: number;
  totalCollected: number;
};

export type CollectionGraphType = {
  collections: { monthly: collectionsResponseType; weekly: collectionsResponseType };
};

export type AggregationQueryType = {
  forDate?: string;
  clientId?: string | null;
  uiMode: UI_MODE;
  grouped: boolean;
  type: AGGREGATION_TYPE;
  assignedAgentId?: string | null;
};

export type GetTodayAggregationDtoType = {
  id?: string;
  uiMode: UI_MODE;
  type: AGGREGATION_TYPE;
  grouped?: boolean;
  agentId?: string | { $in: string[] };
};

export type GetDashboardGraphsDtoType = {
  userType: USER_TYPE;
  clientId?: string;
  userId: string;
  uiMode: UI_MODE;
  clientIdDropdown: string;
  graphType: string;
  filters?: any;
  agentId?: string;
};

export interface TodayDashboardAggregationInterface {
  clientId: string | null;
  assignedAgentId: string | null;
  uiMode: UI_MODE;
  type: AGGREGATION_TYPE;
  forDate: Date;
  grouped: boolean;
  messages: object;
  collections: object;
  updatedAt: Date;
  createdAt: Date;
}

export enum ENGAGEMENT_SCORE {
  LOW = 0,
  MEDIUM = 40,
  HIGH = 70,
}

export const PAYMENT_TRACKING_START_DATE = '2024-03-01';

export type TeamManagementSummaryType = { agents: number; avgCasesPerAgent: number; avgCollectedPerAgent: number };

export const GROUPED_DISPLAY_NAME = 'Value';
export const enum OTHER_CHANNELS {
  EMAIL = 'EMAIL',
}

export type TeamManagementUserType = UserType & {
  name: string;
};

export type FilteredUserType = Pick<TeamManagementUserType, 'name' | 'id'>;

export enum TEAM_MANAGEMENT_ASSETS {
  COLLECTIONS_GRAPH = 'COLLECTIONS_GRAPH',
  RECOVERY_RATE_AND_COMM_METRICS = 'RECOVERY_RATE_AND_COMM_METRICS',
  COMMUNICATION_METRICS = 'COMMUNICATION_METRICS',
  P2P_DETAILS = 'P2P_DETAILS',
}
