import { CustomerContextPaginationPayload } from '../store/customercontext';

export function createPaginationKey(payload: CustomerContextPaginationPayload): string {
  const keyObject = {
    stateFilter: payload.stateFilter || null,
    selectedClientId: payload.selectedClientId,
    query: payload.query || '',
    assignedUserId: payload.assignedUserId || null,
    enabledSecondaryFiltersOrSort: payload.enabledSecondaryFiltersOrSort || false,
    requestHash: payload.requestHash || null,
  };

  return JSON.stringify(keyObject);
}
