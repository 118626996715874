import IconStyles from 'apps/webapp/src/types/iconStyles';

const InfoIcon = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 7.42857V9.57143C1 13.1218 3.87817 16 7.42857 16H9.57143C13.1218 16 16 13.1218 16 9.57143V7.42857C16 3.87817 13.1218 1 9.57143 1H7.42857C3.87817 1 1 3.87817 1 7.42857Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.50007 8.5V12.7857' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M8.50105 5.82143C8.20565 5.82143 7.96533 5.58111 7.96533 5.28571C7.96533 4.99032 8.20565 4.75 8.50105 4.75C8.79644 4.75 9.03676 4.99032 9.03676 5.28571C9.03676 5.58111 8.79644 5.82143 8.50105 5.82143Z'
        fill={color}
      />
      <path
        d='M8.50112 4.21436C9.09284 4.21436 9.57254 4.69406 9.57254 5.28578C9.57254 5.87751 9.09284 6.35721 8.50112 6.35721C7.90939 6.35721 7.42969 5.87751 7.42969 5.28578C7.42969 4.69406 7.90939 4.21436 8.50112 4.21436Z'
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
