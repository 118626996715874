const FourOFourIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='454' height='233' viewBox='0 0 454 233' fill='none'>
      <g clipPath='url(#clip0_6158_44820)'>
        <path
          d='M139.086 210.003C139.086 197.703 177.786 187.603 225.486 187.603C273.186 187.603 311.886 197.703 311.886 210.103C311.87 211.507 311.415 212.871 310.586 214.003C303.386 224.503 267.986 232.503 225.486 232.503C177.786 232.503 139.086 222.403 139.086 210.003Z'
          fill='#7A97F4'
        />
        <path
          d='M121.886 194.703H38.6863C36.5541 194.708 34.4821 195.411 32.7863 196.703L0.78632 221.803C0.454758 222.057 0.210723 222.409 0.0882336 222.808C-0.034256 223.207 -0.0290965 223.635 0.102993 224.031C0.235082 224.427 0.487518 224.773 0.825114 225.019C1.16271 225.265 1.56864 225.399 1.98632 225.403H89.8863C92.116 225.382 94.2566 224.525 95.8863 223.003L122.786 197.003C122.982 196.822 123.119 196.587 123.179 196.327C123.239 196.067 123.219 195.795 123.121 195.546C123.024 195.298 122.854 195.085 122.634 194.934C122.414 194.784 122.153 194.703 121.886 194.703Z'
          fill='#7A97F4'
        />
        <path
          d='M451.986 194.703H368.686C366.554 194.708 364.482 195.411 362.786 196.703L330.786 221.803C330.455 222.057 330.211 222.409 330.088 222.808C329.966 223.207 329.971 223.635 330.103 224.031C330.235 224.427 330.488 224.773 330.825 225.019C331.163 225.265 331.569 225.399 331.986 225.403H419.886C422.116 225.382 424.257 224.525 425.886 223.003L452.786 197.003C453 196.839 453.156 196.612 453.234 196.354C453.311 196.095 453.305 195.819 453.216 195.565C453.128 195.31 452.961 195.09 452.74 194.935C452.52 194.781 452.256 194.7 451.986 194.703Z'
          fill='#7A97F4'
        />
        <path
          d='M139.086 205.903C139.086 193.603 177.786 183.503 225.486 183.503C273.186 183.503 311.886 193.603 311.886 206.003C311.87 207.407 311.415 208.771 310.586 209.903C303.386 220.403 267.986 228.403 225.486 228.403C177.786 228.403 139.086 218.303 139.086 205.903Z'
          fill='#E1ECFF'
        />
        <path
          d='M310.586 209.903C303.386 220.403 268.086 228.403 225.486 228.403C177.786 228.403 139.086 218.303 139.086 205.903C139.086 205.903 154.086 224.003 222.486 224.003C281.286 224.103 304.986 213.003 310.586 209.903Z'
          fill='#C2D1FF'
        />
        <path
          d='M274.686 200.703C271.386 197.103 250.586 194.303 225.486 194.303C199.286 194.303 177.886 197.303 175.986 201.103C176.886 198.903 182.886 191.303 225.486 191.303C264.686 191.303 272.986 198.103 274.686 200.703Z'
          fill='#C2D1FF'
        />
        <path
          d='M122.586 194.703L105.086 69.5034C104.99 68.8587 104.663 68.2706 104.167 67.8479C103.671 67.4251 103.038 67.1962 102.386 67.2034H78.7863C78.3975 67.2057 78.0136 67.2908 77.6601 67.4529C77.3067 67.615 76.9918 67.8506 76.7364 68.1438C76.481 68.437 76.2909 68.7813 76.1788 69.1536C76.0668 69.526 76.0352 69.9179 76.0863 70.3034L81.9863 117.603C82.0435 118.043 82.0058 118.489 81.8759 118.913C81.7459 119.336 81.5266 119.727 81.2329 120.059C80.9391 120.391 80.5776 120.655 80.1729 120.836C79.7681 121.016 79.3294 121.107 78.8863 121.103H51.8863C51.0701 121.109 50.2831 120.8 49.6876 120.242C49.0921 119.684 48.7337 118.918 48.6863 118.103L46.4863 69.9034C46.4522 69.2076 46.1538 68.5511 45.652 68.0679C45.1502 67.5847 44.4829 67.3112 43.7863 67.3034H21.6863C21.3212 67.2961 20.9585 67.3643 20.6209 67.5037C20.2834 67.6432 19.9783 67.8508 19.7248 68.1137C19.4713 68.3766 19.2748 68.6891 19.1478 69.0314C19.0207 69.3738 18.9657 69.7388 18.9863 70.1034L21.9863 152.503C22.0469 153.765 22.5887 154.955 23.5001 155.829C24.4116 156.704 25.6234 157.195 26.8863 157.203H86.9863L91.6863 194.903H122.586V194.703Z'
          fill='#1F1933'
        />
        <path
          d='M452.686 194.703L435.186 69.5034C435.09 68.8587 434.763 68.2706 434.267 67.8479C433.771 67.4251 433.138 67.1962 432.486 67.2034H408.886C408.497 67.2057 408.114 67.2908 407.76 67.4529C407.407 67.615 407.092 67.8506 406.836 68.1438C406.581 68.437 406.391 68.7813 406.279 69.1536C406.167 69.526 406.135 69.9179 406.186 70.3034L412.086 117.603C412.144 118.043 412.106 118.489 411.976 118.913C411.846 119.336 411.627 119.727 411.333 120.059C411.039 120.391 410.678 120.655 410.273 120.836C409.868 121.016 409.429 121.107 408.986 121.103H381.986C381.17 121.109 380.383 120.8 379.788 120.242C379.192 119.684 378.834 118.918 378.786 118.103L376.586 69.9034C376.552 69.2076 376.254 68.5511 375.752 68.0679C375.25 67.5847 374.583 67.3112 373.886 67.3034H351.886C351.521 67.2961 351.158 67.3643 350.821 67.5037C350.483 67.6432 350.178 67.8508 349.925 68.1137C349.671 68.3766 349.475 68.6891 349.348 69.0314C349.221 69.3738 349.166 69.7388 349.186 70.1034L352.186 152.503C352.247 153.765 352.789 154.955 353.7 155.829C354.612 156.704 355.823 157.195 357.086 157.203H417.186L421.886 194.903H452.686V194.703Z'
          fill='#1F1933'
        />
        <path
          d='M104.385 70.8034L91.1853 220.403C91.0749 221.762 90.4588 223.029 89.4589 223.955C88.459 224.881 87.148 225.398 85.7853 225.403H65.9853C65.2152 225.403 64.4539 225.24 63.7516 224.924C63.0494 224.608 62.4223 224.146 61.9119 223.569C61.4014 222.993 61.0192 222.314 60.7905 221.579C60.5618 220.843 60.4919 220.068 60.5853 219.303L68.8853 149.203L9.58532 150.203C8.81923 150.216 8.05975 150.06 7.36138 149.745C6.66301 149.429 6.04305 148.963 5.54599 148.38C5.04892 147.797 4.68708 147.111 4.48641 146.372C4.28574 145.632 4.25122 144.858 4.38532 144.103L4.58532 142.803L18.1853 69.9034C18.3687 68.9224 18.8885 68.0361 19.6552 67.3972C20.4219 66.7584 21.3874 66.4069 22.3853 66.4034H41.2853C41.9043 66.4137 42.5138 66.5567 43.0727 66.8229C43.6316 67.089 44.1269 67.472 44.5251 67.946C44.9233 68.42 45.215 68.974 45.3807 69.5704C45.5463 70.1669 45.582 70.792 45.4853 71.4034L37.3853 117.403C37.3086 117.838 37.3292 118.283 37.4454 118.709C37.5617 119.134 37.7709 119.528 38.0578 119.863C38.3447 120.197 38.7022 120.464 39.1047 120.644C39.5072 120.824 39.9446 120.913 40.3853 120.903H68.6853L75.2853 69.8034C75.4239 68.8383 75.9035 67.9547 76.6372 67.3127C77.371 66.6707 78.3104 66.3126 79.2853 66.3034H100.385C100.944 66.3357 101.49 66.4777 101.993 66.7215C102.497 66.9652 102.947 67.3058 103.319 67.7238C103.69 68.1418 103.976 68.6289 104.159 69.1574C104.342 69.6858 104.419 70.2452 104.385 70.8034Z'
          fill='#E1ECFF'
        />
        <path
          d='M69.2853 142.803L68.7853 149.203L9.48532 150.203C8.71922 150.216 7.95975 150.06 7.26137 149.745C6.563 149.429 5.94304 148.963 5.44598 148.38C4.94892 147.797 4.58707 147.111 4.3864 146.372C4.18573 145.632 4.15121 144.858 4.28532 144.103L4.48532 142.803H69.2853Z'
          fill='#C2D1FF'
        />
        <path
          d='M104.385 70.8034L91.1853 220.403C91.0749 221.762 90.4588 223.029 89.4589 223.955C88.459 224.881 87.148 225.398 85.7853 225.403H82.7853L98.4853 66.4034H100.385C100.941 66.4164 101.489 66.5422 101.994 66.7731C102.5 67.004 102.954 67.3352 103.328 67.7466C103.702 68.158 103.988 68.641 104.17 69.1665C104.352 69.6919 104.425 70.2488 104.385 70.8034Z'
          fill='#C2D1FF'
        />
        <path
          d='M37.2863 117.503C37.2096 117.938 37.2302 118.383 37.3464 118.809C37.4627 119.234 37.6718 119.628 37.9588 119.963C38.2457 120.297 38.6032 120.564 39.0057 120.744C39.4082 120.924 39.8455 121.013 40.2863 121.003H34.4863C33.9859 121.007 33.4909 120.9 33.0361 120.692C32.5812 120.483 32.1775 120.178 31.8535 119.796C31.5294 119.415 31.2928 118.967 31.1602 118.485C31.0277 118.002 31.0025 117.497 31.0863 117.003L39.9863 66.5034H41.2863C41.9053 66.5137 42.5148 66.6568 43.0737 66.9229C43.6326 67.189 44.1279 67.572 44.5261 68.046C44.9242 68.52 45.216 69.074 45.3817 69.6704C45.5473 70.2669 45.583 70.892 45.4863 71.5034L37.2863 117.503Z'
          fill='#C2D1FF'
        />
        <path
          d='M434.385 70.8034L421.185 220.403C421.075 221.762 420.459 223.029 419.459 223.955C418.459 224.881 417.148 225.398 415.785 225.403H395.985C395.215 225.403 394.454 225.24 393.752 224.924C393.049 224.608 392.422 224.146 391.912 223.569C391.401 222.993 391.019 222.314 390.791 221.579C390.562 220.843 390.492 220.068 390.585 219.303L398.885 149.203L339.585 150.203C338.819 150.216 338.06 150.06 337.361 149.745C336.663 149.429 336.043 148.963 335.546 148.38C335.049 147.797 334.687 147.111 334.486 146.372C334.286 145.632 334.251 144.858 334.385 144.103L334.585 142.803L348.185 69.9034C348.369 68.9224 348.889 68.0361 349.655 67.3972C350.422 66.7584 351.387 66.4069 352.385 66.4034H371.285C371.904 66.4137 372.514 66.5567 373.073 66.8229C373.632 67.089 374.127 67.472 374.525 67.946C374.923 68.42 375.215 68.974 375.381 69.5704C375.546 70.1669 375.582 70.792 375.485 71.4034L367.385 117.403C367.309 117.838 367.329 118.283 367.445 118.709C367.562 119.134 367.771 119.528 368.058 119.863C368.345 120.197 368.702 120.464 369.105 120.644C369.507 120.824 369.945 120.913 370.385 120.903H398.685L405.285 69.8034C405.424 68.8383 405.903 67.9547 406.637 67.3127C407.371 66.6707 408.31 66.3126 409.285 66.3034H430.385C430.95 66.318 431.505 66.4483 432.017 66.6861C432.528 66.924 432.986 67.2644 433.361 67.6862C433.736 68.108 434.02 68.6023 434.197 69.1385C434.373 69.6747 434.437 70.2414 434.385 70.8034Z'
          fill='#E1ECFF'
        />
        <path
          d='M399.385 142.803L398.885 149.203L339.585 150.203C338.819 150.216 338.06 150.06 337.361 149.745C336.663 149.429 336.043 148.963 335.546 148.38C335.049 147.797 334.687 147.111 334.486 146.372C334.286 145.632 334.251 144.858 334.385 144.103L334.585 142.803H399.385Z'
          fill='#C2D1FF'
        />
        <path
          d='M434.385 70.8034L421.185 220.403C421.075 221.762 420.459 223.029 419.459 223.955C418.459 224.881 417.148 225.398 415.785 225.403H412.785L428.485 66.4034H430.385C430.947 66.3989 431.502 66.5128 432.017 66.7378C432.531 66.9627 432.992 67.2937 433.369 67.709C433.747 68.1242 434.032 68.6145 434.207 69.1477C434.383 69.681 434.443 70.2451 434.385 70.8034Z'
          fill='#C2D1FF'
        />
        <path
          d='M367.386 117.503C367.31 117.938 367.33 118.383 367.446 118.809C367.563 119.234 367.772 119.628 368.059 119.963C368.346 120.297 368.703 120.564 369.106 120.744C369.508 120.924 369.946 121.013 370.386 121.003H364.586C364.086 121.007 363.591 120.9 363.136 120.692C362.681 120.483 362.278 120.178 361.953 119.796C361.629 119.415 361.393 118.967 361.26 118.485C361.128 118.002 361.102 117.497 361.186 117.003L370.086 66.5034H371.386C372.005 66.5137 372.615 66.6568 373.174 66.9229C373.733 67.189 374.228 67.572 374.626 68.046C375.024 68.52 375.316 69.074 375.482 69.6704C375.647 70.2669 375.683 70.892 375.586 71.5034L367.386 117.503Z'
          fill='#C2D1FF'
        />
        <path
          opacity='0.28'
          d='M185.987 101.3L165.487 205.3C164.387 210.9 167.987 216.2 172.887 216.2H281.487C286.287 216.2 289.887 211.1 288.987 205.7L272.187 101.7C271.487 97.5004 268.387 94.5004 264.687 94.5004H193.487C189.887 94.4004 186.787 97.3004 185.987 101.3Z'
          fill='#FEC272'
        />
        <path
          d='M278.585 107.803L288.485 140.703C288.524 140.87 288.599 141.025 288.705 141.159C288.811 141.293 288.945 141.402 289.098 141.478C289.251 141.555 289.418 141.596 289.589 141.601C289.759 141.605 289.929 141.572 290.085 141.503V141.503C290.355 141.399 290.576 141.197 290.705 140.938C290.835 140.68 290.863 140.382 290.785 140.103L284.285 106.403C284.182 105.978 283.989 105.58 283.718 105.237C283.448 104.894 283.106 104.613 282.717 104.413C282.328 104.214 281.9 104.101 281.463 104.082C281.026 104.063 280.59 104.138 280.185 104.303V104.303C279.541 104.595 279.024 105.111 278.73 105.754C278.436 106.397 278.385 107.125 278.585 107.803V107.803Z'
          fill='#FEC272'
        />
        <path
          d='M259.885 119.702L266.285 145.102C266.308 145.209 266.353 145.31 266.417 145.399C266.481 145.488 266.562 145.563 266.656 145.619C266.749 145.675 266.853 145.711 266.962 145.726C267.07 145.74 267.18 145.732 267.285 145.702V145.702C267.378 145.678 267.465 145.635 267.541 145.576C267.617 145.516 267.68 145.443 267.727 145.359C267.773 145.275 267.803 145.182 267.813 145.087C267.823 144.991 267.813 144.894 267.785 144.802L263.685 118.902C263.635 118.61 263.52 118.333 263.349 118.092C263.177 117.85 262.954 117.65 262.695 117.506C262.435 117.362 262.147 117.278 261.852 117.26C261.556 117.242 261.26 117.291 260.985 117.402V117.402C260.573 117.61 260.241 117.948 260.042 118.364C259.843 118.781 259.788 119.251 259.885 119.702V119.702Z'
          fill='#FEC272'
        />
        <path
          d='M176.485 111.003L168.185 136.303C168.155 136.415 168.101 136.519 168.027 136.607C167.952 136.695 167.859 136.766 167.753 136.813C167.648 136.861 167.534 136.885 167.418 136.883C167.303 136.882 167.189 136.854 167.085 136.803V136.803C166.899 136.721 166.746 136.576 166.655 136.394C166.564 136.211 166.539 136.002 166.585 135.803L172.585 109.903C172.662 109.618 172.798 109.351 172.985 109.122C173.172 108.893 173.406 108.706 173.67 108.573C173.935 108.441 174.225 108.366 174.52 108.354C174.816 108.342 175.111 108.393 175.385 108.503V108.503C175.844 108.712 176.21 109.082 176.413 109.543C176.616 110.005 176.642 110.524 176.485 111.003V111.003Z'
          fill='#FEC272'
        />
        <path
          d='M192.985 118.802L182.185 165.802C182.162 165.909 182.117 166.01 182.054 166.099C181.99 166.188 181.909 166.263 181.815 166.319C181.721 166.375 181.617 166.411 181.509 166.426C181.401 166.44 181.29 166.432 181.185 166.402V166.402C181.092 166.378 181.005 166.335 180.93 166.275C180.854 166.216 180.791 166.143 180.744 166.059C180.697 165.975 180.668 165.882 180.658 165.787C180.648 165.691 180.657 165.594 180.685 165.502L189.185 118.002C189.236 117.71 189.351 117.433 189.522 117.192C189.694 116.95 189.917 116.75 190.176 116.606C190.435 116.462 190.723 116.378 191.019 116.36C191.315 116.342 191.611 116.391 191.885 116.502V116.502C192.333 116.666 192.699 116.998 192.904 117.428C193.11 117.858 193.139 118.351 192.985 118.802V118.802Z'
          fill='#FEC272'
        />
        <path
          d='M215.885 124.299L210.685 158.399C210.671 158.511 210.632 158.618 210.571 158.712C210.51 158.806 210.428 158.885 210.331 158.943C210.235 159.001 210.127 159.036 210.015 159.045C209.903 159.055 209.79 159.04 209.685 158.999V158.999C209.499 158.938 209.338 158.816 209.23 158.653C209.121 158.49 209.07 158.295 209.085 158.099L211.885 123.699C211.929 123.412 212.035 123.138 212.195 122.896C212.356 122.653 212.567 122.449 212.814 122.297C213.062 122.144 213.34 122.048 213.628 122.014C213.917 121.98 214.209 122.009 214.485 122.099V122.099C214.722 122.152 214.946 122.25 215.144 122.389C215.343 122.528 215.512 122.705 215.642 122.909C215.772 123.113 215.86 123.341 215.902 123.58C215.944 123.819 215.938 124.063 215.885 124.299V124.299Z'
          fill='#FEC272'
        />
        <path
          d='M242.685 123.603L243.985 177.703C243.984 177.817 243.958 177.928 243.911 178.031C243.864 178.134 243.796 178.226 243.711 178.301C243.627 178.376 243.527 178.433 243.42 178.468C243.312 178.503 243.198 178.515 243.085 178.503V178.503C242.895 178.47 242.722 178.372 242.595 178.227C242.467 178.081 242.394 177.897 242.385 177.703L238.685 123.603C238.677 123.312 238.734 123.023 238.851 122.756C238.968 122.489 239.143 122.251 239.363 122.06C239.583 121.869 239.842 121.729 240.123 121.65C240.403 121.571 240.698 121.555 240.985 121.603V121.603C241.452 121.697 241.874 121.945 242.183 122.308C242.491 122.671 242.668 123.127 242.685 123.603Z'
          fill='#FEC272'
        />
        <path
          d='M138.786 74.5034C137.779 73.3233 136.938 72.0112 136.286 70.6034L133.986 55.2034L139.286 60.1034L138.786 74.5034Z'
          fill='#8784FF'
        />
        <path
          d='M287.986 79.7034C285.179 85.0326 281.645 89.9462 277.486 94.3034C268.486 103.903 253.286 114.803 230.686 114.803C214.786 114.803 202.986 109.503 194.586 103.103C186.68 97.1229 180.362 89.2941 176.186 80.3034L287.986 79.7034Z'
          fill='#1F1933'
        />
        <path
          d='M287.986 79.7034C285.179 85.0326 281.645 89.9462 277.486 94.3034C268.133 97.8053 258.399 100.188 248.486 101.403C225.286 104.503 207.186 104.303 194.586 103.103C186.68 97.1229 180.362 89.2941 176.186 80.3034L287.986 79.7034Z'
          fill='#6461F3'
        />
        <path
          d='M323.186 54.6034C322.651 59.3584 321.782 64.07 320.586 68.7034C320.247 69.9806 319.707 71.1958 318.986 72.3034C316.886 75.7034 312.886 79.4034 306.386 82.9034C305.286 83.5034 303.986 84.1034 302.686 84.7034C301.686 85.2034 300.686 85.6034 299.586 86.1034C298.086 86.7034 296.586 87.3034 294.986 87.9034C288.45 90.1297 281.767 91.9006 274.986 93.2034C274.386 93.3034 273.886 93.4034 273.286 93.5034C271.786 93.8034 269.986 94.0034 268.086 94.2034C260.086 95.1034 249.486 95.7034 238.186 95.9034C236.086 95.9034 233.986 96.0034 231.886 96.0034C221.186 96.1034 210.386 95.8034 200.986 95.3034C198.986 95.2034 196.986 95.1034 195.186 94.9034C190.557 94.6093 185.949 94.0417 181.386 93.2034C176.286 92.2034 171.686 91.1034 167.586 89.9034C165.886 89.4034 164.286 88.9034 162.686 88.4034C150.286 84.3034 142.786 79.3034 138.886 74.7034C138.323 74.0863 137.821 73.4166 137.386 72.7034C136.976 72.1137 136.64 71.4755 136.386 70.8034C136.065 70.1709 135.829 69.4983 135.686 68.8034C134.833 65.3805 134.199 61.9068 133.786 58.4034C133.386 55.1034 133.286 52.9034 133.286 52.9034L228.286 60.0034L323.286 54.7034L323.186 54.6034Z'
          fill='#99ADF9'
        />
        <path
          d='M323.086 54.4034C322.914 55.4278 322.424 56.3723 321.686 57.1034C319.186 60.0034 313.186 64.3034 302.986 68.2034C301.686 68.7034 300.286 69.2034 298.786 69.7034C298.086 69.9034 297.386 70.2034 296.686 70.4034C295.186 70.9034 293.586 71.4034 291.886 71.8034C285.351 73.635 278.704 75.038 271.986 76.0034C270.186 76.3034 268.286 76.6034 266.286 76.8034C256.897 77.9927 247.449 78.6604 237.986 78.8034C235.986 78.8034 233.886 78.9034 231.786 78.9034H231.686C220.986 78.9034 211.086 78.4034 202.186 77.6034C200.186 77.4034 198.186 77.2034 196.286 77.0034C187.535 76.0399 178.852 74.537 170.286 72.5034C168.586 72.1034 166.886 71.6034 165.286 71.2034C150.386 67.1034 140.586 62.0034 136.086 57.8034C135.299 57.0838 134.595 56.2788 133.986 55.4034C133.442 54.6796 133.128 53.8083 133.086 52.9034C133.086 45.8034 161.086 30.7034 232.186 30.7034C303.286 30.6034 323.086 49.5034 323.086 54.4034Z'
          fill='#1F1933'
        />
        <path
          d='M191.186 38.8034L139.186 60.1034C137.202 58.7224 135.418 57.0734 133.886 55.2034L189.486 37.9034L191.186 38.8034Z'
          fill='#6461F3'
        />
        <path
          d='M323.086 54.5034C322.886 55.4892 322.399 56.3938 321.686 57.1034L268.286 38.8034L269.986 38.0034L323.086 54.5034Z'
          fill='#6461F3'
        />
        <path
          d='M199.986 44.3034L170.286 72.4034C168.586 72.0034 166.886 71.5034 165.286 71.1034L198.186 44.2034L199.986 44.3034Z'
          fill='#6461F3'
        />
        <path
          d='M216.286 47.6034L202.186 77.6034C200.186 77.4034 198.186 77.2034 196.286 77.0034L214.486 47.5034L216.286 47.6034Z'
          fill='#6461F3'
        />
        <path
          d='M272.086 76.1034C270.286 76.4034 268.286 76.7034 266.386 76.9034L252.586 47.6034L254.486 47.5034L272.086 76.1034Z'
          fill='#6461F3'
        />
        <path
          d='M237.986 78.9034C235.986 78.9034 233.886 79.0034 231.786 79.0034H231.686L234.086 49.5034L235.986 49.4034L237.986 78.9034Z'
          fill='#6461F3'
        />
        <path
          d='M302.886 68.2034C301.586 68.7034 300.186 69.2034 298.686 69.7034L262.786 44.3034L264.686 44.2034L302.886 68.2034Z'
          fill='#6461F3'
        />
        <path
          d='M238.086 95.7034C235.986 95.7034 233.886 95.8034 231.786 95.8034V78.9034L237.986 78.8034L238.086 95.7034Z'
          fill='#8784FF'
        />
        <path
          d='M273.086 93.3034C271.486 93.6034 269.786 93.8034 267.886 94.0034L266.286 76.8034L271.986 76.0034L273.086 93.3034Z'
          fill='#8784FF'
        />
        <path
          d='M306.286 82.8034C305.186 83.4034 303.886 84.0034 302.586 84.6034L298.786 69.6034L302.886 68.2034L306.286 82.8034Z'
          fill='#8784FF'
        />
        <path
          d='M202.186 77.6034L200.986 95.1034C198.986 95.0034 196.986 94.9034 195.186 94.7034L196.386 77.0034L202.186 77.6034Z'
          fill='#8784FF'
        />
        <path
          d='M170.286 72.4034L167.486 89.7034C165.786 89.2034 164.186 88.7034 162.586 88.2034L165.186 71.1034L170.286 72.4034Z'
          fill='#8784FF'
        />
        <path
          d='M139.286 60.3034L137.286 72.6034C136.876 72.0138 136.54 71.3756 136.286 70.7034C135.965 70.0709 135.729 69.3983 135.586 68.7034C134.733 65.2805 134.099 61.8068 133.686 58.3034L133.986 55.3034C133.986 55.3034 135.686 57.3034 136.386 58.0034C136.986 58.6034 139.286 60.3034 139.286 60.3034Z'
          fill='#8784FF'
        />
        <path
          d='M227.486 61.4034C256.923 61.4034 280.786 54.6429 280.786 46.3034C280.786 37.9639 256.923 31.2034 227.486 31.2034C198.05 31.2034 174.186 37.9639 174.186 46.3034C174.186 54.6429 198.05 61.4034 227.486 61.4034Z'
          fill='#6461F3'
        />
        <path
          d='M276.186 43.9034C276.186 43.9034 272.286 48.3034 262.686 52.1034C255.586 54.9034 245.386 57.3034 231.386 57.3034C224.677 57.3477 217.98 56.7449 211.386 55.5034C192.686 51.9034 184.586 44.1034 184.586 44.1034C184.586 44.1034 188.686 13.7034 214.286 3.40342C220.21 1.08304 226.525 -0.0714334 232.886 0.00342015C268.086 0.00342015 276.186 43.9034 276.186 43.9034Z'
          fill='#99ADF9'
        />
        <path
          d='M211.386 55.5034C192.686 51.9034 184.586 44.1034 184.586 44.1034C184.586 44.1034 188.686 13.7034 214.286 3.40344C209.286 7.60344 204.386 16.0034 203.586 29.7034C202.786 39.9034 205.186 48.4034 211.386 55.5034Z'
          fill='#7A97F4'
        />
        <path
          d='M276.186 43.9034C276.186 43.9034 272.286 48.3034 262.686 52.1034C261.685 48.4804 260.415 44.9372 258.886 41.5034C258.886 41.5034 249.486 43.5034 245.286 34.6034C241.086 25.7034 251.186 19.3034 251.186 19.3034C251.186 19.3034 248.786 11.6034 236.386 6.60342C223.986 1.60342 214.086 3.40342 214.086 3.40342C220.01 1.08304 226.325 -0.0714334 232.686 0.00342015C268.086 0.00342015 276.186 43.9034 276.186 43.9034Z'
          fill='#C2D1FF'
        />
        <path
          d='M235.886 25.1034C237.377 25.1034 238.586 23.9394 238.586 22.5034C238.586 21.0675 237.377 19.9034 235.886 19.9034C234.395 19.9034 233.186 21.0675 233.186 22.5034C233.186 23.9394 234.395 25.1034 235.886 25.1034Z'
          fill='#C2D1FF'
        />
        <path
          d='M226.486 27.6034C227.646 27.6034 228.586 26.708 228.586 25.6034C228.586 24.4988 227.646 23.6034 226.486 23.6034C225.327 23.6034 224.386 24.4988 224.386 25.6034C224.386 26.708 225.327 27.6034 226.486 27.6034Z'
          fill='#C2D1FF'
        />
        <path
          d='M232.786 41.1034C235.051 41.1034 236.886 39.3125 236.886 37.1034C236.886 34.8943 235.051 33.1034 232.786 33.1034C230.522 33.1034 228.686 34.8943 228.686 37.1034C228.686 39.3125 230.522 41.1034 232.786 41.1034Z'
          fill='#C2D1FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_6158_44820'>
          <rect width='453.287' height='232.503' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FourOFourIcon;
