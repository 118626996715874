// ** React Imports
import { ReactNode } from 'react';

// ** MUI Imports
import { deepmerge } from '@mui/utils';
import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

// ** Type Imports
import { Settings } from 'apps/webapp/src/@core/context/settingsContext';

// ** Theme Config
import themeConfig from 'apps/webapp/src/configs/themeConfig';

// ** Direction component for LTR or RTL
import Direction from 'apps/webapp/src/layouts/components/Direction';

// ** Theme Override Imports
import overrides from './overrides';
import typography from './typography';

// ** Theme
import themeOptions from './ThemeOptions';
import UserThemeOptions from 'apps/webapp/src/layouts/UserThemeOptions';

// ** Global Styles
import GlobalStyling from './globalStyles';

interface Props {
  settings: Settings;
  children: ReactNode;
}

const ThemeComponent = (props: Props) => {
  // ** Props
  const { settings, children } = props;

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings);

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig);

  // ** Deep Merge Component overrides of core and user
  const mergeComponentOverrides = (theme: Theme, settings: Settings) =>
    deepmerge({ ...overrides(theme, settings) }, UserThemeOptions()?.components);

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) => deepmerge(typography(theme), UserThemeOptions()?.typography);

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) },
  });

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme) as any} />
        {children}
      </Direction>
    </ThemeProvider>
  );
};

export default ThemeComponent;
