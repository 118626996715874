const TrendDownIcon = () => {
  return (
    <svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.08577 7.68567C4.41714 7.68567 4.68577 7.41704 4.68577 7.08567C4.68577 6.7543 4.41714 6.48567 4.08577 6.48567L4.08577 7.68567ZM1.00005 6.48567C0.668681 6.48567 0.400052 6.7543 0.400052 7.08567C0.400052 7.41704 0.668681 7.68567 1.00005 7.68567L1.00005 6.48567ZM0.400053 7.08569C0.400053 7.41706 0.668682 7.68569 1.00005 7.68569C1.33142 7.68569 1.60005 7.41706 1.60005 7.08569L0.400053 7.08569ZM1.60005 3.99998C1.60005 3.66861 1.33142 3.39998 1.00005 3.39998C0.668682 3.39998 0.400053 3.66861 0.400053 3.99998L1.60005 3.99998ZM0.575785 6.66143C0.34147 6.89574 0.34147 7.27564 0.575785 7.50996C0.8101 7.74427 1.19 7.74427 1.42431 7.50996L0.575785 6.66143ZM6.40005 1.68569L6.82431 1.26143C6.59 1.02711 6.2101 1.02711 5.97579 1.26143L6.40005 1.68569ZM8.71433 3.99998L8.29007 4.42424C8.52439 4.65856 8.90428 4.65856 9.1386 4.42424L8.71433 3.99998ZM12.2243 1.33853C12.4586 1.10421 12.4586 0.724315 12.2243 0.490001C11.99 0.255686 11.6101 0.255686 11.3758 0.490001L12.2243 1.33853ZM4.08577 6.48567L1.00005 6.48567L1.00005 7.68567L4.08577 7.68567L4.08577 6.48567ZM1.60005 7.08569L1.60005 3.99998L0.400053 3.99998L0.400053 7.08569L1.60005 7.08569ZM1.42431 7.50996L6.82431 2.10996L5.97579 1.26143L0.575785 6.66143L1.42431 7.50996ZM5.97579 2.10996L8.29007 4.42424L9.1386 3.57571L6.82431 1.26143L5.97579 2.10996ZM9.1386 4.42424L12.2243 1.33853L11.3758 0.490001L8.29007 3.57571L9.1386 4.42424Z'
        fill='#DB0000'
      />
    </svg>
  );
};

export default TrendDownIcon;
