const SmsIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.76214 1H11.9495C14.1434 1.01651 15.9087 2.79937 15.8926 4.98244V9.81773C15.9003 10.8663 15.4891 11.8749 14.7494 12.6218C14.0097 13.3686 13.0022 13.7925 11.9484 13.8002H4.76214L0.817978 15.9998V4.98244C0.81024 3.9339 1.22144 2.92524 1.96112 2.17838C2.7008 1.43152 3.70836 1.00764 4.76214 1Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.97119 7.42848C2.97119 6.83676 3.45327 6.35707 4.04795 6.35707C4.64262 6.35707 5.12471 6.83676 5.12471 7.42848C5.12471 8.02021 4.64262 8.4999 4.04795 8.4999C3.76237 8.4999 3.4885 8.38702 3.28657 8.18609C3.08464 7.98516 2.97119 7.71264 2.97119 7.42848Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.27832 7.42848C7.27832 6.83676 7.7604 6.35707 8.35508 6.35707C8.94975 6.35707 9.43183 6.83676 9.43183 7.42848C9.43183 8.02021 8.94975 8.4999 8.35508 8.4999C7.7604 8.4999 7.27832 8.02021 7.27832 7.42848Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5854 7.42848C11.5854 6.83676 12.0675 6.35707 12.6622 6.35707C13.2569 6.35707 13.739 6.83676 13.739 7.42848C13.739 8.02021 13.2569 8.4999 12.6622 8.4999C12.0675 8.4999 11.5854 8.02021 11.5854 7.42848Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SmsIcon;
