import {
  CustomerContext,
  CustomerContextGetAllRequest,
  TaskAssignRequestType,
  TaskBulkAssignRequestType,
  TaskBulkAssignResponseType,
} from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import api from '../../httpClient/api';
import { customerPageCContextSlice } from '../customerPageCContext';

import { stringify } from 'qs';
import { baseQuery } from './baseQuery';

// Module-level variable to track latest request
let latestGetCustomerPageCContextRequestId = '';

export const customerPageCContextApi = createApi({
  reducerPath: 'customerPageCContextApi',
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ['CustomerPageCContext', 'CustomerContext'],
  endpoints: builder => ({
    getCustomerContextById: builder.query<CustomerContext, string>({
      query: id => ({
        url: `${api.customerContextCustomerPage}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'CustomerContext', id }],
    }),
    getCustomerPageCContext: builder.query<CustomerContext[], CustomerContextGetAllRequest>({
      query: customerContextGet => {
        const queryString = stringify(customerContextGet);
        return {
          url: `${api.customerContextCustomerPage}?${queryString}`,
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const currentRequestId = Date.now().toString();
        latestGetCustomerPageCContextRequestId = currentRequestId;

        dispatch(customerPageCContextSlice.actions.setCustomerContextPaginationLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (latestGetCustomerPageCContextRequestId === currentRequestId) {
            dispatch(customerPageCContextSlice.actions.setAllCustomerContexts(data));
          }
        } catch (error) {
          console.error(error);
        } finally {
          if (latestGetCustomerPageCContextRequestId === currentRequestId) {
            dispatch(customerPageCContextSlice.actions.setCustomerContextPaginationLoading(false));
          }
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ task }) => ({ type: 'CustomerContext' as const, id: task.id })),
              { type: 'CustomerPageCContext', id: 'LIST' },
            ]
          : [{ type: 'CustomerPageCContext', id: 'LIST' }],
    }),
    getCustomerPageCContextCount: builder.query<number, CustomerContextGetAllRequest>({
      query: customerContextGetCountRequest => {
        const queryString = stringify(customerContextGetCountRequest);
        return {
          url: `${api.customerContextCustomerPage}/count?${queryString}`,
          method: 'GET',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(customerPageCContextSlice.actions.setCustomerContextCount(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    taskBulkAssign: builder.mutation<TaskBulkAssignResponseType, TaskBulkAssignRequestType>({
      query: taskBulkAssignRequest => ({
        url: `${api.customerContext}/tasks/bulkAssign`,
        method: 'PATCH',
        body: taskBulkAssignRequest,
      }),
    }),
    taskAssign: builder.mutation<CustomerContext[], TaskAssignRequestType>({
      query: taskAssignRequest => ({
        url: `${api.customerContext}/assignUser`,
        method: 'PATCH',
        body: taskAssignRequest,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(customerPageCContextSlice.actions.upsertManyCustomerContexts(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetCustomerPageCContextQuery,
  useTaskBulkAssignMutation,
  useGetCustomerPageCContextCountQuery,
  useGetCustomerContextByIdQuery,
  useTaskAssignMutation,
} = customerPageCContextApi;
