// ** Icon Imports
import Icon from 'apps/webapp/src/@core/components/icon';

// ** Third Party Import
import { useTranslation } from 'react-i18next';

// ** Custom Components Imports
import OptionsMenu from 'apps/webapp/src/@core/components/option-menu';

// ** Type Import
import { Settings } from 'apps/webapp/src/@core/context/settingsContext';
import { Language, LanguagesConfig } from '@finance-ops/types';

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { i18n } = useTranslation();

  const handleLangItemClick = (lang: Language) => {
    i18n.changeLanguage(lang);
  };

  return (
    <OptionsMenu
      iconButtonProps={{ color: 'inherit' }}
      icon={<Icon fontSize='1.5rem' icon='tabler:language' />}
      menuProps={{ sx: { '& .MuiMenu-paper': { minWidth: 130 } } }}
      options={LanguagesConfig.map(languageConfig => ({
        text: languageConfig.text,
        menuItemProps: {
          sx: { py: 2 },
          selected: i18n.language === languageConfig.language,
          onClick: () => {
            handleLangItemClick(languageConfig.language);
            saveSettings({ ...settings, direction: languageConfig.direction, language: languageConfig.language });
          },
        },
      }))}
    />
  );
};

export default LanguageDropdown;
