import { PermissionType } from '@finance-ops/types';
import { useContext } from 'react';
import { AbilityContext } from '../layouts/components/acl/Can';
import { canAccess } from '../layouts/components/acl/acl-utils';
import { useAuth } from './useAuth';

export const useAccess = (permissionType?: PermissionType, resource?: any) => {
  const ability = useContext(AbilityContext);
  const { user } = useAuth();
  // allow everything for super admins
  if (user?.role?.isSuperAdmin) return true;

  // validate through casl ability
  return canAccess(ability, permissionType, resource);
};
