const HunderedIcon = () => {
  return (
    <svg width='32' height='26' viewBox='0 0 32 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.8 19.5H25.6C26.48 19.5 27.2 18.7687 27.2 17.875V8.125C27.2 7.23125 26.48 6.5 25.6 6.5H20.8C19.92 6.5 19.2 7.23125 19.2 8.125V17.875C19.2 18.7687 19.92 19.5 20.8 19.5ZM22.4 9.75H24V16.25H22.4V9.75ZM11.2 19.5H16C16.88 19.5 17.6 18.7687 17.6 17.875V8.125C17.6 7.23125 16.88 6.5 16 6.5H11.2C10.32 6.5 9.6 7.23125 9.6 8.125V17.875C9.6 18.7687 10.32 19.5 11.2 19.5ZM12.8 9.75H14.4V16.25H12.8V9.75ZM4.8 6.5H8V19.5H4.8V6.5ZM0 0V26H32V0H0ZM28.8 22.75H3.2V3.25H28.8V22.75Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default HunderedIcon;
