export type CompletionRequestType = {
  deploymentId: string;
  prompt: string | string[];
  max_tokens?: number;
  temperature?: number;
  top_p?: number;
  logit_bias?: Record<string, number>;
  user?: string;
  n?: number;
  stream?: boolean;
  logprobs?: number;
  suffix?: string;
  echo?: boolean;
  stop?: string | string[];
  presence_penalty?: number;
  frequency_penalty?: number;
  best_of?: number;
};

export type CompletionResponseType = {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    text: string;
    index: number;
    finish_reason: string;
    logprobs: {
      tokens: string[];
      token_logprobs: number[];
      top_logprobs: Record<string, number>[];
      text_offset: number[];
    } | null;
  }[];
  usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
};

export enum ChatRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
  FUNCTION = 'function',
}

export type FunctionCallType = {
  name: string;
  arguments: string;
};

export type ChatMessageType = {
  role: ChatRole;
  content: string;
  name?: string;
  function_call?: FunctionCallType;
};

export type FunctionDefinitionType = {
  name: string;
  description?: string;
  parameters: Record<string, any>;
};

export type ChatCompletionRequestType = {
  deploymentId: string;
  messages: ChatMessageType[];
  temperature?: number;
  top_p?: number;
  n?: number;
  stream?: boolean;
  stop?: string | string[];
  max_tokens?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
  logit_bias?: Record<string, number>;
  user?: string;
  functions?: Array<FunctionDefinitionType>;
  function_call?: 'none' | 'auto' | { name: string };
};

// ... existing code ...

export type ChatCompletionResponseType = {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    index: number;
    message: {
      role: 'assistant' | 'function';
      content: string | null;
      function_call?: {
        name: string;
        arguments: string;
      };
    };
    finish_reason: string;
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
};

export type EmbeddingRequestType = {
  deploymentId: string;
  input: string | string[];
  user?: string;
  input_type?: string;
  encoding_format?: 'float' | 'base64';
  dimensions?: number;
};

export type EmbeddingResponseType = {
  object: string;
  model: string;
  data: {
    index: number;
    embedding: number[];
  }[];
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
};
