import { setUserId, init, track, identify, Identify, getDeviceId, getUserId, add } from '@amplitude/analytics-browser';

export const AmplitudeAnalytics =
  process.browser && process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
    ? {
        init,
        setUserId,
        track,
        identify,
        Identify,
        getDeviceId,
        getUserId,
        add,
      }
    : null;

AmplitudeAnalytics?.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '', undefined, {
  //appVersion: version,
  defaultTracking: {
    pageViews: true,
    // sessions: false will still track the session, but won't send explicit Start Session and End Session events
    sessions: true,
    fileDownloads: true,
    formInteractions: true,
  },
  optOut: false,
});
