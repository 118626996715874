import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '.';

export enum WorkflowPageNamesList {
  'Workflow builder' = 'Workflow builder',
  'Build your workflow' = 'Build your workflow',
}

export interface WorkflowMessage {
  tone?: string;
  prompt?: string;
  message?: string;
  subject?: string;
  messageTemplate?: {
    templateId: string;
    templateContent: string | null;
  };
}

export interface SingleWorkflow {
  id: string;
  name: string;
  criteriaDays: number[];
  action?: {
    sms?: WorkflowMessage;
    email?: WorkflowMessage;
  };
}
export interface WorkflowType {
  name?: string;
  id: number;
  flows: SingleWorkflow[];
}

interface Editing {
  status: boolean;
  index?: number;
  type?: string;
}

interface Template {
  displayName: string;
  id: string;
  name: string;
  preview: string;
}

interface WorkflowSlice {
  currentPage: WorkflowPageNamesList;
  workflows: WorkflowType[];
  currentWorkflowId: number;
  editing: Editing;
  toneList: string[];
  isUpdating: boolean;
  templateList: Template[];
  isValid: boolean;
}

const initialState: WorkflowSlice = {
  currentPage: WorkflowPageNamesList['Workflow builder'],
  workflows: [],
  currentWorkflowId: 1,
  editing: { status: false },
  toneList: [],
  isUpdating: false,
  templateList: [],
  isValid: false,
};

const WorkflowSlice = createSlice({
  name: 'WorkflowState',
  initialState,
  reducers: {
    setWorkflow: (state, action: PayloadAction<WorkflowType | null>) => {
      if (action.payload) {
        state.workflows = [{ ...action.payload }];
      } else {
        state.workflows = [];
      }
    },
    setCurrentWorkflowId: (state, action: PayloadAction<number>) => {
      state.currentWorkflowId = action.payload;
    },
    setCurrentWorkflowPageName: (state, action: PayloadAction<WorkflowPageNamesList>) => {
      state.currentPage = action.payload;
    },
    updateWorkflow: (state, action: PayloadAction<WorkflowType>) => {
      const updatedWorkflow = state.workflows?.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });
      state.workflows = updatedWorkflow;
    },
    deleteWorkflow: (state, action: PayloadAction<number>) => {
      state.workflows = state.workflows.filter(item => item.id !== action.payload);
    },
    setWorkflowEditing: (state, action: PayloadAction<Editing>) => {
      state.editing = action.payload;
    },
    setToneList: (state, action: PayloadAction<string[]>) => {
      state.toneList = action.payload;
    },
    setIsUpdatingWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setTemplateList: (state, action: PayloadAction<Template[]>) => {
      state.templateList = action.payload;
    },
    setIsValidWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
  },
});

export const selectCurrentWorkflow = createSelector(
  [(state: RootState) => state.workflow.workflows, (state: RootState) => state.workflow.currentWorkflowId],
  (workflows, currentWorkflowId) => workflows.find(flow => flow.id === currentWorkflowId),
);

export const {
  setCurrentWorkflowId,
  setCurrentWorkflowPageName,
  updateWorkflow,
  setWorkflow,
  deleteWorkflow,
  setWorkflowEditing,
  setToneList,
  setIsUpdatingWorkflow,
  setTemplateList,
  setIsValidWorkflow,
} = WorkflowSlice.actions;

export default WorkflowSlice.reducer;
