import { InvoiceTransactionType } from '@finance-ops/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const defaultPaginationState = {
  page: 1,
  pageSize: 25,
};

interface PaginationType {
  page: number;
  pageSize: number;
}

interface CustomerInfo {
  name?: string;
  sourceId?: string;
}

export interface Payment {
  _id: string;
  referenceId?: string;
  customerInfo?: CustomerInfo;
  transactions: InvoiceTransactionType;
  invoiceNumber?: string | undefined;
}

export interface Payments {
  selectedPaymentId: string;
  isLoading: boolean;
  paginationLoading: boolean;
  payments: Payment[];
  pagination: PaginationType;
  totalPayments: number;
  totalPaymentsAmount: number;
  paymentFilters: Record<any, any>;
}

const initialState: Payments = {
  selectedPaymentId: '',
  isLoading: false,
  paginationLoading: false,
  payments: [],
  pagination: defaultPaginationState,
  totalPayments: 0,
  totalPaymentsAmount: 0,
  paymentFilters: {},
};

const Payments = createSlice({
  name: 'P2PState',
  initialState,
  reducers: {
    setPaymentList: (state, action: PayloadAction<any[]>) => {
      state.payments = action.payload;
      state.isLoading = false;
    },
    setSelectedPaymentsId: (state, action: PayloadAction<string>) => {
      state.selectedPaymentId = action.payload;
    },
    setPaymentFilters: (state, action: PayloadAction<any>) => {
      state.paymentFilters = action.payload;
    },
    setPaymentPagination: (state, action: PayloadAction<PaginationType>) => {
      state.pagination = action.payload;
      state.paginationLoading = false;
    },
    setPaymentPaginationLoading: (state, action: PayloadAction<boolean>) => {
      state.paginationLoading = action.payload;
    },
    setPaymentLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalPayments: (state, action: PayloadAction<number>) => {
      state.totalPayments = action.payload;
    },
    setTotalPaymentsAmount: (state, action: PayloadAction<number>) => {
      state.totalPaymentsAmount = action.payload;
    },
  },
});

export const {
  setPaymentList,
  setSelectedPaymentsId,
  setPaymentFilters,
  setPaymentPagination,
  setPaymentPaginationLoading,
  setPaymentLoading,
  setTotalPayments,
  setTotalPaymentsAmount,
} = Payments.actions;

export default Payments.reducer;
