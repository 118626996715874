import LogRocket from 'logrocket';
import { UserType } from '@finance-ops/types';

export const initiateLogRocket = (user: UserType | null) => {
  const logRocketKey = process.env.NEXT_PUBLIC_LOG_ROCKET_API_KEY || '';
  if (!logRocketKey) {
    console.error('No LogRocket Key Found, skipping initialization');
    return;
  }
  LogRocket.init(logRocketKey);
  LogRocket.identify(user?.id || 'NO_ID', {
    name: (user?.firstName || '') + (user?.lastName || '') || 'NO_NAME',
    email: user?.email || 'NO_EMAIl',
  });
};
