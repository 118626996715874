import IconStyles from "apps/webapp/src/types/iconStyles";

const DeleteIcon = (styles: IconStyles) => {
  const color = styles.color || '#3E3E3E';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='18' viewBox='0 0 13 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0837 5.39999H1.9163C1.41024 5.39999 1 5.81024 1 6.31629V13.65C1 15.1688 2.23122 16.4 3.75 16.4H9.25C9.97935 16.4 10.6788 16.1103 11.1945 15.5945C11.7103 15.0788 12 14.3793 12 13.65V6.31629C12 5.81024 11.5898 5.39999 11.0837 5.39999Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.79922 3.2L9.00282 1.6083C8.81663 1.2357 8.43595 1.00022 8.01942 1H4.97902C4.56249 1.00022 4.18181 1.2357 3.99562 1.6083L3.19922 3.2H9.79922Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.41668 9.06629C5.41668 8.65208 5.08089 8.31629 4.66668 8.31629C4.25247 8.31629 3.91668 8.65208 3.91668 9.06629H5.41668ZM3.91668 12.7326C3.91668 13.1468 4.25247 13.4826 4.66668 13.4826C5.08089 13.4826 5.41668 13.1468 5.41668 12.7326H3.91668ZM9.08391 9.06629C9.08391 8.65208 8.74813 8.31629 8.33391 8.31629C7.9197 8.31629 7.58391 8.65208 7.58391 9.06629H9.08391ZM7.58391 12.7326C7.58391 13.1468 7.9197 13.4826 8.33391 13.4826C8.74813 13.4826 9.08391 13.1468 9.08391 12.7326H7.58391ZM9.79948 2.44999C9.38527 2.44999 9.04948 2.78578 9.04948 3.19999C9.04948 3.6142 9.38527 3.94999 9.79948 3.94999V2.44999ZM11.9995 3.94999C12.4137 3.94999 12.7495 3.6142 12.7495 3.19999C12.7495 2.78578 12.4137 2.44999 11.9995 2.44999V3.94999ZM3.2 3.94999C3.61421 3.94999 3.95 3.6142 3.95 3.19999C3.95 2.78578 3.61421 2.44999 3.2 2.44999V3.94999ZM1 2.44999C0.585786 2.44999 0.25 2.78578 0.25 3.19999C0.25 3.6142 0.585786 3.94999 1 3.94999V2.44999ZM3.91668 9.06629V12.7326H5.41668V9.06629H3.91668ZM7.58391 9.06629V12.7326H9.08391V9.06629H7.58391ZM9.79948 3.94999H11.9995V2.44999H9.79948V3.94999ZM3.2 2.44999H1V3.94999H3.2V2.44999Z'
        fill={color}
      />
    </svg>
  );
};

export default DeleteIcon;
