import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { CustomerType } from '@finance-ops/types';
import { RootState } from '.';

interface CounterPartyStateItems {
  selectedCounterParty?: CustomerType;
  selectedChat?: CustomerType;
  initIds: string[];
  isLoading: boolean;
}

interface CounterPartyState extends EntityState<CustomerType>, CounterPartyStateItems {}

const counterPartyAdapter = createEntityAdapter<CustomerType>({
  selectId: entity => entity._id.toString(),
});

const initialState: CounterPartyState = {
  ...counterPartyAdapter.getInitialState(),
  selectedCounterParty: undefined,
  selectedChat: undefined,
  initIds: [],
  isLoading: true,
};

const counterPartySlice = createSlice({
  name: 'counterParty',
  initialState: initialState,
  reducers: {
    upsertOneCounterParty: (state, action: PayloadAction<CustomerType | undefined>) => {
      action.payload && counterPartyAdapter.upsertOne(state, action.payload);
    },
    upsertManyCounterParties: counterPartyAdapter.upsertMany,
    setSelectedCounterParty: (state, action: PayloadAction<CustomerType | undefined>) => {
      state.selectedCounterParty = action.payload;
    },
    setSelectedChat: (state, action: PayloadAction<CustomerType | undefined>) => {
      state.selectedChat = action.payload;
    },
    upsertCounterPartiesInitId: (state, action: PayloadAction<string[]>) => {
      state.initIds = state.initIds.concat(action.payload);
    },
    resetCounterPartyInit: state => {
      state.initIds = [];
    },
    resetSelectedCounterParty(state) {
      state.selectedCounterParty = undefined;
    },
    resetCounterParties: () => {
      return initialState;
    },
  },
});

export const counterPartySelectors = counterPartyAdapter.getSelectors<RootState>(state => state.counterParty);

const selectInitIds = (state: RootState) => state.counterParty.initIds;

export const selectInitCounterParties = createSelector(
  counterPartySelectors.selectAll,
  selectInitIds,
  (all, initIds) => {
    const initItems = all.filter(item => !initIds.includes(item._id.toString() ?? 0));
    return initItems;
  },
);

export const selectSecondaryCounterParties = createSelector(
  [counterPartySelectors.selectAll, (_, parentId) => parentId],
  (counterParties, parentId) => {
    if (!parentId) return [];
    const cps: CustomerType[] = counterParties.filter(cp => cp.parentId === parentId);
    return cps || [];
  },
);

export const selectCounterPartyById = createSelector(
  [counterPartySelectors.selectAll, (_, id) => id],
  (counterParties, id) => {
    return counterParties.find(cp => cp._id === id);
  },
);

export const {
  resetCounterParties,
  setSelectedCounterParty,
  setSelectedChat,
  upsertOneCounterParty,
  upsertManyCounterParties,
  upsertCounterPartiesInitId,
  resetCounterPartyInit,
} = counterPartySlice.actions;

export default counterPartySlice.reducer;
