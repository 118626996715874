import { createSlice } from '@reduxjs/toolkit';

export enum ImportCustomerType {
  default = 'default',
  custom = 'custom',
}

export enum OnboardingFormDataKeys {
  companyName = 'companyName',
  typeOfCollections = 'typeOfCollections',
  sector = 'sector',
  fullName = 'fullName',
  email = 'email',
  sizeofCollectionTeam = 'sizeofCollectionTeam',
  prefferedLanguage = 'prefferedLanguage',
  delinquentCustomers = 'delinquentCustomers',
  delinquentAmount = 'delinquentAmount',
  averageTicketSize = 'averageTicketSize',
  badDebt = 'badDebt',
  collectOnTheirBehalf = 'collectOnTheirBehalf',
  accountNumber = 'accountNumber',
  bankName = 'bankName',
  routingNumber = 'routingNumber',
  fileName = 'fileName',
  customFileName = 'customFileName',
  paymentLink = 'paymentLink',
  einNumber = 'einNumber',
  totalNumberOfCustomer = 'totalNumberOfCustomer',
  bussinessType = 'bussinessType',
}
interface Onboarding {
  clientId: string;
  currentStep: number;
  currentSubStep: number;
  heighestStep: number;
  csvData: any[];
  csvDataWithErrors: any[];
  csvDataWithoutErrors: any[];
  customCsvData: any[];
  errorCount: number;
  ingestingData: boolean;
  expandedView: boolean;
  rowsWithErrorCount: number;
  importCustomerType: ImportCustomerType;
  formData: {
    [OnboardingFormDataKeys.companyName]: string;
    [OnboardingFormDataKeys.typeOfCollections]: string;
    [OnboardingFormDataKeys.sector]: string;
    [OnboardingFormDataKeys.fullName]: string;
    [OnboardingFormDataKeys.email]: string;
    [OnboardingFormDataKeys.sizeofCollectionTeam]: string;
    [OnboardingFormDataKeys.prefferedLanguage]: string;
    [OnboardingFormDataKeys.delinquentCustomers]: string;
    [OnboardingFormDataKeys.delinquentAmount]: number;
    [OnboardingFormDataKeys.averageTicketSize]: number;
    [OnboardingFormDataKeys.badDebt]: number;
    [OnboardingFormDataKeys.collectOnTheirBehalf]: boolean;
    [OnboardingFormDataKeys.accountNumber]: string;
    [OnboardingFormDataKeys.bankName]: string;
    [OnboardingFormDataKeys.routingNumber]: string;
    [OnboardingFormDataKeys.fileName]: string;
    [OnboardingFormDataKeys.customFileName]: string;
    [OnboardingFormDataKeys.paymentLink]: string;
    [OnboardingFormDataKeys.einNumber]: string;
    [OnboardingFormDataKeys.totalNumberOfCustomer]: string;
    [OnboardingFormDataKeys.bussinessType]: string;
  };
}

const initialState: Onboarding = {
  clientId: '',
  currentStep: 1,
  currentSubStep: 1,
  csvData: [],
  csvDataWithErrors: [],
  csvDataWithoutErrors: [],
  errorCount: 0,
  ingestingData: false,
  expandedView: false,
  rowsWithErrorCount: 0,
  heighestStep: 0,
  customCsvData: [],
  importCustomerType: ImportCustomerType.custom,
  formData: {
    [OnboardingFormDataKeys.companyName]: '',
    [OnboardingFormDataKeys.typeOfCollections]: '',
    [OnboardingFormDataKeys.sector]: 'Financial Services',
    [OnboardingFormDataKeys.fullName]: '',
    [OnboardingFormDataKeys.email]: '',
    [OnboardingFormDataKeys.sizeofCollectionTeam]: '',
    [OnboardingFormDataKeys.prefferedLanguage]: '',
    [OnboardingFormDataKeys.delinquentCustomers]: '',
    [OnboardingFormDataKeys.delinquentAmount]: 0,
    [OnboardingFormDataKeys.averageTicketSize]: 0,
    [OnboardingFormDataKeys.badDebt]: 0,
    [OnboardingFormDataKeys.collectOnTheirBehalf]: true,
    [OnboardingFormDataKeys.accountNumber]: '',
    [OnboardingFormDataKeys.bankName]: '',
    [OnboardingFormDataKeys.routingNumber]: '',
    [OnboardingFormDataKeys.fileName]: '',
    [OnboardingFormDataKeys.customFileName]: '',
    [OnboardingFormDataKeys.paymentLink]: '',
    [OnboardingFormDataKeys.einNumber]: '',
    [OnboardingFormDataKeys.totalNumberOfCustomer]: '',
    [OnboardingFormDataKeys.bussinessType]: '',
  },
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, [action.payload.key]: action.payload.value };
    },
    setAllFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload, fileName: '', customFileName: '' };
    },
    setCurrentStep: (state, action) => {
      if (action.payload >= state.heighestStep) {
        state.heighestStep = action.payload;
      }
      state.currentStep = action.payload;
    },
    setCurrentSubStep: (state, action) => {
      state.currentSubStep = action.payload;
    },
    setCsvData: (state, action) => {
      state.csvData = action.payload;
    },
    setCsvDataWithErrors: (state, action) => {
      state.csvDataWithErrors = action.payload;
    },
    setCsvDataWithoutErrors: (state, action) => {
      state.csvDataWithoutErrors = action.payload;
    },
    setErrorCount: (state, action) => {
      state.errorCount = action.payload;
    },
    setRowsWithErrorCount: (state, action) => {
      state.rowsWithErrorCount = action.payload;
    },
    setIngestingData: (state, action) => {
      state.ingestingData = action.payload;
    },
    setExpandedView: (state, action) => {
      state.expandedView = action.payload;
    },
    setCustomCsvData: (state, action) => {
      state.customCsvData = action.payload;
    },
    setImportCustomerType: (state, action) => {
      state.importCustomerType = action.payload;
    },
    setOnboardingClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
});

export const {
  setFormData,
  setCurrentStep,
  setCurrentSubStep,
  setCsvData,
  setErrorCount,
  setIngestingData,
  setExpandedView,
  setRowsWithErrorCount,
  setCustomCsvData,
  setImportCustomerType,
  setAllFormData,
  setCsvDataWithErrors,
  setCsvDataWithoutErrors,
  setOnboardingClientId,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
