export enum EVENT_TYPE {
  TASK_UPDATE_MODE = 'TASK_UPDATE_MODE',
  TASK_UPDATE_STATE = 'TASK_UPDATE_STATE',
  TASK_UPDATE_ASSIGNED_AGENT = 'TASK_UPDATE_ASSIGNED_AGENT',
  TASK_UPDATE_BULK_ASSIGN_STATUS = 'TASK_UPDATE_BULK_ASSIGN_STATUS',
  CLIENT_UPDATE_ASSIGN_USER = 'CLIENT_UPDATE_ASSIGN_USER',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  MESSAGE_INBOUND = 'MESSAGE_INBOUND',
  ACTION_CREATED = 'ACTION_CREATED',
  ACTION_UPDATE = 'ACTION_UPDATE',
  ACTION_UPDATE_ASSIGNEE = 'ACTION_UPDATE_ASSIGNEE',
  COMMENT_ADDED = 'COMMENT_ADDED',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  EMAIL_RECEIVED = 'EMAIL_RECEIVED',
  PAYMENT_LINK_OPENED = 'PAYMENT_LINK_OPENED',
  INVOICE_LINK_OPENED = 'INVOICE_LINK_OPENED',
  PAYMENT_UPDATED = 'PAYMENT_UPDATED',
  NOTE_CREATED = 'NOTE_CREATED',
}

export const EventNotificationMapper: { [key in EVENT_TYPE]: string } = {
  TASK_UPDATE_MODE: 'Task Mode Update',
  TASK_UPDATE_STATE: 'Task State Update',
  TASK_UPDATE_ASSIGNED_AGENT: 'Task Assigned Agent',
  TASK_UPDATE_BULK_ASSIGN_STATUS: 'Task Bulk Assign Status',
  CLIENT_UPDATE_ASSIGN_USER: 'User assigned to client',
  INVOICE_UPDATE: 'Invoice Update',
  MESSAGE_INBOUND: 'New Message',
  ACTION_CREATED: 'Action Created',
  ACTION_UPDATE: 'Action Update',
  ACTION_UPDATE_ASSIGNEE: 'Action Assignee Updated',
  COMMENT_ADDED: 'Comment Added',
  PAYMENT_SUCCESS: 'Payment Success',
  EMAIL_RECEIVED: 'Email Received',
  PAYMENT_LINK_OPENED: 'Payment Link Opened',
  INVOICE_LINK_OPENED: 'Invoice Link Opened',
  PAYMENT_UPDATED: 'Payment Updated',
  NOTE_CREATED: 'Note added',
};

export enum EVENT_SOURCE {
  TASK = 'TASK',
  INVOICE = 'INVOICE',
  MESSAGE = 'MESSAGE',
}

export enum EVENT_TARGET {
  USER = 'USER',
  ROLE = 'ROLE',
  CLIENT = 'CLIENT',
  PLATFORM_ROLE = 'PLATFORM_ROLE',
  ALL = 'ALL',
}

export const NOTIFICATION_INDICATOR_CUSTOMER_EVENTS = [
  EVENT_TYPE.MESSAGE_INBOUND,
  EVENT_TYPE.NOTE_CREATED,
  EVENT_TYPE.TASK_UPDATE_ASSIGNED_AGENT,
];

export const NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS = [
  EVENT_TYPE.ACTION_CREATED,
  EVENT_TYPE.COMMENT_ADDED,
  EVENT_TYPE.ACTION_UPDATE,
];

export const NOTIFICATION_INDICATOR_TASK_EVENTS = [
  ...NOTIFICATION_INDICATOR_CUSTOMER_EVENTS,
  ...NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS,
  EVENT_TYPE.EMAIL_RECEIVED,
];
