import IconStyles from 'apps/webapp/src/types/iconStyles';

const EllipseIcon = (styles: IconStyles) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none' {...styles}>
      <circle cx='4' cy='4' r='4' fill='#6461F3' />
    </svg>
  );
};

export default EllipseIcon;
