import { CommonType, GenericObject } from '../common';

export type CustomMetricType = CommonType & {
  clientId: string;
  name: CustomMetricName;
  count?: number;
  metadata?: GenericObject;
  updatedAt: Date;
  createdAt: Date;
};

// DTO for creating a new metric
export type CreateCustomMetricDtoType = Omit<CustomMetricType, 'id' | 'updatedAt' | 'createdAt' | 'version'>;

// DTO for updating an existing metric
export type UpdateCustomMetricDtoType = Partial<Omit<CustomMetricType, 'id' | 'updatedAt' | 'createdAt' | 'version'>>;

// DTO for reading a metric (usually the same as the base type)
export type GetCustomMetricDtoType = CustomMetricType;

// DTO for deleting a metric (usually just the id is needed)
export type DeleteCustomMetricDtoType = Pick<CustomMetricType, 'id'>;

export enum CustomMetricName {
  MESSAGE_SENT = 'message_sent',
  MESSAGE_RECEIVED = 'message_received',
  EMAIL_SENT = 'email_sent',
  EMAIL_RECEIVED = 'email_received',
  COLLECTIONS_AMOUNT = 'collections_amount',
  PAYMENT_LINKS_OPENED = 'payment_links_opened',
}
