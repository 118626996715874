import { Types } from 'mongoose';

export enum PayoutStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export type PayoutType = {
  id: string;
  clientId: Types.ObjectId;
  payoutStatus: PayoutStatus;
  payoutDate: Date;
  statementDescriptor: string;
  payoutAmount: number;
  amountCollected: number;
  // amountChargedInternally: number;
  // amountChargedExternally: number;
};
