const DollarIcon = () => {
  return (
    <svg width='17' height='31' viewBox='0 0 17 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.15128 13.6056C5.36051 12.5894 4.14145 11.5389 4.14145 9.90278C4.14145 8.02556 5.82809 6.71667 8.65029 6.71667C11.6228 6.71667 12.725 8.18056 12.8251 10.3333H16.5157C16.3988 7.37111 14.6454 4.65 11.1552 3.77167V0H6.14538V3.72C2.9057 4.44333 0.300589 6.61333 0.300589 9.93722C0.300589 13.9156 3.49018 15.8961 8.14931 17.05C12.3242 18.0833 13.1591 19.5989 13.1591 21.2006C13.1591 22.3889 12.3409 24.2833 8.65029 24.2833C5.21022 24.2833 3.85756 22.6989 3.67387 20.6667H0C0.200393 24.4383 2.9391 26.5567 6.14538 27.2628V31H11.1552V27.2972C14.4116 26.66 17 24.7139 17 21.1833C17 16.2922 12.942 14.6217 9.15128 13.6056Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default DollarIcon;
