const CheckedWithCircle = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='83' height='83' viewBox='0 0 83 83' fill='none'>
      <path
        d='M19 41.7857L34.3344 56.5714L65 27'
        stroke='#6461F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='41.5' cy='41.5' r='41' stroke='#6461F3' />
    </svg>
  );
};

export default CheckedWithCircle;
