import { ClientType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { clientSlice } from '../client';
import { baseQuery } from './baseQuery';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: baseQuery,
  tagTypes: ['clients'],
  endpoints: builder => ({
    clientAssignUsers: builder.mutation<ClientType, { clientId: string; userIds: string[] }>({
      query: ({ clientId, userIds }) => ({
        url: `/clients/${clientId}/assignUsers`,
        method: 'PATCH',
        body: { assignedUserIds: userIds },
      }),
      invalidatesTags: (result, error, { clientId }) => [{ type: 'clients', id: clientId }],
      async onQueryStarted({ clientId, userIds }, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        dispatch(clientSlice.actions.upsertOneClient(result.data));
      },
    }),
  }),
});

export const { useClientAssignUsersMutation } = clientsApi;
