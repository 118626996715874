import IconStyles from 'apps/webapp/src/types/iconStyles';

const Moneybag = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.52771 13.9664C8.41891 13.7334 8.19363 13.5634 7.92098 13.5331C7.69015 13.5075 7.46415 13.4592 7.2492 13.3897C7.15278 13.3581 7.06234 13.3236 6.97864 13.2866L7.11771 12.9168C7.21594 12.9596 7.31718 12.9988 7.42077 13.0341L7.4251 13.0356L7.42945 13.037C7.76202 13.1441 8.08921 13.1984 8.40194 13.1984C8.7158 13.1984 8.94341 13.1574 9.11826 13.0694L9.12871 13.0641L9.13891 13.0584C9.40379 12.9102 9.55571 12.6555 9.55571 12.3595C9.55571 12.031 9.37396 11.7584 9.05691 11.611C8.89751 11.5353 8.67101 11.4632 8.34476 11.3841C8.01016 11.3016 7.72904 11.2141 7.51108 11.1244C7.35164 11.0541 7.21345 10.9419 7.08883 10.7815C6.98161 10.6384 6.92725 10.4231 6.92725 10.1415C6.92725 9.80166 7.02541 9.52116 7.22735 9.28393C7.40718 9.07601 7.66248 8.93488 8.00513 8.85567C8.25921 8.79691 8.45751 8.62006 8.54664 8.3916C8.64737 8.62321 8.86183 8.79535 9.12856 8.83681C9.35143 8.87145 9.55609 8.92654 9.74005 9.00127L9.61198 9.36363C9.26461 9.22767 8.92274 9.15894 8.59212 9.15894C7.49396 9.15894 7.43834 9.89763 7.43834 10.0457C7.43834 10.3667 7.61614 10.6359 7.92615 10.7842L7.93485 10.7884L7.94369 10.7923C8.10022 10.8604 8.32246 10.926 8.64314 10.9989C8.97467 11.072 9.25112 11.1557 9.46838 11.2485L9.47284 11.2504L9.47736 11.2522C9.63868 11.3177 9.77705 11.427 9.90038 11.5863L9.90234 11.5889L9.90434 11.5914C10.0121 11.7273 10.0668 11.9374 10.0668 12.2159C10.0668 12.5375 9.97129 12.8097 9.77481 13.048C9.60446 13.2548 9.36651 13.3979 9.04729 13.4856C8.80078 13.5533 8.60955 13.737 8.52771 13.9664Z'
        fill={color}
      />
      <path
        d='M8.80567 14.9287H8.27658C8.15824 14.9287 8.06229 14.8327 8.06229 14.7144V14.2842C8.06229 14.1711 7.97423 14.078 7.86178 14.0655C7.59388 14.0358 7.3347 13.9804 7.08427 13.8994C6.84098 13.8196 6.63021 13.7248 6.45195 13.615C6.37002 13.5646 6.34022 13.4621 6.37409 13.372L6.737 12.4072C6.78588 12.2773 6.9436 12.2276 7.0651 12.2947C7.22905 12.3854 7.40524 12.4628 7.59365 12.5271C7.87439 12.6175 8.1438 12.6627 8.40189 12.6627C8.62376 12.6627 8.78223 12.6388 8.87732 12.5909C8.97241 12.5377 9.01995 12.4606 9.01995 12.3595C9.01995 12.2425 8.95657 12.1547 8.82978 12.0962C8.70752 12.0377 8.50377 11.9739 8.21851 11.9047C7.85175 11.8143 7.54612 11.7186 7.30161 11.6175C7.0571 11.5111 6.84428 11.3409 6.66317 11.1069C6.48205 10.8675 6.39149 10.5457 6.39149 10.1415C6.39149 9.67338 6.53413 9.27178 6.81938 8.93668C7.07643 8.63944 7.43143 8.43845 7.88442 8.33373C7.98638 8.31015 8.06229 8.22172 8.06229 8.11707V7.64299C8.06229 7.52465 8.15824 7.42871 8.27658 7.42871H8.80567C8.92402 7.42871 9.01995 7.52465 9.01995 7.64299V8.08998C9.01995 8.19945 9.10261 8.29064 9.21078 8.30745C9.60756 8.36912 9.9562 8.48582 10.2567 8.65755C10.3419 8.70625 10.3758 8.81003 10.3431 8.90257L10.0034 9.86356C9.95842 9.99089 9.80839 10.0448 9.68778 9.98398C9.30517 9.7911 8.93994 9.69465 8.59207 9.69465C8.18002 9.69465 7.974 9.81167 7.974 10.0457C7.974 10.1574 8.03513 10.2425 8.15738 10.301C8.27964 10.3542 8.48113 10.4127 8.76186 10.4766C9.12409 10.5563 9.42973 10.6494 9.67877 10.7558C9.9278 10.8569 10.1429 11.0244 10.324 11.2585C10.5096 11.4925 10.6025 11.8117 10.6025 12.2159C10.6025 12.6627 10.4644 13.0537 10.1882 13.3888C9.94751 13.6809 9.6145 13.8854 9.18913 14.0022C9.09135 14.029 9.01995 14.1156 9.01995 14.217V14.7144C9.01995 14.8327 8.92402 14.9287 8.80567 14.9287Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.18386 1H10.8231C12.0113 1 11.3588 2.78284 10.8778 3.87033L10.3303 5.11747L10.2563 5.28568C10.7749 5.26052 11.2877 5.40412 11.7177 5.69496C13.3264 7.16691 14.6319 8.93904 15.5609 10.9117C15.923 11.6412 16.0666 12.4597 15.9745 13.2688C15.864 15.3312 14.2094 16.9747 12.1463 17.0713H4.86066C2.79705 16.9775 1.14 15.3358 1.02712 13.2731C0.935022 12.464 1.07864 11.6455 1.44069 10.916C2.37078 8.94133 3.67818 7.16768 5.28923 5.69496C5.7193 5.40412 6.23207 5.26052 6.75065 5.28568L6.66493 5.09175L6.12922 3.87033C5.6503 2.78284 4.99459 1 6.18386 1Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2556 6.03578C10.6698 6.03578 11.0056 5.69999 11.0056 5.28578C11.0056 4.87157 10.6698 4.53578 10.2556 4.53578V6.03578ZM6.74991 4.53578C6.3357 4.53578 5.99991 4.87157 5.99991 5.28578C5.99991 5.69999 6.3357 6.03578 6.74991 6.03578V4.53578ZM13.1554 4.86827C13.5166 4.66543 13.6449 4.20823 13.4421 3.84708C13.2392 3.48593 12.782 3.3576 12.4209 3.56044L13.1554 4.86827ZM10.3292 5.11756L10.4705 5.85415L10.4726 5.85374L10.3292 5.11756ZM6.66392 5.09185L6.81547 4.35729L6.80878 4.35597L6.66392 5.09185ZM4.57256 3.55411C4.20792 3.35762 3.75303 3.49394 3.55655 3.85859C3.36006 4.22323 3.49638 4.67812 3.86103 4.87461L4.57256 3.55411ZM10.2556 4.53578H6.74991V6.03578H10.2556V4.53578ZM12.4209 3.56044C11.7245 3.95158 10.9699 4.22874 10.1859 4.38139L10.4726 5.85374C11.4137 5.6705 12.3195 5.3378 13.1554 4.86827L12.4209 3.56044ZM10.188 4.38098C9.0732 4.59472 7.92718 4.58668 6.81546 4.35732L6.51238 5.82638C7.81711 6.09556 9.16209 6.10499 10.4705 5.85415L10.188 4.38098ZM6.80878 4.35597C6.02743 4.20216 5.27361 3.93186 4.57256 3.55411L3.86103 4.87461C4.69432 5.32361 5.59033 5.6449 6.51906 5.82773L6.80878 4.35597Z'
        fill={color}
      />
    </svg>
  );
};

export default Moneybag;
