export interface CountryCodeOption {
  key: string;
  name: string;
  value: string;
}

export const COUNTRY_CODE_OPTIONS: CountryCodeOption[] = [
  { name: 'Australia', value: 'AUS', key: 'AUS' },
  { name: 'Austria', value: 'AUT', key: 'AUT' },
  { name: 'Belgium', value: 'BEL', key: 'BEL' },
  { name: 'Brazil', value: 'BRA', key: 'BRA' },
  { name: 'Canada', value: 'CAN', key: 'CAN' },
  { name: 'China', value: 'CHN', key: 'CHN' },
  { name: 'Czech Republic', value: 'CZE', key: 'CZE' },
  { name: 'Denmark', value: 'DNK', key: 'DNK' },
  { name: 'Finland', value: 'FIN', key: 'FIN' },
  { name: 'France', value: 'FRA', key: 'FRA' },
  { name: 'Germany', value: 'DEU', key: 'DEU' },
  { name: 'Greece', value: 'GRC', key: 'GRC' },
  { name: 'Hong Kong', value: 'HKG', key: 'HKG' },
  { name: 'Hungary', value: 'HUN', key: 'HUN' },
  { name: 'India', value: 'IND', key: 'IND' },
  { name: 'Indonesia', value: 'IDN', key: 'IDN' },
  { name: 'Ireland', value: 'IRL', key: 'IRL' },
  { name: 'Israel', value: 'ISR', key: 'ISR' },
  { name: 'Italy', value: 'ITA', key: 'ITA' },
  { name: 'Japan', value: 'JPN', key: 'JPN' },
  { name: 'Luxembourg', value: 'LUX', key: 'LUX' },
  { name: 'Mexico', value: 'MEX', key: 'MEX' },
  { name: 'Netherlands', value: 'NLD', key: 'NLD' },
  { name: 'New Zealand', value: 'NZL', key: 'NZL' },
  { name: 'Norway', value: 'NOR', key: 'NOR' },
  { name: 'Poland', value: 'POL', key: 'POL' },
  { name: 'Portugal', value: 'PRT', key: 'PRT' },
  { name: 'Russia', value: 'RUS', key: 'RUS' },
  { name: 'Saudi Arabia', value: 'SAU', key: 'SAU' },
  { name: 'Singapore', value: 'SGP', key: 'SGP' },
  { name: 'Slovakia', value: 'SVK', key: 'SVK' },
  { name: 'South Africa', value: 'ZAF', key: 'ZAF' },
  { name: 'South Korea', value: 'KOR', key: 'KOR' },
  { name: 'Spain', value: 'ESP', key: 'ESP' },
  { name: 'Sweden', value: 'SWE', key: 'SWE' },
  { name: 'Switzerland', value: 'CHE', key: 'CHE' },
  { name: 'Taiwan', value: 'TWN', key: 'TWN' },
  { name: 'Thailand', value: 'THA', key: 'THA' },
  { name: 'Turkey', value: 'TUR', key: 'TUR' },
  { name: 'United Kingdom', value: 'GBR', key: 'GBR' },
  { name: 'United States', value: 'USA', key: 'USA' },
  { name: 'Vietnam', value: 'VNM', key: 'VNM' },
];
