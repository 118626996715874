export enum Language {
  EN = 'en',
  FR = 'fr',
  AR = 'ar',
}

export enum LanguageDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export const LanguagesConfig = [
  {
    language: Language.EN,
    direction: LanguageDirection.LTR,
    text: 'English',
  },
  {
    language: Language.AR,
    direction: LanguageDirection.LTR,
    text: 'Arabic',
  },
];
