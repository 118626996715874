import IconStyles from 'apps/webapp/src/types/iconStyles';

const AssignedIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none' {...styles}>
      <path
        d='M1.33333 13C0.966667 13 0.652667 12.8728 0.391333 12.6184C0.130444 12.3636 0 12.0575 0 11.7V2.6C0 2.2425 0.130444 1.93635 0.391333 1.68155C0.652667 1.42718 0.966667 1.3 1.33333 1.3H4.13333C4.28889 0.91 4.53333 0.595833 4.86667 0.3575C5.2 0.119167 5.57778 0 6 0C6.42222 0 6.8 0.119167 7.13333 0.3575C7.46667 0.595833 7.71111 0.91 7.86667 1.3H10.6667C11.0333 1.3 11.3473 1.42718 11.6087 1.68155C11.8696 1.93635 12 2.2425 12 2.6V11.7C12 12.0575 11.8696 12.3636 11.6087 12.6184C11.3473 12.8728 11.0333 13 10.6667 13H1.33333ZM6 2.1125C6.14444 2.1125 6.264 2.06635 6.35867 1.97405C6.45289 1.88218 6.5 1.76583 6.5 1.625C6.5 1.48417 6.45289 1.3676 6.35867 1.2753C6.264 1.18343 6.14444 1.1375 6 1.1375C5.85556 1.1375 5.73622 1.18343 5.642 1.2753C5.54733 1.3676 5.5 1.48417 5.5 1.625C5.5 1.76583 5.54733 1.88218 5.642 1.97405C5.73622 2.06635 5.85556 2.1125 6 2.1125ZM1.33333 10.9525C1.93333 10.3783 2.63067 9.92593 3.42533 9.5953C4.21956 9.2651 5.07778 9.1 6 9.1C6.92222 9.1 7.78067 9.2651 8.57533 9.5953C9.36956 9.92593 10.0667 10.3783 10.6667 10.9525V2.6H1.33333V10.9525ZM6 7.8C6.64444 7.8 7.19445 7.57792 7.65 7.13375C8.10556 6.68958 8.33333 6.15333 8.33333 5.525C8.33333 4.89667 8.10556 4.36042 7.65 3.91625C7.19445 3.47208 6.64444 3.25 6 3.25C5.35556 3.25 4.80556 3.47208 4.35 3.91625C3.89444 4.36042 3.66667 4.89667 3.66667 5.525C3.66667 6.15333 3.89444 6.68958 4.35 7.13375C4.80556 7.57792 5.35556 7.8 6 7.8ZM2.66667 11.7H9.33333V11.5375C8.86667 11.1583 8.35 10.8741 7.78333 10.6847C7.21667 10.4949 6.62222 10.4 6 10.4C5.37778 10.4 4.78333 10.4949 4.21667 10.6847C3.65 10.8741 3.13333 11.1583 2.66667 11.5375V11.7ZM6 6.5C5.72222 6.5 5.48622 6.4051 5.292 6.2153C5.09733 6.02593 5 5.79583 5 5.525C5 5.25417 5.09733 5.02407 5.292 4.8347C5.48622 4.6449 5.72222 4.55 6 4.55C6.27778 4.55 6.51378 4.6449 6.708 4.8347C6.90267 5.02407 7 5.25417 7 5.525C7 5.79583 6.90267 6.02593 6.708 6.2153C6.51378 6.4051 6.27778 6.5 6 6.5Z'
        fill={color}
      />
    </svg>
  );
};

export default AssignedIcon;
