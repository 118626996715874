import IconStyles from 'apps/webapp/src/types/iconStyles';

const NoteIcon = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.308 1.00001H10.829C10.8863 1.00001 10.9433 1.00367 11 1.01101C13.2473 1.16817 14.9924 3.0332 15 5.28601V11.715C14.9917 14.0871 13.0641 16.0044 10.692 16H5.308C2.93551 16.0044 1.00772 14.0865 1 11.714V5.28601C1.00772 2.91353 2.93551 0.995581 5.308 1.00001Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 6.03574C15.4142 6.03574 15.75 5.69996 15.75 5.28574C15.75 4.87153 15.4142 4.53574 15 4.53574V6.03574ZM11 5.28574H10.25C10.25 5.69996 10.5858 6.03574 11 6.03574V5.28574ZM11.75 1.01074C11.75 0.596529 11.4142 0.260742 11 0.260742C10.5858 0.260742 10.25 0.596529 10.25 1.01074H11.75ZM7 12.7497C7.41421 12.7497 7.75 12.414 7.75 11.9997C7.75 11.5855 7.41421 11.2497 7 11.2497V12.7497ZM4 11.2497C3.58579 11.2497 3.25 11.5855 3.25 11.9997C3.25 12.414 3.58579 12.7497 4 12.7497V11.2497ZM11 9.74974C11.4142 9.74974 11.75 9.41396 11.75 8.99974C11.75 8.58553 11.4142 8.24974 11 8.24974V9.74974ZM4 8.24974C3.58579 8.24974 3.25 8.58553 3.25 8.99974C3.25 9.41396 3.58579 9.74974 4 9.74974V8.24974ZM15 4.53574H11V6.03574H15V4.53574ZM11.75 5.28574V1.01074H10.25V5.28574H11.75ZM7 11.2497H4V12.7497H7V11.2497ZM11 8.24974H4V9.74974H11V8.24974Z'
        fill={color}
      />
    </svg>
  );
};

export default NoteIcon;
