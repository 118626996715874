import IconStyles from 'apps/webapp/src/types/iconStyles';

const DropdownIcon = (styles: IconStyles) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='5' viewBox='0 0 9 5' fill='none' {...styles}>
      <path
        d='M1 1L4.36364 4.36364L7.72727 1'
        stroke='#606060'
        strokeWidth='1.00909'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DropdownIcon;
