import IconStyles from 'apps/webapp/src/types/iconStyles';

const ReplyIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9' fill='none'>
      <path
        d='M5.14451 1.53078C5.43765 1.23813 5.43805 0.763261 5.1454 0.47012C4.85276 0.176978 4.37789 0.176576 4.08474 0.46922L5.14451 1.53078ZM0.47012 4.07772C0.176978 4.37037 0.176576 4.84524 0.46922 5.13838C0.761865 5.43152 1.23674 5.43192 1.52988 5.13928L0.47012 4.07772ZM1.51318 4.06158C1.21111 3.77816 0.736479 3.79327 0.453058 4.09534C0.169636 4.39741 0.184752 4.87204 0.48682 5.15546L1.51318 4.06158ZM4.10144 8.54696C4.40351 8.83038 4.87815 8.81527 5.16157 8.5132C5.44499 8.21113 5.42987 7.7365 5.12781 7.45308L4.10144 8.54696ZM1 3.85854C0.585786 3.85854 0.25 4.19432 0.25 4.60854C0.25 5.02275 0.585786 5.35854 1 5.35854V3.85854ZM10.0737 4.60854V5.35854L10.076 5.35853L10.0737 4.60854ZM12.316 5.52952L12.8448 4.99765L12.316 5.52952ZM12.5 7.76848C12.5011 8.18269 12.8379 8.51755 13.2521 8.51641C13.6663 8.51526 14.0011 8.17855 14 7.76434L12.5 7.76848ZM4.08474 0.46922L0.47012 4.07772L1.52988 5.13928L5.14451 1.53078L4.08474 0.46922ZM0.48682 5.15546L4.10144 8.54696L5.12781 7.45308L1.51318 4.06158L0.48682 5.15546ZM1 5.35854H10.0737V3.85854H1V5.35854ZM10.076 5.35853C10.7171 5.35658 11.3326 5.60941 11.7872 6.06138L12.8448 4.99765C12.108 4.26514 11.1104 3.85538 10.0715 3.85854L10.076 5.35853ZM11.7872 6.06138C12.2418 6.51336 12.4982 7.12743 12.5 7.76848L14 7.76434C13.9971 6.7254 13.5816 5.73016 12.8448 4.99765L11.7872 6.06138Z'
        fill={color}
      />
    </svg>
  );
};

export default ReplyIcon;
