const TimerIcon = () => {
  return (
    <svg width='398' height='419' viewBox='0 0 398 419' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_3735_5082)'>
        <circle cx='199' cy='212.838' r='73.9477' stroke='#6461F3' strokeWidth='13.1047' />
        <g filter='url(#filter1_d_3735_5082)'>
          <path
            d='M199.001 160.417C199.001 158.349 200.68 156.66 202.743 156.798C215.492 157.648 227.613 162.828 237.063 171.537C247.434 181.095 253.829 194.205 254.978 208.262C256.126 222.319 251.944 236.293 243.262 247.408C234.58 258.523 222.034 265.964 208.118 268.254C194.201 270.543 179.933 267.513 168.148 259.765C156.363 252.017 147.925 240.119 144.51 226.435C141.095 212.751 142.953 198.283 149.716 185.907C155.878 174.629 165.701 165.839 177.505 160.949C179.416 160.158 181.547 161.221 182.209 163.179L191.71 191.275C193.068 195.29 199.001 194.314 199.001 190.076V160.417Z'
            fill='#6461F3'
          />
        </g>
        <path
          d='M154.626 143.459L154.916 143.896C155.485 144.752 156.637 144.99 157.498 144.429L161.949 141.528C162.728 141.021 162.947 139.978 162.438 139.201C162.124 138.72 162.077 138.111 162.314 137.588L162.943 136.201C163.203 135.628 163.157 134.962 162.822 134.43L160.734 131.115C160.164 130.21 158.954 129.965 158.077 130.578L149.053 136.882C148.205 137.474 147.998 138.641 148.59 139.489L150.218 141.822C150.568 142.323 151.142 142.622 151.753 142.622H153.067C153.694 142.622 154.279 142.936 154.626 143.459Z'
          fill='#6461F3'
        />
        <path
          d='M242.501 143.459L242.211 143.896C241.642 144.752 240.49 144.99 239.629 144.429L235.178 141.528C234.399 141.021 234.18 139.978 234.689 139.201C235.003 138.72 235.05 138.111 234.813 137.588L234.184 136.201C233.924 135.628 233.97 134.962 234.305 134.43L236.393 131.115C236.963 130.21 238.173 129.965 239.049 130.578L248.074 136.882C248.922 137.474 249.129 138.641 248.537 139.489L246.909 141.822C246.559 142.323 245.985 142.622 245.374 142.622H244.06C243.433 142.622 242.848 142.936 242.501 143.459Z'
          fill='#6461F3'
        />
        <path
          d='M209.002 131.91L209.002 132.228C209.002 133.694 207.814 134.882 206.349 134.882L190.405 134.882C188.776 134.882 187.456 133.562 187.456 131.933L187.456 131.751C187.456 130.478 186.575 129.376 185.334 129.097C184.093 128.818 183.211 127.716 183.211 126.444L183.211 114.49C183.211 112.422 184.887 110.746 186.955 110.746L209.928 110.746C211.996 110.746 213.672 112.422 213.672 114.49L213.672 127.24C213.672 128.529 212.626 129.575 211.337 129.575C210.047 129.575 209.002 130.62 209.002 131.91Z'
          fill='#6461F3'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_3735_5082'
          x='0.558128'
          y='-2.4535'
          width='396.884'
          height='421.222'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='9.36047' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_3735_5082' />
          <feOffset dy='7.48837' />
          <feGaussianBlur stdDeviation='54.2907' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.392 0 0 0 0 0.380556 0 0 0 0 0.952778 0 0 0 1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3735_5082' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3735_5082' result='shape' />
        </filter>
        <filter
          id='filter1_d_3735_5082'
          x='24.896'
          y='46.3365'
          width='348.21'
          height='348.093'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='9.36047' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_3735_5082' />
          <feOffset dy='7.48837' />
          <feGaussianBlur stdDeviation='54.2907' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3735_5082' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3735_5082' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};

export default TimerIcon;
