// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Table = (theme: Theme) => {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[0],
          background: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          '& .MuiTableCell-head': {
            fontSize: '0.75rem',
            fontWeight: 700,
            letterSpacing: '1px',
            padding: '0.25rem',
            backgroundColor: theme.palette.customColors.themeColorDark,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-body': {
            letterSpacing: '0.25px',
            color: theme.palette.primary.dark,
            '&:not(.MuiTableCell-sizeSmall):not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)': {},
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '2px 4px',
          },
          '&.extended-row': {
            backgroundColor: 'unset !important',
          },
          '&:nth-of-type(first) th': {
            paddingTop: '10px',
          },
          '& .MuiTableCell-root:first-of-type': {
            borderTopLeftRadius: '18px',
            borderBottomLeftRadius: '18px',
            paddingLeft: '14px',
          },
          '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '18px',
            borderBottomRightRadius: '18px',
            paddingRight: '14px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `none`,
          textAlign: 'unset',
          fontColor: theme.palette.primary.dark,
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
        },
        paddingCheckbox: {
          paddingLeft: theme.spacing(3.25),
        },
        stickyHeader: {
          backgroundColor: theme.palette.customColors.tableHeaderBg,
        },
      },
    },
  };
};

export default Table;
