import { httpDelete, httpGet, httpPatch } from '../../httpClient';
import { ROLE_API_PATHS } from '../../httpClient/api';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { upsertManyRoles } from '../../store/roles';
import { useAuth } from '../useAuth';
import { PermissionType } from '@finance-ops/types';

export const useGetRoles = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const getRoles = useCallback(async () => {
    try {
      // TODO:RAJAT let path = `${ROLE_API_PATHS.GET_ROLES}?isInternal=${isInternalUser}&isSuperAdmin=${isSuperAdmin}`;
      let path = `${ROLE_API_PATHS.GET_ROLES}`;
      if (selectedClientId) {
        path += `?clientId=${selectedClientId}`;
      }
      const response = await httpGet(path);
      dispatch(upsertManyRoles(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }, [isInternalUser, isSuperAdmin, selectedClientId]);
  return { getRoles };
};

export const useUpdateRole = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const updateRole = useCallback(
    async (roleId: string, permissions: PermissionType[]) => {
      try {
        const path = `${ROLE_API_PATHS.UPDATE_ROLE}/${roleId}`;
        const response = await httpPatch(path, { permissions });
        dispatch(upsertManyRoles(response?.data));
        return response?.data;
      } catch (error) {
        console.error(error);
      }
    },
    [isInternalUser, isSuperAdmin, selectedClientId],
  );
  return { updateRole };
};

export const useDeleteRole = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const { getRoles } = useGetRoles();

  const deleteRole = useCallback(
    async (roleId: string) => {
      try {
        const path = `${ROLE_API_PATHS.DELETE_ROLE}/${roleId}`;
        await httpDelete(path);
        getRoles();
      } catch (error) {
        console.error(error);
      }
    },
    [isInternalUser, isSuperAdmin, selectedClientId],
  );
  return { deleteRole };
};
