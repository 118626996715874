import { FileType } from '@finance-ops/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const defaultPaginationState = {
  page: 1,
  pageSize: 25,
};

interface PaginationType {
  page: number;
  pageSize: number;
}

export interface IngestedFilesData {
  isLoading: boolean;
  paginationLoading: boolean;
  ingestedFiles: FileType[];
  pagination: PaginationType;
}

const initialState: IngestedFilesData = {
  isLoading: false,
  paginationLoading: false,
  ingestedFiles: [],
  pagination: defaultPaginationState,
};

const files = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFilesList: (state, action: PayloadAction<any[]>) => {
      state.ingestedFiles = action.payload;
      state.isLoading = false;
    },
    setIngestedFilesPagination: (state, action: PayloadAction<PaginationType>) => {
      state.pagination = action.payload;
      state.isLoading = true;
    },
    setIngestedFilesLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setFilesList, setIngestedFilesPagination, setIngestedFilesLoading } = files.actions;

export default files.reducer;
