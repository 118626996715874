import {
  TeamOverviewAgentCollectionProfileResponseType,
  TeamOverviewAgentStatisticsResponseType,
  collectionsResponseType,
  AgentsTeamOverviewType,
  DashboardAgingSummary,
  messagesResponseType,
  P2psGroupedByType,
  UserType,
} from '@finance-ops/types';
import { createSlice } from '@reduxjs/toolkit';

interface TeamOverviewSliceInterface {
  loading: boolean;
  agents: AgentsTeamOverviewType[];
  agingSummary: DashboardAgingSummary;
  p2pGraph: P2psGroupedByType[];
  collectionsGraph: collectionsResponseType;
  messagesGraph: messagesResponseType;
  selectedAgentInformation: UserType;
  agentCollectionsProfile: TeamOverviewAgentCollectionProfileResponseType;
  agentStatistics: TeamOverviewAgentStatisticsResponseType;
  collectionsProfileTimeframe: string;
}

const initialState: TeamOverviewSliceInterface = {
  loading: false,
  agents: [],
  agingSummary: {
    boundaries: [],
    endDefault: 0,
    info: [],
    predicted: [],
  },
  p2pGraph: [],
  collectionsGraph: [],
  messagesGraph: [],
  selectedAgentInformation: {} as UserType,
  agentCollectionsProfile: {
    collections: [],
    customersInteracted: 0,
    totalMessagesSent: 0,
    responseRate: 0,
    recoveryRate: 0,
  },
  agentStatistics: {
    averageDaysToCollect: 0,
    totalClosed: 0,
    topCollectionAmount: 0,
  },
  collectionsProfileTimeframe: 'Month',
};

export const teamOverviewSlice = createSlice({
  name: 'teamOverview',
  initialState,
  reducers: {
    resetTeamOverview: () => {
      return initialState;
    },
    setAgents: (state, action) => {
      state.agents = [...action.payload];
    },
    setAgingSummary: (state, action) => {
      state.agingSummary = action.payload;
    },
    setP2PGraph: (state, action) => {
      state.p2pGraph = action.payload;
    },
    setCollectionsGraph: (state, action) => {
      state.collectionsGraph = action.payload;
    },
    setMessagesGraph: (state, action) => {
      state.messagesGraph = action.payload;
    },
    setSelectedAgentInformation: (state, action) => {
      state.selectedAgentInformation = action.payload;
    },
    setAgentCollectionsProfile: (state, action) => {
      state.agentCollectionsProfile = action.payload;
    },
    setAgentStatistics: (state, action) => {
      state.agentStatistics = action.payload;
    },
    setCollectionsProfile: (state, action) => {
      state.collectionsProfileTimeframe = action.payload;
    },
  },
});

export const {
  resetTeamOverview,
  setAgents,
  setAgingSummary,
  setP2PGraph,
  setCollectionsGraph,
  setMessagesGraph,
  setSelectedAgentInformation,
  setAgentCollectionsProfile,
  setAgentStatistics,
  setCollectionsProfile,
} = teamOverviewSlice.actions;

export default teamOverviewSlice.reducer;
