// ** React Imports
import { ReactElement, ReactNode, useEffect } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** Hooks Import

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children } = props;
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
  }, [router.route]);

  return <>{children}</>;
};

export default GuestGuard;
