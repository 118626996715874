import { TIME_FORMAT } from '@finance-ops/types';
import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import _ from 'lodash';

import { PaymentTypes } from './types';

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatCurrency = (value: number | string = 0) => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return formatter.format(value);
};

export function formatUSD(number: number, options?: { hideDecimals?: boolean; hideCurrency?: boolean }) {
  const symbols = ['', 'K', 'M', 'B', 'T'];
  let num = Math.abs(number);
  let symbolIndex = 0;
  const showDecimals = options?.hideDecimals ? false : true;
  const showCurrency = options?.hideCurrency ? false : true;

  while (num >= 1000 && symbolIndex < symbols.length - 1) {
    num /= 1000;
    symbolIndex++;
  }

  const formattedNumber = showDecimals ? num.toFixed(2) : Math.round(num).toString();
  let result = '';

  if (showCurrency) {
    result += '$';
  }

  result += formattedNumber;

  if (symbolIndex > 0) {
    result += symbols[symbolIndex];
  }

  return number < 0 ? `-${result}` : result;
}

export function reverseFormatUSD(formattedNumber: string) {
  const symbols = ['', 'K', 'M', 'B', 'T'];
  const numberString = formattedNumber.replace(/^\$/, '');
  let symbolIndex = 0;

  for (let i = 1; i < symbols.length; i++) {
    if (numberString.endsWith(symbols[i])) {
      symbolIndex = i;
      break;
    }
  }

  const numberWithoutSymbol = parseFloat(numberString.replace(symbols[symbolIndex], ''));
  const number = numberWithoutSymbol * Math.pow(1000, symbolIndex);

  return number;
}

export const formatDate = (
  value: Date | string,
  formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: '2-digit' },
) => {
  if (!value) return value;

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (
  value: Date | string | undefined,
  toTimeForCurrentDay = true,
  dateTimeFormatOptions?: Intl.DateTimeFormatOptions,
) => {
  if (!value) return value;
  const date = new Date(value);
  let formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { month: 'numeric', day: 'numeric' };
  }

  if (dateTimeFormatOptions) {
    formatting = dateTimeFormatOptions;
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

const getFriendlyDate = (date: Date, formatString: string) => {
  if (isToday(date)) {
    return 'Today';
  }

  if (isYesterday(date)) {
    return 'Yesterday';
  }

  if (isTomorrow(date)) {
    return 'Tomorrow';
  }
  return format(date, formatString);
};

// ** Returns short month of passed date
export const formatDateShort = (value: Date | string | undefined, formatString = 'MMM d') => {
  if (!value) return value;
  return getFriendlyDate(new Date(value), formatString);
};

export const formatDateLong = (value: Date | string | undefined) => {
  if (!value) return value;
  return getFriendlyDate(new Date(value), 'd MMMM, yyyy');
};

// ? The following functions are taken from https://codesandbox.io/s/ovvwzkzry9?file=/utils.js for formatting credit card details
// Get only numbers from the input value
const clearNumber = (value = '') => {
  return value.replace(/\D+/g, '');
};

// Format credit cards according to their types
export const formatCreditCardNumber = (value: string, Payment: PaymentTypes) => {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19,
      )}`;
      break;
  }

  return nextValue.trim();
};

// Format expiration date in any credit card
export const formatExpirationDate = (value: string) => {
  const finalValue = value
    .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
    .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
    .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
    .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2') // 141 > 01/41
    .replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
    // To allow only digits and `/`
    .replace(/[^\d\/]|^[\/]*$/g, '')
    .replace(/\/\//g, '/'); // Prevent entering more than 1 `/`

  return finalValue;
};

// Format CVC in any credit card
export const formatCVC = (value: string, cardNumber: string, Payment: PaymentTypes) => {
  const clearValue = clearNumber(value);
  const issuer = Payment.fns.cardType(cardNumber);
  const maxLength = issuer === 'amex' ? 4 : 3;

  return clearValue.slice(0, maxLength);
};

export const formatDateAndTime = (value: Date | string, use12HourFormat?: TIME_FORMAT) => {
  try {
    if (_.isString(value)) {
      value = new Date(value);
    }
    if (!_.isDate(value)) return null;
    const timeFormat = use12HourFormat === TIME_FORMAT.HOURS_24 ? 'HH:mm' : 'p';
    return formatDate(value) + ' ' + format(new Date(value), timeFormat);
  } catch (err) {
    console.error('Error in Formatting date', err);
    return null;
  }
};

export const getTranslationLanguageCode = (language: string, dialect?: string | null) => {
  if (dialect) {
    return `${language}-${dialect}`;
  }
  return language;
};

export const getTemplateLanguageCode = (language: string, dialect?: string | null) => {
  if (dialect) {
    return `${language}_${dialect}`;
  }
  return language;
};

export enum LANGUAGE_CODES {
  'en-US' = 'English (US)',
  'es-MX' = 'Spanish (Mexico)',
  'ar' = 'Arabic',
}

export const getLanguageAndDialectFromLanguageCode = (languageCode: keyof typeof LANGUAGE_CODES) => {
  const [language, dialect] = languageCode.split('-');
  if (dialect) {
    return {
      language: language,
      dialect: dialect,
    };
  } else
    return {
      language: language,
      dialect: null,
    };
};

export const getLanguageCode = (language: string, dialect?: string | null) => {
  return getTranslationLanguageCode(language, dialect) as keyof typeof LANGUAGE_CODES;
};

export function timeAgo(inputDate: Date): string {
  const seconds = Math.floor((new Date().getTime() - inputDate.getTime()) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' year' + (Math.floor(interval) > 1 ? 's' : '') + ' ago';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' month' + (Math.floor(interval) > 1 ? 's' : '') + ' ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' day' + (Math.floor(interval) > 1 ? 's' : '') + ' ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hour' + (Math.floor(interval) > 1 ? 's' : '') + ' ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minute' + (Math.floor(interval) > 1 ? 's' : '') + ' ago';
  }
  return Math.floor(seconds) + ' second' + (Math.floor(seconds) > 1 ? 's' : '') + ' ago';
}

export const formatTimestamp = (
  timestamp: Date | string | undefined,
  timeFormat: TIME_FORMAT = TIME_FORMAT.HOURS_12,
) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const today = new Date();

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    let hours = date.getHours();
    const minutes = date.getMinutes();

    if (timeFormat === TIME_FORMAT.HOURS_12) {
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${period}`;
    }

    return `${hours}:${minutes}`;
  }

  return formatDate(timestamp);
};
