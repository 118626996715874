//https://github.com/mongodb/mongo/blob/master/src/mongo/db/repl/oplog_entry.idl
import { ObjectId } from 'bson';

export enum OPLOG_OPERATION {
  INSERT = 'i',
  UPDATE = 'u',
  DELETE = 'd',
  COMMAND = 'c',
  NOOP = 'n',
  DB = 'db',
  XI = 'xi',
  XD = 'xd',
}

export type OpLogBaseType = {
  op: OPLOG_OPERATION; // operation type
  ns: string; // namespace
  ui: string; // uuid of collection
  stmtId: number;
  ts: Timestamp;
  t: number; // replica set term
  v: number; // oplog version
  wall: string; // wall clock time
  prevOpTime: {
    ts: Timestamp;
    t: number;
  };
};

export type Timestamp = {
  t: number;
  i: number;
};

export type OpLogCommandType = OpLogBaseType & {
  op: OPLOG_OPERATION.COMMAND;
  o: {
    [key: string]: any;
  };
};

export type OpLogCRUDType = OpLogBaseType & {
  op: OPLOG_OPERATION.INSERT | OPLOG_OPERATION.UPDATE | OPLOG_OPERATION.DELETE;
  lsid: {
    id: string; // session id
    uid: string; // user id
  };
  txnNumber: number; // transaction number in session
};

export type OpLogInsertType = OpLogCRUDType & {
  op: OPLOG_OPERATION.INSERT;
  o: {
    _id: ObjectId;
    [key: string]: any;
  };
  o2: {
    _id: ObjectId;
  };
};

export type OpLogUpdateType = OpLogCRUDType & {
  op: OPLOG_OPERATION.UPDATE;
  o: {
    $v: number;
    diff: { [key: string]: any };
  };
  o2: {
    _id: ObjectId;
  };
  needsRetryImage: string;
};

export type OpLogDeleteType = OpLogCRUDType & {
  op: OPLOG_OPERATION.DELETE;
  o: {
    _id: ObjectId;
  };
};

export type OpLogType = OpLogInsertType | OpLogUpdateType | OpLogDeleteType;
