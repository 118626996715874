import { ReactNode, useContext } from 'react';
import { AbilityContext } from 'apps/webapp/src/layouts/components/acl/Can';
import { NavGroup } from 'apps/webapp/src/@core/layouts/types';
import { useAuth } from '../../../hooks/useAuth';
import { canAccess } from './acl-utils';
import { AnyAbility } from '@casl/ability';
import { PermissionType } from '@finance-ops/types';

interface Props {
  navGroup?: NavGroup;
  children: ReactNode;
}

const isVisible = (ability: AnyAbility, item: { permission?: PermissionType }): boolean => {
  if (!item.permission) return true;
  return canAccess(ability, item.permission);
};

const hasVisibleChildren = (
  ability: AnyAbility,
  children: Array<{ children?: any; permission?: PermissionType }>,
): boolean => {
  return children.some(child => {
    if (child.children) return hasVisibleChildren(ability, child.children);
    return isVisible(ability, child);
  });
};

const CanViewNavGroup = ({ children, navGroup }: Props) => {
  const ability = useContext(AbilityContext);
  const { user } = useAuth();

  if (user?.role?.isInternal || !navGroup) {
    return <>{children}</>;
  }

  const canView =
    !navGroup.permission || isVisible(ability, navGroup) ? hasVisibleChildren(ability, navGroup.children || []) : false;

  return canView ? <>{children}</> : null;
};

export default CanViewNavGroup;
