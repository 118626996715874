// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import logo from 'apps/webapp/public/images/logos/logo.png';
import { getSelectedClientDetails } from 'apps/webapp/src/store/client';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { hardLogout } from '../../../utils/session-util';

const FallbackSpinner = ({ sx }: { sx?: BoxProps['sx'] }) => {
  const selectedClientDetails = useSelector(getSelectedClientDetails);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
        gap: 6,
      }}
    >
      <Image src={selectedClientDetails?.settings?.logo ?? logo} alt={'FinanceOps'} width={50} />
      <CircularProgress disableShrink />
      <Button variant={'contained'} onClick={hardLogout}>
        Reload
      </Button>
    </Box>
  );
};

export default FallbackSpinner;
