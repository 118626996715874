import { EmailPredictionType, EmailType, WebAttachmentType } from '@finance-ops/types';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { RootState } from '.';

const emailEntityAdapter = createEntityAdapter<EmailType>({
  selectId: email => email.id,
  sortComparer: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
});

interface EmailState extends EntityState<EmailType> {
  selectedThreadId: string;
  selectedEmailId: string;
  invoiceAttachment?: WebAttachmentType | null;
  prediction?: EmailPredictionType;
  loading: boolean;
}

const initialState: EmailState = {
  ...emailEntityAdapter.getInitialState(),
  selectedThreadId: '',
  selectedEmailId: '',
  loading: true,
};

const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    upsertManyEmails: (state, action: PayloadAction<EmailType[]>) => {
      const messageIds = action.payload.map(email => email.messageId);
      const emailsToRemove = state.ids.filter(id => messageIds.includes(id as string));
      emailEntityAdapter.removeMany(state, emailsToRemove);
      emailEntityAdapter.upsertMany(state, action.payload);
    },
    setSelectedThreadId: (state, action: PayloadAction<string>) => {
      state.selectedThreadId = action.payload;
    },
    setSelectedEmailId: (state, action: PayloadAction<string>) => {
      state.selectedEmailId = action.payload;
    },
    setEmailLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInvoiceAttachment: (state, action: PayloadAction<WebAttachmentType | null>) => {
      state.invoiceAttachment = action.payload;
    },
    setEmailPrediction: (state, action: PayloadAction<EmailPredictionType>) => {
      state.prediction = action.payload;
    },
  },
});

export const emailSelectors = emailEntityAdapter.getSelectors((state: RootState) => state.email);

// console.log('Emails in selecttors', emailSelectors);

export const selectEmailsByCustomerId = createCachedSelector(
  [emailSelectors.selectAll, (_: any, customerId: any) => customerId],
  (emails, customerId) => {
    const emailForCustomer: EmailType[] = emails
      .filter(email => email !== undefined && email.customerId === customerId)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    return emailForCustomer ? emailForCustomer : [];
  },
)((_, customerId) => customerId);

export const loadingEmails = (state: RootState) => state.actions.loading;

export const {
  upsertManyEmails,
  setSelectedThreadId,
  setEmailLoading,
  setInvoiceAttachment,
  setEmailPrediction,
  setSelectedEmailId,
} = emailSlice.actions;

export default emailSlice.reducer;
