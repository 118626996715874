import IconStyles from 'apps/webapp/src/types/iconStyles';

const UnAssigned = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg width='11' height='13' viewBox='0 0 11 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...styles}>
      <path
        d='M5.5 10.4C5.67315 10.4 5.81839 10.3376 5.93572 10.2128C6.05265 10.0884 6.11111 9.93417 6.11111 9.75C6.11111 9.56583 6.05265 9.41135 5.93572 9.28655C5.81839 9.16218 5.67315 9.1 5.5 9.1C5.32685 9.1 5.18181 9.16218 5.06489 9.28655C4.94756 9.41135 4.88889 9.56583 4.88889 9.75C4.88889 9.93417 4.94756 10.0884 5.06489 10.2128C5.18181 10.3376 5.32685 10.4 5.5 10.4ZM4.88889 7.8H6.11111V3.9H4.88889V7.8ZM1.22222 13C0.886111 13 0.598278 12.8728 0.358722 12.6184C0.119574 12.3636 0 12.0575 0 11.7V2.6C0 2.2425 0.119574 1.93635 0.358722 1.68155C0.598278 1.42718 0.886111 1.3 1.22222 1.3H3.78889C3.9213 0.91 4.14293 0.595833 4.45378 0.3575C4.76422 0.119167 5.11296 0 5.5 0C5.88704 0 6.23598 0.119167 6.54683 0.3575C6.85728 0.595833 7.0787 0.91 7.21111 1.3H9.77778C10.1139 1.3 10.4017 1.42718 10.6413 1.68155C10.8804 1.93635 11 2.2425 11 2.6V11.7C11 12.0575 10.8804 12.3636 10.6413 12.6184C10.4017 12.8728 10.1139 13 9.77778 13H1.22222ZM1.22222 11.7H9.77778V2.6H1.22222V11.7ZM5.5 2.1125C5.63241 2.1125 5.742 2.06635 5.82878 1.97405C5.91515 1.88218 5.95833 1.76583 5.95833 1.625C5.95833 1.48417 5.91515 1.3676 5.82878 1.2753C5.742 1.18343 5.63241 1.1375 5.5 1.1375C5.36759 1.1375 5.2582 1.18343 5.17183 1.2753C5.08506 1.3676 5.04167 1.48417 5.04167 1.625C5.04167 1.76583 5.08506 1.88218 5.17183 1.97405C5.2582 2.06635 5.36759 2.1125 5.5 2.1125Z'
        fill={color}
      />
    </svg>
  );
};

export default UnAssigned;
