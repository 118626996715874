import { AttachmentType, CommonType } from '../common';

export type EmailType = CommonType & {
  externalId: string;
  threadId: string;
  text: string;
  html?: string;
  raw: any;
  customerId?: string;
  clientId?: string;
  type: EmailDirection;
  status: EmailStatus;
  cc: string[];
  bcc: string[];
  messageAt: Date;
  from: string;
  to: string;
  subject: string;
  attachments?: AttachmentType[];
  userEmail: string;
  isRead: boolean;
  messageId?: string;
  reads: EmailReadType[];
  senderName?: string;
  sentBy?: string;
};

export enum EmailStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  DELIVERED = 'delivered',
  BOUNCED = 'bounced',
  REJECTED = 'rejected',
  RECEIVED = 'received',
}

export type EmailReadType = {
  country?: string;
  region?: string;
  city?: string;
  coordinates?: number[];
  ip: string;
  timestamp: Date;
};

export enum EmailEnitityType {
  CLIENT = 'CLIENT',
  USER = 'USER',
}

export enum EmailDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}
