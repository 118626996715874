import { GenericObject } from '@finance-ops/types';
import { PermissionDisplayMode } from './PermissionDisplayModeType';

export type PermissionSubjectType = {
  [key in PERMISSION_SUBJECT]: {
    displayText: string;
    description: string;
    displayMode?: PermissionDisplayMode;
    isInternal: boolean;
    conditions?: GenericObject;
    fields?: string[];
  };
};

export enum PERMISSION_SUBJECT {
  CHAT_PAGE = 'chatPage',
  TEAM_MANAGEMENT = 'teamManagement',
  USER_MANAGEMENT = 'userManagement',
  ROLE_MANAGEMENT = 'roleManagement',
  STRATEGY_PAGE = 'strategyPage',
  WORKFLOW_PAGE = 'workflowPage',
  INVOICES_PAGE = 'invoicesPage',
  QUEUE_PAGE = 'queuePage',
  CHAT_PAGE_QUICK_FILTER_ALL = 'chatPageQuickFilterAll',
  SETTINGS_PAGE = 'settingsPage',
  NEW_DASHBOARD_PAGE = 'newDashboardPage',
  NON_ACL_PAGE = 'nonACLPage',
  PLAYGROUND = 'playground',
  AI_GENERATED_FEEDBACK = 'aiGeneratedFeedback',
  ASSIGN_COPILOT_EXTERNAL = 'assignCopilotExternal',
  SELECT_CLIENT_ID = 'selectClientId',
  CLIENTS_ASSIGN = 'clientsAssign',
  COLLECTION_MANAGER_INTERNAL = 'collectionManagerInternal',
  COLLECTION_MANAGER_EXTERNAL = 'collectionManagerExternal',
  COLLECTION_AGENT_EXTERNAL = 'collectionAgentExternal',
  COLLECTION_AGENT_INTERNAL = 'collectionAgentInternal',
  CUSTOMERS_ASSIGN = 'customersAssign',
  DB_VIEWER = 'dbViewer',
  PAYMENTS_PAGE = 'paymentsPage',
  TASK_MODE = 'taskMode',
  TASK = 'task',
  ACTION_UPDATE = 'actionUpdate',
  ACTION_COMMENT_CREATE = 'actionCommentCreate',
  ACTION_COMMENT_UPDATE = 'actionCommentUpdate',
  ONLY_SUPER_ADMIN = 'onlySuperAdmin',
  ONLY_INTERNAL_USER = 'onlyInternalUser',
  CUSTOMERS_PAGE_QUICK_FILTERS = 'customersPageQuickFilters',
  HIDE_MESSAGES = 'hideMessages',
  WHATSAPP_COMMUNICATION = 'whatsappCommunication',
  ALL = 'all',
}
