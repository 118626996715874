import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { CommentType } from '@finance-ops/types';
import { RootState } from '.';

const commentsEntityAdapter = createEntityAdapter<CommentType>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

interface CommentsState extends EntityState<CommentType> {
  selectedComment: CommentType | null;
  loading: boolean;
}

const initialState: CommentsState = {
  ...commentsEntityAdapter.getInitialState(),
  selectedComment: null,
  loading: false,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: commentsEntityAdapter.setAll,
    deleteOneComment: commentsEntityAdapter.removeOne,
    upsertOneComment: (state, comment: PayloadAction<CommentType>) => {
      commentsEntityAdapter.upsertOne(state, comment.payload);
      state.loading = false;
    },
    upsertManyComments: (state, comment: PayloadAction<CommentType[]>) => {
      commentsEntityAdapter.upsertMany(state, comment.payload);
      state.loading = false;
    },
    setSelectedComment: (state, comment: PayloadAction<CommentType>) => {
      state.selectedComment = comment.payload;
    },
    resetComments: commentsEntityAdapter.removeAll,
    setCommentsLoading: (state, comment: PayloadAction<boolean>) => {
      state.loading = comment.payload;
    },
  },
});

export const commentsSelectors = commentsEntityAdapter.getSelectors((state: RootState) => state.comments);
export const loadingComments = (state: RootState) => state.comments.loading;

export const selectCommentsByReferenceId = createSelector(
  [commentsSelectors.selectAll, (_, id) => id],
  (comments, id) => {
    return comments.filter(comment => comment.referenceId === id);
  },
);

export const {
  setComments,
  deleteOneComment,
  upsertOneComment,
  upsertManyComments,
  setSelectedComment,
  resetComments,
  setCommentsLoading,
} = commentsSlice.actions;

export default commentsSlice.reducer;
