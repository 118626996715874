import { AbilityBuilder, buildMongoQueryMatcher, createMongoAbility } from '@casl/ability';
import { GenericObject, PermissionType, UserType } from '@finance-ops/types';
import {
  $and,
  $eq,
  $gt,
  $gte,
  $in,
  $lt,
  $lte,
  $ne,
  $nin,
  $or,
  and,
  eq,
  gt,
  gte,
  lt,
  lte,
  ne,
  nin,
  or,
  within,
} from '@ucast/mongo2js';

// Define MongoDB operators mapping
const OPERATORS = {
  $and,
  $or,
  $in,
  $nin,
  $ne,
  $eq,
  $gt,
  $gte,
  $lt,
  $lte,
};

const INTERPRETERS = {
  and,
  or,
  within,
  nin,
  eq,
  ne,
  gt,
  gte,
  lt,
  lte,
};

// Create conditions matcher with MongoDB operators support
const conditionsMatcher = buildMongoQueryMatcher(OPERATORS, INTERPRETERS);

// Function to build user abilities
export function userAbilityBuilder(user: UserType) {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  if (user?.role?.isSuperAdmin) {
    can('manage', 'all');
    return build();
  }

  const permissions: PermissionType[] = user?.role?.permissions || [];

  permissions.forEach(permission => {
    const { subject, actions, fields, conditions } = permission;
    const resolvedConditions = resolveDynamicConditions(conditions ?? {}, user);

    actions.forEach(action => {
      can(action, subject, fields, resolvedConditions);
    });
  });

  return build({ conditionsMatcher });
}

function resolveDynamicConditions(conditions: GenericObject, user: UserType): GenericObject {
  if (!conditions || typeof conditions !== 'object') {
    return conditions;
  }

  // Handle arrays for operators like $and, $or, $in
  if (Array.isArray(conditions)) {
    return conditions.map(condition => resolveDynamicConditions(condition, user));
  }

  const resolvedConditions: GenericObject = {};

  for (const [key, value] of Object.entries(conditions)) {
    if (key.startsWith('$')) {
      // Handle MongoDB operators
      resolvedConditions[key] = resolveDynamicConditions(value, user);
    } else if (typeof value === 'object' && value !== null) {
      // Handle nested objects
      resolvedConditions[key] = resolveDynamicConditions(value, user);
    } else if (typeof value === 'string' && value.startsWith('user.')) {
      // Resolve user property references
      const userProperty = value.split('.').slice(1).join('.');
      const resolvedValue = getNestedProperty(user, userProperty);
      if (resolvedValue !== undefined) {
        resolvedConditions[key] = resolvedValue;
      }
    } else {
      resolvedConditions[key] = value;
    }
  }

  return resolvedConditions;
}

function getNestedProperty(obj: GenericObject, path: string): any {
  return path.split('.').reduce((acc, part) => (acc != null ? acc[part] : undefined), obj);
}
