export enum COLLECTIONS {
  MANUAL_LOG = 'manual_log',
  CUSTOMER_CONTEXT = 'customerContext',
  TASK = 'tasks',
  CUSTOMERS = 'customers',
  INVOICE = 'invoices',
  CLIENT = 'clients',
  USER = 'users',
  ACTION = 'actions',
  MESSAGE = 'messages',
  NOTIFICATION = 'notifications',
  NOTIFICATION_QUEUE = 'notificationQueue',
  COMMENT = 'comments',
  TEMPLATE = 'templates',
  PAYMENT_PLAN = 'paymentPlans',
  EMAIL = 'emails',
  FAQ = 'faqs',
  FILE = 'file',
  LINE_ITEM = 'lineItems',
  OD_PROCREDURE_CODE = 'od_procedurecodes',
  OD_PROCEDURE_LOG = 'od_procedurelogs',
  OD_CLAIM = 'od_claims',
  OD_CLAIM_PROC = 'od_claimprocs',
  DASHBOARD_AGGREGATIONS = 'dashboard_aggregations',
  TODAY_AGGREGATIONS = 'today_aggregations',
  MONTHLY_AGGREGATIONS = 'monthly_aggregations',
  SUMMARY_AGGREGATIONS = 'summary_aggregations',
  PY_INVOICE_PREDICTIONS = 'py_invoice_predictions',
  ROLES = 'roles',
  TRANSACTIONS = 'transactions',
  PAYOUT = 'payouts',
  TC_USER = 'truecaller_users',
  TARGETS = 'targets',
}
