import IconStyles from 'apps/webapp/src/types/iconStyles';

const DownloadIcon = (styles: IconStyles) => {
  const color = styles.color || '#6461F3';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        d='M1.30413 8.32434C1.30413 8.02207 1.0591 7.77704 0.756836 7.77704C0.454572 7.77704 0.209539 8.02207 0.209539 8.32434H1.30413ZM0.756836 9.05407H0.209539H0.756836ZM11.5203 8.32434C11.5203 8.02207 11.2753 7.77704 10.9731 7.77704C10.6708 7.77704 10.4258 8.02207 10.4258 8.32434H11.5203ZM5.43758 8.71217C5.24875 8.9482 5.28702 9.29261 5.52305 9.48143C5.75908 9.67026 6.10349 9.63199 6.29231 9.39596L5.43758 8.71217ZM9.21123 5.74731C9.40005 5.51128 9.36178 5.16687 9.12576 4.97805C8.88973 4.78923 8.54532 4.82749 8.3565 5.06352L9.21123 5.74731ZM5.43758 9.39596C5.6264 9.63199 5.97081 9.67026 6.20684 9.48143C6.44287 9.29261 6.48113 8.9482 6.29231 8.71217L5.43758 9.39596ZM3.37339 5.06352C3.18457 4.82749 2.84016 4.78923 2.60413 4.97805C2.3681 5.16687 2.32983 5.51128 2.51866 5.74731L3.37339 5.06352ZM5.31765 9.05407C5.31765 9.35633 5.56268 9.60136 5.86494 9.60136C6.16721 9.60136 6.41224 9.35633 6.41224 9.05407H5.31765ZM6.41224 1.02704C6.41224 0.724775 6.16721 0.479741 5.86494 0.479741C5.56268 0.479741 5.31765 0.724775 5.31765 1.02704H6.41224ZM0.209539 8.32434V9.05407H1.30413V8.32434H0.209539ZM0.209539 9.05407C0.209539 10.5654 1.43471 11.7906 2.94603 11.7906V10.696C2.03923 10.696 1.30413 9.96086 1.30413 9.05407H0.209539ZM2.94603 11.7906H8.78386V10.696H2.94603V11.7906ZM8.78386 11.7906C10.2952 11.7906 11.5203 10.5654 11.5203 9.05407H10.4258C10.4258 9.96086 9.69065 10.696 8.78386 10.696V11.7906ZM11.5203 9.05407V8.32434H10.4258V9.05407H11.5203ZM6.29231 9.39596L9.21123 5.74731L8.3565 5.06352L5.43758 8.71217L6.29231 9.39596ZM6.29231 8.71217L3.37339 5.06352L2.51866 5.74731L5.43758 9.39596L6.29231 8.71217ZM6.41224 9.05407V1.02704H5.31765V9.05407H6.41224Z'
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
