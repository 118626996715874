import IconStyles from 'apps/webapp/src/types/iconStyles';

const ReviewIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.11817 1.07142H8.11432C8.15582 1.07142 8.19708 1.07407 8.2381 1.07938C9.86474 1.19313 11.1278 2.54306 11.1333 4.17366V8.82704C11.1273 10.544 9.73211 11.9318 8.01516 11.9286H4.11817C2.40094 11.9318 1.00559 10.5435 1 8.82631V4.17366C1.00559 2.45644 2.40094 1.06821 4.11817 1.07142Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.41216 3.33623L3.33296 4.2048C3.22872 4.30414 3.17008 4.4421 3.17091 4.5861C3.17173 4.7301 3.23193 4.86738 3.33731 4.96552L4.03434 5.80442L6.94839 9.30838C6.99697 9.36481 7.06203 9.40456 7.13441 9.42202L8.58203 9.7528C8.77344 9.76364 8.9401 9.62333 8.96203 9.43288L8.89472 8.02434C8.88974 7.95204 8.86069 7.88349 8.8122 7.82964L5.96546 4.40602L5.19822 3.48461C5.01351 3.2401 4.6733 3.17588 4.41216 3.33623Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1334 4.70258C11.4095 4.70258 11.6334 4.47873 11.6334 4.20258C11.6334 3.92644 11.4095 3.70258 11.1334 3.70258V4.70258ZM8.23814 4.20258H7.73814C7.73814 4.47873 7.962 4.70258 8.23814 4.70258V4.20258ZM8.73814 1.07935C8.73814 0.803203 8.51428 0.579346 8.23814 0.579346C7.962 0.579346 7.73814 0.803203 7.73814 1.07935H8.73814ZM6.45789 4.49465C6.50528 4.2226 6.32316 3.96365 6.05111 3.91626C5.77907 3.86887 5.52011 4.05099 5.47272 4.32303L6.45789 4.49465ZM4.10805 5.30766C3.83494 5.26686 3.58047 5.45519 3.53967 5.7283C3.49887 6.00141 3.6872 6.25589 3.96031 6.29669L4.10805 5.30766ZM11.1334 3.70258H8.23814V4.70258H11.1334V3.70258ZM8.73814 4.20258V1.07935H7.73814V4.20258H8.73814ZM5.47272 4.32303C5.3605 4.96725 4.75479 5.40428 4.10805 5.30766L3.96031 6.29669C5.14396 6.47351 6.2525 5.67368 6.45789 4.49465L5.47272 4.32303Z'
        fill={color}
      />
    </svg>
  );
};

export default ReviewIcon;
