import { MESSAGE_TRANSACTION_TYPE, MessageType } from '@finance-ops/types';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '.';
import { httpGet } from '../httpClient';
import api from '../httpClient/api';
import { createCachedSelector } from 're-reselect';

interface MessageStateItems {
  initIds: string[];
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  filteredMessages: MessageType[];
  loading: boolean;
  suggestion: string;
  selectedAction: string;
}

interface MessageState extends EntityState<MessageType>, MessageStateItems {}

const messageEntityAdapter = createEntityAdapter<MessageType>({
  selectId: entity => entity._id,
  sortComparer: (a, b) => new Date(a.messageAt).getTime() - new Date(b.messageAt).getTime(),
});

const initialState: MessageState = {
  ...messageEntityAdapter.getInitialState(),
  initIds: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
  },
  filteredMessages: [],
  loading: false,
  suggestion: '',
  selectedAction: '',
};

export const getMessages = createAsyncThunk(
  'messages/fetch',
  async ({ id, startDate, endDate }: { id: string; startDate?: Date; endDate?: Date }) => {
    const args =
      startDate && endDate ? `/${id}?startDate=${startDate?.toISOString()}&endDate=${endDate.toISOString()}` : `/${id}`;
    const apiUrl = api.getChats + args;
    const response = await httpGet(apiUrl);
    return response.data;
  },
);

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setAllMessages: messageEntityAdapter.setAll,
    upsertManyMessages: messageEntityAdapter.upsertMany,
    upsertOneMessage: messageEntityAdapter.upsertOne,
    updateOneMessage: messageEntityAdapter.updateOne,
    setChatPagination: (state, action: PayloadAction<any>) => {
      state.pagination = action.payload;
    },
    setSuggestion: (state, action: PayloadAction<string>) => {
      state.suggestion = action.payload;
    },
    clearSuggestion: state => {
      state.suggestion = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getMessages.pending, state => {
      state.loading = true;
    });
    builder.addCase(getMessages.fulfilled, (state, action: PayloadAction<MessageType[]>) => {
      state.loading = false;
      state.filteredMessages = action.payload;
    });
    builder.addCase(getMessages.rejected, state => {
      state.loading = false;
    });
  },
});

export const messageSelectors = messageEntityAdapter.getSelectors((state: RootState) => state.message);

export const selectMessagesByCounterPartyId = createCachedSelector(
  [messageSelectors.selectAll, (_: any, counterPartyId: any) => counterPartyId],
  (messages, counterPartyId) => {
    const msg: MessageType[] = messages
      .filter(message => message !== undefined && message.counterPartyId === counterPartyId)
      .sort((a, b) => new Date(a.messageAt).getTime() - new Date(b.messageAt).getTime());
    return msg ? msg : [];
  },
)((_, counterPartyId) => counterPartyId);

export const selectMessageById = createSelector(
  [(state: RootState) => state.message.entities, (_, messageId) => messageId],
  (entities, messageId) => (entities ? entities[messageId] : undefined),
);

export const selectlastInboundMessageByCounterPartyId = createSelector(
  [messageSelectors.selectAll, (_, counterPartyId) => counterPartyId],
  (messages, counterPartyId) => {
    const inboundMessages: MessageType[] = messages.filter(
      message =>
        message !== undefined &&
        message.counterPartyId === counterPartyId &&
        message.type === MESSAGE_TRANSACTION_TYPE.INBOUND,
    );
    const lastInboundMessage = inboundMessages[inboundMessages.length - 1];
    return lastInboundMessage;
  },
);

export const {
  setAllMessages,
  upsertManyMessages,
  upsertOneMessage,
  updateOneMessage,
  setChatPagination,
  setSuggestion,
  clearSuggestion,
} = messageSlice.actions;

export default messageSlice.reducer;
