const LaptopIcon = () => {
  return (
    <svg width='40' height='27' viewBox='0 0 40 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.3333 23.3333C35.1667 23.3333 36.6667 21.8333 36.6667 20V3.33333C36.6667 1.5 35.1667 0 33.3333 0H6.66667C4.83333 0 3.33333 1.5 3.33333 3.33333V20C3.33333 21.8333 4.83333 23.3333 6.66667 23.3333H0V26.6667H40V23.3333H33.3333ZM6.66667 3.33333H33.3333V20H6.66667V3.33333Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default LaptopIcon;
