export enum WebSocketMessageType {
  CLIENT_GET_ALL = 'CLIENT_GET_ALL',
  CLIENT_GET_BY_ID = 'CLIENT_GET_BY_ID',
  CREATE_ACTION = 'CREATE_ACTION',
  CREATE_COMMENT = 'CREATE_COMMENT',
  CUSTOMER_CONTEXT_CLONE = 'CUSTOMER_CONTEXT_CLONE',
  CUSTOMER_CONTEXT_GET = 'CUSTOMER_CONTEXT_GET',
  CUSTOMER_CONTEXT_GET_BY_IDS = 'CUSTOMER_CONTEXT_GET_BY_IDS',
  CUSTOMER_CONTEXT_GET_FOR_ROUTED = 'CUSTOMER_CONTEXT_GET_FOR_ROUTED',
  CUSTOMER_GET_BY_ID = 'CUSTOMER_GET_BY_ID',
  CUSTOMER_UPDATE_CONTACT_STATUS = 'CUSTOMER_UPDATE_CONTACT_STATUS',
  CUSTOMER_UPDATE_DND = 'CUSTOMER_UPDATE_DND',
  CUSTOMER_UPDATE_LANGUAGE = 'CUSTOMER_UPDATE_LANGUAGE',
  CUSTOMER_UPDATE_STATUS = 'CUSTOMER_UPDATE_STATUS',
  DASHBOARD_GET_TEAM_OVERVIEW_GRAPH = 'DASHBOARD_GET_TEAM_OVERVIEW_GRAPH',
  DELETE_ABILITY = 'DELETE_ABILITY',
  DELETE_USER = 'DELETE_USER',
  GET_ABILITIES = 'GET_ABILITIES',
  GET_ACTIONS_BY_CUSTOMER = 'GET_ACTIONS_BY_CUSTOMER',
  GET_ACTIONS_BY_CUSTOMER_IDS = 'GET_ACTIONS_BY_CUSTOMER_IDS',
  GET_ACTIONS_FOR_ACTION_CENTER = 'GET_ACTIONS_FOR_ACTION_CENTER',
  GET_COMMENTS_BY_REFERENCE_ID = 'GET_COMMENTS_BY_REFERENCE_ID',
  GET_COUNTER_PARTY_BY_PARENT_ID = 'GET_COUNTER_PARTY_BY_PARENT_ID',
  GET_COUNTERPARTY_AMOUNT_GRAPH_DATA = 'GET_COUNTERPARTY_AMOUNT_GRAPH_DATA',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_DASHBOARD_AGG = 'GET_DASHBOARD_AGG',
  GET_DASHBOARD_GRAPH = 'GET_DASHBOARD_GRAPH',
  GET_DASHBOARD_SUMMARY = 'GET_DASHBOARD_SUMMARY',
  GET_DASHBOARD_SYNC = 'GET_DASHBOARD_SYNC',
  GET_DASHBOARD_TYPE_COUNTS = 'GET_DASHBOARD_TYPE_COUNTS',
  GET_EMAIL_ATTACHMENT = 'GET_EMAIL_ATTACHMENT',
  GET_EMAIL_BY_CUSTOMER_IDS = 'GET_EMAIL_BY_CUSTOMER_IDS',
  GET_GENERAL_SETTINGS = 'GET_GENERAL_SETTINGS',
  GET_INVOICES_FOR_INVOICE_PAGE = 'GET_INVOICES_FOR_INVOICE_PAGE',
  GET_P2PS = 'GET_P2PS',
  GET_PERSONAL_SETTINGS = 'GET_PERSONAL_SETTINGS',
  GET_QUICKSIGHT_DASHBOARD_MENU = 'GET_QUICKSIGHT_DASHBOARD_MENU',
  GET_QUICKSIGHT_EMBED_URL = 'GET_QUICKSIGHT_EMBED_URL',
  GET_REPORT_BY_DATE = 'GET_REPORT_BY_DATE',
  GET_RESPECTIVE_USERS = 'GET_RESPECTIVE_USERS',
  GET_ROLES = 'GET_ROLES',
  GET_SPECIFIC_USER = 'GET_SPECIFIC_USER',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  HISTORY_LOGS_GET_CUSTOMER_ID = 'HISTORY_LOGS_GET_CUSTOMER_ID',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  MANAGE_ABILITIES = 'MANAGE_ABILITIES',
  MANAGE_GENERAL_SETTINGS = 'MANAGE_GENERAL_SETTINGS',
  MANAGE_PERSONAL_SETTINGS = 'MANAGE_PERSONAL_SETTINGS',
  MANAGE_USER = 'MANAGE_USER',
  MESSAGE_DELIVERY_STATUS = 'MESSAGE_DELIVERY_STATUS',
  MESSAGE_GET_BY_CUSTOMER_ID = 'MESSAGE_GET_BY_CUSTOMER_ID',
  MESSAGE_GET_BY_CUSTOMER_IDS = 'MESSAGE_GET_BY_CUSTOMER_IDS',
  MESSAGE_RECEIVE_PLAYGROUND = 'MESSAGE_RECEIVE_PLAYGROUND',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_SEND = 'MESSAGE_SEND',
  MESSAGE_SEND_PLAYGROUND = 'MESSAGE_SEND_PLAYGROUND',
  MESSAGE_SEND_RETRY = 'MESSAGE_SEND_RETRY',
  MESSAGE_SEND_WHATSAPP_TEMPLATE = 'MESSAGE_SEND_WHATSAPP_TEMPLATE',
  MESSAGE_SENT_STATUS = 'MESSAGE_SENT_STATUS',
  MESSAGE_UPDATE = 'MESSAGE_UPDATE',
  MULTI_TASK_UPDATE_ASSIGN = 'MULTI_TASK_UPDATE_ASSIGN',
  NOTE_CREATE_CLIENT = 'NOTE_CREATE_CLIENT',
  NOTE_CREATE_INFO = 'NOTE_CREATE_INFO',
  NOTE_CREATE_INTERNAL = 'NOTE_CREATE_INTERNAL',
  NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
  NOTIFICATION_UPDATE_MANY_READ = 'NOTIFICATION_UPDATE_MANY_READ',
  NOTIFICATION_UPDATE_READ = 'NOTIFICATION_UPDATE_READ',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  READ_EMAIL = 'READ_EMAIL',
  RESET_DEMO_CUSTOMER = 'RESET_DEMO_CUSTOMER',
  SEARCH_CUSTOMER_TASKS = 'SEARCH_CUSTOMER_TASKS',
  SEND_EMAIL = 'SEND_EMAIL',
  SERVER_ERROR = 'SERVER_ERROR',
  SQS_ERROR_DISCARD = 'SQS_ERROR_DISCARD',
  SQS_ERROR_GET_ALL = 'SQS_ERROR_GET_ALL',
  SQS_ERROR_PROCESS = 'SQS_ERROR_PROCESS',
  STRIPE_PAYMENT = 'STRIPE_PAYMENT',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_PATCH = 'TASK_PATCH',
  TASK_UPDATE_MODE = 'TASK_UPDATE_MODE',
  TASK_UPDATE_MULTI = 'TASK_UPDATE_MULTI',
  TASK_UPDATE_STATE = 'TASK_UPDATE_STATE',
  TASK_UPDATE_UNASSIGN = 'TASK_UPDATE_UNASSIGN',
  TEAM_OVERVIEW_GET_AGENT_COLLECTION_PROFILE = 'TEAM_OVERVIEW_GET_AGENT_COLLECTION_PROFILE',
  TEAM_OVERVIEW_GET_AGENT_STATISTICS = 'TEAM_OVERVIEW_GET_AGENT_STATISTICS',
  TRANSLATION_REQUEST = 'TRANSLATION_REQUEST',
  UPDATE_ACTION = 'UPDATE_ACTION',
  UPDATE_ACTION_STATUS = 'UPDATE_ACTION_STATUS',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  UPDATE_ESCALATION = 'UPDATE_ESCALATION',
  WHATSAPP_TEMPLATE_GET_ALL = 'WHATSAPP_TEMPLATE_GET_ALL',
  DELETE_COMMENT = 'DELETE_COMMENT',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  TRANSACTION_ATTACHMENT_UPLOAD = 'TRANSACTION_ATTACHMENT_UPLOAD',
}
