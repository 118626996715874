import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { httpGet } from '../../../../../httpClient';

function Version() {
  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    httpGet('/webapp/health', {}, {}, true).then(response => {
      setVersion(response.data.version);
    });
  }, [version]);

  return <Typography>v{version}</Typography>;
}

export default Version;
