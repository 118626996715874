import { GenericObject } from '@finance-ops/types';
import { LocalStorageKeys } from '../../constants/localStorageKeys';

const useLocalStorageDashboardState = () => {
  const setLocalStorageDashboardState = (value: GenericObject) => {
    try {
      const previousValue = localStorage.getItem(LocalStorageKeys.DASHBOARD_STATE);
      if (previousValue) {
        localStorage.setItem(
          LocalStorageKeys.DASHBOARD_STATE,
          JSON.stringify({ ...JSON.parse(previousValue), ...value }),
        );
      }
    } catch (error) {
      localStorage.setItem(LocalStorageKeys.DASHBOARD_STATE, JSON.stringify({}));
      console.error(error);
    }
  };
  const getLocalStorageDashboardState = () => {
    if (localStorage.getItem(LocalStorageKeys.DASHBOARD_STATE)) {
      return JSON.parse(localStorage.getItem(LocalStorageKeys.DASHBOARD_STATE) as string);
    }
    localStorage.setItem(LocalStorageKeys.DASHBOARD_STATE, '{}');
    return {};
  };

  const resetLocastorageDashboardState = () => {
    localStorage.removeItem(LocalStorageKeys.DASHBOARD_STATE);
  };

  return {
    setLocalStorageDashboardState,
    getLocalStorageDashboardState,
    resetLocastorageDashboardState,
  };
};
export default useLocalStorageDashboardState;
