import { SMS_PROVIDER } from '../client';
import { MESSAGE_CHANNEL } from './MessageType';

export const getMessageChannel = (smsProvider: SMS_PROVIDER): MESSAGE_CHANNEL => {
  switch (smsProvider) {
    case SMS_PROVIDER.TWILIO:
    case SMS_PROVIDER.TEXTGRID:
    case SMS_PROVIDER.INTERNAL_SMS_PROVIDER:
      return MESSAGE_CHANNEL.SMS;
    case SMS_PROVIDER.WHATSAPP_META:
      return MESSAGE_CHANNEL.WHATSAPP;
    default:
      return MESSAGE_CHANNEL.SMS;
  }
};
