// ** Type Imports
import { PaletteMode } from '@mui/material';
import { Skin } from 'apps/webapp/src/@core/layouts/types';

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  // ** Vars
  const whiteColor = '#FFF';
  const blackColor = '#000';
  const lightColor = '31, 25, 51';
  const darkColor = '228, 230, 244';
  const darkPaperBgColor = '#25293C';
  const mainColor = mode === 'light' ? lightColor : darkColor;

  const defaultBgColor = () => {
    if (mode === 'light') {
      return whiteColor;
    } else {
      return darkPaperBgColor;
    }
  };

  return {
    customColors: {
      dark: darkColor,
      main: 'rgb(31, 25, 51)',
      // light: lightColor,
      lightPaperBg: whiteColor,
      darkPaperBg: darkPaperBgColor,
      bodyBg: mode === 'light' ? '#F8F7FA' : '#25293C', // Same as palette.background.default but doesn't consider bordered skin
      trackBg: mode === 'light' ? '#F1F0F2' : '#3B405B',
      avatarBg: mode === 'light' ? '#F6F6F7' : '#4A5072',
      tableHeaderBg: mode === 'light' ? '#F6F6F7' : '#4A5072',
      headingPrimary: '#141414',
      boldText: '#1E1E1E',
      themeColorDark: '#B3B2FF',
      themeColorLight: '#CECDFF',
      themeColorLighter: '#F5F4FF',
      themeColorBg: '#e9e9ffb3',
      lightGreen: '#B4E5BC',
      darkGreen: '#2EB001',
      darkGreenPastel: '#7F9F80',
      brownPastel: '#672d2da3',
      lightGreenPastel: '#CBFFB9FF',
      lightGrey: '#F1F1F1',
      grey: '#888',
      darkOrange: '#DC7961',
      darkBrown: '#6F6F6F',
      green: '#269200',
      lightBg: '#F4F4F4',
      lightGreyBg: '#F8F8F8',
      purple: '#E25CC4',
      lightPink: '#FFD7F6',
      darkPink: '#E052C2',
      redPastel: '#F09EA7',
      redPastelLight: '#FFC9BC',
      orangePastel: '#ffe4c9',
      yellowPastel: '#ffffd8',
      yellowDark: '#4d3800',
      greenPastel: '#d7ffd6',
      bluePastel: '#d1d3ff',
      purplePastel: '#eddbff',
      pinkPastel: '#ffd1fb',
    },
    mode: mode,
    common: {
      black: '#000',
      white: whiteColor,
    },
    primary: {
      lighter: '#E6E5FA',
      light: '#B8B7FF',
      main: '#6461F3',
      dark: '#1F1933',
      contrastText: whiteColor,
    },
    secondary: {
      light: '#B2B4B8',
      main: '#A8AAAE',
      dark: '#949699',
      contrastText: whiteColor,
    },
    error: {
      light: '#FA9D9D', // NEW
      main: '#FC1414', // NEW
      dark: '#FC1414', // NEW
      contrastText: whiteColor,
    },
    warning: {
      light: '#FFEDD4',
      main: '#FFB800',
      dark: '#FFB800',
      contrastText: whiteColor,
      lightShade: '#FFE6C3',
    },
    info: {
      light: '#1FD5EB',
      main: '#D9D9D9', // NEW
      dark: '#6F6F6F', // NEW
      contrastText: whiteColor,
    },
    success: {
      light: '#C4F2B4', // NEW
      main: '#2EB001', // NEW
      dark: '#269200', // NEW
      contrastText: whiteColor,
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161',
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.6)`,
      disabled: `rgba(${mainColor}, 0.38)`,
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? whiteColor : darkPaperBgColor,
      default: defaultBgColor(),
      light: '#F4F4F4',
      lightGrey: '#F8F8F8',
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
    border: {
      main: '#DEDEDE',
    },
    status: {
      error: '#FFC9BC',
      warning: '#FFE6C3',
      success: '#C3FFAD',
      errorTextShade: '#DB0000',
      warningTextShade: '#FF970C',
      successTextShade: '#2EB001',
      default: '#DADADA',
      defaultTextShade: '#1E1E1E',
    },
  };
};

export default DefaultPalette;
