// ** React Imports
import { useEffect, useState } from 'react';

// ** Type Import
import { VerticalNavItemsType } from 'apps/webapp/src/@core/layouts/types';
import { httpGet } from 'apps/webapp/src/httpClient';
import navigation from 'apps/webapp/src/navigation/vertical';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { defaultPermission } from 'apps/webapp/src/configs/acl';

const ServerSideNavItems = () => {
  // ** State
  const { user } = useAuth();
  const [menuItems, setMenuItems] = useState<VerticalNavItemsType>(navigation());

  useEffect(() => {
    httpGet(process.env.NEXT_PUBLIC_BASE_URL + '/getQuicksightLinks').then(async res => {
      const navItems: VerticalNavItemsType = [...menuItems];
      const quickSightKeys = res.data;

      navItems.push({
        title: 'Insights',
        icon: 'tabler:report-analytics',
        children: quickSightKeys.map(({ client, name }: { client: string; name: string }) => {
          return {
            title: user?.role?.isInternal ? `${client} - ${name}` : name,
            path: `insight?client=${client}&name=${name}`,
            icon: 'tabler:chart-pie-2',
            permission: defaultPermission,
          };
        }),
        permission: defaultPermission,
      });

      setMenuItems(navItems);
    });
  }, []);

  return { menuItems };
};

export default ServerSideNavItems;
