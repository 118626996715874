import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { PaymentPlanType } from '../../../../libs/types/src/paymentPlan/paymentPlan';

const paymentPlansEntityAdapter = createEntityAdapter<PaymentPlanType>({
  selectId: entity => entity?._id,
});

interface PaymentPlanState extends EntityState<PaymentPlanType> {
  selectedActionId: string;
  loading: boolean;
}

const initialState: PaymentPlanState = {
  ...paymentPlansEntityAdapter.getInitialState(),
  selectedActionId: '',
  loading: false,
};

const paymentPlansSlice = createSlice({
  name: 'paymentPlans',
  initialState,
  reducers: {
    setPaymentPlan: paymentPlansEntityAdapter.setAll,
    deleteOnePaymentPlan: paymentPlansEntityAdapter.removeOne,
    upsertOnePaymentPlan: paymentPlansEntityAdapter.upsertOne,
    upsertManyPaymentPlan: (state, action: PayloadAction<PaymentPlanType[]>) => {
      paymentPlansEntityAdapter.upsertMany(state, action.payload);
      state.loading = false;
    },
    updateOnePaymentPlan: paymentPlansEntityAdapter.updateOne,
    setSelectedPaymentPlanId: (state, action: PayloadAction<string>) => {
      state.selectedActionId = action.payload;
    },
    setPaymentPlanLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const paymentPlanSelectors = paymentPlansEntityAdapter.getSelectors((state: RootState) => state.paymentPlans);
export const loadingPaymentPlan = (state: RootState) => state.actions.loading;

export const selectPaymentPlanByCustomerId = createSelector(
  [paymentPlanSelectors.selectAll, (_, id) => id],
  (actions, id) => {
    return actions.filter(action => action.customerId === id);
  },
);

export const selectPaymentPlan = createSelector([paymentPlanSelectors.selectAll, (_, id) => id], (plans, id) =>
  plans.filter(plan => plan._id === id),
);

export const selectAllPaymentPlans = paymentPlanSelectors.selectAll;

export const {
  setPaymentPlan,
  deleteOnePaymentPlan,
  upsertOnePaymentPlan,
  upsertManyPaymentPlan,
  updateOnePaymentPlan,
  setPaymentPlanLoading,
  setSelectedPaymentPlanId,
} = paymentPlansSlice.actions;

export default paymentPlansSlice.reducer;
