// ** Next Import
import Link from 'next/link';
import Image from 'next/image';

// ** MUI Imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// ** Type Import
import { LayoutProps } from 'apps/webapp/src/@core/layouts/types';

// ** Theme Config Import
import themeConfig from 'apps/webapp/src/configs/themeConfig';
import logo from 'apps/webapp/public/images/logos/logo.png';
import logo2 from 'apps/webapp/public/images/logos/logo2.png';

interface Props {
  hidden: LayoutProps['hidden'];
  settings: LayoutProps['settings'];
  saveSettings: LayoutProps['saveSettings'];
  appBarContent: NonNullable<NonNullable<LayoutProps['horizontalLayoutProps']>['appBar']>['content'];
  appBarBranding: NonNullable<NonNullable<LayoutProps['horizontalLayoutProps']>['appBar']>['branding'];
}

const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(8),
}));

const AppBarContent = (props: Props) => {
  // ** Props
  const { appBarContent: userAppBarContent, appBarBranding: userAppBarBranding } = props;

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {userAppBarBranding ? (
        userAppBarBranding(props)
      ) : (
        <LinkStyled href='/'>
          <Image src={themeConfig.templateName === 'ZellaFinance' ? logo2 : logo} alt={'Logo'} width={30} />
          <Typography
            variant='h6'
            sx={{
              ml: 2.5,
              fontWeight: 600,
              lineHeight: '24px',
              fontSize: '1.375rem !important',
            }}
          >
            {themeConfig.templateName}
          </Typography>
        </LinkStyled>
      )}
      {userAppBarContent ? userAppBarContent(props) : null}
    </Box>
  );
};

export default AppBarContent;
