const WorkFlowIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='43' height='55' viewBox='0 0 43 55' fill='none'>
      <g opacity='0.25'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1 6.85714C1 3.62233 3.62233 1 6.85714 1C10.092 1 12.7143 3.62233 12.7143 6.85714C12.7143 10.092 10.092 12.7143 6.85714 12.7143C5.30373 12.7143 3.81394 12.0972 2.71552 10.9988C1.61709 9.90034 1 8.41055 1 6.85714Z'
          stroke='#6461F3'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.2856 6.85714C30.2856 3.62233 32.908 1 36.1428 1C39.3776 1 41.9999 3.62233 41.9999 6.85714C41.9999 10.092 39.3776 12.7143 36.1428 12.7143C32.908 12.7143 30.2856 10.092 30.2856 6.85714Z'
          stroke='#6461F3'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.6431 47.8571C15.6431 44.6223 18.2654 42 21.5002 42C24.735 42 27.3574 44.6223 27.3574 47.8571C27.3574 51.092 24.735 53.7143 21.5002 53.7143C19.9468 53.7143 18.457 53.0972 17.3586 51.9988C16.2602 50.9003 15.6431 49.4106 15.6431 47.8571Z'
          stroke='#6461F3'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.607 12.7144C7.607 12.3001 7.27121 11.9644 6.857 11.9644C6.44278 11.9644 6.107 12.3001 6.107 12.7144H7.607ZM6.857 21.5879L7.607 21.591V21.5879H6.857ZM8.18364 25.8314L8.80244 25.4076L8.79833 25.4017L8.18364 25.8314ZM13.5517 28.6868L13.5461 29.4368H13.5517V28.6868ZM29.448 28.6868V29.4368C29.4555 29.4368 29.4631 29.4367 29.4706 29.4364L29.448 28.6868ZM36.1427 21.5879H35.3927C35.3927 21.595 35.3928 21.6022 35.393 21.6093L36.1427 21.5879ZM36.8927 12.7144C36.8927 12.3001 36.5569 11.9644 36.1427 11.9644C35.7285 11.9644 35.3927 12.3001 35.3927 12.7144H36.8927ZM20.75 42.0003C20.75 42.4145 21.0858 42.7503 21.5 42.7503C21.9142 42.7503 22.25 42.4145 22.25 42.0003H20.75ZM22.25 28.6899C22.25 28.2757 21.9142 27.9399 21.5 27.9399C21.0858 27.9399 20.75 28.2757 20.75 28.6899H22.25ZM6.107 12.7144V21.5879H7.607V12.7144H6.107ZM6.107 21.5848C6.10014 23.2571 6.6108 24.8906 7.56895 26.2611L8.79833 25.4017C8.01753 24.2848 7.6014 22.9537 7.60699 21.591L6.107 21.5848ZM7.56487 26.2552C8.91788 28.2306 11.1519 29.4189 13.5461 29.4368L13.5573 27.9368C11.654 27.9226 9.878 26.978 8.80241 25.4076L7.56487 26.2552ZM13.5517 29.4368H29.448V27.9368H13.5517V29.4368ZM29.4706 29.4364C33.6917 29.3092 37.0125 25.7879 36.8924 21.5666L35.393 21.6093C35.4896 25.0035 32.8194 27.8348 29.4254 27.9371L29.4706 29.4364ZM36.8927 21.5879V12.7144H35.3927V21.5879H36.8927ZM22.25 42.0003V28.6899H20.75V42.0003H22.25Z'
          fill='#6461F3'
        />
      </g>
    </svg>
  );
};

export default WorkFlowIcon;
