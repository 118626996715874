// ** React Imports
import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

const CustomerLayout = ({ children }: Props) => {
  return <Box sx={{ height: '100vh' }}>{children}</Box>;
};

export default CustomerLayout;
