import { ActionGetCountsResponseType, ActionsExtensionType, FiltersForActionsType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { stringify } from 'qs';
import { baseQuery } from './baseQuery';

export const actionsApi = createApi({
  reducerPath: 'actionsApi',
  baseQuery: baseQuery,
  tagTypes: ['Actions', 'ActionsCount'],
  endpoints: builder => ({
    getActionById: builder.query<ActionsExtensionType, string>({
      query: id => `/v2/actions/${id}`,
      providesTags: ['Actions'],
    }),

    getActions: builder.query<ActionsExtensionType[], FiltersForActionsType>({
      query: ({ params, query, priority, status, assignedUserId, clientId }: FiltersForActionsType) => {
        const queryParams = stringify({ params, query, priority, status, assignedUserId, clientId });
        return {
          url: `/v2/actions?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['Actions'],
    }),

    getActionCounts: builder.query<ActionGetCountsResponseType[], FiltersForActionsType>({
      query: ({ params, query, priority, status, assignedUserId, clientId }: FiltersForActionsType) => {
        const queryParams = stringify({ params, query, priority, status, assignedUserId, clientId });
        return {
          url: `/v2/actions/counts?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['ActionsCount'],
    }),
  }),
});

export const { useGetActionsQuery, useGetActionCountsQuery, useGetActionByIdQuery } = actionsApi;
