import { ClientType } from '@finance-ops/types';
import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface ClientStateItems {
  selectedClientId: string | null;
}

interface ClientState extends EntityState<ClientType>, ClientStateItems {}

const clientEntityAdapter = createEntityAdapter<ClientType>({
  selectId: entity => entity.id,
});

const initialState: ClientState = {
  ...clientEntityAdapter.getInitialState(),
  selectedClientId: '',
};

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    setAllClients: clientEntityAdapter.setAll,
    upsertOneClient: clientEntityAdapter.upsertOne,
    upsertManyClients: clientEntityAdapter.upsertMany,
    setSelectedClientId: (state, action: PayloadAction<string | null>) => {
      state.selectedClientId = action.payload;
    },
    resetClients: () => {
      return initialState;
    },
  },
});

export const clientSelectors = clientEntityAdapter.getSelectors<RootState>(state => state.client);

export const selectedClient = createSelector([clientSelectors.selectAll, (_, id) => id], (clients, clientId) => {
  if (!clientId) {
    return undefined;
  } else {
    return clients.find(client => client.id === clientId);
  }
});

export const getSelectedClientDetails = createSelector(
  (state: RootState) => state.client.entities,
  (state: RootState) => state.client.selectedClientId,
  (entities, selectedClientId) => {
    if (selectedClientId) return entities[selectedClientId];
    return undefined;
  },
);

export const { setAllClients, setSelectedClientId, upsertOneClient, upsertManyClients, resetClients } =
  clientSlice.actions;

export default clientSlice.reducer;
