import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { SQSMessageType } from '@finance-ops/types';
import { RootState } from '.';

const defaultPaginationState = {
  page: 0,
  pageSize: 100,
};

export type SQSMessagePaginationType = typeof defaultPaginationState;

interface SQSMessageStateItems {
  loading: boolean;
  totalCount: number;
  pagination: SQSMessagePaginationType;
}

interface SQSMessageState extends EntityState<SQSMessageType>, SQSMessageStateItems {}

const queueEntityAdapter = createEntityAdapter<SQSMessageType>({
  selectId: entity => entity._id,
});

const initialState: SQSMessageState = {
  ...queueEntityAdapter.getInitialState(),
  loading: false,
  totalCount: 100,
  pagination: defaultPaginationState,
};

const sqsMessage = createSlice({
  name: 'sqsMessage',
  initialState,
  reducers: {
    setSqsMessages: (state, action) => {
      queueEntityAdapter.setAll(state, action.payload);
      state.loading = false;
    },
    setSqsMessagesTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    deleteOneSqsMessages: queueEntityAdapter.removeOne,
    upsertOneSqsMessage: (state, action) => {
      if (action.payload) queueEntityAdapter.upsertOne(state, action.payload);
      state.loading = false;
    },
    upsertManySqsMessages: (state, action) => {
      queueEntityAdapter.upsertMany(state, action.payload);
      state.loading = false;
    },
    setLoadingSQSMessages: (state, action) => {
      state.loading = action.payload;
    },
    setSQSMessagesPagination: (state: SQSMessageState, action: PayloadAction<SQSMessagePaginationType>) => {
      state.pagination = action.payload;
    },
  },
});

export const sqsMessageSelectors = queueEntityAdapter.getSelectors((state: RootState) => state.sqsMessage);

export const {
  setSqsMessages,
  deleteOneSqsMessages,
  upsertOneSqsMessage,
  upsertManySqsMessages,
  setLoadingSQSMessages,
  setSqsMessagesTotalCount,
  setSQSMessagesPagination,
} = sqsMessage.actions;

export default sqsMessage.reducer;
