const TrendUpIcon = () => {
  return (
    <svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.71428 0.314328C8.38291 0.314328 8.11428 0.582957 8.11428 0.914328C8.11428 1.2457 8.38291 1.51433 8.71428 1.51433V0.314328ZM11.8 1.51433C12.1314 1.51433 12.4 1.2457 12.4 0.914328C12.4 0.582957 12.1314 0.314328 11.8 0.314328V1.51433ZM12.4 0.914307C12.4 0.582936 12.1314 0.314307 11.8 0.314307C11.4686 0.314307 11.2 0.582936 11.2 0.914307H12.4ZM11.2 4.00002C11.2 4.33139 11.4686 4.60002 11.8 4.60002C12.1314 4.60002 12.4 4.33139 12.4 4.00002H11.2ZM12.2243 1.33857C12.4586 1.10426 12.4586 0.724357 12.2243 0.490043C11.9899 0.255728 11.6101 0.255728 11.3757 0.490043L12.2243 1.33857ZM6.4 6.31431L5.97574 6.73857C6.21005 6.97289 6.58995 6.97289 6.82426 6.73857L6.4 6.31431ZM4.08571 4.00002L4.50998 3.57576C4.27566 3.34144 3.89576 3.34144 3.66145 3.57576L4.08571 4.00002ZM0.575736 6.66147C0.341421 6.89579 0.341421 7.27568 0.575736 7.51C0.810051 7.74431 1.18995 7.74431 1.42426 7.51L0.575736 6.66147ZM8.71428 1.51433H11.8V0.314328H8.71428V1.51433ZM11.2 0.914307V4.00002H12.4V0.914307H11.2ZM11.3757 0.490043L5.97574 5.89004L6.82426 6.73857L12.2243 1.33857L11.3757 0.490043ZM6.82426 5.89004L4.50998 3.57576L3.66145 4.42429L5.97574 6.73857L6.82426 5.89004ZM3.66145 3.57576L0.575736 6.66147L1.42426 7.51L4.50998 4.42429L3.66145 3.57576Z'
        fill='#269200'
      />
    </svg>
  );
};

export default TrendUpIcon;
