import { COLLECTIONS, EVENT_TARGET, EVENT_TYPE, NOTIFICATION_DELIVERY_CHANNEL } from '@finance-ops/constants';
import { ActionType, BaseActionType } from '../action';
import { ClientType } from '../client';
import { CommentType } from '../comments';
import { CommonType, GenericObject } from '../common';
import { CustomerType } from '../customer';
import { EmailType } from '../email';
import { InvoiceType } from '../invoice';
import { MessageType } from '../message';
import { TaskType } from '../task';
import { UserType } from '../user';

export enum NOTIFICATION_STATUS {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export enum NOTIFICATION_PRIORITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export type NotificationType = CommonType & {
  sourceId: string;
  event: EVENT_TYPE;
  userId: string;
  clientId: string;
  content: string;
  status: NOTIFICATION_STATUS;
  priority: NOTIFICATION_PRIORITY;
  templates: NotificationTemplateType;
  data: Partial<CaptureCollectionsDataType>;
};

export type NotificationTemplateType = {
  [key in NOTIFICATION_DELIVERY_CHANNEL]?: string;
};

export type NotificationConfigMapType = {
  [key in EVENT_TYPE]: {
    isEnabled: boolean;
    deliveryModes: NotificationDeliveryMode;
  };
};

// set key in EVENT_TARGET except EVENT_TARGET.ALL
export type NotificationTargetType = {
  [key in Exclude<EVENT_TARGET, EVENT_TARGET.ALL>]: string[];
};

export type NotificationSourceType = {
  id: string;
  event: EVENT_TYPE;
  clientId: string;
  content: string;
  priority: NOTIFICATION_PRIORITY;
  templates: NotificationTemplateType;
  status: NOTIFICATION_STATUS;
  notificationTarget: NotificationTargetType;
  data: Partial<CaptureCollectionsDataType>;
  ignoreAllChannels?: boolean;
  shouldFilterInternalExternal: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type NotificationDeliveryMode = {
  [key in NOTIFICATION_DELIVERY_CHANNEL]: boolean;
};

type CollectionClass<T> = {
  [K in keyof T]: EventGeneric;
} & {
  [key: string]: EventGeneric;
};

export type EventMap = {
  [COLLECTIONS.TASK]: CollectionClass<Partial<TaskType>>;
  [COLLECTIONS.ACTION]: CollectionClass<Partial<ActionType>>;
  [COLLECTIONS.INVOICE]: CollectionClass<Partial<InvoiceType>>;
  [COLLECTIONS.CLIENT]: CollectionClass<Partial<ClientType>>;
  [COLLECTIONS.USER]: CollectionClass<Partial<UserType>>;
  [COLLECTIONS.MESSAGE]: CollectionClass<Partial<MessageType>>;
  [COLLECTIONS.COMMENT]: CollectionClass<Partial<CommentType>>;
};

export type EventGeneric = {
  event: EVENT_TYPE;
  content: string;
  notificationTarget: NotificationTargetType;
  templates: NotificationTemplateType;
  priority: NOTIFICATION_PRIORITY;
  captureFields: string[];
  captureDocuments: COLLECTIONS[];
  shouldFilterInternalExternal: boolean;
  handler?: (notificationSource: NotificationSourceType) => Promise<NotificationSourceType>;
};

export type EventGenericObjectType = EventGeneric & { document: GenericObject };

export type CaptureCollectionsDataType = {
  [COLLECTIONS.TASK]: TaskType;
  [COLLECTIONS.ACTION]: BaseActionType;
  [COLLECTIONS.INVOICE]: InvoiceType;
  [COLLECTIONS.CLIENT]: ClientType;
  [COLLECTIONS.USER]: UserType;
  [COLLECTIONS.MESSAGE]: MessageType;
  [COLLECTIONS.CUSTOMERS]: CustomerType;
  [COLLECTIONS.EMAIL]: EmailType;
  [COLLECTIONS.COMMENT]: CommentType;
};

export type NotificationHandlerType = {
  notification: NotificationType;
  user: UserType;
  channel?: NOTIFICATION_DELIVERY_CHANNEL;
};
