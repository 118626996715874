const ConversationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='55' height='55' viewBox='0 0 55 55' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.35289 27.1262C1.22068 36.8787 6.53133 45.893 15.1258 50.5043C23.7203 55.1156 34.1676 54.5562 42.2205 49.0534L53.3029 50.226L53.6054 27.8321C53.8003 13.403 42.2612 1.54784 27.832 1.35292C13.4029 1.15801 1.5478 12.6971 1.35289 27.1262Z'
        stroke='#F1F1FF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1936 34.0428C15.7794 34.0372 15.4391 34.3684 15.4335 34.7826C15.4279 35.1968 15.7592 35.537 16.1733 35.5426L16.1936 34.0428ZM31.1026 35.7443C31.5168 35.7499 31.8571 35.4187 31.8627 35.0045C31.8683 34.5903 31.537 34.25 31.1229 34.2444L31.1026 35.7443ZM16.3456 22.8458C15.9314 22.8402 15.5911 23.1714 15.5855 23.5856C15.5799 23.9998 15.9111 24.34 16.3253 24.3456L16.3456 22.8458ZM38.7192 24.6482C39.1334 24.6537 39.4737 24.3225 39.4793 23.9083C39.4849 23.4942 39.1537 23.1539 38.7395 23.1483L38.7192 24.6482ZM16.1733 35.5426L31.1026 35.7443L31.1229 34.2444L16.1936 34.0428L16.1733 35.5426ZM16.3253 24.3456L38.7192 24.6482L38.7395 23.1483L16.3456 22.8458L16.3253 24.3456Z'
        fill='#F1F1FF'
      />
    </svg>
  );
};

export default ConversationIcon;
