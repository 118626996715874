import { createHash } from 'crypto';
import { FILTER_ID, FILTER_TYPES, FiltersChat, GenericObject, Sort } from '../common';
import { TASK_STATE } from '../task';

export class CustomerContextGet {
  filters: FiltersChat[];
  pagination: {
    page: number;
    pageSize: number;
  };
  sort: GenericObject = {};
  requestHash: string;

  constructor(filters?: FiltersChat[], pagination?: { page: number; pageSize: number }) {
    this.filters = filters ?? [];
    this.pagination = pagination ?? { page: 1, pageSize: 50 };
  }

  private addGenericFilter(
    id: FILTER_ID,
    value: string | undefined | null,
    valueKey: string,
    meta: FILTER_TYPES = FILTER_TYPES.MATCH,
  ): this {
    const filter: FiltersChat = {
      id,
      meta,
      value: { [valueKey]: value },
    };

    if (value) {
      this.addFilter(filter);
    } else {
      this.removeAppliedFilter(id);
    }
    return this;
  }

  addFuzzySearch(query: string | undefined | null): this {
    const queryString = query?.trim();
    if (queryString?.length) {
      this.addGenericFilter(FILTER_ID.FUZZY_SEARCH, queryString, 'value', FILTER_TYPES.FUZZY);
    }
    return this;
  }

  addCustomerID(customerId: string | undefined | null): this {
    return this.addGenericFilter(FILTER_ID.CUSTOMER_ID, customerId, 'customerId');
  }

  addTaskState(state: TASK_STATE | undefined | null): this {
    if (this.filterExists(FILTER_ID.FUZZY_SEARCH)) {
      return this;
    }
    return this.addGenericFilter(FILTER_ID.TASK_STATE, state, 'state');
  }

  addAssignedUserId(assignedUserId: string | undefined | null): this {
    return this.addGenericFilter(FILTER_ID.ASSIGNED_USER_ID, assignedUserId, 'assignedUserId');
  }

  addAppliedFilters(filters: FiltersChat[] | undefined | null): this {
    if (!filters) return this;
    for (const filter of filters) {
      this.addFilter(filter);
    }
    return this;
  }

  // check if the filter is already in the filters array
  private filterExists(filterId: FILTER_ID): boolean {
    return this.filters.some(f => f.id === filterId);
  }

  // Add filter to filters if it doesn't exist, otherwise replace it use filter.id as key
  private addFilter(filter: FiltersChat | null): this {
    if (!filter) return this;
    const index = this.filters.findIndex(f => f.id === filter.id);
    if (index === -1) {
      this.filters.push(filter);
    } else {
      this.filters[index] = filter;
    }
    return this;
  }

  private removeAppliedFilter(filterId: FILTER_ID): this {
    this.filters = this.filters.filter(f => f.id !== filterId);
    return this;
  }

  addSort(sort: Sort[] | null): this {
    if (!sort) return this;
    for (const item of sort) {
      if (item.isActive) this.sort[item.fieldName] = item.order;
    }
    return this;
  }

  paginate(page: number, pageSize?: number): this {
    this.pagination = {
      page: page ?? this.pagination.page,
      pageSize: pageSize ?? this.pagination.pageSize,
    };
    return this;
  }

  updateRequestHash(): this {
    const filterAndSort = { ...this.filters.filter(f => f.id !== FILTER_ID.TASK_STATE), ...this.sort };
    this.requestHash = createHash('sha256').update(JSON.stringify(filterAndSort)).digest('hex');

    return this;
  }
}
