import { LANGUAGE, TranslationResultDto } from '@finance-ops/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LanguageSlice = {
  displayLanguage: LANGUAGE;
  messageText: string | null;
  messageTextLanguage: LANGUAGE | null;
  translations: Record<string, string> | null;
  translating: boolean;
  translationCode: string | null;
};

const initialState: LanguageSlice = {
  displayLanguage: LANGUAGE.ENGLISH,
  messageText: null,
  messageTextLanguage: null,
  translationCode: null,
  translations: null,
  translating: false,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    setDisplayLanguage(state, action: PayloadAction<LANGUAGE>) {
      state.displayLanguage = action.payload;
    },
    setTranslations: (state, action: PayloadAction<TranslationResultDto | null>) => {
      if (action.payload) {
        state.messageText = action.payload.translatedText;
        state.messageTextLanguage = action.payload.targetLanguage;
        state.translations = {
          ...state.translations,
          [action.payload.sourceLanguage]: action.payload.sourceText,
          [action.payload.targetLanguage]: action.payload.translatedText,
        };
      } else {
        state.messageText = null;
        state.messageTextLanguage = null;
        state.translations = null;
      }
      state.translating = false;
    },
    setMessageText(state, action: PayloadAction<string | null>) {
      state.messageText = action.payload;
    },
    setMessageTextLanguage(state, action: PayloadAction<LANGUAGE | null>) {
      state.messageTextLanguage = action.payload;
    },
    setTranslating(state, action: PayloadAction<boolean>) {
      state.translating = action.payload;
    },
    setTranslationCode(state, action: PayloadAction<string>) {
      state.translationCode = action.payload;
    },
  },
});

export const {
  setTranslationCode,
  setDisplayLanguage,
  setTranslations,
  setMessageText,
  setMessageTextLanguage,
  setTranslating,
} = languageSlice.actions;
export default languageSlice.reducer;
