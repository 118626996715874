import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface DatabaseViewerState {
  selectedCustomerId: string;
  selectedInvoiceId: string;
  selectedTaskId: string;
  selectedActionId: string;
}

const initialState: DatabaseViewerState = {
  selectedCustomerId: '',
  selectedInvoiceId: '',
  selectedTaskId: '',
  selectedActionId: '',
};

export const databaseViewerSlice = createSlice({
  name: 'database-viewer',
  initialState,
  reducers: {
    setSelectedCustomerId: (state, action: PayloadAction<string>) => {
      state.selectedCustomerId = action.payload;
    },
    setSelectedInvoiceId: (state, action: PayloadAction<string>) => {
      state.selectedInvoiceId = action.payload;
    },
    setSelectedTaskId: (state, action: PayloadAction<string>) => {
      state.selectedTaskId = action.payload;
    },
    setSelectedActionId: (state, action: PayloadAction<string>) => {
      state.selectedActionId = action.payload;
    },
  },
});

export const { setSelectedCustomerId, setSelectedInvoiceId, setSelectedTaskId, setSelectedActionId } =
  databaseViewerSlice.actions;

export default databaseViewerSlice.reducer;
