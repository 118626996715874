import {
  dashboardInitialValues,
  DashboardInterface,
  ExtendedTargetDocumentType,
  GraphDataPoint,
  RecentPayments,
  TEAM_MANAGEMENT_ASSETS,
  TeamManagementSummaryType,
  TeamManagementUserType,
} from '@finance-ops/types';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

interface TeamManagementSliceInterface {
  agents: { loading: boolean; data: TeamManagementUserType[] };
  agentsWithDetails: { loading: boolean; data: any[] };
  collectionsGraph: { loading: boolean; data: { name: string; data: GraphDataPoint[] }[] };
  p2pDetails?: { _id: string; amount: number; count: number }[];
  summary: { loading: boolean; data: TeamManagementSummaryType };
  hourlyGraphs: { loading: boolean; data: { name: string; data: any[] }[] };
  selectedAgentId?: string;
  selectedAgentDashboardSummary: {
    aggregation: DashboardInterface;
    aggregation30DaysBack: DashboardInterface;
  };
  selectedAgentRecentPayments: { loading: boolean; data: RecentPayments[] };
  selectedAgentResolutionCenter: { loading: boolean; data: { _id: string; count: number }[] };
  selectedAgentP2pDetails: { loading: boolean; data: { _id: string; amount: number; count: number }[] };
  selectedAgentHighestCollected: {
    loading: boolean;
    data: { amount: number; date: Date | number; collectedFrom: string };
  };
  selectedAgentOverallCollections: {
    loading: boolean;
    info: {
      amountCollected: number;
      customersInteracted: number;
      totalOutbound: number;
      totalInbound: number;
      responseRate: number;
      timestamp: string | Date;
    };
    oldInfo: {
      amountCollected: number;
      customersInteracted: number;
      totalOutbound: number;
      totalInbound: number;
      responseRate: number;
      timestamp: string | Date;
    };
  };
  targets: ExtendedTargetDocumentType[];
  currentTarget: { loading: false; data: ExtendedTargetDocumentType[] };
  isTargetModalOpen: boolean;
  isTargetEditMode: boolean;
  averageCollections: { loading: false; info: number; percentageChange: number };
  persistingAggregations: { loading: false; info: any };
}

const initialState: TeamManagementSliceInterface = {
  agents: { loading: true, data: [] },
  agentsWithDetails: { loading: true, data: [] },
  summary: {
    loading: true,
    data: {
      agents: 0,
      avgCasesPerAgent: 0,
      avgCollectedPerAgent: 0,
    },
  },
  collectionsGraph: { loading: true, data: [] },
  hourlyGraphs: { loading: true, data: [] },
  selectedAgentId: undefined,
  selectedAgentDashboardSummary: { aggregation: dashboardInitialValues, aggregation30DaysBack: dashboardInitialValues },
  selectedAgentRecentPayments: { loading: false, data: [] },
  selectedAgentResolutionCenter: { loading: false, data: [] },
  selectedAgentP2pDetails: { loading: false, data: [] },
  selectedAgentHighestCollected: {
    loading: false,
    data: {
      amount: 0,
      date: new Date(),
      collectedFrom: '-',
    },
  },
  selectedAgentOverallCollections: {
    loading: true,
    info: {
      amountCollected: 0,
      customersInteracted: 0,
      totalOutbound: 0,
      totalInbound: 0,
      responseRate: 0,
      timestamp: new Date(),
    },
    oldInfo: {
      amountCollected: 0,
      customersInteracted: 0,
      totalOutbound: 0,
      totalInbound: 0,
      responseRate: 0,
      timestamp: new Date(),
    },
  },
  targets: [],
  currentTarget: { loading: false, data: [] },
  isTargetModalOpen: false,
  isTargetEditMode: false,
  averageCollections: { loading: false, info: 0, percentageChange: 0 },
  persistingAggregations: { loading: false, info: {} },
};

export const teamManagementSlice = createSlice({
  name: 'teamManagement',
  initialState,
  reducers: {
    resetTeamManagement: () => {
      return initialState;
    },
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    setAgentsWithDetails: (state, action) => {
      state.agentsWithDetails = action.payload;
    },
    setCollectionsGraph: (state, action) => {
      state.collectionsGraph.data = action.payload;
      state.collectionsGraph.loading = false;
    },
    setP2pDetails: (state, action) => {
      state.p2pDetails = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setHourlyAggregations: (state, action) => {
      state.hourlyGraphs.data = action.payload;
      state.hourlyGraphs.loading = false;
    },
    setSelectedAgentId: (state, action) => {
      state.selectedAgentId = action.payload;
    },
    setSelectedAgentDashboardSummary: (state, action) => {
      state.selectedAgentDashboardSummary = action.payload;
    },
    setSelectedAgentRecentPayments: (state, action) => {
      state.selectedAgentRecentPayments.data = action.payload;
      state.selectedAgentRecentPayments.loading = false;
    },
    setSelectedAgentResolutionCenter: (state, action) => {
      state.selectedAgentResolutionCenter.data = action.payload;
      state.selectedAgentResolutionCenter.loading = false;
    },
    setSelectedAgentP2pDetails: (state, action) => {
      state.selectedAgentP2pDetails.data = action.payload;
      state.selectedAgentP2pDetails.loading = false;
    },
    setSelectedAgentHighestCollected: (state, action) => {
      state.selectedAgentHighestCollected.data = {
        ...action.payload,
        date: new Date(action.payload.date ?? new Date()),
      };
      state.selectedAgentHighestCollected.loading = false;
    },
    setSelectedAgentOverallCollections: (state, action) => {
      state.selectedAgentOverallCollections.info = action.payload.info;
      state.selectedAgentOverallCollections.loading = action.payload.loading;
    },
    setSelectedAgentOverallOldCollections: (state, action) => {
      state.selectedAgentOverallCollections.oldInfo = action.payload;
      state.selectedAgentOverallCollections.loading = false;
    },
    setDataToLoading: (state, action: { payload: TEAM_MANAGEMENT_ASSETS }) => {
      switch (action.payload) {
        case TEAM_MANAGEMENT_ASSETS.COLLECTIONS_GRAPH:
          state.collectionsGraph.loading = true;
          state.collectionsGraph.data = [];
          break;
        case TEAM_MANAGEMENT_ASSETS.P2P_DETAILS:
          state.selectedAgentP2pDetails.loading = true;
          state.selectedAgentP2pDetails.data = [];
          break;
        case TEAM_MANAGEMENT_ASSETS.RECOVERY_RATE_AND_COMM_METRICS:
          state.hourlyGraphs.data = [];
          state.hourlyGraphs.loading = true;
          break;
      }
    },
    setCurrentTarget: (state, action) => {
      state.currentTarget = action.payload;
    },
    setTargetsModalOpen: (state, action) => {
      if (typeof action.payload === 'boolean') {
        state.isTargetModalOpen = action.payload;
      } else {
        state.isTargetModalOpen = action.payload.newState;
        if (action.payload.selectedAgentId) {
          state.selectedAgentId = action.payload.selectedAgentId;
        }
        if (action.payload?.isEdit) {
          state.isTargetEditMode = true;
        }
      }
    },
    setAverageCollections: (state, action) => {
      state.averageCollections = action.payload;
    },
    setPersistingAggregations: (state, action) => {
      state.persistingAggregations = action.payload;
    },
  },
});

export const getSelectedAgentDetails = (state: RootState) => {
  return state.teamManagement.agents.data.find(agent => agent._id === state.teamManagement.selectedAgentId);
};

export const {
  resetTeamManagement,
  setAgents,
  setCollectionsGraph,
  setP2pDetails,
  setSummary,
  setHourlyAggregations,
  setSelectedAgentId,
  setSelectedAgentDashboardSummary,
  setSelectedAgentRecentPayments,
  setSelectedAgentResolutionCenter,
  setSelectedAgentP2pDetails,
  setSelectedAgentHighestCollected,
  setSelectedAgentOverallCollections,
  setSelectedAgentOverallOldCollections,
  setDataToLoading,
  setCurrentTarget,
  setTargetsModalOpen,
  setAgentsWithDetails,
  setAverageCollections,
  setPersistingAggregations,
} = teamManagementSlice.actions;

export default teamManagementSlice.reducer;
