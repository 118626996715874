import IconStyles from 'apps/webapp/src/types/iconStyles';

const UnPaidIcon = (styles: IconStyles) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='15' viewBox='0 0 17 15' fill='none' {...styles}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 2.73701V11.263C2.00159 11.7255 2.18689 12.1683 2.51511 12.4941C2.84332 12.8199 3.28755 13.0019 3.75 13H14.25C14.7124 13.0019 15.1567 12.8199 15.4849 12.4941C15.8131 12.1683 15.9984 11.7255 16 11.263V2.73701C15.9984 2.27457 15.8131 1.83171 15.4849 1.50593C15.1567 1.18015 14.7124 0.998154 14.25 1.00001H3.75C3.28755 0.998154 2.84332 1.18015 2.51511 1.50593C2.18689 1.83171 2.00159 2.27457 2 2.73701Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 5.85498C16.4142 5.85498 16.75 5.51919 16.75 5.10498C16.75 4.69077 16.4142 4.35498 16 4.35498V5.85498ZM2 4.35498C1.58579 4.35498 1.25 4.69077 1.25 5.10498C1.25 5.51919 1.58579 5.85498 2 5.85498V4.35498ZM9.875 8.93398C10.2892 8.93398 10.625 8.59819 10.625 8.18398C10.625 7.76977 10.2892 7.43398 9.875 7.43398V8.93398ZM2 7.43398C1.58579 7.43398 1.25 7.76977 1.25 8.18398C1.25 8.59819 1.58579 8.93398 2 8.93398V7.43398ZM13.375 8.93398C13.7892 8.93398 14.125 8.59819 14.125 8.18398C14.125 7.76977 13.7892 7.43398 13.375 7.43398V8.93398ZM12.5 7.43398C12.0858 7.43398 11.75 7.76977 11.75 8.18398C11.75 8.59819 12.0858 8.93398 12.5 8.93398V7.43398ZM16 4.35498H2V5.85498H16V4.35498ZM9.875 7.43398H2V8.93398H9.875V7.43398ZM13.375 7.43398H12.5V8.93398H13.375V7.43398Z'
        fill='black'
      />
      <circle cx='2.99916' cy='12' r='2.57143' fill='white' />
      <path
        d='M2.997 9C1.341 9 0 10.344 0 12C0 13.656 1.341 15 2.997 15C4.656 15 6 13.656 6 12C6 10.344 4.656 9 2.997 9ZM3 14.4C1.674 14.4 0.6 13.326 0.6 12C0.6 10.674 1.674 9.6 3 9.6C4.326 9.6 5.4 10.674 5.4 12C5.4 13.326 4.326 14.4 3 14.4ZM3.15 10.5H2.7V12.3L4.275 13.245L4.5 12.876L3.15 12.075V10.5Z'
        fill='black'
      />
    </svg>
  );
};

export default UnPaidIcon;
