// ** Redux Imports
import { PaymentStatusTypes, RenderedTransactionType, TransactionPageRequest } from '@finance-ops/types';
import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

const transactionsEntityAdapter = createEntityAdapter<RenderedTransactionType>({
  selectId: entity => entity.id.toString(),
  sortComparer: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
});

interface PaginationType {
  page: number;
  pageSize: number;
}
interface TransactionsState extends EntityState<RenderedTransactionType> {
  pagination: PaginationType;
  loading: boolean;
  count: number;
  customerSearch?: string;
  totalCopilot: number;
  totalAutopilot: number;
  transactionsRequest?: TransactionPageRequest;
  selectedTransactionId?: string;
}

const initialState: TransactionsState = transactionsEntityAdapter.getInitialState({
  pagination: {
    page: 1,
    pageSize: 25,
  },
  loading: false,
  count: 0,
  customerSearch: undefined,
  totalCopilot: 0,
  totalAutopilot: 0,
});

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<RenderedTransactionType[]>) => {
      transactionsEntityAdapter.setMany(state, action.payload);
    },
    upsertTransactions: (state, action: PayloadAction<RenderedTransactionType[]>) => {
      transactionsEntityAdapter.upsertMany(state, action.payload);
    },
    setPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },
    setTransactionsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTransactionsCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    setCustomerSearch(state, action: PayloadAction<string>) {
      state.customerSearch = action.payload;
    },
    setTotalCopilot(state, action: PayloadAction<number>) {
      state.totalCopilot = action.payload;
    },
    setTotalAutopilot(state, action: PayloadAction<number>) {
      state.totalAutopilot = action.payload;
    },
    setTransactionsRequest(state, action: PayloadAction<TransactionPageRequest>) {
      state.transactionsRequest = action.payload;
    },
    setSelectedTransactionId(state, action: PayloadAction<string | undefined>) {
      state.selectedTransactionId = action.payload;
    },
  },
});
export const transactionsSelectors = transactionsEntityAdapter.getSelectors<RootState>(state => state.transactions);

export const selectSortedTransactionsByCustomerId = createSelector(
  [transactionsSelectors.selectAll, (_, customerId) => customerId],
  (transactionsAll, customerId) => {
    const transactions: RenderedTransactionType[] = transactionsAll
      .filter(transaction => transaction !== undefined && transaction.customerId === customerId)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return transactions ?? [];
  },
);

export const selectAttachmentsByTransactionId = createSelector(
  [transactionsSelectors.selectAll, (_, transactionId) => transactionId],
  (transactionsAll, transactionId) => {
    const transaction = transactionsAll.find(transaction => transaction.id === transactionId);
    return { attachments: transaction?.attachments ?? [], customerId: transaction?.customerId };
  },
);

export const selectLatestSuccessfulTransactionByCustomerId = createSelector(
  [transactionsSelectors.selectAll, (_, customerId) => customerId],
  (transactionsAll, customerId) => {
    const transactions: RenderedTransactionType[] = transactionsAll
      .filter(
        transaction =>
          transaction !== undefined &&
          transaction.customerId === customerId &&
          transaction.paymentStatus === PaymentStatusTypes.SUCCESS,
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return transactions?.[0];
  },
);

export const getTransactions = (state: RootState) => transactionsSelectors.selectAll(state);

export const getSelectedTransaction = (state: RootState) => {
  const selectedTransactionId = state.transactions.selectedTransactionId;
  if (!selectedTransactionId) return;
  return transactionsSelectors.selectById(state, selectedTransactionId);
};

export const {
  setCustomerSearch,
  setPagination,
  setTotalAutopilot,
  setTotalCopilot,
  setTransactions,
  setTransactionsCount,
  setTransactionsLoading,
  setTransactionsRequest,
  upsertTransactions,
  setSelectedTransactionId,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
