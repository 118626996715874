import { USER_TYPE } from '@finance-ops/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpGet, httpPatch } from '../../httpClient';
import { ROLE_API_PATHS, USERS_API_PATHS } from '../../httpClient/api';
import { RootState } from '../../store';
import { upsertManyRoles } from '../../store/roles';
import { setUsers, upsertUser } from '../../store/users';
import { useAuth } from '../useAuth';

export const useGetUsers = () => {
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const dispatch = useDispatch();
  const user = useAuth().user;
  const getUsers = useCallback(async () => {
    try {
      let path = `${USERS_API_PATHS.GET_USERS}`;
      // if (selectedClientId) {
      //   path += `?customerId=${selectedClientId}`;
      // }
      path += `?isInternal=${user?.role?.isInternal}&isSuperAdmin=${Boolean(user?.role?.isInternal)}`;
      const response = await httpGet(path);
      if (response?.data?.length > 0) {
        dispatch(setUsers(response.data));
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }, [selectedClientId]);
  return { getUsers };
};

export const useGetRoles = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const getRoles = useCallback(async () => {
    try {
      //TODO: RAJAT let path = `${ROLE_API_PATHS.GET_ROLES}?isInternal=${isInternalUser}&isSuperAdmin=${isSuperAdmin}`;
      let path = `${ROLE_API_PATHS.GET_ROLES}`;
      if (selectedClientId) {
        path += `&clientId=${selectedClientId}`;
      }
      const response = await httpGet(path);
      dispatch(upsertManyRoles(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }, [isInternalUser, isSuperAdmin, selectedClientId]);
  return { getRoles };
};

export const useUpdateUser = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const updateUser = useCallback(
    async (
      userId: string,
      {
        firstName,
        lastName,
        contactNumber,
        email,
        roleId,
        type,
      }: {
        firstName?: string;
        lastName?: string;
        contactNumber?: string;
        email?: string;
        roleId?: string;
        type?: USER_TYPE;
      },
    ) => {
      try {
        const path = `${USERS_API_PATHS.UPDATE_USER}/${userId}`;
        const response = await httpPatch(path, { firstName, lastName, contactNumber, email, roleId, type });
        dispatch(upsertUser(response?.data));
      } catch (error) {
        console.error(error);
      }
    },
    [isInternalUser, isSuperAdmin, selectedClientId],
  );
  return { updateUser };
};
