import IconStyles from 'apps/webapp/src/types/iconStyles';

const EmailEmojiIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        d='M3.85686 7.42856C3.98637 7.76592 4.19859 8.06533 4.47401 8.29927C5.34992 9.03448 6.62461 9.04377 7.51115 8.32141C7.79037 8.09161 8.00748 7.79543 8.14258 7.45998'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.85714 5.2857V4.57141' stroke={color} strokeLinecap='round' />
      <path d='M8.14328 5.2857V4.57141' stroke={color} strokeLinecap='round' />
    </svg>
  );
};

export default EmailEmojiIcon;
