import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { DataParams, InvoiceType, SummaryData } from '@finance-ops/types';
import { httpGet, httpPost } from '../httpClient';
import api from '../httpClient/api';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';

export const getInvoices = createAsyncThunk('invoice/fetch', async (params: DataParams) => {
  return await httpGet(api.getInvoices, params);
});

export const getInvoicesFiltered = createAsyncThunk('invoice/filter', async (query: any) => {
  return await httpPost(api.getFilteredInvoices, query);
});

interface InvoicesStateItems {
  invoiceQueries: GridFilterItem | null;
  rowCount: number;
  filterTotalCount: 0;
  summaryData: SummaryData;
  isLoading: boolean;
  error: string | null;
}

interface InvoicesState extends EntityState<InvoiceType>, InvoicesStateItems {}

const invoiceEntityAdapter = createEntityAdapter<InvoiceType>({
  selectId: entity => entity.invoiceNumber,
});

const initialState: InvoicesState = {
  ...invoiceEntityAdapter.getInitialState(),
  rowCount: 0,
  filterTotalCount: 0,
  summaryData: { current_overdue: 0, past_overdue: 0, total_overdue: 0 },
  invoiceQueries: null,
  isLoading: false,
  error: null,
};

const invoicesSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoices: invoiceEntityAdapter.setAll,
    upsertOneInvoice: invoiceEntityAdapter.upsertOne,
    upsertManyInvoices: invoiceEntityAdapter.upsertMany,
    setInvoiceFilterData(state) {
      state.summaryData = { current_overdue: 0, past_overdue: 0, total_overdue: 0 };
      state.filterTotalCount = 0;
    },
    setInvoiceQueries(state, action) {
      state.invoiceQueries = action.payload;
    },
    resetInvoices: () => {
      return initialState;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      invoiceEntityAdapter.setAll(state, action.payload.docs);
      state.rowCount = action.payload.count;
      state.filterTotalCount = 0;
    });
    builder.addCase(getInvoicesFiltered.fulfilled, (state, action) => {
      invoiceEntityAdapter.setAll(state, action.payload.docs);
      state.summaryData = action.payload.summary;
      state.filterTotalCount = action.payload.totalCount;
    });
  },
});

export const { setInvoiceQueries, resetInvoices } = invoicesSlice.actions;

export default invoicesSlice.reducer;
