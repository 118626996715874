import { io, Socket } from 'socket.io-client';

import authConfig from '../configs/auth';
import { WebSocketMessageType } from '@finance-ops/types';
import { WebsocketMessage } from 'libs/types/src/websocket/WebsocketMessage';
import { Track } from '../@core/track';
import Router from 'next/router';
import { v4 as uuidv4 } from 'uuid';

let socket: Socket | null = null;

type MessageHandlersType = {
  [key in WebSocketMessageType]: (payload: any) => void;
};

const messageHandlers: MessageHandlersType = {} as MessageHandlersType;

const inMemoryMessages: Record<string, WebsocketMessage<any>> = {};
let existingIntervalId: NodeJS.Timer | null = null;

const processInMemoryMessages = () => {
  if (existingIntervalId) {
    clearInterval(existingIntervalId);
  }
  const interval = setInterval(() => {
    Object.entries(inMemoryMessages).forEach(([id, message]) => {
      messageHandlers[message.type](message.payload);
      delete inMemoryMessages[id];
    });
  }, 2000);
  existingIntervalId = interval;
};

export const setupWebsocket = (callback?: () => void) => {
  if (socket?.connected) {
    return;
  } else if (socket) {
    socket?.disconnect();
  }
  if (typeof window !== 'undefined') {
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName);
    socket = io(process.env.NEXT_PUBLIC_WEBSOCKET_URL as string, { auth: { accessToken: storedToken } });

    socket.on('connect', () => {
      if (callback) callback();
      processInMemoryMessages();
    });

    socket.on('message', message => {
      const { type, payload } = message as WebsocketMessage<any>;

      if (messageHandlers[type]) {
        inMemoryMessages[uuidv4()] = message;
      } else {
        console.log('Unhandled message type:', type);
      }
    });
    // Add redirect to login on disconnect
    socket.on('disconnect', (reason: any) => {
      if (existingIntervalId) {
        clearInterval(existingIntervalId);
      }
      // Avoid redirecting on web page refresh / closing the browser window
      if (reason === 'io server disconnect') {
        console.log('Redirecting to login page, because ->', reason);
        Track.getInstance().redirectedToLoginPage({ reason });
        Router.push('/login');
      }
    });
  }
};

export const disconnectWebsocket = () => {
  if (socket) {
    socket.disconnect();
  }
  if (existingIntervalId) {
    clearInterval(existingIntervalId);
  }
};

export const addMessageHandler = (type: WebSocketMessageType, handler: (payload: any) => void) => {
  messageHandlers[type] = handler;
};

const sendMessage = (type: WebSocketMessageType, payload?: any) => {
  socket?.emit('message', {
    type,
    payload,
  });
};

export { socket, sendMessage };
