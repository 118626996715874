const OpenedEmailFilledIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
      <rect width='30' height='30' rx='15' fill='#6461F3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9157 19.0993C11.3705 19.1476 10.0771 17.9432 10.0231 16.4058V13.7116L10.0195 12.3652C9.92634 11.8775 10.1737 11.3882 10.6228 11.1718L13.556 9.43076C14.498 8.85641 15.6842 8.85641 16.6262 9.43076L19.5593 11.174C20.0084 11.3903 20.2558 11.8796 20.1626 12.3674L20.159 13.7145V16.408C20.1039 17.9445 18.8109 19.1476 17.2665 19.0993H12.9157Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2709 11.933C10.0322 11.7942 9.72615 11.8751 9.58732 12.1138C9.44848 12.3525 9.52944 12.6586 9.76815 12.7974L10.2709 11.933ZM13.5799 14.436L13.8357 14.0063L13.8313 14.0038L13.5799 14.436ZM16.6016 14.436L16.3502 14.0038L16.3459 14.0063L16.6016 14.436ZM20.4134 12.7974C20.6521 12.6586 20.733 12.3525 20.5942 12.1138C20.4553 11.8751 20.1493 11.7942 19.9106 11.933L20.4134 12.7974ZM16.4206 12.3692C16.6886 12.4358 16.9599 12.2726 17.0265 12.0047C17.0932 11.7367 16.93 11.4654 16.662 11.3987L16.4206 12.3692ZM15.0911 11.5232L15.2118 11.038C15.1325 11.0183 15.0496 11.0183 14.9704 11.038L15.0911 11.5232ZM13.5201 11.3987C13.2521 11.4654 13.0889 11.7367 13.1556 12.0047C13.2222 12.2726 13.4935 12.4358 13.7615 12.3692L13.5201 11.3987ZM9.76815 12.7974L13.3286 14.8682L13.8313 14.0038L10.2709 11.933L9.76815 12.7974ZM13.3242 14.8657C14.4122 15.5131 15.7693 15.5131 16.8573 14.8657L16.3459 14.0063C15.5731 14.4663 14.6084 14.4663 13.8356 14.0063L13.3242 14.8657ZM16.853 14.8682L20.4134 12.7974L19.9106 11.933L16.3502 14.0038L16.853 14.8682ZM16.662 11.3987L15.2118 11.038L14.9704 12.0084L16.4206 12.3692L16.662 11.3987ZM14.9704 11.038L13.5201 11.3987L13.7615 12.3692L15.2118 12.0084L14.9704 11.038Z'
        fill='white'
      />
    </svg>
  );
};

export default OpenedEmailFilledIcon;
