// ** React Import
import { useRef, useState } from 'react';

// ** MUI Imports
import List from '@mui/material/List';
import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

// ** Third Party Components
import PerfectScrollbar from 'react-perfect-scrollbar';

// ** Type Import
import { LayoutProps } from 'apps/webapp/src/@core/layouts/types';

import themeConfig from 'apps/webapp/src/configs/themeConfig';

// ** Component Imports
import Drawer from './Drawer';
import VerticalNavItems from './VerticalNavItems';
import VerticalNavHeader from './VerticalNavHeader';

// ** Util Import
import { hexToRGBA } from 'apps/webapp/src/@core/utils/hex-to-rgba';

import UserDropdown from '../../shared-components/UserDropdown';

import Notifications from 'apps/webapp/src/notification/Notifications';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import Version from './Version';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';

interface Props {
  navWidth: number;
  navVisible: boolean;
  collapsedNavWidth: number;
  hidden: LayoutProps['hidden'];
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  settings: LayoutProps['settings'];
  children: LayoutProps['children'];
  setNavVisible: (value: boolean) => void;
  saveSettings: LayoutProps['saveSettings'];
  navMenuContent: LayoutProps['verticalLayoutProps']['navMenu']['content'];
  navMenuBranding: LayoutProps['verticalLayoutProps']['navMenu']['branding'];
  menuLockedIcon: LayoutProps['verticalLayoutProps']['navMenu']['lockedIcon'];
  verticalNavItems: LayoutProps['verticalLayoutProps']['navMenu']['navItems'];
  navMenuProps: LayoutProps['verticalLayoutProps']['navMenu']['componentProps'];
  menuUnlockedIcon: LayoutProps['verticalLayoutProps']['navMenu']['unlockedIcon'];
  afterNavMenuContent: LayoutProps['verticalLayoutProps']['navMenu']['afterContent'];
  beforeNavMenuContent: LayoutProps['verticalLayoutProps']['navMenu']['beforeContent'];
}

const StyledBoxForShadow = styled(Box)<BoxProps>(({ theme }) => ({
  top: 60,
  left: -8,
  zIndex: 2,
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  transition: 'opacity .15s ease-in-out',
  '&.scrolled': {
    opacity: 1,
  },
}));

const Navigation = (props: Props) => {
  // ** Props
  const {
    hidden,
    settings,
    afterNavMenuContent,
    beforeNavMenuContent,
    navigationBorderWidth,
    navMenuContent: userNavMenuContent,
    navVisible,
    setNavVisible,
  } = props;

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false);
  const [groupActive, setGroupActive] = useState<string[]>([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mode } = settings;
  const { user } = useAuth();

  // ** Var
  const { afterVerticalNavMenuContentPosition, beforeVerticalNavMenuContentPosition } = themeConfig;

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = (ref: HTMLElement) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect;

      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect();

        return { ...original, height: Math.floor(original.height) };
      };
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container: any) => {
    if (beforeVerticalNavMenuContentPosition === 'static' || !beforeNavMenuContent) {
      container = hidden ? container.target : container;
      if (shadowRef && container.scrollTop > 0) {
        // @ts-ignore
        if (!shadowRef.current.classList.contains('scrolled')) {
          // @ts-ignore
          shadowRef.current.classList.add('scrolled');
        }
      } else {
        // @ts-ignore
        shadowRef.current.classList.remove('scrolled');
      }
    }
  };

  const shadowBgColor = () => {
    if (mode === 'semi-dark') {
      return `linear-gradient(${theme.palette.customColors.darkPaperBg} 5%,${hexToRGBA(
        theme.palette.customColors.darkPaperBg,
        0.85,
      )} 30%,${hexToRGBA(theme.palette.customColors.darkPaperBg, 0.5)} 65%,${hexToRGBA(
        theme.palette.customColors.darkPaperBg,
        0.3,
      )} 75%,transparent)`;
    } else {
      return `linear-gradient(${theme.palette.background.paper} 5%,${hexToRGBA(
        theme.palette.background.paper,
        0.85,
      )} 30%,${hexToRGBA(theme.palette.background.paper, 0.5)} 65%,${hexToRGBA(
        theme.palette.background.paper,
        0.3,
      )} 75%,transparent)`;
    }
  };

  const ScrollWrapper = hidden ? Box : PerfectScrollbar;

  const titleStyles = {
    box: { display: 'flex', alignItems: 'center', px: 5, width: '100%' },
    typography: { ml: 2, fontSize: '0.8rem', color: theme.palette.secondary.dark, fontWeight: 500 },
  };

  if (isMobile && !navVisible) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        p={0}
        sx={{ position: 'absolute', top: '0px', left: '10px' }}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={() => setNavVisible(true)}
          sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Drawer {...props} navHover={navHover} setNavHover={setNavHover} navigationBorderWidth={navigationBorderWidth}>
      <VerticalNavHeader {...props} navHover={navHover} />
      {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === 'fixed' ? beforeNavMenuContent(props) : null}
      {(beforeVerticalNavMenuContentPosition === 'static' || !beforeNavMenuContent) && (
        <StyledBoxForShadow ref={shadowRef} sx={{ background: shadowBgColor() }} />
      )}
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ height: '100%' }}>
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          {/* @ts-ignore */}
          <ScrollWrapper
            {...(hidden
              ? {
                  onScroll: (container: any) => scrollMenu(container),
                  sx: { height: '100%', overflowY: 'auto', overflowX: 'hidden' },
                }
              : {
                  options: { wheelPropagation: false },
                  onScrollY: (container: any) => scrollMenu(container),
                  containerRef: (ref: any) => handleInfiniteScroll(ref),
                })}
          >
            {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === 'static'
              ? beforeNavMenuContent(props)
              : null}
            {userNavMenuContent ? (
              userNavMenuContent(props)
            ) : (
              <List className='nav-items' sx={{ pt: 0, '& > :first-of-type': { mt: '0' } }}>
                <VerticalNavItems
                  navHover={navHover}
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                  {...props}
                />
              </List>
            )}
            {afterNavMenuContent && afterVerticalNavMenuContentPosition === 'static'
              ? afterNavMenuContent(props)
              : null}
          </ScrollWrapper>
        </Box>
        {afterNavMenuContent && afterVerticalNavMenuContentPosition === 'fixed' ? afterNavMenuContent(props) : null}
        <Box
          sx={{
            textAlign: 'center',
            padding: '10px 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              '& > *': { my: 1.25 },
            }}
          >
            <Box sx={titleStyles.box}>
              <Notifications navHover={navHover} />
            </Box>

            {/* <CanWrapper action={RBACActions.READ} subject={RBACSubjects.ONLY_SUPER_ADMIN}>
              <Box sx={titleStyles.box}>
                <ModeToggler settings={settings} saveSettings={saveSettings} />
                {navHover && (
                  <Typography sx={titleStyles.typography}>
                    Switch to {settings.mode === 'light' ? 'Dark' : 'Light'} mode
                  </Typography>
                )}
              </Box>
            </CanWrapper> */}
            <Box sx={titleStyles.box}>
              <UserDropdown navHover={navHover} settings={settings} />
            </Box>
            {/* <Box sx={titleStyles.box}>
              <LanguageDropdown settings={settings} saveSettings={saveSettings} />
              {navHover && <Typography sx={titleStyles.typography}>Language</Typography>}
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', mx: 5, justifyContent: 'space-around' }}>
              <Version />
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Navigation;
