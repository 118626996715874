import IconStyles from 'apps/webapp/src/types/iconStyles';

const ReplyAllIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='10' viewBox='0 0 21 10' fill='none'>
      <path d='M6 1L2 5.00003L6 8.66699' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M10.6609 1.69777C10.954 1.40513 10.9544 0.930253 10.6618 0.637112C10.3691 0.343971 9.89426 0.343568 9.60112 0.636213L10.6609 1.69777ZM5.47012 4.76021C5.17698 5.05286 5.17658 5.52773 5.46922 5.82087C5.76187 6.11401 6.23674 6.11442 6.52988 5.82177L5.47012 4.76021ZM6.51318 4.74405C6.21111 4.46063 5.73648 4.47574 5.45306 4.77781C5.16964 5.07988 5.18475 5.55451 5.48682 5.83793L6.51318 4.74405ZM9.61782 9.71393C9.91989 9.99736 10.3945 9.98224 10.6779 9.68017C10.9614 9.37811 10.9462 8.90347 10.6442 8.62005L9.61782 9.71393ZM6 4.54099C5.58579 4.54099 5.25 4.87678 5.25 5.29099C5.25 5.70521 5.58579 6.04099 6 6.04099V4.54099ZM16.37 5.29099V6.041L16.3723 6.04099L16.37 5.29099ZM18.9326 6.34354L19.4614 5.81168V5.81168L18.9326 6.34354ZM19.25 8.90206C19.2511 9.31627 19.5879 9.65113 20.0021 9.64999C20.4163 9.64885 20.7511 9.31213 20.75 8.89792L19.25 8.90206ZM9.60112 0.636213L5.47012 4.76021L6.52988 5.82177L10.6609 1.69777L9.60112 0.636213ZM5.48682 5.83793L9.61782 9.71393L10.6442 8.62005L6.51318 4.74405L5.48682 5.83793ZM6 6.04099H16.37V4.54099H6V6.04099ZM16.3723 6.04099C17.1333 6.03867 17.8641 6.33883 18.4038 6.87541L19.4614 5.81168C18.6395 4.99456 17.5267 4.53747 16.3677 4.541L16.3723 6.04099ZM18.4038 6.87541C18.9435 7.41199 19.2479 8.14102 19.25 8.90206L20.75 8.89792C20.7468 7.73898 20.2833 6.6288 19.4614 5.81168L18.4038 6.87541Z'
        fill={color}
      />
    </svg>
  );
};

export default ReplyAllIcon;
