// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'clients',
  initialState: {
    queriesToFilter: [],
  },
  reducers: {
    setQueriesToFilter(state, action) {
      return {
        ...state,
        queriesToFilter: action.payload,
      };
    },
  },
});

export const { setQueriesToFilter } = commonSlice.actions;

export default commonSlice.reducer;
