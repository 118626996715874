import { AnyMongoAbility, subject as makeSubject } from '@casl/ability';
import { PermissionType } from '@finance-ops/types';

export const canAccess = (ability: AnyMongoAbility, permissionType?: PermissionType, resource?: any) => {
  if (!permissionType) {
    return false;
  }

  const { actions, subject: permissionSubject } = permissionType;

  const subject = resource ? makeSubject(permissionSubject, resource) : permissionSubject;

  const hasAccess = actions.some(action => {
    return ability.can(action, subject);
  });

  return hasAccess;
};
