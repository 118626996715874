export const enum MESSAGE_CONSUMER_TYPE {
  AI_AUTOPILOT_GENERATED_TEXT = 'AI_AUTOPILOT_GENERATED_TEXT',
  AI_COPILOT_GENERATED_TEXT = 'AI_COPILOT_GENERATED_TEXT',
  NLS_BATCH_CONTACT_DATA_MESSAGE_CONSUMER = 'NLS_BATCH_CONTACT_DATA_MESSAGE_CONSUMER',
  NLS_BATCH_LOAN_DATA_MESSAGE_CONSUMER = 'NLS_BATCH_LOAN_DATA_MESSAGE_CONSUMER',
  NLS_PAYMENT_MESSAGE_CONSUMER = 'NLS_PAYMENT_MESSAGE_CONSUMER',
  NOTIFICATION_CHANNEL_EMAIL_MESSAGE_CONSUMER = 'NOTIFICATION_CHANNEL_EMAIL_MESSAGE_CONSUMER',
  NOTIFICATION_CHANNEL_SMS_MESSAGE_CONSUMER = 'NOTIFICATION_CHANNEL_SMS_MESSAGE_CONSUMER',
  NOTIFICATION_CHANNEL_WEBAPP_MESSAGE_CONSUMER = 'NOTIFICATION_CHANNEL_WEBAPP_MESSAGE_CONSUMER',
  NOTIFICATION_CUSTOM_SOURCE_EVENT_MESSAGE_CONSUMER = 'NOTIFICATION_CUSTOM_SOURCE_EVENT_MESSAGE_CONSUMER',
  NOTIFICATION_HISTORY_LOG_EVENT_MESSAGE_CONSUMER = 'NOTIFICATION_HISTORY_LOG_EVENT_MESSAGE_CONSUMER',
  NOTIFICATION_EVENT_MESSAGE_CONSUMER = 'NOTIFICATION_EVENT_MESSAGE_CONSUMER',
  NOTIFICATION_QUEUE_MESSAGE_CONSUMER = 'NOTIFICATION_QUEUE_MESSAGE_CONSUMER',
  OD_BATCH_JOB = 'OD_BATCH_JOB',
  SMS_RECEIVE_MESSAGE_CONSUMER = 'SMS_RECEIVE_MESSAGE_CONSUMER',
  SMS_SEND_MESSAGE_CONSUMER = 'SMS_SEND_MESSAGE_CONSUMER',
  SMS_STATUS_MESSAGE_CONSUMER = 'SMS_STATUS_MESSAGE_CONSUMER',
  STRIPE_PAYMENT = 'STRIPE_PAYMENT',
  NUVEI_PAYMENT = 'NUVEI_PAYMENT',
  WHATSAPP_META_RECEIVE_MESSAGE_CONSUMER = 'WHATSAPP_META_RECEIVE_MESSAGE_CONSUMER',

  PAYMENT_CONFIRMATION_MESSAGE_CONSUMER = 'PAYMENT_CONFIRMATION_MESSAGE_CONSUMER',
  CURATIVE_XML_PAYMENT_MESSAGE_CONSUMER = 'CURATIVE_XML_PAYMENT_MESSAGE_CONSUMER',
  SES_EMAIL_NOTIFICATION_MESSAGE_CONSUMER = 'SES_EMAIL_NOTIFICATION_MESSAGE_CONSUMER',
}

export enum MESSAGE_DELIVERY_TYPE {
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
}
