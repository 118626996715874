import { DashboardAgingSummary, DashboardInterface, P2psGroupedByType, UI_MODE } from '../dashboard';

export type DashboardRequestType = {
  uiMode: UI_MODE;
  clientIdDropdown: string;
  filters?: any;
  downSamplingSize?: any;
};

export enum TEAM_OVERVIEW_GRAPH_TYPE {
  AGING_SUMMARY = 'AGING_SUMMARY',
  P2P_GRAPH = 'P2P_GRAPH',
  COLLECTIONS_GRAPH = 'COLLECTIONS_GRAPH',
  MESSAGES_GRAPH = 'MESSAGES_GRAPH',
}

export enum AGGREGATION_TYPE {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum CUSTOMER_ENGAGEMENT_TYPE {
  POOR = 'Poor',
  MEDIUM = 'Medium',
  HIGH = 'High',
  NOT_APPLICABLE = 'Not Applicable',
}

export type DashboardTeamOverviewGraphRequestType = {
  uiMode: UI_MODE;
  clientIdDropdown: string;
  graphType: TEAM_OVERVIEW_GRAPH_TYPE;
  timeframe: string;
  agentId?: string;
};

export type agingSummaryReportRequestType = {
  clientIdDropdown: string;
  uiMode: UI_MODE;
  agentId?: string;
};

export type collectionsResponseType = { _id: number; amount: number; delinquencyRate: number }[];
export type messagesResponseType = { _id: number; incomingMsgCount: number; outgoingMsgCount: number }[];

export type DashboardResponseType = {
  aggregation: DashboardInterface;
  aggregation30DaysBack: DashboardInterface;
  formulaCards: {
    cei: {
      prev: number;
      current: number;
    };
  };
};

export type MessageGraphResponseType = {
  monthly: messagesResponseType;
  weekly: messagesResponseType;
};

export type DashboardTeamOverviewResponseType = {
  type: TEAM_OVERVIEW_GRAPH_TYPE;
  payload:
    | DashboardAgingSummary
    | null
    | P2psGroupedByType[]
    | { monthly: collectionsResponseType; weekly: collectionsResponseType }
    | MessageGraphResponseType
    | { _id: string }
    | collectionsResponseType
    | [];
};

export type DashboardCountResponseType = {
  copilotCount: number;
  autopilotCount: number;
};

export type TeamOverviewAgentCollectionProfileResponseType = {
  collections: any[];
  customersInteracted: number;
  totalMessagesSent: number;
  responseRate: number;
  recoveryRate: number;
};

export type TeamOverviewAgentStatisticsResponseType = {
  averageDaysToCollect: number;
  totalClosed: number;
  topCollectionAmount: number;
};

export type GetDashboardSync = {
  uiMode: UI_MODE;
  type: AGGREGATION_TYPE;
};

export type GetDashboardGraphsRequestType = {
  uiMode: UI_MODE;
  clientIdDropdown: string;
  graphType: string;
  filters?: any;
};
