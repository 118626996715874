export type SmsConfigType = {
  isActive: boolean;
  smsProvider: SMS_PROVIDER;
  smsNumber: string;
};

export enum SMS_PROVIDER {
  TWILIO = 'twilio',
  TEXTGRID = 'textgrid',
  WHATSAPP_META = 'whatsappMeta',
  INTERNAL_SMS_PROVIDER = 'internalSmsProvider',
}

export type FindSmsConfigDTOType = {
  smsNumber: string;
};
