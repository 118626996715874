// ** React Imports
import { ReactNode } from 'react';

// ** Component Imports
import { PermissionType } from '@finance-ops/types';
import { useAccess } from '../../../hooks/useAccess';

interface Props {
  children: ReactNode;
  permission: PermissionType;
  resource?: any;
  fallback?: ReactNode;
}

const CanWrapper = ({ children, permission, resource, fallback = null }: Props) => {
  const canAccessElement = useAccess(permission, resource);

  return canAccessElement ? <>{children}</> : <>{fallback}</>;
};

export default CanWrapper;
