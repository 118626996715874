const OpenedEmail = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.81035 16.1232C3.52704 16.1941 1.61584 14.4246 1.5361 12.1659V8.2076L1.53074 6.22949C1.3931 5.51286 1.7586 4.794 2.42224 4.47612L6.75649 1.91815C8.14848 1.07432 9.90127 1.07432 11.2933 1.91815L15.6275 4.4793C16.2912 4.79718 16.6567 5.51604 16.519 6.23267L16.5137 8.21184V12.1691C16.4322 14.4265 14.5215 16.1941 12.2394 16.1232H5.81035Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.90671 5.58013C1.54813 5.37277 1.08935 5.49535 0.881994 5.85393C0.674636 6.2125 0.79722 6.67128 1.15579 6.87864L1.90671 5.58013ZM6.79236 9.27181L7.17432 8.62632L7.16782 8.62256L6.79236 9.27181ZM11.2573 9.27181L10.8819 8.62252L10.8754 8.62635L11.2573 9.27181ZM16.8939 6.87864C17.2525 6.67128 17.3751 6.2125 17.1677 5.85393C16.9603 5.49535 16.5016 5.37277 16.143 5.58013L16.8939 6.87864ZM10.9885 6.25041C11.3906 6.34986 11.7972 6.10452 11.8966 5.70242C11.9961 5.30033 11.7507 4.89374 11.3486 4.79429L10.9885 6.25041ZM9.02553 4.99231L9.2056 4.26425C9.08733 4.235 8.96372 4.235 8.84545 4.26425L9.02553 4.99231ZM6.70243 4.79429C6.30034 4.89374 6.05499 5.30033 6.15445 5.70242C6.2539 6.10452 6.66048 6.34986 7.06258 6.25041L6.70243 4.79429ZM1.15579 6.87864L6.4169 9.92107L7.16782 8.62256L1.90671 5.58013L1.15579 6.87864ZM6.41042 9.91727C8.02073 10.8701 10.029 10.8701 11.6393 9.91727L10.8754 8.62635C9.73619 9.30045 8.31351 9.30045 7.1743 8.62635L6.41042 9.91727ZM11.6328 9.92107L16.8939 6.87864L16.143 5.58013L10.8819 8.62256L11.6328 9.92107ZM11.3486 4.79429L9.2056 4.26425L8.84545 5.72037L10.9885 6.25041L11.3486 4.79429ZM8.84545 4.26425L6.70243 4.79429L7.06258 6.25041L9.2056 5.72037L8.84545 4.26425Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default OpenedEmail;
