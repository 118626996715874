export const LoanStatus = {
  Active: 0,
  Restructured: 2,
  Cancelled: 4,
};

export const getLoanStatusDisplayName = (status: number) => {
  switch (status) {
    case LoanStatus.Active:
      return 'Active';
    case LoanStatus.Cancelled:
      return 'Cancelled';
    case LoanStatus.Restructured:
      return 'Restructured';
    default:
      return 'Unknown';
  }
};
