import IconStyles from 'apps/webapp/src/types/iconStyles';

const ClockIcon = (styles: IconStyles) => {
  const color = styles?.color || 'black';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none' {...styles}>
      <path
        d='M6.993 0C3.129 0 0 3.136 0 7C0 10.864 3.129 14 6.993 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 6.993 0ZM7 12.6C3.906 12.6 1.4 10.094 1.4 7C1.4 3.906 3.906 1.4 7 1.4C10.094 1.4 12.6 3.906 12.6 7C12.6 10.094 10.094 12.6 7 12.6ZM7.35 3.5H6.3V7.7L9.975 9.905L10.5 9.044L7.35 7.175V3.5Z'
        fill={color}
      />
    </svg>
  );
};

export default ClockIcon;
