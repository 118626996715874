import IconStyles from 'apps/webapp/src/types/iconStyles';

const ChatIcon = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5809 18.4039C10.6644 18.9892 10.2604 19.5324 9.67586 19.6209L7.99986 19.9839C7.6306 20.1186 7.21674 20.0263 6.93964 19.7476C6.66253 19.4688 6.57273 19.0544 6.70958 18.686C6.84644 18.3175 7.18498 18.0622 7.57686 18.0319L9.25186 17.6699C9.82117 17.5088 10.414 17.8363 10.5809 18.4039V18.4039Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 14.9919C1.89543 14.9919 1 14.0965 1 12.9919V9.99194C1 8.88737 1.89543 7.99194 3 7.99194C4.10457 7.99194 5 8.88737 5 9.99194V12.9919C5 14.0965 4.10457 14.9919 3 14.9919Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 14.9919C11.8954 14.9919 11 14.0965 11 12.9919V9.99194C11 8.88737 11.8954 7.99194 13 7.99194C14.1046 7.99194 15 8.88737 15 9.99194V12.9919C15 14.0965 14.1046 14.9919 13 14.9919Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 10C14.25 10.4142 14.5858 10.75 15 10.75C15.4142 10.75 15.75 10.4142 15.75 10H14.25ZM0.25 10C0.25 10.4142 0.585786 10.75 1 10.75C1.41421 10.75 1.75 10.4142 1.75 10H0.25ZM15.75 13.0023C15.7513 12.5881 15.4165 12.2513 15.0023 12.25C14.5881 12.2487 14.2513 12.5835 14.25 12.9977L15.75 13.0023ZM9.51128 18.8951C9.10706 18.9855 8.85267 19.3865 8.94309 19.7907C9.0335 20.1949 9.43449 20.4493 9.83872 20.3589L9.51128 18.8951ZM15.75 10V8H14.25V10H15.75ZM15.75 8C15.75 3.71979 12.2802 0.25 8 0.25V1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75ZM8 0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75C1.75 4.54822 4.54822 1.75 8 1.75V0.25ZM0.25 8V10H1.75V8H0.25ZM14.25 12.9977C14.2414 15.8289 12.2742 18.2771 9.51128 18.8951L9.83872 20.3589C13.2853 19.588 15.7393 16.534 15.75 13.0023L14.25 12.9977Z'
        fill={color}
      />
    </svg>
  );
};

export default ChatIcon;
