import axios, { AxiosResponse } from 'axios'; // ** Config
import qs from 'qs';
import authConfig from '../configs/auth';
import { BaseAPIResponse } from './model';

const storedToken = typeof window !== 'undefined' && window.localStorage.getItem(authConfig.storageTokenKeyName)!;

const clientInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Authorization: storedToken,
    'X-py-webservice-api-key': process.env.PY_WS_API_KEY,
  },
});

clientInstance.interceptors.response.use(
  (response: AxiosResponse<BaseAPIResponse>) => response.data.data || response,
  error => Promise.reject(error.response?.data),
);

export function setReduxValue(value: string) {
  clientInstance.defaults.headers.common['customerId'] = value;
}

export const httpGet = (url: string, params?: object, headers?: object, useNextServer?: boolean): Promise<any> =>
  new Promise((resolve, reject) => {
    clientInstance({
      method: 'GET',
      url: useNextServer ? `${window.location.origin}${url}` : url,
      params,
      headers,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

export const httpGetSerialized = (url: string, params?: object): Promise<any> =>
  new Promise((resolve, reject) => {
    clientInstance({
      method: 'GET',
      url,
      params,
      paramsSerializer: params => qs.stringify(params),
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

export function setHttpToken(token: string) {
  clientInstance.defaults.headers['Authorization'] = token;
}

export async function httpPost(url: string, data: unknown, headers?: any) {
  if (!url) return;
  return new Promise<any>((resolve, reject) => {
    return clientInstance({
      method: 'POST',
      url,
      data,
      headers,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function httpPatch<RequestDataType = any, ResponseDataType = any>(
  url: string,
  data: RequestDataType,
  headers?: object,
) {
  if (!url) return;
  return new Promise<AxiosResponse<ResponseDataType, any>>((resolve, reject) => {
    return clientInstance({
      method: 'PATCH',
      url,
      data,
      headers,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function httpDelete(url: string, headers?: object) {
  return new Promise<any>((resolve, reject) => {
    return clientInstance({
      method: 'DELETE',
      url,
      headers,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
