import IconStyles from 'apps/webapp/src/types/iconStyles';

const SettingIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.08907 10.115C6.97036 10.115 6.06348 9.20807 6.06348 8.08937C6.06348 6.97067 6.97036 6.06378 8.08907 6.06378C9.20777 6.06378 10.1147 6.97067 10.1147 8.08937C10.1147 8.62659 9.90125 9.14181 9.52137 9.52168C9.1415 9.90155 8.62629 10.115 8.08907 10.115Z'
        stroke='#6F6F6F'
        strokeWidth='1.41791'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4746 12.2045V10.8454C13.4746 10.5088 13.6083 10.186 13.8463 9.94803L14.8074 8.98689C15.303 8.4913 15.303 7.6878 14.8074 7.19222L13.8463 6.23107C13.6083 5.99309 13.4746 5.67031 13.4746 5.33374V3.97356C13.4746 3.27269 12.9064 2.70452 12.2056 2.70452H10.8454C10.5088 2.70452 10.186 2.57082 9.94803 2.33283L8.98689 1.37169C8.4913 0.876105 7.6878 0.876105 7.19222 1.37169L6.23107 2.33283C5.99309 2.57082 5.67031 2.70452 5.33374 2.70452H3.97356C3.63681 2.70452 3.31387 2.83836 3.07586 3.07657C2.83784 3.31478 2.70425 3.63782 2.70452 3.97457V5.33374C2.70452 5.67031 2.57082 5.99309 2.33283 6.23107L1.37169 7.19222C0.876105 7.6878 0.876105 8.4913 1.37169 8.98689L2.33283 9.94803C2.57082 10.186 2.70452 10.5088 2.70452 10.8454V12.2045C2.70452 12.9054 3.27269 13.4736 3.97356 13.4736H5.33374C5.67031 13.4736 5.99309 13.6073 6.23107 13.8453L7.19222 14.8064C7.6878 15.302 8.4913 15.302 8.98689 14.8064L9.94803 13.8453C10.186 13.6073 10.5088 13.4736 10.8454 13.4736H12.2045C12.5413 13.4738 12.8643 13.3403 13.1025 13.1022C13.3407 12.8642 13.4746 12.5413 13.4746 12.2045Z'
        stroke='#6F6F6F'
        strokeWidth='1.41791'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SettingIcon;
