import IconStyles from 'apps/webapp/src/types/iconStyles';

const EmailItalicIcon = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='10' viewBox='0 0 9 10' fill='none'>
      <path d='M3 0V2.14286H4.6575L2.0925 7.85714H0V10H6V7.85714H4.3425L6.9075 2.14286H9V0H3Z' fill={color} />
    </svg>
  );
};

export default EmailItalicIcon;
