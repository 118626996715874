import IconStyles from 'apps/webapp/src/types/iconStyles';

const EmailImage = (styles: IconStyles) => {
  const color = styles.color || '#6F6F6F';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.92193 1H4.07621C3.25555 1.00453 2.47031 1.33489 1.89323 1.9184C1.31615 2.50191 0.994512 3.29077 0.999071 4.11143V7.88857C0.999077 8.00958 1.00599 8.13049 1.01979 8.25071C1.19278 9.81024 2.50714 10.9925 4.07621 11H7.92193C8.74259 10.9955 9.52783 10.6651 10.1049 10.0816C10.682 9.49809 11.0036 8.70923 10.9991 7.88857V4.11143C11.0036 3.29077 10.682 2.50191 10.1049 1.9184C9.52783 1.33489 8.74259 1.00453 7.92193 1Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.89421 3.5328C3.89421 3.7328 3.73207 3.89494 3.53206 3.89494C3.33206 3.89494 3.16992 3.7328 3.16992 3.5328C3.16992 3.33279 3.33206 3.17065 3.53206 3.17065C3.73207 3.17065 3.89421 3.33279 3.89421 3.5328Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.01953 8.25075C1.40281 7.90717 1.75888 7.53439 2.08453 7.13575C2.56643 6.48379 3.42044 6.22753 4.18167 6.50647C5.98453 7.0779 7.59382 9.17718 9.13739 8.08575C9.70428 7.65318 10.143 7.07472 10.4067 6.41218C10.5618 6.04824 10.7607 5.70453 10.9988 5.38861'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EmailImage;
