import { ObjectId } from 'bson';
import { CommonType, LANGUAGE } from '../common';
import { NotificationConfigMapType } from '../notification';
import { RoleType } from '../rbac';

export enum TIME_FORMAT {
  HOURS_12 = '12',
  HOURS_24 = '24',
}

export type collectorCharacteristicsType = {
  reponsiveness: string;
  tone: string[];
  eocEffectiveness: {
    'Difficult to collect': string;
    'Easy to collect': string;
  };
};

export type UserDtoType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  customerId: string;
  type: USER_TYPE;
  notificationConfigs: NotificationConfigMapType;
  googleCredentials: GoogleCredentialsType;
  googleHistoryId: string;
  settings?: userSettingsType;
  contactNumber: string;
  googleEmail: string;
  role?: RoleType;
  collectorCharacteristics?: collectorCharacteristicsType;
  profileScore?: number;
  sentimentScore?: number;
};

export type UserType = CommonType & UserDtoType;

export type UserDocumentType = Omit<UserType, 'role'> & {
  roleId: ObjectId;
};

export type GoogleCredentialsType = {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  expiry_date: number;
};

export enum USER_TYPE {
  ADMIN = 'ADMIN',
  COLLECTION_MANAGER = 'COLLECTION_MANAGER',
  COLLECTION_AGENT = 'COLLECTION_AGENT',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_COLLECTION_AGENT = 'SUPER_COLLECTION_AGENT',
  SUPER_COLLECTION_MANAGER = 'SUPER_COLLECTION_MANAGER',
  GUEST = 'GUEST',
}

export const getUserFullName = (user?: UserType, defaultStr = '-'): string => {
  if (!user) {
    return defaultStr;
  }
  return `${user.firstName} ${user.lastName}`;
};

export type userSettingsType = {
  colorTheme: string;
  timeFormat: TIME_FORMAT;
  theme: 'dark' | 'light';
  language: LANGUAGE;
};
