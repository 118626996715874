export enum TEMPLATE_PARAMETER {
  DUE_DATE = 'DueDate',
  P2P_DATE = 'P2PDate',
  DUE_AMOUNT = 'DueAmount',
  TRANSACTION_AMOUNT = 'TransactionAmount',
  NUMBER_OF_INVOICES = 'Invoices',
  NAME = 'CustomerName',
  DPD = 'DPD',
  COLLECTION_AGENT_NAME = 'CollectionAgentName',
  CLIENT_DISPLAY_NAME = 'ClientName',
  CLABE = 'CLABE',
  BANK = 'Bank',
  BANK_1 = 'Bank_1',
  BANK_2 = 'Bank_2',
  BANK_ACCOUNT_NUMBER_1 = 'Bank_Acc_1',
  BANK_ACCOUNT_NUMBER_2 = 'Bank_Acc_2',
  ACCOUNT_NUMBER_1 = 'Account_1',
  ACCOUNT_NUMBER_2 = 'Account_2',
}
