import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerType, Prediction, PredictionType } from '@finance-ops/types';
import { RootState } from '.';

const predictionsEntityAdapter = createEntityAdapter<PredictionType>({
  selectId: entity => entity.customerId,
});

// export const getPrediction = createAsyncThunk(
//   'api/getPrediction',
//   async ({ counterPartyId, customPrompt, taskId }: any) => {
//     const data: PredictionApiRequest = {
//       Request: {
//         payload: [
//           {
//             counterPartyId,
//             customPrompt,
//             primaryKey: uuid(),
//             sessionID: taskId
//           }
//         ]
//       }
//     };
//     const response = await httpPost('http://54.85.137.118:8080/ai_chatbot/predictions', data);
//     return response.json();
//   }
// );

const predictionsSlice = createSlice({
  name: 'predictions',
  initialState: predictionsEntityAdapter.getInitialState(),
  reducers: {
    upsertOnePrediction: (state, action) => {
      if (!action.payload.predictions[0].reply) return;
      const prediction = state.entities[action.payload.customerId];
      if (prediction) {
        const newPrediction: Prediction = {
          customPrompt: action.payload.customPrompt,
          primaryKey: action.payload.predictions[0].primaryKey,
          reply: action.payload.predictions[0].reply,
          suggestedActions: action.payload.predictions[0].suggestedActions,
        };
        prediction.predictions.unshift(newPrediction);
        const upsertPrediction: PredictionType = {
          ...action.payload,
          predictions: prediction.predictions,
        };
        predictionsEntityAdapter.upsertOne(state, upsertPrediction);
      } else {
        const newPrediction: Prediction = {
          customPrompt: action.payload.customPrompt,
          primaryKey: action.payload.predictions[0].primaryKey,
          reply: action.payload.predictions[0].reply,
          suggestedActions: action.payload.predictions[0].suggestedActions,
        };
        const newPredictionType: PredictionType = {
          clientId: action.payload.clientId,
          customerId: action.payload.customerId,
          loading: false,
          predictions: [newPrediction],
          customPrompt: action.payload.customPrompt,
        };
        predictionsEntityAdapter.addOne(state, newPredictionType);
      }
    },
    setLoadingPredictionForCounterParty: (state, action: PayloadAction<CustomerType>) => {
      const prediction = state.entities[action.payload._id.toString()];
      if (prediction) {
        prediction.loading = true;
      } else {
        const newPredictionType: PredictionType = {
          clientId: action.payload.clientId?.toString() ?? '',
          customerId: action.payload._id.toString(),
          loading: true,
          predictions: [],
          customPrompt: '',
        };
        predictionsEntityAdapter.addOne(state, newPredictionType);
      }
    },
    clearPrediction: predictionsEntityAdapter.removeOne,
  },
  // extraReducers: builder => {
  //   builder.addCase(getPrediction.pending, (state, action) => {
  //     // make loading true for the counterparty id
  //   });
  //   builder.addCase(getPrediction.fulfilled, action => {
  //     // make loading false for the counterparty id and add the prediction
  //   });
  //   builder.addCase(getPrediction.rejected, action => {
  //     // make loading false for the counterparty id
  //   });
  // }
});

export const predictionsSelectors = predictionsEntityAdapter.getSelectors((state: RootState) => state.predictions);

export const { upsertOnePrediction, clearPrediction, setLoadingPredictionForCounterParty } = predictionsSlice.actions;

export const selectPredictionByCounterPartyId = createSelector(
  [predictionsSelectors.selectAll, (_, customerId) => customerId],
  (predictionsAll, customerId) => {
    const prediction: PredictionType | undefined = predictionsAll.find(
      prediction => prediction !== undefined && prediction.customerId === customerId,
    );
    return prediction;
  },
);

export default predictionsSlice.reducer;
