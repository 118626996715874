import {
  DashboardTrendsInterface,
  dashboardInitialValues,
  DashboardGraphTypes,
  DashboardInterface,
  RecentPayments,
  TopDebtor,
  PaymentMethod,
  PaymentStatusTypes,
  UI_MODE,
  overallSummaryAggregationType,
  defaultOverallSummaryAggregation,
} from '@finance-ops/types';
import { ACTION_CENTER_STATUS_TYPES } from '@finance-ops/constants';
import { createSlice } from '@reduxjs/toolkit';

interface DashboardSliceInterface {
  loading: boolean;
  aggregation: DashboardInterface;
  trends: DashboardTrendsInterface;
  aggregation30DaysBack: DashboardInterface;
  uiMode: UI_MODE;
  selectedClientId: string | undefined;
  quickSightEmbedUrl?: string | null;
  quickSightKeys: string[];
  typeCounts: { copilotCount: number; autopilotCount: number };
  formulaCards: {
    cei: {
      prev: number;
      current: number;
    };
    ctc: {
      prev: number;
      current: number;
    };
    rr: {
      prev: number;
      current: number;
    };
  };
  actionCenter: { data: { _id: string; count: number }[]; loading: boolean };
  customerStatus: { data: { value: number; text: string }[]; loading: boolean };
  topDebtors: { loading: boolean; data: TopDebtor[] };
  recentPayments: { loading: boolean; data: RecentPayments[] };
  collections: { loading: boolean; data: Array<{ name: string; data: Array<{ x: Date | string; y: number }> }> };
  delinquency: { loading: boolean; data: { x: Date | string; y: number }[] };
  paymentTypes: { loading: boolean; data: Array<{ _id: PaymentMethod; count: number }> };
  paymentStatus: { loading: boolean; data: Array<{ _id: PaymentStatusTypes; count: number }> };
  //REMOVE RECEIVABLES
  receivables: { loading: boolean; data: { openAmount: number; collectedAmount: number } };
  overview: {
    loading: boolean;
    data: overallSummaryAggregationType;
  };
}

const initialState: DashboardSliceInterface = {
  loading: true,
  aggregation: dashboardInitialValues,
  aggregation30DaysBack: dashboardInitialValues,
  trends: {
    currentDueAmountTrend: 0,
    pastDueAmountTrend: 0,
    delinquencyDaysTrend: 0,
    totalDueAmountTrend: 0,
  },
  uiMode: UI_MODE.COPILOT,
  selectedClientId: undefined,
  quickSightEmbedUrl: null,
  quickSightKeys: [],
  typeCounts: { copilotCount: 0, autopilotCount: 0 },
  formulaCards: {
    cei: {
      prev: 0,
      current: 0,
    },
    ctc: {
      prev: 0,
      current: 0,
    },
    rr: {
      prev: 0,
      current: 0,
    },
  },
  actionCenter: {
    loading: true,
    data: [
      { count: 0, _id: ACTION_CENTER_STATUS_TYPES.OPEN },
      { count: 0, _id: ACTION_CENTER_STATUS_TYPES.IN_PROGRESS },
      { count: 0, _id: ACTION_CENTER_STATUS_TYPES.CLOSED },
    ],
  },
  customerStatus: {
    loading: false,
    data: [
      { value: 0, text: 'Active' },
      { value: 0, text: 'Closed' },
      { value: 0, text: 'Dispute' },
    ],
  },
  topDebtors: { loading: false, data: [] },
  recentPayments: { loading: false, data: [] },
  collections: { loading: false, data: [{ name: 'Collections', data: [] }] },
  delinquency: { loading: false, data: [] },
  paymentTypes: { loading: false, data: [] },
  paymentStatus: { loading: false, data: [] },
  receivables: { loading: false, data: { openAmount: 0, collectedAmount: 0 } },
  overview: {
    loading: true,
    data: defaultOverallSummaryAggregation,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: () => {
      return initialState;
    },
    resetDashboardAnalytics: state => {
      state.aggregation = initialState.aggregation;
      state.aggregation30DaysBack = initialState.aggregation30DaysBack;
      state.trends = initialState.trends;
    },
    setDashboardData: (state, action) => {
      return { ...state, ...action.payload, loading: false };
    },
    setSelectedClientId: (state, action) => {
      state.selectedClientId = action.payload;
    },
    setQuickSightEmbedUrl: (state, action) => {
      state.quickSightEmbedUrl = action.payload;
    },
    setQuickSightDashboardKeys: (state, action) => {
      state.quickSightKeys = action.payload;
    },
    setDashboardToLoading: (state, action) => {
      state.loading = true;
    },
    setCustomersTypeCount: (state, action) => {
      state.typeCounts = action.payload;
    },
    setGraphLoading: (state, action) => {
      switch (action.payload.graphType) {
        case DashboardGraphTypes.ACTION_CENTER_GRAPH:
          state.actionCenter.loading = true;
          break;
        case DashboardGraphTypes.CUSTOMER_STATUS_GRAPH:
          state.customerStatus.loading = true;
          break;
        case DashboardGraphTypes.TOP_DEBTORS:
          state.topDebtors.loading = true;
          break;
        case DashboardGraphTypes.DELINQUENCY_GRAPH:
          state.delinquency.loading = true;
          break;
        case DashboardGraphTypes.RECENT_PAYMENTS:
          state.recentPayments.loading = true;
          break;
        case DashboardGraphTypes.PAYMENT_TYPES_GRAPH:
          state.paymentTypes.loading = true;
          break;
        case DashboardGraphTypes.PAYMENT_STATUS_GRAPH:
          state.paymentStatus.loading = true;
          break;
        //REMOVE RECEIVABLES
        case DashboardGraphTypes.RECEIVABLES_GRAPH:
          state.receivables.loading = true;
          break;
      }
    },
    setLiveGraph: (state, action) => {
      const data = action.payload.data ?? [];
      switch (action.payload.graphType) {
        case DashboardGraphTypes.ACTION_CENTER_GRAPH:
          state.actionCenter = { loading: false, data };
          break;
        case DashboardGraphTypes.CUSTOMER_STATUS_GRAPH:
          state.customerStatus = { loading: false, data };
          break;
        case DashboardGraphTypes.TOP_DEBTORS:
          state.topDebtors = { loading: false, data };
          break;
        case DashboardGraphTypes.DELINQUENCY_GRAPH:
          state.delinquency = { loading: false, data };
          break;
        case DashboardGraphTypes.RECENT_PAYMENTS:
          state.recentPayments = { loading: false, data };
          break;
        case DashboardGraphTypes.PAYMENT_TYPES_GRAPH:
          state.paymentTypes = { loading: false, data };
          break;
        case DashboardGraphTypes.PAYMENT_STATUS_GRAPH:
          state.paymentStatus = { loading: false, data };
          break;
        //REMOVE RECEIVABLES
        case DashboardGraphTypes.RECEIVABLES_GRAPH:
          state.receivables = { loading: false, data };
          break;
      }
    },
    setCollections: (state, action) => {
      state.collections = { loading: action.payload.loading, data: Object.values(action.payload.data) };
    },
    setUIMode: (state, action) => {
      // Resetting the state because the copilot status is being changed, so the data needs to be re-fetched
      return { ...initialState, uiMode: action.payload };
    },
    setOverviewData: (state, action) => {
      state.overview = action.payload;
    },
  },
});

export const {
  resetDashboard,
  setDashboardData,
  setSelectedClientId,
  resetDashboardAnalytics,
  setQuickSightEmbedUrl,
  setQuickSightDashboardKeys,
  setDashboardToLoading,
  setCustomersTypeCount,
  setLiveGraph,
  setCollections,
  setUIMode,
  setGraphLoading,
  setOverviewData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
