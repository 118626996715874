import IconStyles from 'apps/webapp/src/types/iconStyles';

const NotePen = (styles: IconStyles) => {
  const color = styles?.color || '#3E3E3E';
  return (
    <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.61571 1.00001H11.5311C11.5925 1.00001 11.6536 1.00394 11.7143 1.01179C14.1221 1.18018 15.9918 3.17843 16 5.59215V12.4804C15.9911 15.0219 13.9258 17.0762 11.3843 17.0714H5.61571C3.07376 17.0762 1.00827 15.0212 1 12.4793V5.59215C1.00827 3.05021 3.07376 0.995266 5.61571 1.00001Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.05078 4.35243L4.45328 5.63814C4.29897 5.7852 4.21217 5.98941 4.21339 6.20256C4.21461 6.41572 4.30373 6.61893 4.45971 6.76421L5.49149 8.006L9.80507 13.1928C9.87696 13.2763 9.97328 13.3352 10.0804 13.361L12.2233 13.8506C12.5066 13.8667 12.7533 13.659 12.7858 13.3771L12.6861 11.2921C12.6788 11.185 12.6358 11.0836 12.564 11.0039L8.35007 5.936L7.21435 4.57207C6.94093 4.21014 6.43733 4.11507 6.05078 4.35243Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9999 6.38493C16.4141 6.38493 16.7499 6.04915 16.7499 5.63493C16.7499 5.22072 16.4141 4.88493 15.9999 4.88493V6.38493ZM11.7142 5.63493H10.9642C10.9642 6.04915 11.3 6.38493 11.7142 6.38493V5.63493ZM12.4642 1.01172C12.4642 0.597505 12.1284 0.261719 11.7142 0.261719C11.3 0.261719 10.9642 0.597505 10.9642 1.01172H12.4642ZM9.08866 6.06898C9.15974 5.66091 8.88656 5.27248 8.47849 5.20139C8.07043 5.13031 7.682 5.40348 7.61091 5.81155L9.08866 6.06898ZM5.60202 7.26099C5.19235 7.1998 4.81064 7.48229 4.74944 7.89196C4.68824 8.30162 4.97073 8.68334 5.3804 8.74453L5.60202 7.26099ZM15.9999 4.88493H11.7142V6.38493H15.9999V4.88493ZM12.4642 5.63493V1.01172H10.9642V5.63493H12.4642ZM7.61091 5.81155C7.44571 6.75989 6.55407 7.40322 5.60202 7.26099L5.3804 8.74453C7.13781 9.00706 8.78371 7.81953 9.08866 6.06898L7.61091 5.81155Z'
        fill={color}
      />
    </svg>
  );
};

export default NotePen;
