export enum TaskDrawerSections {
  details = 'details',
  conversations = 'conversations',
  transactions = 'transactions',
  transactions_ = 'transactions_',
  invoices = 'invoices',
  activityLog = 'activity-log',
  relatedParties = 'related-parties',
  actions = 'actions',
}

export type ActiveTaskSection =
  | TaskDrawerSections.details
  | TaskDrawerSections.conversations
  | TaskDrawerSections.transactions
  | TaskDrawerSections.transactions_
  | TaskDrawerSections.activityLog
  | TaskDrawerSections.relatedParties
  | TaskDrawerSections.actions
  | TaskDrawerSections.invoices;
